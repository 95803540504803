import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import { useDispatch } from 'react-redux';

const { TextArea } = Input;

function VideoRecorderModal(props) {
    const { onClose } = props;
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [key, setKey] = useState('');


    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };
    const dispatch = useDispatch();
    const handleOk = () => {
        if(key !== undefined && key !== ''){
            dispatch({type: 'SEND_MESSAGE', payload: {message: key, type: 'video-recorder', data: {"title": key, "deviceKey": key}}})
        }
        setIsModalVisible(false);
    };

    return (
        <Modal title="Video Recorder"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row>
                <Col span={24}>
                    <Input value={key} onChange={(e)=>{setKey(e.target.value)}} placeholder="Device key" />
                </Col>
            </Row>
            <Row className="action-grp mt-4">
                <Col span={14} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full" onClick={handleOk}>Add</Button>
                </Col>
                <Col span={10} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default VideoRecorderModal;
