import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {Input, Button, Row, Col, Radio, Checkbox} from 'antd';
import { SearchOutlined, DownOutlined, CloseOutlined } from '@ant-design/icons';
import _ from "lodash";
import Icon from '@ant-design/icons';


function SettingsPop(props) {
    const { setActivePop, settings, updateSettings } = props;
    // const [track, setTrack] = useState('auto');
    // const [breadcrumbs, setBreadcrumbs] = useState(['Show breadcrumbs']);

    return (
        <div className="pop-content setting-popup">
            <Row className="d-flex mb-4">
                <Col><h3>Settings</h3></Col>
                <Col><CloseOutlined className="close-icon" onClick={() => setActivePop()}/></Col>
            </Row>
            <Row>
                <Col span={24}>
                    <h5>Select an Option</h5>
                </Col>
            </Row>
            <hr />
            <Row className="d-flex mt-2">
                <Col span={12} className="pr-2"><Button onClick={()=>{updateSettings('track', 'auto')}} className={`beam-btn w-full ${settings.track === 'auto' ? 'cover green-btn': ''}`}>Auto Track</Button></Col>
                <Col span={12} className="pl-2"><Button onClick={()=>{updateSettings('track', 'manual')}} className={`beam-btn w-full ${settings.track === 'manual' ? 'cover green-btn': ''}`}>Manual</Button></Col>
            </Row>
            <hr />
            <Row className="mt-3">
                <Col span={24}>
                    {/*<Radio.Group defaultValue={1} className="plain-radios">*/}
                    {/*     /!*<Radio value={2} className="green-radio">Display Details</Radio>*!/*/}
                    {/*     <Radio value={3} className="green-radio">Show breadcrumbs</Radio>*/}
                    {/*</Radio.Group>*/}
                    <Checkbox.Group options={['Show breadcrumbs']} value={settings.breadcrumbs} onChange={(checked)=>{updateSettings('breadcrumbs', checked)}} />
                </Col>
            </Row>
        </div>
    )
}

export default SettingsPop;
