import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import {useDispatch, useSelector} from 'react-redux';



const { TextArea } = Input;

function CameraVideoModal(props) {
    const { onClose } = props;
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [url, setUrl] = useState();
    const { snapshotUrl } = useSelector(state => state.dashboard);
    let interval;

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };
    const dispatch = useDispatch();
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const toDataURL = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    const toCanvasDataURL = (url, callback) => {
        const img = new Image();
        img.crossOrigin="anonymous";
        img.onload = function () {
            let canvas = document.createElement('CANVAS');
            let ctx = canvas.getContext('2d');
            canvas.height = this.naturalHeight;
            canvas.width = this.naturalWidth;
            ctx.drawImage(this, 0, 0);
            let dataURL = canvas.toDataURL();
            callback(dataURL);
        };
        img.src = url;
    }

    useEffect(() => {
        dispatch({type: 'GET_VIDEO_SNAPSHOT', payload: {id: props.videoObj.deviceKey}});
        // toCanvasDataURL('https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg', function(result){
        //     setUrl(result)
        // })
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    },[]);

    useEffect(() => {
        console.log('snap shot url new updated here', snapshotUrl);
        if(snapshotUrl) {
            // dispatch({type: 'GET_VIDEO_SNAPSHOT', payload: {id: props.videoObj.deviceKey}});
        }
        if(snapshotUrl && snapshotUrl.URL){
            toCanvasDataURL(process.env.REACT_APP_VIDEO_URI + snapshotUrl.URL, function(dataUrl) {
                // console.log('RESULT:', dataUrl)
                setUrl(dataUrl)
            })
            // setUrl(process.env.REACT_APP_VIDEO_URI + snapshotUrl.URL)
        }
    },[snapshotUrl]);

    return (
        <Modal title="Video Stream"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width='760px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row className="custom-table mt-4">
                <Col span={24} style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    minHeight: '464px'
                }}>
                    {url ?
                        <img style={{width: '100%', height: '100%'}} src={url} key={url}/>:
                        <Spin tip="Waiting for stream..."/>
                    }
                </Col>
            </Row>
        </Modal>
    )
}

export default CameraVideoModal;
