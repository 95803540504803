import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import { SelectDateTimeModal } from '.';


function IotDeviceModal(props) {
    const { onClose } = props;

    const [isModalVisible, setIsModalVisible] = useState(true);
    const [dateTimeModalDisplayed, setDateTimeModalDisplayed] = useState(false);

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };

    const handleOk = () => {
        setIsModalVisible(false);
    }

    return (
        <div>
          <Modal title="IoT Device" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='350px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row className="mb-3">
                <Col span={24}><div className="gps-report"><label>GPS</label>GPS Reporting in-progress...</div></Col>
            </Row>
            <hr />
            <Row className="mb-3">
                <Col span={24}><Button onClick={() => setDateTimeModalDisplayed(true)} className="beam-btn w-full">Generate Report</Button></Col>
            </Row>
            <Row className="mb-3">
                <Col span={24}><Button className="beam-btn w-full">Start Tracking</Button></Col>
            </Row>
            <Row className="mb-3">
                <Col span={24}><Button className="beam-btn w-full">Edit</Button></Col>
            </Row>
            <Row className="mb-3">
                <Col span={24}><Button className="beam-btn w-full">Remove</Button></Col>
            </Row>
            <Row className="mb-3">
                <Col span={24}><Button className="beam-btn w-full">Copy</Button></Col>
            </Row>
            <Row className="mb-3">
                <Col span={24}><Button className="beam-btn w-full">Download JSON</Button></Col>
            </Row>
            <Row className="mb-3">
                <Col span={24}><Button className="beam-btn w-full">Download Excel</Button></Col>
            </Row>
            <Row className="mb-3">
                <Col span={24}><Button className="beam-btn w-full">Alert</Button></Col>
            </Row>
            <Row>
                <Col span={24}><Button className="beam-btn w-full">Lock Tracking</Button></Col>
            </Row>
        </Modal>
        {dateTimeModalDisplayed && <SelectDateTimeModal onClick={() => setDateTimeModalDisplayed(false)} /> }
        </div>
    )
}

export default IotDeviceModal;
