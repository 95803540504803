import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, Dropdown, Menu, Switch, Radio } from 'antd';
import { SearchOutlined, EllipsisOutlined } from '@ant-design/icons';
import _ from "lodash";
import { useDispatch, useSelector } from 'react-redux';
import { getPagesRequestPublishTopic } from 'hooks/MQTTConstants';


function PeopleModal(props) {
	const { selectedItem } = useSelector(state => state.search);
	const { users } = useSelector(state => state.auth);
    const { onClose, client } = props;
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState(selectedItem.members || []);
    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };

    const dispatch = useDispatch();
    const handleOk = (users) => {
        client.publish(`beam/connection/${localStorage.getItem('CID')}/service/live/updatePage`, JSON.stringify({
            pageId: selectedItem.pageId,
            operation: "replace",
            path: "$.persistent.static.members",
            value: users || selectedUsers
        }), error => {
            if (error) {
                console.log('Publish error: ', error);
            } else {
                setTimeout(() => client.publish(getPagesRequestPublishTopic(), JSON.stringify({
                    paths: [
                      "$.persistent"
                    ],
                    returnPaths: [
                      "$.persistent.static"
                    ],
                    pagination: {
                      offset: 0,
                      limit: 200
                    }
                  }), { qos: 1 }, error => {
                    if (error) {
                        console.log('Publish error: ', error);
                    }
                }), 1000)
            }
        });
        if(!users) {
            dispatch({type: 'SEND_MESSAGE', payload: {message: 'People Item added', type: 'People'}})
            setIsModalVisible(false);
        }
    }

	const addUser = (user) => {
        const users = [...selectedUsers, {rootUserID: user.id, chat_name: user.name, email: user.email, phone: user.phone }]
		setSelectedUsers(users);
        handleOk(users);
	}

	const removeUser = (user) => {
		selectedUsers.splice(selectedUsers.findIndex(item => item.rootUserID === (user.id || user.rootUserID)), 1);
        const users = [...selectedUsers];
		setSelectedUsers(users);
        handleOk(users);
	}
    const editMenu = (
        <div className="menu-container edit-menu ml-auto people-dropdown">
            <div className="menu-item">
                <Radio.Group defaultValue="Edit">
                    {["Edit", "Read", "Write"].map(item => 
                        <Radio.Button value={item} key={item}>{item}</Radio.Button>
                    )}
                </Radio.Group>
            </div>
            <div className="menu-item">
                <p className="mr-3">Classify</p> <Switch defaultChecked />
            </div>
        </div>
    );

    return (
        <Modal title="" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='480px'
            className="beam-modal scrollable"
            footer={[]}
            closable={false}
            destroyOnClose={true}
        >
            <Row className="d-flex mt-1 mb-4">
                <Col><h3>People</h3></Col>
                <Col>
                    <Button className="beam-btn blue-btn cover">Create new Group</Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Input placeholder="Search" suffix={
                      <SearchOutlined />
                    } />
                </Col>
            </Row>
            <Row className="scrollable-body mt-4">
                <Col span={24}>
                    <Row className="d-flex mb-4">
                        <Col><h5>On page</h5></Col>
                        <Col><p>{ selectedUsers.length || 0} person</p></Col>
                    </Row>
                    {
                        selectedUsers && selectedUsers.map(member => 
                            <Row key={member.rootUserID} className="person-item">
                                <Col span={4}><img src='/images/users/user.png' width="45" height="45" /></Col>
                                <Col span={16}>
                                    <h5>{member.chat_name}</h5>
                                    <p>{member.email || "-"}</p>
                                    <p>{member.phone || "-"}</p>
                                </Col>
                                <Col span={4} className="t-right">
                                    <Button className="beam-btn orange-btn" onClick={() => removeUser(member)}>Remove</Button>
                                    <Dropdown overlay={editMenu} className="message-dropdown" placement="bottomRight">
                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}><EllipsisOutlined /></a>
                                    </Dropdown>
                                </Col>
                            </Row>
                        )
                    }
                    
                    <Row className="d-flex mt-4 mb-4">
                        <Col><h5>Add to page</h5></Col>
                        <Col><p>{users ? users.length : 0} people</p></Col>
                    </Row>
                    {users && users.map(user => !selectedUsers.find(item => item.rootUserID === user.id) && (
                        <Row className="person-item" key={user.id}>
                            <Col span={4}><img src={`/images/users/user-1.png`} width="45" height="45" /></Col>
                            <Col span={16}>
                                <h5>{user.name}</h5>
                                <p>{user.email || "-"}</p>
                                <p>{user.phone || "-"}</p>
                            </Col>
                            <Col span={4} className="t-right">
                                {
                                    selectedUsers.find(item => item.rootUserID === user.id) ? 
                                    <Button className="beam-btn orange-btn mr-2" onClick={() => removeUser(user)}>
                                        Remove
                                    </Button> : 
                                    <Button className="beam-btn green-btn" onClick={() => addUser(user)}>
                                        Add
                                    </Button>

                                }
                                {/* <Dropdown overlay={editMenu} className="message-dropdown" placement="bottomRight">
                                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}><EllipsisOutlined /></a>
                                </Dropdown> */}
                            </Col>
                        </Row>
                    ))}
                </Col>
            </Row>
            {/*<Row className="action-grp mt-4">
                            <Col span={14} className="pr-2">
                                <Button className="beam-btn green-btn cover w-full" onClick={handleOk}>Save</Button>
                            </Col>
                            <Col span={10} className="pl-2">
                                <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                            </Col>
                        </Row>*/}
        </Modal>
    )
}

export default PeopleModal;
