import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from "lodash";

const { TextArea } = Input;

function MediaEditModal(props) {
    const { onClose } = props;
    const [isModalVisible, setIsModalVisible] = useState(true);

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };

    const handleOk = () => {
        setIsModalVisible(false);
    }

    return (
        <Modal title="Edit IoT" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row>
                <Col span={24}>
                    <Input placeholder="Media Title" />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={24}>
                    <TextArea autoSize={{ minRows: 4, maxRows: 6 }} placeholder="Description" />
                </Col>
            </Row>
            <Row className="d-flex px-3">
                <Col>
                    <p>Optional</p>
                </Col>
                <Col>
                    <p>max. 500 character</p>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={24}>
                    <Select className="w-full" placeholder="Profile" options={[
                        {label: 'Profile', value: 'Profile'}
                    ]} />
                </Col>
            </Row>
            <Row className="action-grp d-flex mt-4">
                <Col span={8} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full" onClick={handleOk}>Save</Button>
                </Col>
                <Col span={8} className="pl-2 pr-2">
                    <Button className="beam-btn yellow-btn bordered w-full" onClick={handleOk}>Map</Button>
                    </Col>
                <Col span={8} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default MediaEditModal;
