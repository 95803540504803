import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Button, Dropdown } from "antd";
import { ReactSVG } from 'react-svg';
import { PAGE_TYPES } from 'utils/PageTypes';
import moment from 'moment';
import { Link } from 'react-router-dom';

function SearchCard(props) {

  const {active, onClick, item} = props;
  const type = item.type && item.type === PAGE_TYPES.BROADCAST ? 'orange' : undefined;
  let image = '';
  if(type==='orange'){
    // image='/images/emergency-icon.svg';
    image='/images/broadcast-icon.svg';
  } else if(type === 'green') {
    image='/images/car-icon.svg';
  } else {
    image='/images/community-blue-icon.svg';
  }

  let clsName = "search-card"
  if (active) {
    clsName += " active-card"
  }
  
  const stopPropagation = (e) => {
    e.stopPropagation();
    console.log(item.pageId);
  }

  const openInNewWindow = (e) => {
    e.stopPropagation();
    window.open(window.location.origin + `/page/${item.pageId}`, '_blank', 'toolbar=0,location=0,menubar=0');
  }
  const menu = (
    <ul className="user-dropdown">
      <li onClick={stopPropagation}>
          <span className="ml-3">Leave</span>
      </li>
      <li onClick={stopPropagation}>
          <span className="ml-3">Copy</span>
      </li>
      <li onClick={stopPropagation}>
          <span className="ml-3">Merge</span>
      </li>
      <li onClick={stopPropagation}>
          <Link to={`/page/${item.pageId}`} target="_blank" className="ml-3 text-white">Open New Tab</Link>
      </li>
      <li onClick={openInNewWindow}>
          <span className="ml-3">Open New Window</span>
      </li>

    </ul>
  );
  return (
    <div className={`${clsName} ${type}-card cursor-pointer`} onClick={onClick}>
      { item.count && <div className="spin-number"><span>{item.count}</span></div> }

      <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
        <ReactSVG onClick={stopPropagation} src={image} className="colorable mark-icon" />
      </Dropdown>
      <div className="d-flex w-full">
        <div className="ml-2">
          <h3>{item.title || `Page ${item.pageId}`}</h3>
          <Row>
            <Col className="card-info date-info mr-2">
                <ReactSVG src="/images/refresh-icon.svg" className="svg-icon" />
                <p className="text-black">{item.createdAt ? moment(item.createdAt).format('MM/DD/YYYY hh:mm a') : '03/02/21 11:33 PM'}</p>
            </Col>
            <Col className="card-info message-info">
              <ReactSVG src="/images/message-black-icon.svg" className="colorable svg-icon" />
              <p className="text-black">{item.lastMessage || 'last message'}</p>
            </Col>
          </Row>
        </div>
        {item.number && 
          <Button className="beam-btn ml-2">
            <ReactSVG src="/images/call-icon.svg" className="colorable" />
          </Button>
        }
      </div>
    </div>
  );

  // switch (type) {
  //   case 'orange': 
  //     return (
  //       <div className={`${clsName} ${type}-card cursor-pointer`} onClick={onClick}>
  //         <div className="spin-number"><span>15</span></div>
  //         <ReactSVG src={image} className="colorable mark-icon" />
  //         <div className="ml-2 w-full">
  //           <h3>Emergency page name</h3>
  //           <Row>
  //             <Col className="card-info date-info mr-2">
  //                 <ReactSVG src="/images/refresh-icon.svg" className="svg-icon" />
  //                 <p>{item.date}</p>
  //             </Col>
  //             <Col className="card-info message-info">
  //               <ReactSVG src="/images/message-black-icon.svg" className="colorable svg-icon" />
  //               <p>{item.title}</p>
  //             </Col>
  //           </Row>
  //         </div>
  //       </div>
  //     )
  //   case 'green': 
  //     return (
  //       <div className={`${clsName} green-card cursor-pointer`} onClick={onClick}>
  //         <div className="spin-number"><span>15</span></div>
  //         <ReactSVG src={image} className="colorable mark-icon" />
  //         <div className="d-flex w-full">
  //           <div className="ml-2">
  //             <h3>03/02/21 - 11:33 AM</h3>
  //             <Row>
  //               <Col className="card-info date-info mr-2">
  //                   <ReactSVG src="/images/refresh-icon.svg" className="svg-icon" />
  //                   <p>03/02/21 - 12:36</p>
  //               </Col>
  //               <Col className="card-info message-info">
  //                 <ReactSVG src="/images/message-black-icon.svg" className="svg-icon"/>
  //                 <p>Last message long text</p>
  //               </Col>
  //             </Row>
  //           </div>
  //           <Button className="beam-btn ml-2">
  //             <ReactSVG src="/images/call-icon.svg" className="colorable" />
  //           </Button>
  //         </div>
  //       </div>
  //     )
  //   default: 
  //     return (
  //       <div className={`${clsName} blue-card cursor-pointer`} onClick={onClick}>
  //         <div className="spin-number"><span>15</span></div>
  //         <ReactSVG src="/images/community-icon.svg" className="colorable mark-icon" />
  //         <div className="ml-2 w-full">
  //           <h3>Very Long Title Lorem ipsum</h3>
  //           <Row>
  //             <Col className="card-info date-info mr-2">
  //                 <ReactSVG src="/images/refresh-icon.svg" className="svg-icon" />
  //                 <p>03/02/21 - 12:36</p>
  //             </Col>
  //             <Col className="card-info message-info">
  //               <ReactSVG src="/images/message-black-icon.svg" className="svg-icon" />
  //               <p>Last message long text</p>
  //             </Col>
  //           </Row>
  //         </div>
  //       </div>
  //     )
  // }
}

export default SearchCard;
