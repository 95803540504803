import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import {useDispatch, useSelector} from 'react-redux';

const tableRows = [
    {sensor: 'HEART_RATE', value: '94', unit: 'bpm', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'OXYGEN', value: '98', unit: '%', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'BLOOD_PRESSURE', value: '130/90', unit: 'mmHg', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'TEMPERATURE', value: '36.3', unit: 'C', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'STEPS', value: '630', unit: 'Steps', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'DISTANCE', value: '422', unit: 'm', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'CAL', value: '25', unit: 'calories', datetime: '26/01/2021 - 20:30:42'}
];

const { TextArea } = Input;

function RxSensorTableModal(props) {
    const { onClose } = props;
    const [isModalVisible, setIsModalVisible] = useState(true);
    const { rx } = useSelector(state => state.dashboard);
    const [sensors, setSensors] = useState([]);
    let interval;

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };
    const dispatch = useDispatch();
    const handleOk = () => {
        dispatch({type: 'SEND_MESSAGE', payload: {message: 'IoT Item added', type: 'IoT'}});
        setIsModalVisible(false);
    };

    useEffect(() => {
        dispatch({type: 'GET_RX', payload: {id: props.sensorObj.deviceKey}});
        interval = setInterval(()=>{
            dispatch({type: 'GET_RX', payload: {id: props.sensorObj.deviceKey}});
        },5000);
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    },[]);

    useEffect(() => {
        if(rx && rx.length > 0){
            let tempSensors = [];
            let latest = rx[0];
            if(latest.deviceId !== props.sensorObj.deviceKey)return;
            if(latest.heartRateValue && latest.heartRateValue !== '')tempSensors.push({sensor: 'Heart rate', value: latest.heartRateValue, unit: latest.heartRateUnit, timestamp: latest.heartRateTimestamp});
            if(latest.temperatureValue && latest.temperatureValue !== '')tempSensors.push({sensor: 'Temperature', value: latest.temperatureValue, unit: latest.temperatureUnit, timestamp: latest.temperatureTimestamp});
            if(latest.bloodOxygenValue && latest.bloodOxygenValue !== '')tempSensors.push({sensor: 'Blood oxygen', value: latest.bloodOxygenValue, unit: latest.bloodPressureUnit, timestamp: latest.bloodPressureTimestamp});
            if(latest.bloodPressureValue && latest.bloodPressureValue !== '')tempSensors.push({sensor: 'Blood pressure', value: latest.bloodPressureValue, unit: latest.bloodPressureUnit, timestamp: latest.bloodPressureTimestamp});
            if(latest.distanceValue && latest.distanceValue !== '')tempSensors.push({sensor: 'Distance', value: latest.distanceValue, unit: latest.distanceUnit, timestamp: latest.distanceTimestamp});
            if(latest.calValue && latest.calValue !== '')tempSensors.push({sensor: 'Calories', value: latest.calValue, unit: latest.calUnit, timestamp: latest.calTimestamp});
            if(latest.respiratoryRateValue && latest.respiratoryRateValue !== '')tempSensors.push({sensor: 'Respiratory rate', value: latest.respiratoryRateValue, unit: latest.respiratoryRateUnit, timestamp: latest.respiratoryRateTimestamp});
            if(latest.sleepValue && latest.sleepValue !== '')tempSensors.push({sensor: 'Sleep', value: latest.sleepValue, unit: latest.sleepUnit, timestamp: latest.sleepTimestamp});
            if(latest.stepsValue && latest.stepsValue !== '')tempSensors.push({sensor: 'Steps', value: latest.stepsValue, unit: latest.stepsUnit, timestamp: latest.stepsTimestamp});
            setSensors(tempSensors)
        }
    },[rx]);

    return (
        <Modal title="Rx Sensor"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width='760px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row className="custom-table mt-4">
                <Col span={24}>
                    <Row className="header">
                        <Col span={9}>Sensor</Col>
                        <Col span={6}>Value</Col>
                        <Col span={6}>Unit</Col>
                        <Col span={3}>Date & time</Col>
                    </Row>
                    {
                        sensors.map((item, idx) =>
                            <Row className="table-row d-flex" idx={idx} style={{ alignItems: 'baseline'}}>
                                <Col span={6}>{item.sensor}</Col>
                                <Col span={3}>{item.value}</Col>
                                <Col span={3}>{item.unit}</Col>
                                <Col span={3}>{new Date(item.timestamp).toLocaleString()}</Col>
                            </Row>
                        )
                    }
                </Col>
            </Row>
        </Modal>
    )
}

export default RxSensorTableModal;
