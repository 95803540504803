import {Circle, DirectionsRenderer, GoogleMap, Marker, Polygon, withGoogleMap, withScriptjs, InfoWindow} from "react-google-maps";
import React, {useEffect, useRef} from "react";
import MeasureTool from "measuretool-googlemaps-v3";
import {DrawingManager} from "react-google-maps/lib/components/drawing/DrawingManager";
import PinPop from "./PinPop";
import { compose, withProps, withStateHandlers, lifecycle } from "recompose";
import {MAP} from 'react-google-maps/lib/constants';
import {CloseOutlined} from "@ant-design/icons";
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");


const getPaths = (polygon) => {
    var polygonBounds = polygon.getPath().getArray();
    var bounds = [];
    for (var i = 0; i < polygonBounds.length; i++) {
        var point = {
            lat: polygonBounds[i].lat(),
            lng: polygonBounds[i].lng()
        };
        bounds.push(point);
    }
    bounds.push(bounds[0]);
    return bounds;
};

/*global google*/
const Map = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDNIyoSDV1wnJVW8wdHkiNG-eZ2bMcq0iY&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withStateHandlers(() => ({
        isOpen: false,
        activeMarker: null,
        directions: null,
        selectDirectionPin: null,
        drawingMode: null,
        measuringToolLoaded: false
    }), {
        onToggleOpen: ({ isOpen }) => (marker) => ({
            isOpen: !isOpen,
            activeMarker: marker
        }),
        showDirection: () => (result) => ({
            directions: result
        }),
        setDrawingMode: () => (mode) => ({
            drawingMode: mode
        }),
        setSelectDirectionPin: () => (pin) => ({
            selectDirectionPin: pin
        }),
        setMeasuringToolLoaded: () => (loaded) => ({
            measuringToolLoaded: loaded
        }),
    }),
    withScriptjs,
    withGoogleMap
)((props) => {
        const mapRef = useRef(null);
        let timeout;

        const handleDirections = (destination) => {
            const DirectionsService = new google.maps.DirectionsService();
            DirectionsService.route({
                origin: props.selectDirectionPin,
                destination: new google.maps.LatLng(destination.lat, destination.lng),
                travelMode: google.maps.TravelMode.DRIVING,
            }, (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    props.showDirection(result);
                    props.showPanel(true);
                    props.setActive(true);
                    props.setSelectDirectionPin(null)
                } else {
                  console.error(`error fetching directions ${result}`);
                }
            });
        };

        const onBoundsChanged = () => {
            if(mapRef.current) {
                let zoom = mapRef.current.getZoom();
                if(zoom >= 15){
                    let ne = mapRef.current.getBounds().getNorthEast();
                    let sw = mapRef.current.getBounds().getSouthWest();
                    props.getPlaces({minLat: sw.lat() , maxLat: ne.lat(), minLon: sw.lng(), maxLon: ne.lng()});
                }
                else{
                    props.clearPlaces();
                }
            }
        };

        const fitBounds = (gpsMarkersOnly = false) => {
            const bounds = new google.maps.LatLngBounds();
            Object.keys(props.gpsMarkers).forEach((key) => {
                if(!props.lockTracking.checked || (props.lockTracking.checked && props.lockTracking.lock === props.latestUpdateImei.imei)) {
                    if (props.latestUpdateImei.mode || props.settings.breadcrumbs.length === 0) {
                        let lastMarker = props.gpsMarkers[key].markers[0];
                        if(props.activePop === 'filters') {
                            bounds.extend({lat: lastMarker.lat, lng: lastMarker.lng});
                        }
                        else if(mapRef) {
                            mapRef.current.panTo(new google.maps.LatLng({lat: lastMarker.lat, lng: lastMarker.lng}));
                        }
                    } else {
                        props.gpsMarkers[key].markers.forEach((marker) => {
                            bounds.extend({lat: marker.lat, lng: marker.lng});
                        });
                    }
                }
            });

            if(!gpsMarkersOnly) {
                if(props.markers.length > 0 && props.markers[0].fitBounds) {
                    props.markers.forEach((marker) => {
                        bounds.extend({lat: marker.lat, lng: marker.lng});
                    });
                }
                props.polygons.forEach((polygon) => {
                    polygon.paths.forEach((path) => {
                        bounds.extend(path);
                    })
                });
                props.circles.forEach((circle) => {
                    bounds.union(new google.maps.Circle({
                        center: {lat: circle.lat, lng: circle.lng},
                        radius: circle.radius
                    }).getBounds())
                });
            }
            if(bounds.isEmpty())
                return;
            // bounds.extend({lat: props.currentLocation.lat, lng: props.currentLocation.lng});
            if(mapRef) {
                let zoom =
                mapRef.current.fitBounds(bounds);
            }
        };

        useEffect(() => {
            console.log('map rerendered')
        }, []);

        useEffect(() => {
            onBoundsChanged();
        }, [props.mapType]);


        useEffect(() => {
            fitBounds();
        }, [props.markers, props.polygons, props.circles]);

        useEffect(() => {
            // if(props.settings.track === 'auto' && (!props.lockTracking.checked || props.localTracking.lock === props.latestUpdateImei.imei))
            if(props.settings.track === 'auto')
                fitBounds(props.activePop !== 'filters');
        }, [props.gpsMarkers]);

        return(
            <GoogleMap
                defaultZoom={14}
                // options = {{ maxZoom: 17 }}
                center={props.currentLocation}
                onZoomChanged={()=> {
                    onBoundsChanged();
                }}
                onCenterChanged={()=>{
                    if(timeout)clearTimeout(timeout)
                    timeout = setTimeout(()=>{
                        onBoundsChanged();
                    },500)
                }}
                onClick={props.onClick}
                mapTypeControl="true"
                onTilesLoaded={()=>{
                    if(mapRef.current && !props.measuringToolLoaded) {
                        new MeasureTool(mapRef.current.context[MAP], {
                            showSegmentLength: true,
                            unit: MeasureTool.UnitTypeId.IMPERIAL // or just use 'imperial'
                        });
                        props.setMeasuringToolLoaded(true);
                    }
                }}
                ref={mapRef}
            >

                    {props.markers.map((marker, idx) =>
                        <Marker
                            // key={`${new Date().toString()}`}
                            key={idx}
                            icon={marker.markerIcon ? marker.markerIcon: {
                                url: `/images/map-plain-icon-${marker.color}.svg`,
                            }}
                            position={{lat: marker.lat, lng: marker.lng}}
                            onClick={() => {
                                // props.onToggleOpen(marker)
                                if(props.selectDirectionPin){
                                    handleDirections(marker)
                                }
                                else {
                                    props.onToggleOpen({type: 'marker', ...marker, mode: marker.filter ? marker.filter: 'edit'})
                                }
                            }}
                        >
                            {marker.objectType === 'media' &&
                                <InfoWindow>
                                    <div className="image-window"><img className="map-window-image"
                                                                       src={`${process.env.REACT_APP_MEDIA_UPLOAD_API_URI}/${marker.settings?.filename ?? ''}`}
                                                                       width="100"/>
                                    </div>
                                </InfoWindow>
                            }
                        </Marker>
                    )}



                <Marker position={props.currentLocation} icon={{
                    url: `/images/map-plain-icon-blue.svg`,
                }}

                        onClick={() => {
                            if(props.selectDirectionPin){
                                handleDirections({lat: props.currentLocation.lat, lng: props.currentLocation.lng})
                            }
                            else {
                                props.onToggleOpen({
                                    title: 'current location',
                                    lat: props.currentLocation.lat,
                                    lng: props.currentLocation.lng,
                                    type: 'marker'
                                })
                            }
                          }
                            // props.onToggleOpen({type: 'marker', ...props.currentLocation, mode: 'edit'})
                        }
                >
                </Marker>

                <DrawingManager
                    // defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
                    drawingMode={props.drawingMode}
                    defaultOptions={{
                        drawingControl: true,
                        drawingControlOptions: {
                            position: google.maps.ControlPosition.TOP_CENTER,
                            drawingModes: [google.maps.drawing.OverlayType.MARKER, google.maps.drawing.OverlayType.POLYGON, google.maps.drawing.OverlayType.CIRCLE]
                        },
                        polygonOptions: {
                            editable: true
                        },
                        markerOptions: {
                            icon: `/images/map-plain-icon-blue.svg`
                        }
                    }}
                    onCircleComplete={(circle) => {
                        props.onToggleOpen({
                            type: 'circle',
                            mode: 'new',
                            geometry: circle,
                            title: 'Circle',
                            lat: circle.center.lat(),
                            lng: circle.center.lng(),
                            radius: circle.radius
                        })
                        props.setDrawingMode(undefined)
                    }}
                    onPolygonComplete={(polygon) => {
                        let paths = getPaths(polygon);
                        props.onToggleOpen({
                            type: 'polygon',
                            mode: 'new',
                            geometry: polygon,
                            title: 'Polygon',
                            lat: paths[0].lat,
                            lng: paths[0].lng
                        })
                        props.setDrawingMode(undefined)
                    }}
                    onMarkerComplete={(marker) => {
                        props.onToggleOpen({
                            type: 'marker',
                            mode: 'new',
                            geometry: marker,
                            title: 'Marker',
                            lat: marker.position.lat(),
                            lng: marker.position.lng()
                        })
                        props.setDrawingMode(undefined)
                    }}
                />

                {props.polygons.map((polygon, idx) =>
                    <Polygon
                        path={polygon.paths}
                        key={idx}
                        editable={false}
                        options={{
                            strokeColor: polygon.color?.hex ?? '#000',
                            fillColor: polygon.color?.hex ??  '#000',
                        }}
                        onClick={(click) => {
                            props.onToggleOpen({type: 'polygon', ...polygon, mode: 'edit'})
                          }
                        }
                    />)
                }

                {props.circles.map((circle, idx) =>
                    <Circle
                        key={idx}
                        center={{
                            lat: circle.lat,
                            lng: circle.lng
                        }}
                        radius={circle.radius}
                        options={{
                            strokeColor: circle.color?.hex ?? '#000',
                            fillColor: circle.color?.hex ??  '#000',
                        }}
                        onClick={(click) => {
                            props.onToggleOpen({type: 'circle', ...circle, mode: 'edit'})
                          }
                        }
                    />)
                }


                {props.directions && props.active && <DirectionsRenderer
                    directions={props.directions}
                    options={{
                        markerOptions: {opacity: 0},
                        panel: document.getElementById('direction-panel'),
                        icon: { scale: 10 }
                    }}
                />}

                {/*{props.markers.map((marker, idx) =>*/}
                {/*    <Marker*/}
                {/*        // key={`${new Date().toString()}`}*/}
                {/*        key={idx}*/}
                {/*        icon={{*/}
                {/*            url: `/images/map-plain-icon-${marker.color}.svg`,*/}
                {/*        }}*/}
                {/*        position={{lat: marker.lat, lng: marker.lng}}*/}
                {/*        onClick={() => {*/}
                {/*            // props.onToggleOpen(marker)*/}
                {/*            if(props.selectDirectionPin){*/}
                {/*                handleDirections(marker)*/}
                {/*            }*/}
                {/*            else {*/}
                {/*                props.onToggleOpen({type: 'marker', ...marker, mode: marker.filter ? marker.filter: 'edit'})*/}
                {/*            }*/}
                {/*        }}*/}
                {/*    >*/}
                {/*    </Marker>*/}
                {/*)}*/}

                {Object.keys(props.gpsMarkers).map((key, idx) =>
                    props.gpsMarkers[key].markers.slice(0, props.settings.breadcrumbs?.[0] === 'Show breadcrumbs' ? props.gpsMarkers[key].length: 1 ?? 1).map((marker, idy) =>
                        <Marker
                            // key={`${new Date().toString()}`}
                            key={idy}
                            icon={marker.icon}
                            position={{lat: marker.lat, lng: marker.lng}}
                            onRightClick={()=>{props.toggleLock(marker.imei)}}
                            onClick={() => {
                                // props.onToggleOpen(marker)
                                if(props.selectDirectionPin){
                                    handleDirections(marker)
                                }
                                else {
                                    props.onToggleOpen({type: 'marker', ...marker, mode: 'edit'});
                                }
                            }}
                        >
                        </Marker>
                    )
                )}


                {props.isOpen && <PinPop setActivePop={props.onToggleOpen}
                                         currentLocation={props.currentLocation}
                                         marker={props.activeMarker}
                                         // showDirection={(directions)=>{
                                             // props.showDirection(directions);
                                             // props.showPanel(true)
                                         // }}
                                         showDirection={(pin)=>{
                                             props.setSelectDirectionPin(pin)
                                         }}
                                         setActive={props.setActive}
                />}

                {props.selectDirectionPin &&
                    <div className="destination-pin">
                        Select destination pin to find the direction
                        <CloseOutlined className="close-icon" onClick={()=>{props.setSelectDirectionPin(null)}}/>
                    </div>
                }
            </GoogleMap>
        )
    }
);

export default Map;
