import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Row, Col, Radio, Select, Collapse } from 'antd';
import { SearchOutlined, DownOutlined, CloseOutlined } from '@ant-design/icons';
import _ from "lodash";
import Icon from '@ant-design/icons';
import {Marker} from "react-google-maps";
import {useDispatch, useSelector} from "react-redux";

const { Option } = Select;
const { Panel } = Collapse;
const defaultPagination = 20;
let timeout;

const filter =  {
    PLACE: 'place',
    ASSET: 'asset',
    USER: 'user',
    MEDIA: 'media',
    PAGE: 'page',
};

const filterMap = {
    place: 'SET_PLACES',
    asset: 'SET_ASSETS',
    user: 'SET_PEOPLES',
    media: 'SET_MEDIAS',
    page: 'SET_PAGES',
};

const messageTypeMap = {
    user: 'gps',
    place: 'place',
    media: 'media',
};

function FilterPop(props) {
    const { setActivePop } = props;
    const dispatch = useDispatch();
    const { messages } = useSelector(state => state.message);
    const { selectedItem } = useSelector(state => state.search);
    const {map_places_filter, map_assets_filter, map_peoples_filter, map_pages_filter, map_medias_filter, map_places_bounds, places, assets, medias, pages, peoples, selected_places, location_profiles} = useSelector(state => state.dashboard);
    const [selectedPlacesIds, setSelectedPlacesIds] = useState([]);
    const [selectedAssetsIds, setSelectedAssetsIds] = useState([]);
    const [selectedPeoplesIds, setSelectedPeoplesIds] = useState([]);
    const [selectedPagesIds, setSelectedPagesIds] = useState([]);
    const [selectedMediaIds, setSelectedMediasIds] = useState([]);
    const clientId = '60c83dcefe8cea1cd6699618';


    const setLocations = (type, places) => {
        dispatch({type: filterMap[type], payload: places});
    };

    const setSelectedLocations = (places) => {
        dispatch({type: 'SET_SELECTED_PLACES', payload: {items: places, fitBounds: true}});
    };

    const onGroup = (type) => {
        // dispatch({type: 'ADD_PAGE_GROUP', payload: {type}});
    };

    const getFilterLocations = (type, search, page= 0, pagination = true, profiles) => {
        dispatch({
            type: 'GET_MAP_PLACES_FILTER', payload: {
                clientId,
                objectType: type,
                limit: defaultPagination,
                offset: page * defaultPagination,
                search,
                profiles: profiles?.map((e)=>{return e.id}) ?? undefined
            }
        });
        setLocations(type, {
            searching: true,
            page,
            pagination
        });
    };

    const onFilterChange = (type, filteredLocations, allLocations) => {
        let tempPlaces = [...selected_places.items, ...filteredLocations];
        tempPlaces = tempPlaces.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            )) && value.objectType === type
        );
        setLocations(type, {
            items: allLocations.page === 0 ? tempPlaces: [...allLocations.items, ...filteredLocations],
            searching: false,
        });
    };

    useEffect(() => {
        if(map_places_filter) {
            onFilterChange(filter.PLACE, map_places_filter, places);
        }
    }, [map_places_filter]);


    useEffect(() => {
        if(map_assets_filter) {
            onFilterChange(filter.ASSET, map_assets_filter, assets);
        }
    }, [map_assets_filter]);

    useEffect(() => {
        if(map_peoples_filter) {
            onFilterChange(filter.USER, map_peoples_filter, peoples);
        }
    }, [map_peoples_filter]);

    useEffect(() => {
        if(map_pages_filter) {
            onFilterChange(filter.PAGE, map_pages_filter, pages);
        }
    }, [map_pages_filter]);

    useEffect(() => {
        if(map_medias_filter) {
            onFilterChange(filter.MEDIA, map_medias_filter, medias);
        }
    }, [map_medias_filter]);

    useEffect(() => {
        if(selected_places){
            let filteredPlaces = selected_places.items.filter((e)=>{return e.objectType === filter.PLACE});
            let filteredAssets = selected_places.items.filter((e)=>{return e.objectType === filter.ASSET});
            let filteredPeoples = selected_places.items.filter((e)=>{return e.objectType === filter.USER});
            let filteredPages = selected_places.items.filter((e)=>{return e.objectType === filter.PAGE});
            let filteredMedias = selected_places.items.filter((e)=>{return e.objectType === filter.MEDIA});
            setSelectedPlacesIds(filteredPlaces.map((e)=>{return e.id}));
            setSelectedAssetsIds(filteredAssets.map((e)=>{return e.id}));
            setSelectedPagesIds(filteredPages.map((e)=>{return e.id}));
            setSelectedPeoplesIds(filteredPeoples.map((e)=>{return e.id}));
            setSelectedMediasIds(filteredMedias.map((e)=>{return e.id}));
        }
    }, [selected_places]);

    const filterPageMessagesByType = (type) => {
        let filteredLocations = messages[selectedItem.pageId].filter((e)=>{
            return e.messageType === messageTypeMap[type]
        });

        filteredLocations = filteredLocations.map((e)=> {
            return {
                ...e,
                id: e.id,
                name: e.message,
                location: e.data.location ? {
                    type: 'Point',
                    coordinates: [e.data.location.longitude, e.data.location.latitude],
                }: undefined,
                objectType: type,
                settings: {
                    filename: e.data.filename
                }
            }
        });
        return filteredLocations
    };

    const resetLocations = (type) => {
        if(props.mapType == 'PAGE'){
            setLocations(type, {
                items: filterPageMessagesByType(type),
                pagination: false,
            });
            return;
        }
        // console.log('map locations bound here', map_places_bounds)
        // if(map_places_bounds && map_places_bounds.length > 0) {
        //     let tempPlaces = [...map_places_bounds, ...selected_places.items];
        //     tempPlaces = tempPlaces.filter((value, index, self) =>
        //         index === self.findIndex((t) => (
        //             t.id === value.id
        //         )) && value.objectType === type
        //     );
        //     setLocations(type, {
        //         items: tempPlaces,
        //         pagination: false,
        //     });
        // }
        // else
            getFilterLocations(type);
    };

    const onAll = (type) => {
        let temp = [];
        switch (type) {
            case 'place':
                temp = [...selected_places.items, ...places.items];
                break;
            case 'asset':
                temp = [...selected_places.items, ...assets.items];
                break;
            case 'page':
                temp = [...selected_places.items, ...pages.items];
                break;
            case 'user':
                temp = [...selected_places.items, ...peoples.items];
                break;
            case 'media':
                temp = [...selected_places.items, ...medias.items];
                break;
        }
        temp = temp.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
        );
        setSelectedLocations(temp)
    };

    const onClear = (type) => {
        let places = [...selected_places.items];
        places = places.filter((e)=>{return e.objectType !== type});
        setSelectedLocations(places)
    };


    return (
        <div className="pop-content filter-popup">
            <Row className="d-flex mb-4">
                <Col><h3>Filters</h3></Col>
                <Col><CloseOutlined className="close-icon" onClick={() => setActivePop()}/></Col>
            </Row>

            <Collapse defaultActiveKey={[]} bordered={false}>
                <Panel header={
                    <Row>
                        <Col span={24}>
                            <p  className="mb-1" style={{color: "#000000"}}>Peoples</p>
                        </Col>
                    </Row>
                } key="1">
                    <Select
                        mode="multiple"
                        placeholder="Please select peoples"
                        onChange={(values) =>{
                            let tempPlaces = peoples.items.filter((e) => {
                                return values.indexOf(e.id) !== -1
                            });
                            tempPlaces = [...selected_places.items, ...tempPlaces];
                            tempPlaces = tempPlaces.filter((value, index, self) =>
                                index === self.findIndex((t) => (
                                    t.id === value.id
                                )) && (values.indexOf(value.id) !== -1 || value.objectType !== filter.USER)
                            );
                            setSelectedLocations(tempPlaces);
                        }}
                        value={selectedPeoplesIds}
                        loading={peoples.searching}
                        maxTagCount={'responsive'}
                        style={{ width: '100%' }}
                        listHeight={100}
                        onFocus={()=>{
                            resetLocations(filter.USER);
                        }}
                        autoClearSearchValue={false}
                        onSearch={(search)=> {
                            if(timeout)clearTimeout(timeout);
                            timeout = setTimeout(() => {
                                if(props.mapType == 'PAGE')return;
                                if(search && search !== '') {
                                    let matchedProfiles = (location_profiles?.admin?.liveuser?.profile ?? []).filter((e)=> {
                                        return e.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                                    });
                                    getFilterLocations(filter.USER, search, 0, false, matchedProfiles);
                                    setLocations(filter.USER, {
                                        pagination: false,
                                    });
                                }
                                else
                                    resetLocations(filter.USER);
                            }, 500);
                        }}
                        filterOption={(input, option) => {
                            if(props.mapType == 'HOME')
                                return true;
                            else{
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
                            }
                        }}
                        onPopupScroll={(e)=>{
                            e.persist();
                            let target = e.target;
                            if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                                if(peoples.pagination){
                                    getFilterLocations(filter.USER,undefined, peoples.page + 1)
                                }
                            }
                        }}
                    >
                        {peoples && peoples.items.map((people, idx) =>
                            <Option value={people.id} key={people.id}>{people.name}</Option>
                        )}
                    </Select>

                    <hr />
                    <Row className="d-flex mt-2">
                        <Col span={8} className="pr-2"><Button className="beam-btn green-btn cover w-full" onClick={()=>{onAll(filter.USER)}}>All</Button></Col>
                        <Col span={8} className="px-2"><Button className="beam-btn w-full" onClick={()=>{onClear(filter.USER)}}>Clear</Button></Col>
                        <Col span={8} className="pl-2"><Button className="beam-btn w-full" onClick={()=>{onGroup(filter.USER)}}>Group</Button></Col>
                    </Row>
                </Panel>
            </Collapse>

            <br/>


            <Collapse defaultActiveKey={[]} bordered={false}>
                <Panel header={
                    <Row>
                        <Col span={24}>
                            <p  className="mb-1" style={{color: "#000000"}}>Place</p>
                        </Col>
                    </Row>
                } key="1">
                    <Select
                        mode="multiple"
                        placeholder="Please select places"
                        onChange={(values) =>{
                            let tempPlaces = places.items.filter((e) => {
                               return values.indexOf(e.id) !== -1
                            });
                            tempPlaces = [...selected_places.items, ...tempPlaces];
                            tempPlaces = tempPlaces.filter((value, index, self) =>
                                index === self.findIndex((t) => (
                                    t.id === value.id
                                )) && (values.indexOf(value.id) !== -1 || value.objectType !== filter.PLACE)
                            );
                            setSelectedLocations(tempPlaces);
                        }}
                        value={selectedPlacesIds}
                        loading={places.searching}
                        maxTagCount={'responsive'}
                        style={{ width: '100%' }}
                        listHeight={100}
                        onFocus={()=>{
                            resetLocations(filter.PLACE);
                        }}
                        autoClearSearchValue={false}
                        onSearch={(search)=> {
                            if(timeout)clearTimeout(timeout);
                            timeout = setTimeout(() => {
                                if(props.mapType == 'PAGE')return;
                                if(search && search !== '') {
                                    let matchedProfiles = (location_profiles?.admin?.clbs?.profile ?? []).filter((e)=>{
                                        return e.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                                    });
                                    console.log(matchedProfiles);
                                    getFilterLocations(filter.PLACE, search, 0, false, matchedProfiles);
                                    setLocations(filter.PLACE, {
                                        pagination: false,
                                    });
                                }
                                else
                                    resetLocations(filter.PLACE);
                            }, 500);
                        }}
                        filterOption={(input, option) => {
                            if(props.mapType == 'HOME')
                                return true;
                            else{
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
                            }
                        }}
                        onPopupScroll={(e)=>{
                            e.persist();
                            let target = e.target;
                            if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                                if(places.pagination){
                                    getFilterLocations(filter.PLACE,undefined, places.page + 1)
                                }
                            }
                        }}
                    >
                        {places && places.items.map((place, idx) =>
                            <Option value={place.id} key={place.id}>{place.name}</Option>
                        )}
                    </Select>

                    <hr />
                    <Row className="d-flex mt-2">
                        <Col span={8} className="pr-2"><Button className="beam-btn green-btn cover w-full" onClick={()=>{onAll(filter.PLACE)}}>All</Button></Col>
                        <Col span={8} className="px-2"><Button className="beam-btn w-full" onClick={()=>{onClear(filter.PLACE)}}>Clear</Button></Col>
                        <Col span={8} className="pl-2"><Button className="beam-btn w-full" onClick={()=>{onGroup(filter.PLACE)}}>Group</Button></Col>
                    </Row>
                </Panel>
            </Collapse>

            <br/>

            <Collapse defaultActiveKey={[]} bordered={false}>
                <Panel header={
                    <Row>
                        <Col span={24}>
                            <p  className="mb-1" style={{color: "#000000"}}>Assets</p>
                        </Col>
                    </Row>
                } key="1">
                    <Select
                        mode="multiple"
                        placeholder="Please select assets"
                        onChange={(values) =>{
                            let tempPlaces = assets.items.filter((e) => {
                                return values.indexOf(e.id) !== -1
                            });
                            tempPlaces = [...selected_places.items, ...tempPlaces];
                            tempPlaces = tempPlaces.filter((value, index, self) =>
                                index === self.findIndex((t) => (
                                    t.id === value.id
                                )) && (values.indexOf(value.id) !== -1 || value.objectType !== filter.ASSET)
                            );
                            setSelectedLocations(tempPlaces);
                        }}
                        value={selectedAssetsIds}
                        loading={assets.searching}
                        maxTagCount={'responsive'}
                        style={{ width: '100%' }}
                        listHeight={100}
                        onFocus={()=>{
                            resetLocations(filter.ASSET);
                        }}
                        autoClearSearchValue={false}
                        onSearch={(search)=> {
                            if(timeout)clearTimeout(timeout);
                            timeout = setTimeout(() => {
                                if(props.mapType == 'PAGE')return;
                                if(search && search !== '') {
                                    let matchedProfiles = (location_profiles?.admin?.asset?.profile ?? []).filter((e)=> {
                                        return e.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                                    });
                                    getFilterLocations(filter.ASSET, search, 0, false, matchedProfiles);
                                    setLocations(filter.ASSET, {
                                        pagination: false,
                                    });
                                }
                                else
                                    resetLocations(filter.ASSET);
                            }, 500);
                        }}
                        filterOption={(input, option) => {
                            if(props.mapType == 'HOME')
                                return true;
                            else{
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
                            }
                        }}
                        onPopupScroll={(e)=>{
                            e.persist();
                            let target = e.target;
                            if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                                if(assets.pagination){
                                    getFilterLocations(filter.ASSET,undefined, assets.page + 1)
                                }
                            }
                        }}
                    >
                        {assets && assets.items.map((asset, idx) =>
                            <Option value={asset.id} key={asset.id}>{asset.name}</Option>
                        )}
                    </Select>

                    <hr />
                    <Row className="d-flex mt-2">
                        <Col span={8} className="pr-2"><Button className="beam-btn green-btn cover w-full" onClick={()=>{onAll(filter.ASSET)}}>All</Button></Col>
                        <Col span={8} className="px-2"><Button className="beam-btn w-full" onClick={()=>{onClear(filter.ASSET)}}>Clear</Button></Col>
                        <Col span={8} className="pl-2"><Button className="beam-btn w-full" onClick={()=>{onGroup(filter.ASSET)}}>Group</Button></Col>
                    </Row>
                </Panel>
            </Collapse>


            {props.mapType == 'HOME' &&
                <React.Fragment>
                    <br/>
                    <Collapse defaultActiveKey={[]} bordered={false}>
                        <Panel header={
                            <Row>
                                <Col span={24}>
                                    <p className="mb-1" style={{color: "#000000"}}>Page</p>
                                </Col>
                            </Row>
                        } key="1">
                            <Select
                                mode="multiple"
                                placeholder="Please select pages"
                                onChange={(values) => {
                                    let tempPlaces = pages.items.filter((e) => {
                                        return values.indexOf(e.id) !== -1
                                    });
                                    tempPlaces = [...selected_places.items, ...tempPlaces];
                                    tempPlaces = tempPlaces.filter((value, index, self) =>
                                        index === self.findIndex((t) => (
                                            t.id === value.id
                                        )) && (values.indexOf(value.id) !== -1 || value.objectType !== filter.PAGE)
                                    );
                                    setSelectedLocations(tempPlaces);
                                }}
                                value={selectedPagesIds}
                                loading={pages.searching}
                                maxTagCount={'responsive'}
                                style={{width: '100%'}}
                                listHeight={100}
                                onFocus={() => {
                                    resetLocations(filter.PAGE);
                                }}
                                autoClearSearchValue={false}
                                onSearch={(search) => {
                                    if (timeout) clearTimeout(timeout);
                                    timeout = setTimeout(() => {
                                        if (props.mapType == 'PAGE') return;
                                        if (search && search !== '') {
                                            getFilterLocations(filter.PAGE, search, 0, false);
                                            setLocations(filter.PAGE, {
                                                pagination: false,
                                            });
                                        } else
                                            resetLocations(filter.PAGE);
                                    }, 500);
                                }}
                                filterOption={(input, option) => {
                                    if(props.mapType == 'HOME')
                                        return true;
                                    else{
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
                                    }
                                }}
                                onPopupScroll={(e) => {
                                    e.persist();
                                    let target = e.target;
                                    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                                        if (pages.pagination) {
                                            getFilterLocations(filter.PAGE, undefined, pages.page + 1)
                                        }
                                    }
                                }}
                            >
                                {pages && pages.items.map((page, idx) =>
                                    <Option value={page.id} key={page.id}>{page.name}</Option>
                                )}
                            </Select>

                            <hr/>
                            <Row className="d-flex mt-2">
                                <Col span={8} className="pr-2"><Button className="beam-btn green-btn cover w-full"
                                                                       onClick={() => {
                                                                           onAll(filter.PAGE)
                                                                       }}>All</Button></Col>
                                <Col span={8} className="px-2"><Button className="beam-btn w-full" onClick={() => {
                                    onClear(filter.PAGE)
                                }}>Clear</Button></Col>
                                <Col span={8} className="pl-2"><Button className="beam-btn w-full">Group</Button></Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </React.Fragment>
            }
            <br/>

            <Collapse defaultActiveKey={[]} bordered={false}>
                <Panel header={
                    <Row>
                        <Col span={24}>
                            <p  className="mb-1" style={{color: "#000000"}}>Media</p>
                        </Col>
                    </Row>
                } key="1">
                    <Select
                        mode="multiple"
                        placeholder="Please select media"
                        onChange={(values) =>{
                            let tempPlaces = medias.items.filter((e) => {
                                return values.indexOf(e.id) !== -1
                            });
                            tempPlaces = [...selected_places.items, ...tempPlaces];
                            tempPlaces = tempPlaces.filter((value, index, self) =>
                                index === self.findIndex((t) => (
                                    t.id === value.id
                                )) && (values.indexOf(value.id) !== -1 || value.objectType !== filter.MEDIA)
                            );
                            setSelectedLocations(tempPlaces);
                        }}
                        value={selectedMediaIds}
                        loading={medias.searching}
                        maxTagCount={'responsive'}
                        style={{ width: '100%' }}
                        listHeight={100}
                        onFocus={()=> {
                            resetLocations(filter.MEDIA);
                        }}
                        autoClearSearchValue={false}
                        onSearch={(search)=> {
                            if(timeout)clearTimeout(timeout);
                            timeout = setTimeout(() => {
                                if(props.mapType == 'PAGE')return;
                                if(search && search !== '') {
                                    getFilterLocations(filter.MEDIA, search, 0, false);
                                    setLocations(filter.MEDIA, {
                                        pagination: false,
                                    });
                                }
                                else
                                    resetLocations(filter.MEDIA);
                            }, 500);
                        }}
                        filterOption={(input, option) => {
                            if(props.mapType == 'HOME')
                                return true;
                            else{
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1
                            }
                        }}
                        onPopupScroll={(e) => {
                            e.persist();
                            let target = e.target;
                            if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                                if(medias.pagination){
                                    getFilterLocations(filter.MEDIA,undefined, medias.page + 1)
                                }
                            }
                        }}
                    >
                        {medias && medias.items.map((media, idx) =>
                            <Option value={media.id} key={media.id}>{media.name}</Option>
                        )}
                    </Select>

                    <hr />
                    <Row className="d-flex mt-2">
                        <Col span={8} className="pr-2"><Button className="beam-btn green-btn cover w-full" onClick={()=>{onAll(filter.MEDIA)}}>All</Button></Col>
                        <Col span={8} className="px-2"><Button className="beam-btn w-full" onClick={()=>{onClear(filter.MEDIA)}}>Clear</Button></Col>
                        <Col span={8} className="pl-2"><Button className="beam-btn w-full">Group</Button></Col>
                    </Row>
                </Panel>
            </Collapse>

        </div>
    )
}

export default FilterPop;
