import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { 
  Login, 
  Register,
  Resend,
  ResetPin,
  ChangePin,
  NewPin,
  EnterEmailNumber
} from "../../components/Auth";
// import { createBrowserHistory } from 'history';
import './index.scss';
import { 
  Button,
  Row,
  Col,
} from 'antd';
import VerifyCode from 'components/Auth/VerifyCode';

function Auth (props) {

  return (
    <Row className="auth-layout">
      <Col span={12}>
        <Switch>            
          <Route exact path="/auth">
            <Redirect to="/auth/login" />
          </Route>
          <Route path="/auth/login" component={Login} />
          <Route path="/auth/register" component={Register} />
          <Route path="/auth/pin/resend" component={Resend} />
          <Route path="/auth/pin/resetpin" component={ResetPin} />
          <Route path="/auth/pin/reset" component={EnterEmailNumber} />
          <Route path="/auth/pin/change" component={ChangePin} />
          <Route path="/auth/pin/new" component={NewPin} />
          <Route path="/auth/pin/verify" component={VerifyCode} />
        </Switch>
      </Col>
      <Col span={12}>
        <div className="welcome-text">
          <h3>Mobile IoT Communication</h3>
          <p>Configure IoT data into mobile messaging apps.</p>
          <div className="d-flex">
            <Button type="primary">Learn More</Button>
            <Button type="default" className="ml-2 request-demo">Request Live Demo</Button>
          </div>
        </div>
        <div className="bg-icons">
          <img alt='1' src="/images/home.png" className="bg-icon home-png" />
          <img alt='1' src="/images/audio.png" className="bg-icon audio-png" />
          <img alt='1' src="/images/place.png" className="bg-icon place-png" />
        </div>
      </Col>
    </Row>
  );
}

export default Auth;
