import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import { useDispatch } from 'react-redux';


function RSVPRespModal(props) {
    const { onClose, rsvp } = props;
    const [isModalVisible, setIsModalVisible] = useState(true);

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };
    const dispatch = useDispatch();
    const handleOptionClick = (option) => {
        dispatch({type: 'SEND_MESSAGE', payload: {message: `${rsvp.rsvpTitle} ${option}`, type: 'text'}})
    }

    return (
        <Modal title={!rsvp ? "Add RSVP" : ""}
            visible={isModalVisible}
            onCancel={handleCancel}
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row className="mt-4 mb-4">
                <span>{rsvp.rsvpTitle}</span>
            </Row>
            <Row className="tiles">
                <Col span={24}>
                    <Row className="d-flex mb-4">
                        <Col span={12} className="pr-2"><Button onClick={()=>{handleOptionClick(rsvp.options.option1)}} className="beam-btn green-btn cover w-full">{rsvp.options.option1}</Button></Col>
                        <Col span={12} className="pl-2"><Button onClick={()=>{handleOptionClick(rsvp.options.option2)}} className="beam-btn w-full">{rsvp.options.option2}</Button></Col>
                    </Row>
                    <Row>
                        <Col span={24}><Button onClick={()=>{handleOptionClick(rsvp.options.option3)}} className="beam-btn orange-btn cover w-full">{rsvp.options.option3}</Button></Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    )
}

export default RSVPRespModal;
