import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Input, Button, message, TimePicker, Dropdown } from "antd";
import { ReactSVG } from 'react-svg';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import MessageItem from './MessageItem';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import _ from "lodash";
import { PopupModal } from 'components/Modals';
import { useDispatch, useSelector } from 'react-redux';
import { PageSettingsModal } from 'components/Modals';
import GroupIcon from 'assets/SVGs/GroupIcon';
import SettingsIcon from 'assets/SVGs/SettingsIcon';
import FilterIcon from 'assets/SVGs/FilterIcon';
import CompassIcon from 'assets/SVGs/CompassIcon';
import DesktopIcon from 'assets/SVGs/DesktopIcon';
import DocIcon from 'assets/SVGs/DocIcon';
import { PAGE_TYPES } from 'utils/PageTypes';
import { SummaryModal } from 'components/Modals';
import { AnalyticsSummaryModalTemp } from 'components/Modals';
import './MapPanel.scss';
import moment from 'moment';
import { PeopleModal } from 'components/Modals';
import { AddSurveyModal } from 'components/Modals';

const { TextArea } = Input;

const MessageSpliterToday = () =>
    <div className="message-spliter-today">
        <label>TODAY</label>
    </div>

const MessageSpliterNews = () =>
    <div className="message-spliter-news">
        <label>New messages</label>
    </div>

const MessageUserAdded = (user) =>
    <div className="message-user-added">
        <h4>11:12:48</h4>
        <p><span>Leonetta Lloyd</span> has been added to the page by zsolt.lengyel </p>
    </div>

const MessageOppositeUserStatus = () =>
    <div className="message-opposite-user-status">
        <label>Leona is typing </label>
        <Loader
            type="ThreeDots"
            color="#91A5C6"
            height={10}
            width={10}
          />
    </div>

const clientId = '60c83dcefe8cea1cd6699618';

function MessagePanel(props) {
  const { messages, messageToSend, currentMessage } = useSelector(state => state.message);

  const [messageCount, setMessageCount] = useState(messages && messages[selectedItem?.pageId] ? messages[selectedItem?.pageId].length : 0);
  const { fileToSend } = useSelector(state => state.message);
  const messagesEndRef = useRef(null)
  const [messagesVisible, setMessageVisible] = useState(false)

	const dispatch = useDispatch();

	const [popupDisplayed, setPopUpDisplayed] = useState(false);
	const [peoplePopupDisplayed, setPeoplePopupDisplayed] = useState(false);
	const [filterPopupDisplayed, setFilterPopUpDisplayed] = useState(false);
	const [settingsModalDisplayed, setSettingsModalDisplayed] = useState(false);
	const [summaryModalDisplayed, setSummaryModalDisplayed] = useState(false);
	const [surveyModalDisplayed, setSurveyModalDisplayed] = useState(false);
	const [selectedMessageType, setSelectedMessageType] = useState('text');
	const [text, setText] = useState('');
	const [currentLocation, setCurrentLocation] = useState();
	const {screens, setScreens, client} = props;
	const { selectedItem } = useSelector(state => state.search);
	const [edit, setEdit] = useState();
	const type = selectedItem && selectedItem.type && selectedItem.type === PAGE_TYPES.BROADCAST ? 'orange' : undefined;
	const color = type && type === 'orange' ? "#FF5F25" : "#508AFA"
	const openPopupModal = () => {
			console.log('open')
			setPopUpDisplayed(true);
	}

	const openInNewWindow = () => {
		window.open(window.location.origin + `/map/${selectedItem.pageId}`, '_blank', 'toolbar=0,location=0,menubar=0');
	}

	const menu = (
		<ul className="user-dropdown">
			<li>
				<Link to={`/map/${selectedItem.pageId}`} target="_blank" className="ml-3 text-white">Open New Tab</Link>
			</li>
			<li onClick={openInNewWindow}>
				<span className="ml-3">Open New Window</span>
			</li>

		</ul>
	);

	useEffect(() => {
		setMessageVisible(false)
		if(client){
			client.subscribe(`live/page/${selectedItem.pageId}/connection/${localStorage.getItem('CID')}/pageLoginResult`, { qos: 1 }, (error) => {
				if (error) {
					console.log('Subscribe to topics error', error)
					return
				} else {
					// this needs to subscribe on the results from pageLoginResult
					// '/#' is needed at the end as a whildcard, because each message type has it's own ending

					// client.subscribe(`live/page/${selectedItem.pageId}/message`, { qos: 1 }, (error) => {
					client.subscribe(`${process.env.REACT_APP_ECO_SYSTEM}/page/${selectedItem.pageId}/connection/+/createMessageResult`, { qos: 1 }, (error) => {
						if (error) {
							console.log('Subscribe to topics error', error)
							return
						}
						console.log('Subsribed on live/page/${}/message', selectedItem.pageId)
					});


					dispatch({type: 'CLEAR_MESSAGE', payload: {pageId: selectedItem.pageId} })

					client.publish(`${process.env.REACT_APP_ECO_SYSTEM}/connection/${localStorage.getItem('CID')}/service/page-messaging/searchMessage`, JSON.stringify({
						query: {pageId: selectedItem.pageId},
						options: {order: {createdAt: 'ASC'}}
						// skip: 0, take: 10
					}))
				}

				console.log('Subsribed To pageLogin')
				// setIsSub(true)
			});

			client.subscribe(`${process.env.REACT_APP_ECO_SYSTEM}/service/page-messaging/connection/${localStorage.getItem('CID')}/searchMessageResult`, { qos: 1 }, (error) => {
				if (error) {
					console.log('Subscribe to topics error', error)
				}
			});


			client.publish(`user/connection/${localStorage.getItem('CID')}/page/${selectedItem.pageId}/pageLogin`, JSON.stringify({
				header: {
					accessToken: "",
					connectionId: localStorage.getItem('CID'),
					rootUserId: localStorage.getItem('RID'),
					udid: localStorage.getItem('UUID')
				},
				connectInfo: {
					pageId: selectedItem.pageId,
					rootUserId: localStorage.getItem('RID'),
					connectionId: localStorage.getItem('CID'),
					udid: localStorage.getItem('UUID'),
					connectionPubTopic: `user/connection/${localStorage.getItem('CID')}/page/${selectedItem.pageId}/pageLogin`,
					attributes: {}
				}
			}), { qos: 1 }, error => {
				if (error) {
					console.log('Publish error: ', error);
				}
			});


			// client.subscribe('live/page/1/connection/20/group', { qos: 1 }, (error) => {
			//   if (error) {
			//     console.log('Subscribe to topics error', error)
			//     return
			//   }
			//   console.log('Subsribed')
			//   // setIsSub(true)
			// });

			getCurrentLocation();
		}
	}, [selectedItem]);

	useEffect(() => {
		if(messageToSend) {
			sendMessage(messageToSend.message, messageToSend.type, messageToSend.data)
			setPopUpDisplayed(false);
		}
	}, [messageToSend])

	useEffect(() => {

	}, [currentMessage])

	const getCurrentLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
					setCurrentLocation({latitude: position.coords.latitude, longitude: position.coords.longitude})
				}
			);
		} else {
			console.log("Geolocation is not supported by this browser.");
		}
	};

	useEffect(() => {
		if(fileToSend) {
			// sendMessage(messageToSend.message, messageToSend.type, messageToSend.data)
			setPopUpDisplayed(false);
			console.log('file to send here', fileToSend)
			sendMessage(fileToSend.originalname, 'media', {...fileToSend, location: currentLocation, clientId})
		}
	}, [fileToSend])

	const sendMessage = (message, type, data=undefined, del = undefined) => {
		if(client){
			setMessageVisible(false)
			// client.publish(`user/connection/${localStorage.getItem('CID')}/page/${selectedItem.pageId}/message`, JSON.stringify({
			client.publish(`${process.env.REACT_APP_ECO_SYSTEM}/connection/${localStorage.getItem('CID')}/page/${selectedItem.pageId}/createMessage`, JSON.stringify({
				header: {
					accessToken: "",
					connectionId: localStorage.getItem('CID'),
					rootUserId: localStorage.getItem('RID'),
					udid: localStorage.getItem('UUID')
				},
				messages: [
					{
						message: {
							type,
							// senderName: `User ID - ${localStorage.getItem('RID')}`,
							// messageType: type,
							// data,
							attributes: {
								body: message,
								flag: edit ? 'replace': del ? 'remove': undefined,
								originalPageMessageId: edit ? edit.id: del ? del: undefined,
								...data,
							}
						},
						connectionInfo: {
							connectionMessageId: "a8085b77-2adb-4cbe-a1e4-a0a2cc7d42b9",
							connectionId: localStorage.getItem('CID'),
							pageId: selectedItem.pageId,
							rootUserId: localStorage.getItem('RID'),
							createdAt: new Date(),
							originalCreatedAt: new Date()
						}
					}
				],
				connectionId: localStorage.getItem('CID'),
				connectionPubTopic: `${process.env.REACT_APP_ECO_SYSTEM}/connection/${localStorage.getItem('CID')}/page/${selectedItem.pageId}/createMessage`
			}), { qos: 1 }, error => {
				if (error) {
					console.log('Publish error: ', error);
				}
			});
			setText('')
			setEdit(undefined)
			setTimeout(() => scrollToBottom() , 1500)
		}
	}


	const handleMessageSend = () => {
		if(!text) {
			return;
		}
		sendMessage(text, 'text')
	}

	const handleClassify = () => {
		setPeoplePopupDisplayed(true);
		setSelectedMessageType('classify');
	}

	const scrollToBottom = () => {
		const current = messagesEndRef.current;
		console.log('scrolled')
		if(current)
			current.scrollIntoView();
	}

	useEffect(() => {
		if(messagesVisible) {
			setTimeout(() => scrollToBottom() , 200	)
		}
	}, [messagesVisible]);

	useEffect(() => {
		if(currentMessage) {
			setTimeout(() => scrollToBottom() , 200	)
		}
	}, [currentMessage]);

	const handleMapClick = () => {
		dispatch({ type: 'SET_MAP_TYPE', payload: 'PAGE' })
		setScreens({...screens, map: true});
	}

	const deleteMessage = (message) => {
		sendMessage(undefined, message.messageType, {type: message.data.type}, message.id)
	}

	const editMessage = (message) => {
		setEdit(message)
		setText(message.message)
	}

	const handleVideoIconClick = () => {
		popupCenter(window.location.origin + `/video/${selectedItem.pageId}`,"beamLive Video Call",
		800,
		600);
	}


	const popupCenter = (url, title, w, h) => {
		const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
		const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;
	
		// const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
		// const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
	
		// const systemZoom = width / window.screen.availWidth;
		// const left = (width - w) / 2 / systemZoom + dualScreenLeft
		// const top = (height - h) / 2 / systemZoom + dualScreenTop
		const newWindow = window.open(url, title, 'scrollbars=no,status=no,location=no,toolbar=no,menubar=no,width=' + w  + ', height=' + h);
	
		// Puts focus on the newWindow
		if (window.focus) {
		  newWindow.focus();
		}
	  }
	return (
		<div className="main-column message-panel mr-4">
				<div className="main-content">
					<div className="main-header">
							<div className="d-flex">
									<GroupIcon color={color} />
									<h3 className="ml-3" style={{color}}>{`${selectedItem.title || `Page ${selectedItem.pageId}`}’s messages`}</h3>
							</div>
							<div className="d-flex">
									<ReactSVG src="/images/expand-icon.svg" className="close-icon mr-4" onClick={() => setScreens({...screens, active: screens.active? null: 'message'})} />
									<ReactSVG src="/images/close-icon.svg" className="close-icon" onClick={() => setScreens({...screens, message: false})} />
							</div>
					</div>
					<div className="search-bar mt-3">
						<div className="d-flex">
							<Input className="beam-input" placeholder="Search..." suffix={
								<SearchOutlined />
							} />
							<Button className="beam-btn ml-2" onClick={() => setSurveyModalDisplayed(true)}>
								<DocIcon />
							</Button>
							<Button className="beam-btn ml-2" onClick={() => setSummaryModalDisplayed(true)}>
								<DesktopIcon/>
							</Button>
							<Button className="beam-btn ml-2" onClick={() => setSettingsModalDisplayed(true)}>
								<SettingsIcon />
							</Button>
							<Button className="beam-btn ml-2" onClick={()=> setFilterPopUpDisplayed(true)}>
								<FilterIcon />
							</Button>
							<Dropdown overlay={menu} placement="bottomRight" trigger={['contextMenu']}>
								<Button className="beam-btn ml-2" onClick={handleMapClick}>
									<CompassIcon />
								</Button>
							</Dropdown>
						</div>
					</div>
					<div className="main-cards">
							{messages[selectedItem.pageId] && messages[selectedItem.pageId].map(message => <MessageItem screens={screens} setScreens={setScreens} client={client} onMessageVisible={() => setMessageVisible(true)} onEdit={editMessage} onRemove={deleteMessage} message={message} key={Math.random()} />)}
							{/* {_.times(3, (i) => (
									<MessageItem key={i}/>
							))}
							<div className="message-seen-by">
									<p>Seen by Sister, Leona, Ekaterina</p>
							</div>
							<MessageUserAdded />
							<MessageSpliterToday />
							<MessageItem />
							<MessageUserAdded />
							<MessageSpliterNews />
							{_.times(2, (i) => (
									<MessageItem key={i}/>
							))} */}
							<div ref={messagesEndRef} />
					</div>
					<div className="main-footer">
							{/* <MessageOppositeUserStatus /> */}
							<div className="message-input">
									<Button className="beam-btn ml-2" onClick={openPopupModal}><ReactSVG src="/images/plus-icon.svg" className="add-icon"/></Button>
									<TextArea value={text} autoSize={{ minRows: 1, maxRows: 6 }} onChange={(e) => setText(e.target.value)} placeholder="Type a message..." />
									<Button className="beam-btn ml-2" onClick={handleMessageSend}>{text ? <ReactSVG src="/images/send.svg" /> : <ReactSVG onClick={handleVideoIconClick} src="/images/media-icon.svg" />}</Button>
							</div>
							{text &&
								<div>
									{selectedMessageType === 'snap' && <Col span={24} className="datetime-item mt-1 mb-1">
										<img src="/images/time.png" /><TimePicker placeholder="Enter time in secs" suffixIcon={<DownOutlined />} />
									</Col> }
									<Row className="d-flex px-2">
										<Col>Message Type</Col>
										<Col className="text-right" span={6}><Button onClick={() => setSelectedMessageType('text')} className={`type-button${selectedMessageType === 'text' ?  ' type-selected': ''}`}>Text</Button></Col>
										<Col className="text-right" span={5}><Button onClick={() => setSelectedMessageType('snap')} className={`type-button${selectedMessageType === 'snap' ?  ' type-selected': ''}`}>Snap</Button></Col>
										<Col className="text-right" span={6}><Button onClick={handleClassify} className={`type-button${selectedMessageType === 'classify' ? ' type-selected': ''}`}>Classify</Button></Col>
									</Row>
								</div>
							}
					</div>
				</div>
			{popupDisplayed && <PopupModal client={client} type="MESSAGE" onClose={()=> setPopUpDisplayed(false)} /> }
			{filterPopupDisplayed && <PopupModal onSelectedFilterUpdate={console.log} type="FILTER" onClose={()=> setFilterPopUpDisplayed(false)} /> }
			{settingsModalDisplayed && <PageSettingsModal onClose={() => setSettingsModalDisplayed(false)} /> }
			{surveyModalDisplayed && <AddSurveyModal onClose={() => setSurveyModalDisplayed(false)} /> }
			{summaryModalDisplayed && <AnalyticsSummaryModalTemp onClose={() => setSummaryModalDisplayed(false)} /> }
			{peoplePopupDisplayed && <PeopleModal client={client} onClose={() => setPeoplePopupDisplayed(false)} />}

			</div>
	)
}

export default MessagePanel;
