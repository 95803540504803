import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Section,
  Container,
  Columns,
  Button,
  Form,
  Notification
} from "react-bulma-components";

import videoRoomPropType from "../../propTypes/videoRoom";
import VideoRoom from "../VideoRoom/VideoRoom";
import FieldInput from "../Fields/FieldInput";

import AppContainer from "./AppContainer";

const App = (props) => {
  const {
    videoRoom,
    userName,
    roomName,
    isJoining,
    isVideoSupported,
    isScreenSharingSupported,
    isScreenSharingEnabled,
    canJoin,
    onJoin,
    onLeave,
    onShare,
    handleMuteChange,
    handleVideoChange,
    onUserNameChange,
    onRoomNameChange,
    errorMessage,
    onErrorMessageHide, 
  } = props;
  console.log(props)
  let content = null;

  const [isMuted, setMuted] = useState(false);
  const [isVideoPaused, setVideoPaused] = useState(true);
  if (!isVideoSupported) {
    content = <div>Video is not supported</div>;
  } else {
    content = videoRoom ? (
      <>
        {<VideoRoom videoRoom={videoRoom} />}

        <div className="video-actions">
          <Button className="beam-btn ml-2" onClick={() => {handleMuteChange(!isMuted); setMuted(!isMuted)}}>
            {!isMuted ? 
              <img className="action-icon" src="/images/unmuted-icon.png" /> :
              <img className="action-icon" src="/images/muted-icon.png" /> 
            }
          </Button>
          <Button className="beam-btn ml-2" onClick={() => {handleVideoChange(!isVideoPaused); setVideoPaused(!isVideoPaused)}}>
            {!isVideoPaused ? 
              <img className="action-icon" src="/images/resume-video-icon.png" /> :
              <img className="action-icon" src="/images/pause-video-icon.png" /> 
            }
          </Button>
          <Button className="beam-btn ml-2" disabled={!isScreenSharingSupported} onClick={() => onShare()}>
            {isScreenSharingEnabled ? 
              <img className="action-icon" src="/images/stop-screen-share.png" /> : 
              <img className="action-icon" src="/images/screen-share-icon.png" /> 
            }
          </Button>
          <Button className="beam-btn ml-2" onClick={() => onLeave()}>
            <img className="action-icon" src="/images/end-call.png" /> 
          </Button>
          
        </div>
      </>
    ) :  isJoining ? (
        <div className="page-alert">
          <h4>Starting Call</h4>
          <p>Please wait...</p>
        </div> 
      ) : (
        <div className="page-alert">
          <h4>Call Ended</h4>
        </div>
      )
  }

  return (
    <Section>
      <Container>
        <h1>{roomName}</h1>
        {errorMessage && (
          <Notification color="danger">
            Error: {errorMessage}
            <Button onClick={onErrorMessageHide} remove />
          </Notification>
        )}
        {content}
      </Container>
    </Section>
  );
};

App.propTypes = {
  videoRoom: videoRoomPropType,
  userName: PropTypes.string.isRequired,
  roomName: PropTypes.string.isRequired,
  isJoining: PropTypes.bool.isRequired,
  isVideoSupported: PropTypes.bool.isRequired,
  isScreenSharingSupported: PropTypes.bool.isRequired,
  isScreenSharingEnabled: PropTypes.bool.isRequired,
  canJoin: PropTypes.bool.isRequired,
  onJoin: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  onUserNameChange: PropTypes.func.isRequired,
  onRoomNameChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  onErrorMessageHide: PropTypes.func.isRequired,
  token: PropTypes.string
};

App.defaultProps = {
  videoRoom: null,
  errorMessage: null
};

const render = containerProps => <App {...containerProps} />;
export default props => <AppContainer render={render} {...props} />;
