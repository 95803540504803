import {geGPSApiV1, geRXApiV1, geVideoApiV1, getCUDBClientApiV1, getApiV1 as v1Api} from "../api";
import {getMessagingApiV1 as v1MessageApi} from "../api";
import {parseApiResponse, parseApiResponseJSON} from '../apiError';
import URls from "../../../constants/urls";

export const fetchRequestToken = (auth0UserId) => {
  return v1Api().post('/', JSON.stringify({auth0UserId: auth0UserId}))
    .then(response => parseApiResponse(response))
};

export const getPosts = (auth0Userd) => {
  return v1Api().get(`/${URls.GET_LIST}`)
    .then(response => parseApiResponse(response))
};

export const loginUser = async (payload) => {
  return v1Api().postJson('/login', payload.data)
    .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const authenticate = async (payload) => {
  return v1Api().postJson('/authenticate', payload.data)
    .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const registerUser = async (payload) => {
  const { email, password, phoneNumber } = payload;
  // const register = {email, password, phoneNumber, beamId: '1', termsandcondition: true};
  return v1Api().postJson('/register', payload.data)
    .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const requestResetPassword = async (payload) => {
  const { email, password, phoneNumber } = payload;
  // const register = {email, password, phoneNumber, beamId: '1', termsandcondition: true};
  return v1Api().postJson('/addAuth', payload.data)
    .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const resetPassword = async (payload) => {
  const { email, password, phoneNumber } = payload;
  // const register = {email, password, phoneNumber, beamId: '1', termsandcondition: true};
  return v1Api().postJson('/modifyAuth', payload.data)
    .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const modifyIds = async (payload) => {
  const { email, password, phoneNumber } = payload;
  // const register = {email, password, phoneNumber, beamId: '1', termsandcondition: true};
  return v1Api().postJson('/modifyIds', payload.data)
    .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const getUserInfo = async (payload) => {
  return v1Api().postJson('/searchInUser', payload.data)
    .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const getUserAccount = async (payload) => {
  return v1Api().postJson('/getUserAccount', payload.data)
    .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const updateUserInfo = async (payload) => {
  return v1Api().postJson('/updateUser', payload.data)
    .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const getUsersList = async (payload) => {
  return v1Api().postJson(`/searchUser`, payload.data)
    .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const uploadMedia = async (payload) => {
  return v1MessageApi().postWithoutHeaders('/upload/media', payload)
      .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const getGPSHistoricalData = async (payload) => {
  return geGPSApiV1().postJson('gps/list', payload)
      .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const getRXData = async (id) => {
  return geRXApiV1().get('page/rx2?deviceId=' + id)
      .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const getVideoSnapshot = async (id) => {
  let headers = {'APIKey': process.env.REACT_APP_VIDEO_API_KEY}
  return geVideoApiV1().getWithHeaders(`/SmartWitness/RESTCommandWebService/ExecuteServerCommand/Snapshots/GetURLs?RecorderID=${id}&CameraChannel=*`, headers)
  // return geVideoApiV1().getWithHeaders(`SmartWitness/RESTCommandWebService/ExecuteServerCommand/Devices/All`, headers)
      .then(response => parseApiResponseJSON(response)).catch(error => error.response.data.message)
};

export const getMapPlaces = async (payload) => {
  return v1MessageApi().postJson('/get/placesByBounds', payload)
      .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const getMapPlacesFilter = async (payload) => {
  return v1MessageApi().postJson('/get/places', payload)
      .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};

export const getLocationProfile = async (payload) => {
  return getCUDBClientApiV1().postJson(`/searchInUser`, payload)
      .then(response => parseApiResponse(response)).catch(error => error.response.data.message)
};
