import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Row, Col, Radio, Select, Switch, Checkbox, DatePicker, Collapse } from 'antd';
import { SearchOutlined, DownOutlined, CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import _ from "lodash";
import Icon from '@ant-design/icons';
import { ReactSVG } from 'react-svg';
import Geocode from "react-geocode";
import {useDispatch, useSelector} from "react-redux";
import { CompactPicker } from 'react-color';

const google = window.google = window.google ? window.google : {}

Geocode.setApiKey("AIzaSyDNIyoSDV1wnJVW8wdHkiNG-eZ2bMcq0iY");
Geocode.setLanguage("en");
Geocode.setRegion("us");

const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

function PinPop(props) {
    const { currentLocation, setActivePop, marker, showDirection, setActive } = props;
    const {location_profiles} = useSelector(state => state.dashboard);
    const [address, setAddress] = useState();
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [profile, setProfile] = useState([]);
    const [geometry, setGeometry] = useState();
    const [type, setType] = useState();
    const [paths, setPaths] = useState();
    const [icon, setIcon] = useState('plain');
    const [color, setColor] = useState('green');
    const [flag, setFlag] = useState('green');
    const [timestamp, setTimestamp] = useState(new Date());
    const [eta, setETA] = useState({enable: false, eta: ''});
    const [geocode, setGeocode] = useState({enable: false, radius: '', alert: [], expiry: new Date()});
    const [edit, toggleEdit] = useState();

    const dispatch = useDispatch();

    const handleClear = () => {
        if(geometry)geometry.setMap(null);
        setActivePop();
    }

    const getPolygonPaths = (polygon) => {
        var polygonBounds = polygon.getPath().getArray();
        var bounds = [];
        for (var i = 0; i < polygonBounds.length; i++) {
            var point = {
                lat: polygonBounds[i].lat(),
                lng: polygonBounds[i].lng()
            };
            bounds.push(point);
        }
        bounds.push(bounds[0]);
        reverseGeocode(shapeCenter(bounds));
        return bounds;
    }

    const shapeCenter  = (arr) => {
        var x = arr.map (xy => xy.lat);
        var y = arr.map (xy => xy.lng);
        var cx = (Math.min (...x) + Math.max (...x)) / 2;
        var cy = (Math.min (...y) + Math.max (...y)) / 2;
        return {lat: cx, lng: cy};
    }


    useEffect(() => {
        setTitle(marker.title);
        if(marker.geometry)setGeometry(marker.geometry);
        if(marker.description)setDescription(marker.description);
        if(marker.flag)setFlag(marker.flag);
        if(marker.color)setColor(marker.color);
        if(marker.icon)setIcon(marker.icon);
        if(marker.timestamp)setTimestamp(new Date(marker.timestamp));
        if(marker.gpsTimestamp)setTimestamp(new Date(marker.gpsTimestamp));
        if(marker.eta)setETA(marker.eta);
        if(marker.geocode)setGeocode(marker.geocode);
        if(marker.settings?.profile?.[0] ?? false){
            let profiles = [];
            if(marker.objectType === 'place')
                profiles = location_profiles?.admin?.clbs?.profile ?? [];
            else if(marker.objectType === 'asset')
                profiles = location_profiles?.admin?.asset?.profile ?? [];
            profiles = profiles.filter((e)=>{return marker.settings.profile.indexOf(e.id) !== -1});
            setProfile(profiles.map((e)=>{return e.name}))
        }
        setType(marker.type);
    }, []);

    useEffect(() => {
        const {mode, paths} = marker;
        switch (type) {
            case 'polygon':
                if(mode === 'new'){
                    setPaths(getPolygonPaths(marker.geometry));
                }
                else{
                    setPaths(paths);
                    reverseGeocode(shapeCenter(paths))
                }
                break;
            case 'marker':
                setPaths({lat: marker.lat, lng: marker.lng})
                reverseGeocode(marker);
                break;
            case 'gps':
                setPaths({lat: marker.lat, lng: marker.lng})
                reverseGeocode(marker);
                break;
            case 'circle':
                setPaths({lat: marker.lat, lng: marker.lng, radius: marker.radius})
                reverseGeocode(marker);
                break;
        }
        if(marker.geometry)
            marker.geometry.setMap(null);

    }, [type]);

    const handleSave = () => {
        const {mode, id} = marker;
        let data = {
            title,
            description,
            type,
            paths,
            flag,
            color,
            icon,
            timestamp,
            eta,
            geocode,
            address,
        };

        if(mode === 'edit'){
            data['originalPageMessageId'] = id;
            data['flag'] = 'replace'
        }
        console.log('mode', mode, data)

        dispatch({type: 'SEND_MESSAGE', payload: {message: title, type: 'place', data}});
        if(geometry)geometry.setMap(null);
        setActivePop();
    }

    const handleDirection = () => {
        // setActivePop(null);
        // const DirectionsService = new google.maps.DirectionsService();
        // DirectionsService.route({
        //     origin: new google.maps.LatLng(currentLocation.lat, currentLocation.lng),
        //     destination: new google.maps.LatLng(marker.lat, marker.lng),
        //     travelMode: google.maps.TravelMode.DRIVING,
        // }, (result, status) => {
        //     if (status === google.maps.DirectionsStatus.OK) {
        //         setActive(true)
        //         showDirection(result)
        //     } else {
        //       console.error(`error fetching directions ${result}`);
        //     }
        // });
        setActivePop(null);
        props.showDirection(new google.maps.LatLng(marker.lat, marker.lng))
    }

    const reverseGeocode = (marker) => {
        Geocode.fromLatLng(marker.lat, marker.lng).then(
            (response) => {
                setAddress(response.results[0].formatted_address)
            },
            (error) => {
                console.error(error);
            }
        );
    }

    const updateEta = (key, value) => {
        setETA(prevState => ({
            ...prevState,
            [key]: value
        }));
    }

    const updateGeocode = (key, value) => {
        setGeocode(prevState => ({
            ...prevState,
            [key]: value
        }));
    }


    // if (!address)
    //     return null

    return (
        <div className="pop-content">
            <Row className="d-flex mb-4">
                <Col className="d-flex">
                    {!edit ?
                        <h3 className="mr-3">{title}</h3> :
                        <Input value={title} onChange={(e) => setTitle(e.target.value)}/>
                    }
                    <ReactSVG src="/images/edit-icon.svg" onClick={()=>{toggleEdit(!edit)}}/>
                </Col>
                <Col><CloseOutlined className="close-icon" onClick={() => {
                    if(geometry)geometry.setMap(null);
                    setActivePop();
                }}/></Col>
            </Row>
            <Row className="mt-3 d-line">
                <Col className="pr-3"><ReactSVG src="/images/gps-icon.svg" className="pin-icons" /></Col>
                <Col className="pr-3"><h5 style={{color: '#40CC52'}}>{JSON.stringify(paths)}</h5></Col>
                {/*<Col><h5 style={{color: '#508AFA'}}>{marker.lng}</h5></Col>*/}
            </Row>
            {type === 'gps' &&
            <React.Fragment>
                <hr/>
                <Row className="mt-3 d-line">
                    <Col className="pr-3"><ReactSVG src="/images/tester-icon.svg" className="pin-icons"/></Col>
                    <Col className="pr-3"><h5>{marker.speed?.toFixed(2) ?? 0} {marker.speedUnit}</h5></Col>
                    <Col className="pr-3"><ReactSVG src="/images/heading-icon.svg" className="pin-icons"/></Col>
                    <Col><h5>Heading: {Math.floor(marker.heading)}°</h5></Col>
                </Row>
            </React.Fragment>
            }
            <hr />
            <Row className="mt-3 d-line">
                <Col className="pr-3"><ReactSVG src="/images/timestamp-icon.svg" className="pin-icons" /></Col>
                <Col span={18} className="pr-3"><h5>Timestamp: {timestamp.toLocaleString()}</h5></Col>
            </Row>
            <hr />
            <Row className="mt-3 d-line">
                <Col className="pr-3"><ReactSVG src="/images/home-icon.svg" /></Col>
                <Col span={18} className="pr-3"><h5>
                    {address ? address: 'N/A'}
                </h5></Col>
            </Row>
            <hr />
            <Collapse defaultActiveKey={[]} bordered={false}>
                <Panel header={
                    <Row className="d-line">
                        <Col className="pr-3"><EllipsisOutlined style={{ fontSize: 20 }} /></Col>
                        <Col><h5 style={{ color: '#91A5C6'}}>More details</h5></Col>
                    </Row>
                } key="1">
                    <hr />
                    {type === 'marker' && marker.mode !== 'viewOnly' &&
                    <React.Fragment>
                        <h5>Select Pin Icon</h5>
                        <Row className="d-flex mt-2 map-type-list">
                            {['plain', 'minus', 'cross', 'plus', 'star', 'exclamation'].map(item =>
                                <Col span={4} key={item}><Button onClick={() => {
                                    setIcon(item)
                                }} className="primary beam-btn"><ReactSVG src={`/images/map-${item}-icon.svg`}
                                                                          className={item === icon ? 'active-map' : ''}/></Button></Col>
                            )}
                        </Row>
                        <hr/>
                        <h5>Select Pin Color</h5>
                        <Row className="d-flex mt-2 map-color-list">
                            {['green', 'blue', 'orange', 'yellow', 'gray', 'purple'].map(item =>
                                <Col span={4} key={item}><Button onClick={() => {
                                    setColor(item)
                                }} className={`primary beam-btn ${item === color ? 'beam-btn-active' : ''}`}><ReactSVG
                                    src="/images/map-plain-icon.svg" className={`${item}-icon`}/></Button></Col>
                            )}
                        </Row>
                        {/*<hr/>*/}
                        {/*<h5>Select Flag</h5>*/}
                        {/*<Row className="d-flex mt-2">*/}
                        {/*    {['green', 'blue', 'orange', 'yellow', 'gray'].map(item =>*/}
                        {/*        <Col key={item}><Button onClick={() => {*/}
                        {/*            setFlag(item)*/}
                        {/*        }} className={`beam-btn round-btn ${item === flag ? 'beam-btn-active' : ''}`}><img*/}
                        {/*            src={`/images/flag-${item}.png`}/></Button></Col>*/}
                        {/*    )}*/}
                        {/*</Row>*/}
                    </React.Fragment>
                    }
                    {/*<hr />*/}
                    {/*<h5>Select Shape</h5>*/}
                    {/*<Row className="d-flex mt-2">*/}
                    {/*    <Col span={4} className="pr-2 t-center"><Button className="primary beam-btn"><ReactSVG src="/images/dot-icon.svg"/></Button><p>Point</p></Col>*/}
                    {/*    <Col span={4} className="pr-2 t-center"><Button className="primary beam-btn"><ReactSVG src="/images/area-icon.svg"/></Button><p>Area</p></Col>*/}
                    {/*    <Col span={4} className="pr-2 t-center"><Button className="primary beam-btn"><ReactSVG src="/images/circle-icon.svg"/></Button><p>Circle</p></Col>*/}
                    {/*    <Col span={10}><Input placeholder="Radius" style={{height: 40}} /><p>&nbsp;</p></Col>*/}
                    {/*</Row>*/}
                    <h5 className="mt-3">Location Description</h5>
                    <Row className="mt-2">
                        <Col span={24}>
                            <TextArea value={description} onChange={(e) => setDescription(e.target.value)} autoSize={{ minRows: 4, maxRows: 6 }} placeholder="Description" />
                        </Col>
                    </Row>
                    <Row className="d-flex px-2">
                        <Col><p>Optional</p></Col>
                        <Col><p>max. 500 character</p></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}>
                            <Select mode="multiple" className="w-full" placeholder="Profile" value={profile} />
                        </Col>
                    </Row>

                    {marker.type !== 'marker' &&
                    <React.Fragment>
                        <hr/>
                        <h5>Select shape color</h5>
                        <Row className="mt-3">
                            <Col span={24}>
                                <CompactPicker
                                    color={color}
                                    onChangeComplete={setColor}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                    }

                </Panel>
            </Collapse>
            {marker.mode !== 'viewOnly' &&
            <React.Fragment>
                {type === 'marker' &&
                <React.Fragment>
                    <Row className="d-flex px-2 mt-3">
                        <Col span="7"><h5>Disable ETA</h5></Col>
                        <Col span="6">
                            <Switch checked={eta.enable} onChange={() => {
                                updateEta('enable', !eta.enable)
                            }}/>
                        </Col>
                        <Col span="11">
                            <Input value={eta.eta} onChange={(e) => updateEta('eta', e.target.value)}
                                   placeholder="Minutes"/>
                        </Col>
                    </Row>
                    <hr/>
                </React.Fragment>
                }
                <Row className="geofence-panel mt-3">
                    <Col span={24}>
                        <Row className="d-flex">
                            <Col><h5>Geofence</h5></Col>
                            <Col><Switch checked={geocode.enable} onChange={()=>{updateGeocode('enable', !geocode.enable)}}/></Col>
                        </Row>
                        {type === 'marker' &&
                        <Row className="mt-2">
                            <Col span={24}><Input value={geocode.radius}
                                                  onChange={(e) => updateGeocode('radius', e.target.value)}
                                                  placeholder="Radius(m)"/></Col>
                        </Row>
                        }
                        <Row className="d-flex pl-2 mt-3">
                            <Col span="7"><h5>Alert on:</h5></Col>
                            <Col>
                                <Checkbox.Group onChange={(values)=>{updateGeocode('alert', values)}} value={geocode.alert} options={['Enter', 'Exit', 'Inside']}/>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col span={24}>
                                <div className="datetime-item">
                                    <img src="/images/date.png"/><DatePicker onChange={(date)=>{updateGeocode('expiry', date.toDate())}} placeholder="Expiration date"
                                                                             suffixIcon={<DownOutlined/>}/>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
            }
            <Row className="action-grp d-flex mt-2">
                <Col span={12} className="pr-2">
                    <Button className="beam-btn blue-btn cover w-full" onClick={handleDirection}>Directions</Button>
                </Col>
                <Col span={12} className="pl-2">
                    <Button className="beam-btn yellow-btn cover w-full">Navigation</Button>
                </Col>
            </Row>
            {type !== 'gps' && marker.mode !== 'viewOnly' &&
            <Row className="action-grp d-flex mt-3">
                <Col span={12} className="pr-2">
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleClear}>Clear</Button>
                </Col>
                <Col span={12} className="pl-2">
                    <Button className="beam-btn green-btn bordered w-full" onClick={handleSave}>Save</Button>
                </Col>
            </Row>
            }
        </div>
    )
}

export default PinPop;
