import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, DatePicker, Collapse } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import _ from "lodash";
import './index.scss'
import { GET_USER_INFO, UPDATE_USER_INFO } from 'reducers/authReducer';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import UploadFileModal from './UploadFileModal';

const { Panel } = Collapse;


function EditBioModal(props) {
    const { onClose } = props;
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [gender, setGender] = useState('Male');
    const [dob, setDOB] = useState(moment());
    const [address, setAddress] = useState('');
    const { userInfo } = useSelector(state => state.auth);
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
   
    useEffect(() => {
        dispatch(GET_USER_INFO());
    }, [])

    useEffect(() => {
        if (userInfo) {
            setFirstname(userInfo.firstName)
            setLastname(userInfo.lastName)
            setAddress(userInfo.address)
            setGender(userInfo.gender || 'Male')
            setDOB(userInfo.dob ? moment(userInfo.dob, 'YYYY-MM-DD') : moment())
        }
        console.log('userInfo', userInfo);
    }, [userInfo])

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };

    const handleOk = () => {
        dispatch(UPDATE_USER_INFO({...userInfo, firstName: firstname, lastName: lastname, address, gender, dob: moment(dob).format('YYYY-MM-DD')}))
        setIsModalVisible(false);
        onClose && onClose();
    }

    const [imgData, setImgData] = useState(null);
    const onChangePicture = e => {
      if (e.target.files[0]) {
        console.log("picture: ", e.target.files);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    };

    return (
        <Modal title="Edit Bio" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row className="text-center mb-5">
                <Col span={24}>
                    <img src={imgData || '/images/scene.png'} 
                        className="profileImage cursor-pointer" onClick={() => setIsUploadModalVisible(true)} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Input value={firstname} placeholder="First Name" onChange={e => setFirstname(e.target.value)} />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={24}>
                    <Input value={lastname} placeholder="Last Name" onChange={e => setLastname(e.target.value)} />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={24}>
                    <div className="datetime-item">
                        <img src="/images/date.png" /><DatePicker value={dob} placeholder="Date of Birth" onChange={e => setDOB(e)} suffixIcon={<DownOutlined />} />
                    </div>
                </Col>
            </Row>

            <Row className="d-flex mb-4 mt-3">
                <Col span={12} className="pr-2"><Button onClick={() => setGender('Female')} className={`beam-btn w-full${gender==='Female' ? " green-btn cover": ""}`}>Female</Button></Col>
                <Col span={12} className="pl-2"><Button onClick={() => setGender('Male')} className={`beam-btn w-full${gender==='Male' ? " green-btn cover": ""}`}>Male</Button></Col>
            </Row>

            <Row>
                <Col span={24}>
                    <div className="datetime-item prefix-select-wrapper">
                        <img src="/images/location.png" />
                        <Input value={address} onChange={e => setAddress(e.target.value)}  className="field-with-image" placeholder="Enter Street Address" />
                    </div>
                </Col>
            </Row>

            <Row className="action-grp mt-4">
                <Col span={14} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full" onClick={handleOk}>Save</Button>
                </Col>
                <Col span={10} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                </Col>
            </Row>
            {isUploadModalVisible && <UploadFileModal isModalVisible={isUploadModalVisible} setIsModalVisible={setIsUploadModalVisible} onClose={() => setIsUploadModalVisible(false)}/> }
        </Modal>
    )
}

export default EditBioModal;
