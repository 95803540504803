import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, Collapse, Checkbox, DatePicker, Switch } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import _ from "lodash";

const { Panel } = Collapse;
const { TextArea } = Input;

function LocationEditModal(props) {
    const { onClose } = props;
    const [isModalVisible, setIsModalVisible] = useState(true);

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };

    const handleOk = () => {
        setIsModalVisible(false);
    }

    return (
        <Modal title="Edit Location" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row>
                <Col span={24}>
                    <Input placeholder="Title" />
                </Col>
            </Row>
            <Row className="d-flex px-2 mt-3">
                <Col span="7"><h5>Disable ETA</h5></Col>
                <Col span="5">
                    <Switch />
                </Col>
                <Col span="12">
                    <Input placeholder="Minutes" />
                </Col>
            </Row>
            <hr />
            <Row className="geofence-panel mt-3">
                <Col span={24}>
                    <Row className="d-flex">
                        <Col><h5>Geofence</h5></Col>
                        <Col><Switch /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}><Input placeholder="Radius" /></Col>
                    </Row>
                    <Row className="d-flex pl-2 mt-3">
                        <Col span="7"><h5>Alert on:</h5></Col>
                        <Col>
                            <Checkbox.Group options={['Enter', 'Exit', 'Inside']} defaultValue={['Read']} />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}>
                            <div className="datetime-item">
                                <img src="/images/date.png" /><DatePicker placeholder="Expiration date" suffixIcon={<DownOutlined />} />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={24}>
                    <TextArea autoSize={{ minRows: 4, maxRows: 6 }} placeholder="Profile" />
                </Col>
            </Row>
            <Row className="d-flex px-3">
                <Col>
                    <p>Optional</p>
                </Col>
                <Col>
                    <p>max. 500 character</p>
                </Col>
            </Row>
            <Row className="action-grp d-flex mt-4">
                <Col span={8} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full" onClick={handleOk}>Add</Button>
                </Col>
                <Col span={8} className="pl-2 pr-2">
                    <Button className="beam-btn yellow-btn bordered w-full" onClick={handleOk}>Map</Button>
                    </Col>
                <Col span={8} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default LocationEditModal;
