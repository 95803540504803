import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useSocket from 'use-socket.io-client';

export default () => {
    const [socket] = useSocket(process.env.REACT_APP_GPS_SOCKET);
    const dispatch = useDispatch();


    useEffect(() => {
        return () => {
            if (socket) {
                socket.disconnect(() => {
                    console.log('socket disconnected');
                });
            }
        }
    },[]);

    const handleData = (response) => {
        const gps = response.data.gps;
        if (!gps) {
            return;
        }
        console.log('gps live added here', gps)
        dispatch({type: 'ADD_GPS', payload: {response: {meta_data: {gps: [gps]}}, live: true}})
        // dispatch gps data
    };

    const connectSocket = () => {
        socket.connect();
        socket.on('connect', ()=>{console.log('socket connected')});
        socket.on('error', (error)=>{console.log('socket error')});
        // Handle incoming gps data
        socket.on('gpsDetails', handleData);
    };

    const subscribeToImeis = (imeis) => {
        console.log('subscribed imei', imeis)
        if (imeis.length > 0) {
            socket.emit('gps/subscribe-for-devices', { devices: imeis });
        }
    };

    const unsubscribeToImeis = (imeis) => {
        console.log('un subscribed imei', imeis)
        if (imeis.length > 0) {
            socket.emit('gps/unsubscribe-from-devices', { devices: imeis });
        }
    };

    return {
        socket,
        connectSocket,
        subscribeToImeis,
        unsubscribeToImeis
    }
};
