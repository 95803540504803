export const getPagesSubscriptionTopic = () => {
    return `${process.env.REACT_APP_ECO_SYSTEM}/service/live/connection/${localStorage.getItem('CID')}/searchPageResult`;
};

export const getPagesRequestPublishTopic = () => {
    return `${process.env.REACT_APP_ECO_SYSTEM}/connection/${localStorage.getItem('CID')}/service/live/searchPage`;
};

export const getCreatePagePublishTopic = () => {
    return `${process.env.REACT_APP_ECO_SYSTEM}/connection/${localStorage.getItem('CID')}/service/live/createPage`;
};

export const getCLBSLocations = () => {
    return `get/places`;
};

export const getTwilioTokenSubscriptionTopic = () => {
    return `${process.env.REACT_APP_ECO_SYSTEM}/service/twilio/connection/${localStorage.getItem('CID')}/getTokenResult`;
};

export const getTwilioTokenPublishTopic = () => {
    return `${process.env.REACT_APP_ECO_SYSTEM}/connection/${localStorage.getItem('CID')}/service/twilio/getToken`;
};

export const getTwilioJoinRoomSubscriptionTopic = () => {
    return `${process.env.REACT_APP_ECO_SYSTEM}/service/twilio/connection/${localStorage.getItem('CID')}/joinRoomResult`;
};

export const getTwilioJoinRoomPublishTopic = () => {
    return `${process.env.REACT_APP_ECO_SYSTEM}/connection/${localStorage.getItem('CID')}/service/twilio/joinRoom`;
};

export const getTwilioLeaveRoomSubscriptionTopic = () => {
    return `${process.env.REACT_APP_ECO_SYSTEM}/service/twilio/connection/${localStorage.getItem('CID')}/leaveRoomResult`;
};

export const getTwilioLeaveRoomPublishTopic = () => {
    return `${process.env.REACT_APP_ECO_SYSTEM}/connection/${localStorage.getItem('CID')}/service/twilio/leaveRoom`;
};
