import React from 'react';
import './index.scss';
import { Link } from "react-router-dom";
import { 
  Row,
  Col,
  Button
} from 'antd';

function Bottomfooter(props) {

  const goToTopScreen = () => {
    window.scrollTo(0, 0);
  }
  
  return (
    <Row className="footer">
      <Col span={10}>
        <p>2021 Beam.live. All rights reserved.</p>
      </Col>
      {/* <Col span={2} className="t-center">
        <Button onClick={goToTopScreen}><img alt='1' src="/images/top.png" /></Button>
      </Col> */}
      <Col span={10} className="t-right">
        <Link>Privacy Policy</Link>
        <Link>Terms & Conditions</Link>
      </Col>
    </Row>
  )
}

export default Bottomfooter;
