import React, { useEffect } from 'react';
import './index.scss';
// import logoImg from '../../assets/images/logo.png';
import { Link } from "react-router-dom";
import { 
  Form,
  Input,
  Button,
  Row,
  Col,
  Menu,
  Dropdown
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

function TopHeader(props) {
  
  useEffect(() => {
  }, [])

  const menu = (
    <Menu>
      <Menu.Item key="0">Menu1</Menu.Item>
      <Menu.Item key="1">Menu2</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2">Menu2</Menu.Item>
    </Menu>
  );

  return (
    <Row className="header">
      <Col span={11}>
        <Link to="/"><img alt='1' src="/logo-small.png" /></Link>
      </Col>
      <Col span={13}>
        <Row>
          <Col span={18}>
            <Menu mode="horizontal" arrow={false}>
              <Menu.Item key="industries">Industries</Menu.Item>
              <Menu.Item key="products">Products</Menu.Item>
              <Menu.Item key="Partners">Partners</Menu.Item>
              <Menu.Item key="developers">Developers</Menu.Item>
              <Menu.Item key="about">About</Menu.Item>
              <Menu.Item key="contact-us">Contact us</Menu.Item>
            </Menu>
          </Col>
          <Col span={6}>
            <div className="navs">
              <div className="nav">
                <Dropdown overlay={menu} trigger={['click']}>
                  <a href="#"><span className="mr-1">EN</span><DownOutlined /></a>
                </Dropdown>
              </div>
              <div className="nav">
                <Link to="/blogs">Blog</Link>
              </div>
              <div className="nav">
                <Link to="/auth/login" className="auth-btn">Sign in</Link>
              </div>
            </div>
          </Col>          
        </Row>
      </Col>
    </Row>
  )
}

export default TopHeader;
