import React, { useState } from 'react';
// svg 
export default ({color = '#91A5C6'}) => {
  const [isHovered, setHover] = useState(false);
  
  return (
    <svg 
      onMouseEnter={() => setHover(true)} 
      onMouseLeave={() => setHover(false)}  width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd"
       clipRule="evenodd" 
       d="M17.7795 0C18.8841 0 19.7795 0.89543 19.7795 2C19.7795 2.46168 19.6198 2.90915 19.3275 3.26648L13.4521 10.4475C13.1598 10.8048 13 11.2522 13 11.7139V15.5585C13 16.4193 12.4492 17.1836 11.6325 17.4558L9.63249 18.1225C8.5846 18.4718 7.45196 17.9055 7.10267 16.8576C7.03469 16.6537 7.00003 16.4401 7.00003 16.2251V11.7139C7.00003 11.2522 6.8403 10.8048 6.54795 10.4475L0.672605 3.26648C-0.0268505 2.41159 0.099154 1.15154 0.954044 0.452085C1.31137 0.159729 1.75883 0 2.22052 0H17.7795ZM17.7795 2H2.22052L8.09586 9.18097C8.63185 9.83607 8.94495 10.6427 8.99341 11.4838L9.00003 11.7139V16.225L11 15.5585V11.7139C11 10.8675 11.2685 10.045 11.7636 9.36331L11.9042 9.18097L17.7795 2Z" 
       fill={isHovered ? '#508AFA': (color || '#91A5C6')}/>
    </svg>
  )
}
