import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Input, Button, Popover, message } from "antd";
import { ReactSVG } from 'react-svg';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { compose, withProps, withStateHandlers, lifecycle } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon, DirectionsRenderer } from "react-google-maps"
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import FilterIcon from 'assets/SVGs/FilterIcon';
import SettingsIcon from 'assets/SVGs/SettingsIcon';
import './MapPanel.scss'

const MARKERS = [
  {id: '1', title: 'marker1', type: 'green', lat: 51.809865, lng: -0.018092 }, 
  {id: '2', title: 'marker2', type: 'green', lat: 51.609865, lng: -0.218092 }, 
  {id: '3', title: 'marker3', type: 'blue', lat: 51.509865, lng: -0.818092 }, 
  {id: '4', title: 'marker4', type: 'orange', lat: 51.909865, lng: -1.18092 },
  {id: '5', title: 'marker5', type: 'orange', lat: 51.109865, lng: 0.54092 },
  {id: '6', title: 'marker6', type: 'orange', lat: 51.309865, lng: 1.21092 },
  {id: '7', title: 'marker7', type: 'orange', lat: 51.029865, lng: 1.56092 }
]

const DEFAULT_LOCATION = { lat: 51.509865, lng: -0.118092 }

const Map = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDNIyoSDV1wnJVW8wdHkiNG-eZ2bMcq0iY&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withStateHandlers(() => ({
    isOpen: false,
    activeMarker: null,
    directions: null
  }), {
    onToggleOpen: ({ isOpen }) => (marker) => ({
      isOpen: !isOpen,
      activeMarker: marker
    }),
    showDirection: ({directions}) => (result) => ({
      directions: result
    })
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap
    defaultZoom={14}
    center={props.location}
    onClick={props.onClick}
    defaultOptions={{fullscreenControl: false}}
  >
    <Marker position={props.location} icon={{
      url: `/images/Place-icon.png`,
    }}
      onClick={() => props.onToggleOpen({
        id: '0', title: 'current location', type: 'green', lat: props.location.lat, lng: props.currentLocation.lng
      })}
    ></Marker>
  </GoogleMap>
)

function MapPreview(props) {
  const { selectedItem, mapType } = useSelector(state => state.search);

  const {screens, setScreens, location} = props;
  const [activePop, setActivePop] = useState();
  const [markers, setMarkers] = useState(MARKERS);
  const [active, setActive] = useState(true);
  const googleSearchInputRef = useRef();
  const [showMap, setShowMap] = useState(true);

  const delay = (delayInms) => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(1);
      }, delayInms);
    });
  }

  const changeMapStatus = async () => {
    setShowMap(false)
    await delay(50)
    setShowMap(true)
  }

  useEffect(() => {
    changeMapStatus()
  }, [location])

  console.log(location)

  return (
    <div className="main-column map-panel">
      <div className="main-content">
        <div className="main-cards">
          { showMap && <Map
            isMarkerShown={false}
            onClick={(event) => {}}
            location={location}
            markers={markers}
            active={active}
            setActive={setActive}
          />}
          {/* <Row className="map-address">
            <span>{location.address}</span>
          </Row> */}
        </div>
        
        {/* <div
            className="map-overlay"
            flex={1}
            backgroundColor="rgba(0,0,0,0.3)"
            zIndex={10000}
            alignItems="center"
            justifyContent="center"
            >
        </div> */}
      </div>
    </div>
  )
}

export default MapPreview;
