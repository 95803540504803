import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import { ReactSVG } from 'react-svg';
import { AnalyticsSummaryModal } from '.';


const InfoItems = [
    {name: 'Customer', value: 'N/A'},
    {name: 'First Name', value: 'N/A'},
    {name: 'Last Name', value: 'N/A'},
    {name: 'Phone', value: 'N/A'},
    {name: 'Alternative Phone', value: 'N/A'},
    {name: 'Incident Type', value: 'N/A'},
    {name: 'CAD ID', value: 'N/A'},
    {name: 'Responder ID', value: '0001'},
    {name: 'Distance', value: 'N/A'},
    {name: 'Dispatch Time', value: '06 / 11 / 2021 - 03:35:36'},
    {name: 'Start Time', value: '06 / 11 / 2021 - 03:35:36'},
    {name: 'Stop Time', value: '06 / 11 / 2021 - 03:35:36'},
]

const breakTime = [
    {time: 2, name: 'Event', icon: 'orange'},
    {time: 12, name: 'Place', icon: 'purple'},
    {time: 20, name: 'IoT', icon: 'yellow'},
    {time: 33, name: 'Capture', icon: 'gray'},
    {time: 41, name: 'Incident', icon: 'orange'},
]


const { TextArea } = Input;

const LocationItem = ({type}) =>
    <Row className="location-item d-flex">
        <Col>
            <Button className="beam-btn inbound icon-btn"><ReactSVG src="/images/geolocation-icon.svg" className={`${type}-icon`} /></Button>
        </Col>
        <Col span={20}>
            <h5>Source - 06 / 11 / 2021 - 05:36:26</h5>
            <p>712 Tom Kite Drive, Round Rock, Texas, USA</p>
        </Col>
    </Row>

const PersonItem = ({type, avatar, onClick}) =>
    <Row className="person-item d-flex">
        <Col onClick={onClick}><img style={{cursor: 'pointer'}} src={avatar} /></Col>
        <Col span={9}>
            <h5>Jerome Bell</h5>
            <p>ID: 154687654</p>
        </Col>
        <Col><label>Injured officer</label></Col>
        <Col>
            <Button className="beam-btn icon-btn"><ReactSVG src="/images/flag-icon.svg" className={`${type}-icon`} /></Button>
        </Col>
    </Row>


function AnalyticsSummaryModalTemp(props) {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const { onClose } = props;
    const [peopleModalDisplayed, setPeopleModalDisplayed] = useState(false);


    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };

    const handleOk = () => {
        setIsModalVisible(false);
        onClose && onClose();
    }

    const getTime = (time) => {
        if (time < 10)
            time = '0' + time;
        return time;
    }

    const checkin = (time) => {
        let points = breakTime.filter(bt => bt.time === time);
        if (points.length > 0)
            return points[0]
        return null
    }

    return (
        <Modal title=""
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width='926px'
            className="beam-modal analytics-summary-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row>
                <Col span={12} className="pr-3">
                    <Row className="d-flex">
                        <Col>
                            <h3 className="mb-1">Summary name</h3>
                            <h4>Incidents information</h4>
                        </Col>
                        <Col>
                            <Button className="beam-btn icon-btn ml-2"><ReactSVG src="/images/flag-icon.svg" /></Button>
                            <Button className="beam-btn icon-btn ml-2"><ReactSVG src="/images/iot-icon.svg" /></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <ul className="incident-information mt-2">
                                {
                                    InfoItems.map((item, i) =>
                                        <li key={i}>
                                            <Row>
                                                <Col span={12}><p>{item.name}</p></Col>
                                                <Col span={12}><p>{item.value}</p></Col>
                                            </Row>
                                        </li>
                                    )
                                }
                            </ul>
                        </Col>
                    </Row>
                </Col>
                <Col span={12} className="pl-3 mt-3">
                    <h4 className="mt-3">Locations</h4>
                    <Row className="locations mt-2">
                        <Col span={24}>
                            <LocationItem type="green" />
                            <LocationItem type="blue" />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}>
                            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} placeholder="Protocals" />
                        </Col>
                    </Row>
                    <Row className="d-flex px-3">
                        <Col><p>Optional</p></Col>
                        <Col><p>max. 500 character</p></Col>
                    </Row>
                    <Row className="d-flex mt-3">
                        <Col span={8} className="pr-2"><Button className="beam-btn w-full">Survey</Button></Col>
                        <Col span={8} className="px-2"><Button className="beam-btn w-full">NCIC</Button></Col>
                        <Col span={8} className="pl-2"><Button className="beam-btn w-full">NFIRS</Button></Col>
                    </Row>
                    <Row className="d-flex mt-3">
                        <Col span={24}><Button className="beam-btn w-full green-btn cover">End Event</Button></Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col><h4 className="mt-3">People On Page</h4></Col>
            </Row>
            <Row className="mt-3">
                <Col span={12} className="pr-3">
                    <PersonItem onClick={()=>{setPeopleModalDisplayed(true)}} type="orange" avatar="/images/users/user.png" />
                    <PersonItem onClick={()=>{setPeopleModalDisplayed(true)}} type="blue" avatar="/images/users/user-0.png"/>
                    <PersonItem onClick={()=>{setPeopleModalDisplayed(true)}} type="green" avatar="/images/users/user-1.png"/>
                </Col>
                <Col span={12} className="pl-3">
                    <PersonItem onClick={()=>{setPeopleModalDisplayed(true)}} type="yellow" avatar="/images/users/user-2.png"/>
                    <PersonItem onClick={()=>{setPeopleModalDisplayed(true)}} type="purple" avatar="/images/users/user-3.png"/>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col><h4 className="mt-3">People Recommended</h4></Col>
            </Row>
            <Row className="mt-3">
                <Col span={12} className="pr-3">
                    <PersonItem onClick={()=>{setPeopleModalDisplayed(true)}} type="orange" avatar="/images/users/user-4.png"/>
                    <PersonItem onClick={()=>{setPeopleModalDisplayed(true)}} type="blue" avatar="/images/users/user-5.png"/>
                </Col>
                <Col span={12} className="pl-3">
                    <PersonItem onClick={()=>{setPeopleModalDisplayed(true)}} type="yellow" avatar="/images/users/user-6.png"/>
                    <PersonItem onClick={()=>{setPeopleModalDisplayed(true)}} type="purple" avatar="/images/users/user-7.png"/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={12} className="pr-3">
                    <Row>
                        <Col><h4 className="mt-3">Assets On Scene</h4></Col>
                    </Row>
                    <Row className="assets-block d-flex mt-3">
                        <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                        <Col><ReactSVG src="/images/user-icon.svg" /></Col>
                        <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                        <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                        <Col><ReactSVG src="/images/user-icon.svg" /></Col>
                        <Col span={6}><ReactSVG src="/images/heart-icon.svg" /></Col>
                    </Row>
                </Col>
                <Col span={12} className="pl-3">
                    <Row>
                        <Col><h4 className="mt-3">Assets Recommended</h4></Col>
                    </Row>
                    <Row className="assets-block d-flex mt-3">
                        <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                        <Col><ReactSVG src="/images/user-icon.svg" /></Col>
                        <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                        <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                        <Col><ReactSVG src="/images/user-icon.svg" /></Col>
                        <Col span={6}><ReactSVG src="/images/heart-icon.svg" /></Col>
                    </Row>
                </Col>
            </Row>
            <Row className="timeline mt-4">
                <Col span={24}>
                    <div className="main-bar">
                        {_.times(48, (i) => {
                            let timePoint = null;
                            let breakPoint = null;
                            let point = checkin(i-1);
                            if ((i-1) % 5 === 0)
                                timePoint = <h6>9:{getTime(i-1)} am</h6>
                            if (point)
                                breakPoint = <div className="break-point">
                                    <Button className="beam-btn icon-btn"><img src={`/images/${point.name}-icon.png`} /></Button>
                                    <h5 className="mt-2">{point.name}</h5>
                                    <p className="mt-1">9:{getTime(i-1)} am</p>
                                </div>
                            if (timePoint != null || breakPoint != null)
                                return (
                                    <div className={`time-point mark-time ${breakPoint? point.icon +'-icon break': ''}`} key={i}>
                                        {timePoint}
                                        {breakPoint}
                                    </div>
                                )
                            else
                                return  <div className="time-point" key={i}></div>
                        })}
                    </div>
                </Col>
            </Row>
            <Row className="custom-table mt-4">
                <Col span={24}>
                    <Row className="header">
                        <Col span={4}>Event</Col>
                        <Col span={4}>User</Col>
                        <Col span={3}>Time</Col>
                        <Col span={3}>Status</Col>
                        <Col span={10}>Location</Col>
                    </Row>
                    <Row className="table-row d-flex">
                        <Col span={4} className="event-column">
                            <div><img src="/images/Event-icon.png" /></div>
                            <span>Event</span>
                        </Col>
                        <Col span={4}>Dispatcher XYZ</Col>
                        <Col span={3}>9:02 am</Col>
                        <Col span={3}><label className="orange-icon">Stopped</label></Col>
                        <Col span={10}>746 W University Dr, Mesa, Maricopa County, AZ85201</Col>
                    </Row>
                    <Row className="table-row d-flex">
                        <Col span={4} className="event-column">
                            <div><img src="/images/Place-icon.png" /></div>
                            <span>Place</span>
                        </Col>
                        <Col span={4}></Col>
                        <Col span={3}>9:02 am</Col>
                        <Col span={3}><label className="green-icon">Open</label></Col>
                        <Col span={10}>746 W University Dr, Mesa, Maricopa County, AZ85201</Col>
                    </Row>
                    <Row className="table-row d-flex">
                        <Col span={4} className="event-column">
                            <div><img src="/images/IoT-icon.png" /></div>
                            <span>IoT</span>
                        </Col>
                        <Col span={4}>Call taker ABC</Col>
                        <Col span={3}>9:02 am</Col>
                        <Col span={3}><label className="green-icon">Received</label></Col>
                        <Col span={10}>746 W University Dr, Mesa, Maricopa County, AZ85201</Col>
                    </Row>
                    <Row className="table-row d-flex">
                        <Col span={4} className="event-column">
                            <div><img src="/images/Capture-icon.png" /></div>
                            <span>Capture</span>
                        </Col>
                        <Col span={4}>Capture</Col>
                        <Col span={3}>9:02 am</Col>
                        <Col span={3}><label className="green-icon">Open</label></Col>
                        <Col span={10}>746 W University Dr, Mesa, Maricopa County, AZ85201</Col>
                    </Row>
                    <Row className="table-row d-flex">
                        <Col span={4} className="event-column">
                            <div><img src="/images/Incident-icon.png" /></div>
                            <span>Incident</span>
                        </Col>
                        <Col span={4}>Responder ID</Col>
                        <Col span={3}>9:02 am</Col>
                        <Col span={3}><label className="green-icon">Open</label></Col>
                        <Col span={10}>746 W University Dr, Mesa, Maricopa County, AZ85201</Col>
                    </Row>
                </Col>
            </Row>
            {peopleModalDisplayed && <AnalyticsSummaryModal onClose={() => setPeopleModalDisplayed(false)} /> }

        </Modal>
    )
}

export default AnalyticsSummaryModalTemp;
