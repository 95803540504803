import React, { useState } from 'react';

export default ({color = '#91A5C6'}) => {
  const [isHovered, setHover] = useState(false);
  return (
    <svg
      onMouseEnter={() => setHover(true)} 
      onMouseLeave={() => setHover(false)} 
			width="18" height="24"
      viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1 3C1 1.89543 1.89543 1 3 1H15C16.1046 1 17 1.89543 17 3V21C17 22.1046 16.1046 23 15 23H3C1.89543 23 1 22.1046 1 21V3Z"
			 stroke={isHovered ? '#508AFA': (color || '#91A5C6')} strokeWidth="2"/>
			<path d="M11.7924 1.60976C12.1291 1.17201 12.0472 0.54416 11.6095 0.207426C11.1717 -0.129308 10.5439 -0.0474151 10.2072 0.390339L8.8076 2.10976C8.6183 2.35585 8.32544 2.50005 8.01498 2.50005H6.63022C6.01471 2.50005 5.41409 2.68938 4.90984 3.04235L2.61576 5.04821C2.55282 5.09227 2.48501 5.12892 2.41368 5.15745L0.628387 5.07157C0.115604 5.27669 -0.133812 5.85866 0.071301 6.37144C0.276414 6.88422 0.858385 7.13364 1.37117 6.92853L3.15647 7.01441C3.37046 6.92881 3.57387 6.81884 3.76268 6.68667L6.05676 4.68082C6.22484 4.56316 6.42505 4.50005 6.63022 4.50005H8.01498C8.94638 4.50005 9.82496 4.06744 10.3928 3.32918L11.7924 1.60976Z" 
				fill={isHovered ? '#508AFA': (color || '#91A5C6')}/>
			<path d="M-1.86672e-07 11C-1.86672e-07 10.4478 0.447715 10 1 10H11C11.5523 10 12 10.4478 12 11C12 11.5523 11.5523 12 11 12H1C0.447715 12 -1.86672e-07 11.5523 -1.86672e-07 11Z" 
				fill={isHovered ? '#508AFA': (color || '#91A5C6')}/>
			<path d="M0.071301 15C0.071301 14.4478 0.519016 14 1.0713 14H6.08369C6.63598 14 7.08369 14.4478 7.08369 15C7.08369 15.5523 6.63598 16 6.08369 16H1.0713C0.519016 16 0.071301 15.5523 0.071301 15Z" 
				fill={isHovered ? '#508AFA': (color || '#91A5C6')}/>
		</svg>
  );
}
