import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import { useDispatch } from 'react-redux';


function AddRSVPModal(props) {
    const { onClose, rsvp } = props;

    const [isModalVisible, setIsModalVisible] = useState(true);
    const [rsvpTitle, setRSVPTitle] = useState('');
    const [errorText, setErrorText] = useState('');
    const [threeButton, setThreeButton] = useState({option1: 'Yes', option2: 'Maybe', option3: 'No'});

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };
    const dispatch = useDispatch();
    const handleOk = () => {
        if (rsvpTitle) {
            setRSVPTitle('');
            dispatch({type: 'SEND_MESSAGE', payload: {message: rsvpTitle, type: 'RSVP', data: { type: 'threeButtons', options: threeButton, rsvpTitle }}})
            setIsModalVisible(false);
        } else {
            setErrorText('Please enter RSVP Title');
        }
    }

    const updateThreeButtons = (key, value) =>{
        setThreeButton(prevState => ({
            ...prevState,
            [key]: value
        }));
    }

    return (
        <Modal title={!rsvp ? "Add RSVP" : ""}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            {/* <Row className="mb-4">
                <Col><h5>Choose the RSVP type</h5></Col>
            </Row>
            <Row className="d-flex mb-3">
                <Col span={7}><Button className="beam-btn green-btn cover w-full">3-Button</Button></Col>
                <Col span={6}><Button className="beam-btn w-full">Yes / No</Button></Col>
                <Col span={9}><Button className="beam-btn w-full">Multiple Choice</Button></Col>
            </Row>
            <Row className="d-flex mb-3">
                <Col span={5}><Button className="beam-btn w-full">Bar</Button></Col>
                <Col span={8}><Button className="beam-btn w-full">Number</Button></Col>
                <Col span={9}><Button className="beam-btn w-full">Currency</Button></Col>
            </Row>
            <Row className="d-flex mb-3">
                <Col span={9}><Button className="beam-btn w-full">Date & Time</Button></Col>
                <Col span={6}><Button className="beam-btn w-full">Date</Button></Col>
                <Col span={7}><Button className="beam-btn w-full">Time</Button></Col>
            </Row>
            <Row className="d-flex mb-3">
                <Col span={6}><Button className="beam-btn w-full">Text</Button></Col>
                <Col span={7}><Button className="beam-btn w-full">Photo</Button></Col>
                <Col span={9}><Button className="beam-btn w-full">Signature</Button></Col>
            </Row>
             */}
            <Row className="mt-4 mb-4">
                {!rsvp ?
                    <Col span={24}>
                        <Input value={rsvpTitle} placeholder="Enter Title" onChange={e => setRSVPTitle(e.target.value)} />
                        {errorText && <span className="error-text">Please fill this field</span> }
                    </Col> :
                    <span>{rsvp.rsvpTitle}</span>
                }
            </Row>
            <Row className="tiles">
                <Col span={24}>
                    <Row className="d-flex mb-4">
                        <Col span={12} className="pr-2"><Button className="beam-btn green-btn cover w-full"><Input value={threeButton.option1} onChange={(e)=>{updateThreeButtons('option1', e.target.value)}} className="beam-input t-white" placeholder="Yes"/></Button></Col>
                        <Col span={12} className="pl-2"><Button className="beam-btn w-full"><Input value={threeButton.option2} onChange={(e)=>{updateThreeButtons('option2', e.target.value)}} className="beam-input" placeholder="Maybe"/></Button></Col>
                    </Row>
                    <Row>
                        <Col span={24}><Button className="beam-btn orange-btn cover w-full"><Input value={threeButton.option3} onChange={(e)=>{updateThreeButtons('option3', e.target.value)}} className="beam-input t-white" placeholder="No"/></Button></Col>
                    </Row>
                </Col>
            </Row>
            {!rsvp && <Row className="action-grp mt-4">
                <Col span={14} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full" onClick={handleOk}>Add</Button>
                </Col>
                <Col span={10} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                </Col>
            </Row> }
        </Modal>
    )
}

export default AddRSVPModal;
