import React, { useEffect,useState } from "react";
import { Row, Col, Input, Button } from "antd";
import { ReactSVG } from 'react-svg';
import { SearchOutlined } from '@ant-design/icons';
import {
  SearchPanel,
  MessagePanel,
  MapPanel,
  HeaderPanel,
  RideDispatchPanel,
  RxPanel,
  Nine11Panel,
  StorePanel,
  LogisticsPanel,
  ContactTracePanel
} from "../../components/Dashboard";
import "../Dashboard/Dashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "react-google-autocomplete";
import RidePanel from "components/Dashboard/RidePanel";
import { useHistory, useParams } from "react-router-dom";
import { getPagesRequestPublishTopic, getPagesSubscriptionTopic, getCLBSLocations, getTwilioTokenSubscriptionTopic, getTwilioTokenPublishTopic, getTwilioJoinRoomPublishTopic, getTwilioLeaveRoomPublishTopic } from "hooks/MQTTConstants";
import { GET_USERS_LIST } from "reducers/authReducer";
import Room from "./Room";
import Video from "twilio-video";
import App from "./SSVideo/App/App";


export default ({client}) => {
  const { searchResult, selectedItem, twilioToken } = useSelector((state) => state.search);
  const search = useSelector((state) => state.search);
  const history = useHistory();
  const dispatch = useDispatch();
  const {pageId} = useParams();
  const [roomName, setRoomName] = useState(selectedItem?.title);
  const [localVideoTrack, setLocalVideoTrack] = useState(undefined);
  const [room, setRoom] = useState(null);
  const [token, setToken] = useState('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTS2EyOGQzYmIyMTA2N2ZhYmE5NTlkODQxNTY2ZTgwYjgyLTE2NDUwMzAzMzUiLCJncmFudHMiOnsiaWRlbnRpdHkiOiI2MTYxZDQ1ZDZjZDQ1MzQ1ODBiZWVmMGMiLCJjaGF0Ijp7InNlcnZpY2Vfc2lkIjoiSVNmNTU0MTQzZGRlYjQ0M2I1YWMxZDRkYWU1YWRlMzhhYiIsImVuZHBvaW50X2lkIjoiYmVhbUxpdmU6NjE2MWQ0NWQ2Y2Q0NTM0NTgwYmVlZjBjOmJyb3dzZXIifSwidmlkZW8iOnt9LCJ2b2ljZSI6eyJpbmNvbWluZyI6eyJhbGxvdyI6dHJ1ZX0sIm91dGdvaW5nIjp7ImFwcGxpY2F0aW9uX3NpZCI6IkFQNDgwOGZhMTQzZmI2Y2QwOTE5NWJmN2RmYzc2ZDEzY2MifSwicHVzaF9jcmVkZW50aWFsX3NpZCI6IkNSNzBlMWQ5MzdlZTNhNGIyYjAxNzUzMDUzNWMxNTYwODEiLCJlbmRwb2ludF9pZCI6ImJlYW1MaXZlOjYxNjFkNDVkNmNkNDUzNDU4MGJlZWYwYzpicm93c2VyIn19LCJpYXQiOjE2NDUwMzAzMzUsImV4cCI6MTY0NTExMzEzNSwiaXNzIjoiU0thMjhkM2JiMjEwNjdmYWJhOTU5ZDg0MTU2NmU4MGI4MiIsInN1YiI6IkFDODdiMzU1YzQ3ZWUzNDExNTk0NmZhMjhhYmRiOTY3M2YifQ.-Pcyz70_2Gc-yJyREk1Y1yASVY2VIKkn8E-frqKj3Ks');
  const [screens, setScreens] = useState({
    list: false,
    message: false,
    map: true,
    active: 'map',
    ridePanel: false,
    cadType: '',
  });

  const params = {
    screens,
    setScreens
  }

  useEffect(() => {
    console.log(twilioToken)
    if(twilioToken) {
      // setupVideo();
    }
  }, [twilioToken]) //token

  const setupVideo = async () => {
    const localVideoTrack = await Video.createLocalVideoTrack();
    setLocalVideoTrack(localVideoTrack);
    Video.connect(twilioToken, {
        name: pageId,
    })
    .then((room) => {
        setRoom(room);
    })
    .catch((err) => {
        console.error(err);
    });
  }

  useEffect(() => {
    if(selectedItem) {
      const selectedPage = searchResult.find(obj => obj.pageId === pageId);
      if(selectedPage) {
        setRoomName(selectedPage.title)
      }
    }
  }, [selectedItem]);
  

  useEffect(() => {
    if(client){
      const deviceId = localStorage.getItem('UUID')
      const identity = localStorage.getItem('RID')
      client.subscribe(getPagesSubscriptionTopic(), { qos: 1 }, (error) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
        console.log('Subscribed')
      });

      client.publish(getPagesRequestPublishTopic(), JSON.stringify({
        paths: [
          "$.persistent"
        ],
        // paths: [`$.persistent.static.members[?(@.rootUserID == 21)]`, '$.persistent'],
        returnPaths: [
          "$.persistent.static"
        ],
        pagination: {
          offset: 0,
          limit: 200
        }
      }), { qos: 1 }, error => {
				if (error) {
					console.log('Publish error: ', error);
				}
			});

      client.subscribe(getTwilioTokenSubscriptionTopic(), { qos: 1 }, (error) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
        console.log('Subscribed')
      });

      client.publish(getTwilioTokenPublishTopic(), JSON.stringify({
        deviceId,
        identity 
      }), { qos: 1 }, error => {
        if (error) {
          console.log('Publish error: ', error);
        }
      });
    }
  },[client]);

  const handleRoomJoin = () => {
    const userId = localStorage.getItem('RID');
    client.publish(getTwilioJoinRoomPublishTopic(), JSON.stringify({
      pageId,
      roomId: pageId,
      userId
    }), { qos: 1 }, error => {
      if (error) {
        console.log('Publish error: ', error);
      }
    });
  }

  const handleRoomLeave = () => {
    const userId = localStorage.getItem('RID');
    client.publish(getTwilioLeaveRoomPublishTopic(), JSON.stringify({
      pageId,
      userId
    }), { qos: 1 }, error => {
      if (error) {
        console.log('Publish error: ', error);
      }
    });
  }

  const fixedColSize = pageId ? 12 : 8;

  return (
    <Row className="main-layout">
        <Col md={24} xs={24} className={'active-screen'}>
          {twilioToken && <App onRoomJoin={handleRoomJoin} onRoomLeave={handleRoomLeave} pageId={pageId} pageTitle={roomName} token={twilioToken} /> }
          {/* {room && <Room localVideoTrack={localVideoTrack} roomName={roomName} room={room} {...params} /> } */}
        </Col>
    </Row>
  );
};
