import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';

const ICON_GPS = {
    path: 'M5.57873 8.76602C5.84846 8.46215 6 8.05001 6 7.62027C6 6.72539 5.35605 5.99994 4.56169 5.99994L1.43831 5.99994C1.05684 5.99994 0.691005 6.17065 0.42127 6.47452C-0.140424 7.1073 -0.140424 8.13324 0.42127 8.76602L1.98296 10.5254C2.54466 11.1581 3.45534 11.1581 4.01704 10.5254L5.57873 8.76602ZM3.00001 4.99998C3.55229 4.99998 4.00001 4.55226 4.00001 3.99998L3.99998 1.00002C3.99998 0.447731 3.55226 1.60572e-05 2.99998 1.60813e-05L2.96918 1.60827e-05C2.4169 1.61068e-05 1.96918 0.447731 1.96918 1.00002L1.96921 3.99998C1.96921 4.55227 2.41692 4.99998 2.96921 4.99998L3.00001 4.99998Z',
    rotation: 0,
    fillColor: 'black',
    fillOpacity: 1.0,
    strokeColor: '#000000',
    strokeWeight: 2,
    scale: 2
};
const START_ICON = 'M9 0C13.9706 0 18 3.85724 18 8.61539C18 12.6711 15.2762 18.729 9.82852 26.7891C9.75698 26.8949 9.66582 26.9861 9.55998 27.0576C9.13507 27.3448 8.56869 27.2616 8.24232 26.8819L8.17144 26.7891L7.84134 26.2981L7.20151 25.3312L6.5756 24.3634C2.19187 17.503 0 12.2536 0 8.61539C0 3.85724 4.02944 0 9 0ZM8.92103 4.7552C11.1302 4.7552 12.921 6.54606 12.921 8.7552C12.921 10.9643 11.1302 12.7552 8.92103 12.7552C6.71189 12.7552 4.92103 10.9643 4.92103 8.7552C4.92103 6.54606 6.71189 4.7552 8.92103 4.7552ZM8.92103 6.7552C7.81646 6.7552 6.92103 7.65063 6.92103 8.7552C6.92103 9.85977 7.81646 10.7552 8.92103 10.7552C10.0256 10.7552 10.921 9.85977 10.921 8.7552C10.921 7.65063 10.0256 6.7552 8.92103 6.7552Z';
const GPS_RED = 'rgb(243,0,0)';
const GPS_AMBER = 'rgb(255,255,0)';
const GPS_GREEN = 'rgb(102,255,102)';

const Speed = {
        Motion: 2,
        Speeding: 25,
};

const markerTypeColor =  {
    place: 'orange',
    media: 'green',
    // page: 'yellow',
    // asset: 'purple',
    // user: 'blue',

    page: 'blue',
    asset: 'green',
    user: 'green',
};

/*global google*/
export default () => {
    const dispatch = useDispatch();
    const {location_profiles} = useSelector(state => state.dashboard);

    const showGPSHistorical = (deviceKey, reportingStart, reportingEnd, live=false) => {
        let requestData = {
            imei: deviceKey,
            from: new Date(reportingStart),
            to: reportingEnd ? new Date(reportingEnd) : new Date(),
        };
        dispatch({type: 'GET_HISTORICAL_GPS', payload: {requestData, live}});
        if(live){
            dispatch({type: 'ADD_IMEI_SUBSCRIPTION', payload: {imei: deviceKey}})
        }
    };

    const getSpeedColor = (speed, unit = 'kph') => {
        // convert speed to meters per second
        let mpsSpeed = speed;
        switch (unit) {
            case 'mps':
                break;
            case 'mph':
                mpsSpeed = speed / 2.23694;
                break;
            case 'kph':
                mpsSpeed = speed / 3.6;
                break;
        }

        if (mpsSpeed <= Speed.Motion) {
            return GPS_AMBER;
        }
        if (mpsSpeed >= Speed.Speeding) {
            return GPS_RED;
        }
        return GPS_GREEN;
    };

    const createPeopleMarker = (gps, color, start = false) => {
        const icon = { ...ICON_GPS };
        const markerIcon = {
            ...icon,
            anchor: new google.maps.Point(3, 5),
            rotation: gps.heading - 180,
            fillColor:  color,
            strokeColor: getSpeedColor(gps.speed, gps.speedUnit)
        };
        if(start){
            markerIcon.path = START_ICON;
            markerIcon.rotation = 0;
            markerIcon.scale = 1;
            markerIcon.anchor = new google.maps.Point(9, 28);
        }
        return {
            ...gps,
            lat: gps.lat,
            lng: gps.long,
            icon: markerIcon,
            paths: {lat: gps.lat, lng: gps.long},
            type: 'gps'
        }
    };

    const getIcon = (url) => {
        return new Promise((resolve)=>{
            let xhr = new XMLHttpRequest();
            xhr.onload = function() {
                let template = document.createElement('template');
                template.innerHTML = xhr.responseText.trim();
                resolve(template.content.firstElementChild.getElementsByTagName('path')[0].getAttribute('d'));
            };
            xhr.onerror = function(){
                resolve(START_ICON);
            };
            xhr.open('GET', url);
            xhr.responseType = 'text';
            xhr.send();
        })
    };

    const createPlaceMarker = async (point, fitBounds = false) => {
        let markerIcon;
        if(point.settings?.profile?.[0] ?? false){
            let profiles;
            if(point.objectType === 'place')
                profiles = location_profiles?.admin?.clbs?.profile ?? [];
            else if(point.objectType === 'asset')
                profiles = location_profiles?.admin?.asset?.profile ?? [];
            else if(point.objectType === 'user')
                profiles = location_profiles?.liveuser?.asset?.profile ?? [];
            else
                profiles = [];
            profiles = profiles.filter((e)=>{return point.settings.profile.indexOf(e.id) !== -1});
            if(profiles?.[0] ?? false){
                let icon = await getIcon(process.env.REACT_APP_ADMIN_API + '/' + profiles[0].icon);
                markerIcon = {
                    path: icon,
                    rotation: 0,
                    fillColor: profiles[0].color,
                    fillOpacity: 1.0,
                    scale: 1
                };
            }
        }

        console.log('color here', markerTypeColor[point.objectType ? point.objectType: 'place'])
        return {
            ...point,
            lat: point.location?.coordinates?.[1],
            lng: point.location?.coordinates?.[0],
            title: point.name,
            color: markerTypeColor[point.objectType ? point.objectType: 'place'],
            paths: {lat: point.location?.coordinates?.[1], lng: point.location?.coordinates?.[0]},
            type: 'marker',
            filter: 'viewOnly',
            fitBounds,
            markerIcon
        }
    };

    const generateUniqueColor = () => {
        let letters = '0123456789ABCDEF'.split('');
        let color = '#';
        for (let i = 0; i < 6; i++ ) {
            color += letters[Math.round(Math.random() * 15)];
        }
        return color;
    };

    return {
        showGPSHistorical,
        createPeopleMarker,
        generateUniqueColor,
        createPlaceMarker
    }
};

