import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Input, Button, Select, Radio, DatePicker, Collapse } from "antd";
import { ReactSVG } from 'react-svg';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import _ from "lodash";

const { Option } = Select;

const { TextArea } = Input;

const { Panel } = Collapse;


function ContactTracePanel(props) {
    const {screens, setScreens} = props;
    
    return (
        <div className="main-column ride-dispatch-panel mr-4">
          <div className="main-content">
            <div className="main-header">
                <div className="d-flex">
                    <ReactSVG src="/images/ride-dispatch-icon.svg" />
                    <h3 className="ml-3">Contact Tracing</h3>
                </div>
                <div className="d-flex">
                    <ReactSVG src="/images/expand-icon.svg" className="close-icon mr-4" onClick={() => setScreens({...screens, active: screens.active? null: 'rideDispatch'})} />
                    <ReactSVG src="/images/close-icon.svg" className="close-icon" onClick={() => setScreens({...screens, ridePanel: false, message: true})} />
                </div>
            </div>
            <Row className="mt-3">
                <Col span={24}>
                    <Select className="w-full" placeholder="Features">
                        <Option>Option1</Option>
                    </Select>
                </Col>
            </Row>
            <div className="main-cards">
                <Row>
                    <Col span={24}><Input placeholder="Phone Number" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Alternative Phone" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={12} className="pr-2"><Input placeholder="First Name" /></Col>
                    <Col span={12} className="pl-2"><Input placeholder="Last Name" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Email" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}>
                        <div className="datetime-item">
                            <img src="/images/date.png" /><DatePicker placeholder="Date" suffixIcon={<DownOutlined />} />
                        </div>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col span={24}><Input placeholder="Reference Phone" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Reference Email" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={12} className="pr-2"><Input placeholder="Reference First Name" /></Col>
                    <Col span={12} className="pl-2"><Input placeholder="Reference Last Name" /></Col>
                </Row>
                <hr />
                <Row>
                    <Col span={12} className="pr-2"><Input placeholder="Emergency First Name" /></Col>
                    <Col span={12} className="pl-2"><Input placeholder="Emergency Last Name" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Emergency Phone" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Emergency Alternative Phone" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Emergency Email" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Emergency Relationship" /></Col>
                </Row>
                <hr />
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Home Address" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Work Address" /></Col>
                </Row>
                <hr />
                <Row>
                    <Col span={18} className="pr-2">
                        <Select className="w-full" placeholder="Symptoms" options={[
                            { label: 'Fever', value: 'Fever'},
                            { label: 'Dry Cough', value: 'Dry Cough'},
                            { label: 'Shortness of breath', value: 'Shortness of breath'},
                            { label: 'Headache', value: 'Headache'},
                            { label: 'Sore throat', value: 'Sore throat'},
                            { label: 'Sore muscles', value: 'Sore muscles'},
                            { label: 'Tightness/pain in chest', value: 'Tightness/pain in chest'},
                            { label: 'Loss of smell and/or taste', value: 'Loss of smell and/or taste'},
                            { label: 'Fatigue', value: 'Fatigue'},
                            { label: 'Second', value: 'Second'},
                            { label: 'Chills', value: 'Chills'},
                            { label: 'Blue lips', value: 'Blue lips'},
                            { label: 'Pink eyes', value: 'Pink eyes'},
                            { label: 'Dizziness', value: 'Dizziness'},
                            { label: 'Blue fingers', value: 'Blue fingers'},
                            { label: 'Swollen red to purple toes', value: 'Swollen red to purple toes'},
                            { label: 'Low oxygen below 94%', value: 'Low oxygen below 94%'},
                            { label: 'Disorientation', value: 'Disorientation'},
                            { label: 'Nausea', value: 'Nausea'},
                            { label: 'Diarrhea', value: 'Diarrhea'},
                            { label: 'Hives', value: 'Hives'}
                        ]}>
                        </Select>
                    </Col>
                    <Col span={6} className="pl-2">
                        <Button className="beam-btn w-full more-btn">Details</Button>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}>
                        <Select className="w-full" placeholder="Handicaps">
                        </Select>
                    </Col>
                </Row>
                <Row className="d-flex mt-3">
                    <Col span={12} className="pr-2">
                        <div className="sub-cards-block">
                            <h5>People</h5>
                            <Row className="mt-2 d-flex">
                                <Col><Button className="primary count-btn">+</Button></Col>
                                <Col><p>01</p></Col>
                                <Col><Button className="primary count-btn">-</Button></Col>
                            </Row>
                            <hr />
                            <h5>Places</h5>
                            <Row className="mt-2 d-flex">
                                <Col><Button className="primary count-btn">+</Button></Col>
                                <Col><p>01</p></Col>
                                <Col><Button className="primary count-btn">-</Button></Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={12} className="pl-2">
                        <div className="sub-cards-block d-block d-space">
                            <h5>Schedule Times</h5>
                            <Button className="beam-btn w-full more-btn">Schedule</Button>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24} className="sub-cards-block d-block d-space">
                        <h5 className="mb-2">Close Case</h5>
                        <TextArea autoSize={{ minRows: 2, maxRows: 2 }} placeholder="Reason" />
                        <Button className="beam-btn w-full more-btn mt-2">Close Case</Button>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col span={12} className="pr-2"><Button className="beam-btn w-full more-btn">Negative</Button></Col>
                    <Col span={12} className="pl-2"><Button className="beam-btn w-full more-btn">In-Progress</Button></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={12} className="pr-2"><Button className="beam-btn w-full more-btn">Positive</Button></Col>
                    <Col span={12} className="pl-2"><Button className="beam-btn w-full more-btn">Stay Home</Button></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={12} className="pr-2"><Button className="beam-btn w-full more-btn">Case Worker</Button></Col>
                    <Col span={12} className="pl-2"><Button className="beam-btn w-full more-btn">Submit Hospital</Button></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}>
                        <TextArea autoSize={{ minRows: 4, maxRows: 6 }} placeholder="Instructions" />
                    </Col>
                </Row>
            </div>
            <Row className="action-grp mt-3">
                <Col span={12} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full">Submit</Button>
                </Col>
                <Col span={12} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full">Cancel</Button>
                </Col>
            </Row>
          </div>
        </div>
    )
}

export default ContactTracePanel;
