import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Input, Button, Dropdown, Menu } from "antd";
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT_USER } from 'reducers/authReducer';
import { DownOutlined } from '@ant-design/icons';
import { EditBioModal, 
    EditPrivacyModal,
    EditPaymentModal,
    EditPermissionsModal,
    EditNotificationModal
} from 'components/Modals';
import Clock from 'common/Clock';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { GET_USER_INFO } from 'reducers/authReducer';
import VerifyPinModal from 'components/Modals/VerifyPinModal';
import { REQUEST_VERIFICATION_CODE } from 'reducers/authReducer';


function HeaderPanel(props) {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [name, setName] = useState('Ekaterina Tankova');
    const [editPrivacyModalOpen, setEditPrivacyModalOpen] = useState(false);
    const [editPaymentModalOpen, setEditPaymentModalOpen] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const [editPermissionsModalOpen, setEditPermissionsModalOpen] = useState(false);
    const [editNotificationModalOpen, setEditNotificationModalOpen] = useState(false);
    const [verifyPinModalOpen, setVerifyPinModalOpen] = useState(false);

    useEffect(() => {
        dispatch(GET_USER_INFO());
    }, [])
    useEffect(() => {
        console.log(userInfo)
        if (userInfo) {
            const firstname = userInfo.firstName || ''
            const lastname = userInfo.lastName || ''
            if(!firstname && !lastname) {
                setName('Ekaterina Tankova');
                return;
            }
            const name = firstname + ' ' + lastname;
            setName(name)
        } else {
            setName('Ekaterina Tankova');
        }
        console.log('userInfo', userInfo);
    }, [userInfo])

    const handlePinVerification = (status) => {
        setVerifyPinModalOpen(false);
        if(status) {
            setEditPrivacyModalOpen(true);
        }
    }

    const handlePrivacyClick = () => {
        const email = localStorage.getItem('email');
        const phone = localStorage.getItem('phone');
        let payload = {}
        if(email) {
            payload['email'] = email;
        } 
        if(phone) {
            payload['phone'] = phone;
        }
		dispatch(REQUEST_VERIFICATION_CODE(payload));
        setVerifyPinModalOpen(true)
    }

    const menu = (
      <ul className="user-dropdown">
        <li onClick={() => setEditModalOpen(true)}>
            <ReactSVG src="/images/male-icon.svg" />
            <span className="ml-4">Edit Bio</span>
        </li>
        <li onClick={handlePrivacyClick}>
            <ReactSVG src="/images/privacy-icon.svg" className="popup-icon" style={{marginLeft: '-12px'}}/>
            <span className="ml-3">Privacy</span>
        </li>
        <li onClick={() => setEditNotificationModalOpen(true)}>
            <ReactSVG src="/images/message-white-icon.svg" className="popup-icon" style={{marginLeft: '-12px'}} />
            <span className="ml-3">Notification</span>
        </li>
        <li onClick={() => setEditPaymentModalOpen(true)}>
            <ReactSVG src="/images/payment-icon.svg" className="popup-icon payment " style={{}} />
            <span className="ml-4">Payment</span>
        </li>
        <li onClick={() => setEditPermissionsModalOpen(true)}>
            <ReactSVG src="/images/permission-icon.svg" className="popup-icon" style={{}} />
            <span className="ml-3">Permission</span>
        </li>
        <li onClick={() => setConfirmationDialog(true)}>
          <ReactSVG src="/images/logout-icon.svg" />
          <span className="ml-3">Logout</span>
        </li>
      </ul>
    );
    return (
        <div className="header-panel mr-4">
            <ReactSVG src="/images/logo-icon.svg" className="logo-icon" />
            <div className="d-flex">
                <ReactSVG src="/images/clock-icon.svg" className="clock-icon" />
                <Clock/>
                <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                    <div className="user-avatar d-flex">
                        <img src="/images/users/user.png" />
                        <p className="mx-2">{name}</p>
                        <DownOutlined style={{ fontSize: 14}} />
                    </div>
                </Dropdown>
            </div>
            {editModalOpen && <EditBioModal onClose={()=> setEditModalOpen(false)} /> }
            {editPrivacyModalOpen && <EditPrivacyModal onClose={()=> setEditPrivacyModalOpen(false)} /> }
            {editPaymentModalOpen && <EditPaymentModal onClose={()=> setEditPaymentModalOpen(false)} /> }
            {editPermissionsModalOpen && <EditPermissionsModal onClose={()=> setEditPermissionsModalOpen(false)} /> }
            {editNotificationModalOpen && <EditNotificationModal onClose={()=> setEditNotificationModalOpen(false)} /> }
            {verifyPinModalOpen && <VerifyPinModal onClose={()=> setVerifyPinModalOpen(false)} onVerification={handlePinVerification} /> }
            { confirmationDialog && <ConfirmationModal handleConfirm={() => dispatch(LOGOUT_USER())} message={'Do you really want to logout?'} onClose={() => setConfirmationDialog(false)} /> }
        </div>
    )
}

export default HeaderPanel;
