import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Input, Button, Select, Radio, DatePicker } from "antd";
import { ReactSVG } from 'react-svg';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import _ from "lodash";

const { Option } = Select;

const { TextArea } = Input;


function RxPanel(props) {
    const {screens, setScreens} = props;
    
    return (
        <div className="main-column ride-dispatch-panel mr-4">
          <div className="main-content">
            <div className="main-header">
                <div className="d-flex">
                    <ReactSVG src="/images/ride-dispatch-icon.svg" />
                    <h3 className="ml-3">Rx</h3>
                </div>
                <div className="d-flex">
                    <ReactSVG src="/images/expand-icon.svg" className="close-icon mr-4" onClick={() => setScreens({...screens, active: screens.active? null: 'rideDispatch'})} />
                    <ReactSVG src="/images/close-icon.svg" className="close-icon" onClick={() => setScreens({...screens, ridePanel: false, message: true})} />
                </div>
            </div>
            <Row className="mt-3">
                <Col span={24}>
                    <Select className="w-full" placeholder="Features">
                        <Option>Option1</Option>
                    </Select>
                </Col>
            </Row>
            <div className="main-cards">
                <Row>
                    <Col span={12} className="pr-2"><Input placeholder="Account Number" /></Col>
                    <Col span={12} className="pl-2"><Input placeholder="Account Name" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Name" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Email" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Phone" /></Col>
                </Row>
                <hr />
                <Row className="mt-3">
                    <Col span={24}>
                        <Select className="w-full" placeholder="Project">
                            <Option>Option1</Option>
                        </Select>
                    </Col>
                </Row>
                <hr />
                <div className="sub-cards mt-3">
                    <Row>
                        <Col span={12} className="pr-2"><Input placeholder="Patient First Name" /></Col>
                        <Col span={12} className="pl-2"><Input placeholder="Patient Last Name" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}><Input placeholder="Patient ID" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}><Input placeholder="Patient Email" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}><Input placeholder="Patient Phone" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}>
                            <Select className="w-full" placeholder="Mobility">
                                <Option>Option1</Option>
                            </Select>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={6} className="pr-2">
                            <Select className="w-full" placeholder="Gender" options={[
                                {label: "Male", value: 'Male'},
                                {label: "Female", value: 'Female'},
                            ]}>
                            </Select>
                        </Col>
                        <Col span={18} className="pl-2">
                            <div className="datetime-item">
                                <img src="/images/date.png" /><DatePicker placeholder="Date" suffixIcon={<DownOutlined />} />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={12} className="pr-2"><Input placeholder="Weight" /></Col>
                        <Col span={12} className="pl-2"><Input placeholder="Height" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}><Button className="beam-btn w-full">Alert Chart</Button></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}><Input placeholder="Address" /></Col>
                    </Row>
                    <hr />
                    <h5 className="mt-3">Medical Conditions</h5>
                    <Row className="mt-3">
                        <Col span={24}>
                            <Select className="w-full" placeholder="Medical Conditions">
                                <Option>Option1</Option>
                            </Select>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}>
                            <Select className="w-full" placeholder="Features">
                                <Option>Option1</Option>
                            </Select>
                        </Col>
                    </Row>
                </div>
                <div className="sub-cards mt-3">
                    <h5>Next of Kin Info</h5>
                    <Row className="mt-3">
                        <Col span={12} className="pr-2"><Input placeholder="First Name" /></Col>
                        <Col span={12} className="pl-2"><Input placeholder="Last Name" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={12} className="pr-2"><Input placeholder="Email" /></Col>
                        <Col span={12} className="pl-2"><Input placeholder="Phone" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24} className="pr-2"><Input placeholder="Relationship" /></Col>
                    </Row>
                </div>
                <hr />
                <Row className="mt-3">
                    <Col span={12} className="pr-2"><Input placeholder="Practitioner Name" /></Col>
                    <Col span={12} className="pl-2"><Input placeholder="Custom ID" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={12} className="pr-2"><Input placeholder="Facility Name" /></Col>
                    <Col span={12} className="pl-2"><Input placeholder="Custom ID" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24} className="pr-2"><Input placeholder="Facility Address" /></Col>
                </Row>
                <div className="sub-cards mt-3">
                    <Row>
                        <Col span={24} className="pr-2"><Input placeholder="Location" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24} className="pr-2"><Input placeholder="Instructions" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}>
                            <Radio.Group defaultValue={1} className="plain-radios">
                                <Radio value={1} className="green-radio">P</Radio>
                                <Radio value={2} className="green-radio">D</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </div>
            </div>
            <Row className="action-grp mt-3">
                <Col span={8} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full">Submit</Button>
                </Col>
                <Col span={8} className="px-2">
                    <Button className="beam-btn yellow-btn cover w-full">Save</Button>
                </Col>
                <Col span={8} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full">Clear</Button>
                </Col>
            </Row>
          </div>
        </div>
    )
}

export default RxPanel;
