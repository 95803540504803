import { all, fork } from 'redux-saga/effects'
import authSagas from './authSagas'
import mapSagas from './mapSagas'
import {onMessageFromEventListenerSaga} from './WebSocketSagas'

export default function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(mapSagas),
    fork(onMessageFromEventListenerSaga),

  ])
}
