import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { 
  Form,
  Input,
  Button,  
} from 'antd';
import { REQUEST_VERIFICATION_CODE } from 'reducers/authReducer';
import { useDispatch, useSelector } from 'react-redux';

function Resend() {

	const { resetPinPayload, isLoading } = useSelector((state) => state.auth);
    const [error, setError] = useState(null);
    const [changePinForm] = Form.useForm();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if(resetPinPayload)
            history.push('/auth/pin/resetpin');
    }, [resetPinPayload]);
    
    const onSubmitHandler = (values) => {
		dispatch(REQUEST_VERIFICATION_CODE({ email: values.email }));
    };

    return (
        <div className="page-container auth-page">
            <Form 
                className="auth-form"
                name="resend-form"
                id="resend-form"
                layout='vertical'
                form={changePinForm}
                onFinish={onSubmitHandler}
                requiredMark={false}
            >
                <img alt='1' src='/images/back.png' className="back-btn" onClick={() => history.goBack()}/>
                <h3>Resend</h3>
                <p>Please enter your Phone Number/Email here</p>
                <Form.Item name="email" rules={[{ required: true, message: '**Required Field' }]}>
                    <Input type="text" placeholder="Phone Number/Email Address" />
                </Form.Item>
                
                {error && <Form.Item><p className="error">{ error }</p></Form.Item>}
                <Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit" onClick={() => setError('')}>Send to Phone / Email</Button>
                </Form.Item>
                <Form.Item className="mb-0">
                    <p className="option-text">Want to login? <Link to="/auth/login">Login</Link></p>
                </Form.Item>
            </Form>
        </div>
    )
}

export default Resend;
