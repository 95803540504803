import {
  GET_LIST,
  GET_LIST_SUCCESS,
  GET_LIST_FAILURE,
  GET_LOGIN_ERROR
} from './actionTypes'


export const getList = () => ({
  type: GET_LIST
})

export const getListSuccess = (data) => ({
  type: GET_LIST_SUCCESS,
  payload: data
})

export const getListFailure = () => ({
  type: GET_LIST_FAILURE
})

export const getLoginError = () => ({
  type: GET_LOGIN_ERROR
})
