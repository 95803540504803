import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Input, Button, Select, Radio } from "antd";
import { ReactSVG } from 'react-svg';
import { SearchOutlined } from '@ant-design/icons';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import _ from "lodash";
import { PopupModal } from 'components/Modals';
import { useSelector } from 'react-redux';

const { Option } = Select;

const { TextArea } = Input;

const MessageSpliterToday = () => 
    <div className="message-spliter-today">
        <label>TODAY</label>
    </div>

const MessageSpliterNews = () =>
    <div className="message-spliter-news">
        <label>New messages</label>
    </div>

const MessageUserAdded = (user) => 
    <div className="message-user-added">
        <h4>11:12:48</h4>
        <p><span>Leonetta Lloyd</span> has been added to the page by zsolt.lengyel </p>
    </div>

const MessageOppositeUserStatus = () => 
    <div className="message-opposite-user-status">
        <label>Leona is typing </label>
        <Loader
            type="ThreeDots"
            color="#91A5C6"
            height={10}
            width={10}
          />
    </div>

function RideDispatchPanel(props) {
    const [popupDisplayed, setPopUpDisplayed] = useState(false);
    const {screens, setScreens} = props;
    const {selectedItem} = useSelector(state => state.search);

    const openPopupModal = () => {
        console.log('open')
        setPopUpDisplayed(true);
    }
    
    return (
        <div className="main-column ride-dispatch-panel mr-4">
          <div className="main-content">
            <div className="main-header">
                <div className="d-flex">
                    <ReactSVG src="/images/ride-dispatch-icon.svg" />
                    <h3 className="ml-3">Ride Dispatch</h3>
                </div>
                <div className="d-flex">
                    <ReactSVG src="/images/expand-icon.svg" className="close-icon mr-4" onClick={() => setScreens({...screens, active: screens.active? null: 'rideDispatch'})} />
                    <ReactSVG src="/images/close-icon.svg" className="close-icon" onClick={() => setScreens({...screens, ridePanel: false, message: true})} />
                </div>
            </div>
            <Row className="mt-3">
                <Col span={24}>
                    <Select className="w-full" placeholder="Ride Features">
                        <Option>Option1</Option>
                    </Select>
                </Col>
            </Row>
            <div className="main-cards">
                <Row>
                    <Col span={24}><Input placeholder="Passenger name" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={12} className="pr-2"><Input placeholder="Phone Number" /></Col>
                    <Col span={12} className="pl-2"><Input placeholder="Alternate Phone" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Email" /></Col>
                </Row>
                <hr />
                <Row className="mt-3">
                    <Col span={12} className="pr-2"><Input placeholder="Account Number" /></Col>
                    <Col span={12} className="pl-2"><Input placeholder="Account Name" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Booker name" /></Col>
                </Row>
                <hr />
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Pick up location" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Dropoff location" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}>
                        <Select className="w-full" placeholder="Payment">
                            <Option>Option1</Option>
                        </Select>
                    </Col>
                </Row>
                <hr />
                <Row className="d-flex mt-3">
                    <Col span={14}><h5>Safe Hands Delivery</h5></Col>
                    <Col span={10}><Button className="primary beam-btn purple-btn w-full">Add Package</Button></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}>
                        <Select className="w-full" placeholder="Types">
                            <Option>Option1</Option>
                        </Select>
                    </Col>
                </Row>
                <div className="sub-cards">
                    <Row>
                        <Col span={24}><Input placeholder="Pick up location" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={8} className="pr-2"><Input placeholder="Phone/Email"/></Col>
                        <Col span={8} className="px-2"><Input placeholder="9/2/2021" /></Col>
                        <Col span={8} className="pl-2"><Input placeholder="12:00 AM" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}>
                            <Radio.Group defaultValue={1} className="plain-radios">
                                 <Radio value={1} className="green-radio">Picture</Radio>
                                 <Radio value={2} className="green-radio">Video</Radio>
                                 <Radio value={3} className="green-radio">Signature</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}>
                            <TextArea autoSize={{ minRows: 4, maxRows: 6 }} placeholder="Instructions" />
                        </Col>
                    </Row>
                    <Row className="d-flex px-2">
                        <Col><p>Optional</p></Col>
                        <Col><p>max. 500 character</p></Col>
                    </Row>
                </div>
                <div className="sub-cards">
                    <Row>
                        <Col span={24}><Input placeholder="Dropoff location" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={8} className="pr-2"><Input placeholder="Phone/Email"/></Col>
                        <Col span={8} className="px-2"><Input placeholder="9/2/2021" /></Col>
                        <Col span={8} className="pl-2"><Input placeholder="12:00 AM" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}>
                            <Radio.Group defaultValue={1} className="plain-radios">
                                 <Radio value={1} className="green-radio">Picture</Radio>
                                 <Radio value={2} className="green-radio">Video</Radio>
                                 <Radio value={3} className="green-radio">Signature</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}>
                            <TextArea autoSize={{ minRows: 4, maxRows: 6 }} placeholder="Instructions" />
                        </Col>
                    </Row>
                    <Row className="d-flex px-2">
                        <Col><p>Optional</p></Col>
                        <Col><p>max. 500 character</p></Col>
                    </Row>
                </div>
                <Row className="mt-3">
                    <Col span={24}>
                        <Select className="w-full" placeholder="Fleet">
                            <Option>Option1</Option>
                        </Select>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}>
                        <Select className="w-full" placeholder="IoT Device">
                            <Option>Option1</Option>
                        </Select>
                    </Col>
                </Row>
                <div className="d-flex mt-3">
                    <div className="sub-cards-block mr-1">
                        <h5>Passsenger</h5>
                        <Row className="mt-2 d-flex">
                            <Col><Button className="primary count-btn">+</Button></Col>
                            <Col><p>01</p></Col>
                            <Col><Button className="primary count-btn">-</Button></Col>
                        </Row>
                        <hr />
                        <h5>Passsenger</h5>
                        <Row className="mt-2 d-flex">
                            <Col><Button className="primary count-btn">+</Button></Col>
                            <Col><p>01</p></Col>
                            <Col><Button className="primary count-btn">-</Button></Col>
                        </Row>
                    </div>
                    <div className="sub-cards-block mx-1">
                        <h5>Driver Gender</h5>
                        <Row className="mt-4 d-flex d-around">
                            <Col><Button className="primary beam-btn blue-btn">
                                <ReactSVG src="/images/male-icon.svg" /></Button>
                                <h5 className="mt-2"><b>Male</b></h5>
                            </Col>
                            <Col><Button className="primary beam-btn blue-btn">
                                <ReactSVG src="/images/female-icon.svg" /></Button>
                                <h5 className="mt-2"><b>Female</b></h5>
                            </Col>
                        </Row>
                    </div>
                    <div className="sub-cards-block ml-1">
                        <h5>Scheduled Times</h5>
                        <Button className="primary beam-btn purple-btn w-full mt-3">Schedule</Button>
                    </div>
                </div>
                <Row className="mt-3 d-flex d-space">
                    
                    { ['Fixed', 'Bid', 'Safe', 'Pool', 'Hire', 'Rent'].map((item, idx) => 
                        <Button className="primary beam-btn gray-btn" key={idx}>{item}</Button>
                    )}
                    
                </Row>
                <Row className="mt-3">
                    <Col span={24}>
                        <TextArea autoSize={{ minRows: 4, maxRows: 6 }} placeholder="Instructions" />
                    </Col>
                </Row>
                <Row className="d-flex px-2">
                    <Col><p>Optional</p></Col>
                    <Col><p>max. 500 character</p></Col>
                </Row>
            </div>
            <Row className="action-grp mt-3">
                <Col span={8} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full">Submit</Button>
                </Col>
                <Col span={8} className="px-2">
                    <Button className="beam-btn yellow-btn cover w-full">Save</Button>
                </Col>
                <Col span={8} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full">Clear</Button>
                </Col>
            </Row>
          </div>
        {popupDisplayed && <PopupModal onClose={()=> setPopUpDisplayed(false)} /> }

        </div>
    )
}

export default RideDispatchPanel;
