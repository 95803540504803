import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Input, Button, Dropdown } from "antd";
import { ReactSVG } from 'react-svg';
import { SearchOutlined } from '@ant-design/icons';
import SideBar from './SideBar';
import SearchCard from './SearchCard';
import _ from "lodash";
import { 
  FilterByModal,
} from "../../Modals";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import EditBioModal from 'components/Modals/EditBioModal';
import FilterIcon from 'assets/SVGs/FilterIcon';
import CompassIcon from 'assets/SVGs/CompassIcon';

function SearchPanel(props) {
  const { searchResult, selectedItem } = useSelector((state) => state.search);
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState(selectedItem)
  const [searchText, setSearchText] = useState('')
  const [isFilterByModalVisible, setIsFilterByModalVisible] = useState(false);
  const {screens, setScreens, client} = props;

  const openInNewWindow = () => {
    window.open(window.location.origin + `/map/home`, '_blank', 'toolbar=0,location=0,menubar=0');
  }

  const menu = (
    <ul className="user-dropdown">
      <li>
          <Link to={`/map/home`} target="_blank" className="ml-3 text-white">Open New Tab</Link>
      </li>
      <li onClick={openInNewWindow}>
          <span className="ml-3">Open New Window</span>
      </li>

    </ul>
  );

  const handleSearchItemClick = (item) => {
    setActiveItem(item);
    setScreens({...screens, map: true, message: true, rxPanel: false, ridePanel: false, active: null});
    dispatch({type: 'SET_SELECTED_ITEM', payload: item})
  }

  useEffect(() => {
    if(selectedItem)
      setActiveItem(selectedItem)
  }, [selectedItem])
  
  const handleMapClick = () => {
    dispatch({ type: 'SET_MAP_TYPE', payload: 'HOME' })
    setScreens({...screens, map: true});
  }


  return (
    <div className="main-column search-panel mr-4">
      <SideBar {...props} client={client} />
      <div className="main-content">
        <ReactSVG src="/images/next-icon.svg" className="main-next-icon" />
        <div className="search-bar">
          <div className="d-flex">
            <Input className="beam-input" onChange={e => setSearchText(e.target.value)} placeholder="Search Pages & Users" suffix={
              <SearchOutlined />
            } />
            <Button className="beam-btn ml-2" onClick={() => setIsFilterByModalVisible(true)}>
              <FilterIcon />
            </Button>
            <Dropdown overlay={menu} placement="bottomRight" trigger={['contextMenu']}>
              <Button className="beam-btn ml-2" onClick={handleMapClick}>
                <CompassIcon />
              </Button>
            </Dropdown>
          </div>
          <FilterByModal isModalVisible={isFilterByModalVisible} setIsModalVisible={setIsFilterByModalVisible} />
        </div>
        <div className="main-cards">
          {searchResult.filter(search => search.title && search.title.toLowerCase().includes(searchText.toLowerCase())).map((item, idx) => 
              <SearchCard item={item} key={idx} active={item.pageId === activeItem?.pageId} onClick={() => handleSearchItemClick(item)} />
          )}
        </div>
      </div>
    </div>
  )
}

export default SearchPanel;
