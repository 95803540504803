import React, { useEffect, useState, useRef, useReducer } from 'react';
import {Row, Col, Input, Button, Popover, Switch} from "antd";
import { ReactSVG } from 'react-svg';
import SettingsPop from './SettingsPop';
import FilterPop from './FilterPop';
import PinPop from './PinPop';
import {useDispatch, useSelector} from 'react-redux';
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import Geocode from "react-geocode";
import FilterIcon from 'assets/SVGs/FilterIcon';
import SettingsIcon from 'assets/SVGs/SettingsIcon';
import CompassIcon from 'assets/SVGs/CompassIcon';
import { PAGE_TYPES } from 'utils/PageTypes';
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import MapService from "./MapService";
import GPSSocketService from "./GPSSocketService";
import Map from './Map'
import {object} from "prop-types";

const DEFAULT_LOCATION = { lat: 51.509865, lng: -0.118092 };
/*global google*/

function useMounted() {
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    setIsMounted(true)
  }, [])
  return isMounted
}


function MapPanel(props) {
  const { selectedItem, mapType } = useSelector(state => state.search);
  const { focusLatLng } = useSelector(state => state.message);
  const { gps, subscriptions, map_places_bounds, selected_places, places } = useSelector(state => state.dashboard);
  const [latestUpdateImei, setLatestUpdatedImei] = useState();
  const [subscribedImeis, setSubscribedImeis] = useState([]);
  const {screens, setScreens} = props;
  const [activePop, setActivePop] = useState();
  const [currentLocation, setCurrentLocation] = useState(DEFAULT_LOCATION);
  const [active, setActive] = useState(true);
  const [addressComponent, setAddressComponent] = useState();
  const [showMap, setShowMap] = useState(true);
  const [markers, setMarkers] = useState([]);
  const [gpsMarkers, setGpsMarkers] = useState({});
  const [polygons, setPolygons] = useState([]);
  const [circles, setCircles] = useState([]);
  const [panel, setPanel] = useState(false);
  const [mode, setMode] = useState('multi');
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [settings, setSetting] = useState({track: 'auto', breadcrumbs: ['Show breadcrumbs']});
  const [lock, setLock] = useState();
  const [lockTracking, setLockTracking] = useState({checked: false});
  const { showGPSHistorical, createPeopleMarker, generateUniqueColor, createPlaceMarker } = MapService();
  const {connectSocket, subscribeToImeis, unsubscribeToImeis } = GPSSocketService();
  const dispatch = useDispatch();
  const isMounted = useMounted();

  const geometryMapping = {
    marker: markers,
    polygon: polygons,
    circle: circles
  };

  const setGeometryMapping = {
    marker: setMarkers,
    polygon: setPolygons,
    circle: setCircles
  };

  const type = selectedItem && selectedItem.type && selectedItem.type === PAGE_TYPES.BROADCAST ? 'orange' : undefined;
  const color = type && type === 'orange' ? "#FF5F25" : "#508AFA";


  useEffect(() => {
    // if(mapType == 'PAGE') {
    //   getMyLocationHandler();
    // }
    connectSocket()
  }, []);


  useEffect(() => {
    // console.log('selcted item here', selectedItem)
    if(isMounted) {
      clearMapHandler();
      getMyLocationHandler();
    }
  }, [selectedItem]);

  useEffect(() => {
      clearMapHandler();
      if (mapType == 'HOME') {
        // let objs = map_places_bounds?.map((obj, idx) => {
        //   return createPlaceMarker(obj)
        // }) ?? [];
        // objs = objs.filter((x) => {
        //   return x.lat && x.lng
        // });
        // setMarkers(objs)
        console.log('home map do nothing');
      } else {
        getMyLocationHandler();
      }
  }, [mapType]);


  useEffect(() => {
    if (isMounted) {
      // let filteredPlaces = places.items.filter((e)=>{return selected_places.indexOf(e.id) !== -1});

      if(mapType == 'HOME') {
        (async function () {
          let filteredPlaces = await Promise.all(selected_places?.items.map(async (obj, idx) => {
            return await createPlaceMarker(obj, selected_places.fitBounds)
          })) ?? [];

          filteredPlaces = filteredPlaces.filter((x) => {
            return x.lat && x.lng
          });
          setMarkers(filteredPlaces)
        }());
      }
      if(mapType == 'PAGE'){
        let filteredMediaPlaces = selected_places.items.filter((e)=>{
          return e.objectType === 'media'
        });
        let filteredLocationPlaces = selected_places.items.filter((e)=>{
          return e.objectType === 'place'
        });
        let filteredPeoplePlaces = selected_places.items.filter((e)=>{
          return e.objectType === 'user'
        });

        let filteredPeoplePlacesDeviceKeys = selected_places.items.map((e)=>{
          return e.data.deviceKey
        });

        //think about it


        const imeisToUnsubscribe = [];
        const currentImeis = new Set(subscribedImeis);


        filteredPeoplePlaces.map((e) => {
          if(!currentImeis.has(e.data.deviceKey))
            showGPSHistorical(e.data.deviceKey, e.data.reportingStart, undefined, true);
        });

        let gpsMarkersTemp = {...gpsMarkers}

        for(let key of Object.keys(gpsMarkers)){
          if(!filteredPeoplePlacesDeviceKeys.includes(key)){
            delete gpsMarkersTemp[key]
            imeisToUnsubscribe.push(key)
            currentImeis.delete(key)
          }
        }

        console.log('unsubscribe to imeis', imeisToUnsubscribe)
        console.log('subscribed imeis', Array.from(currentImeis))

        unsubscribeToImeis(imeisToUnsubscribe);
        setGpsMarkers(gpsMarkersTemp);
        setSubscribedImeis(Array.from(currentImeis));


        let markers = [];
        let polygons = [];
        let circles = [];

        filteredLocationPlaces.map((e) => {
          if(e.data.type === 'polygon'){
            polygons.push(getGeometry(e))
          }
          else if(e.data.type === 'marker'){
            markers.push(getGeometry(e, selected_places.fitBounds))
          }
          else if(e.data.type === 'circle'){
            circles.push(getGeometry(e))
          }
        });
        (async function () {
          let filteredPlaces = await Promise.all(filteredMediaPlaces.map(async (obj, idx) => {
            return await createPlaceMarker(obj, selected_places.fitBounds)
          })) ?? [];
          filteredPlaces = filteredPlaces.filter((x) => {
            return x.lat && x.lng
          });
          setMarkers([...filteredPlaces, ...markers]);
          setPolygons(polygons);
          setCircles(circles);
        }());
      }
    }
  }, [selected_places]);


  const getMyLocationHandler = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
            setCurrentLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                (response) => {
                  let city, country;
                  for (let i = 0, len = response.results[0].address_components.length; i < len; i++) {
                    var ac = response.results[0].address_components[i];
                    if (ac.types.indexOf("administrative_area_level_1") >= 0) city = ac.short_name;
                    if (ac.types.indexOf("country") >= 0) country = ac.short_name;
                  }
                  setAddressComponent({city, country})
                },
                (error) => {
                  console.error(error);
                }
            );
          }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const clearMapHandler = () => {
    setActive(false);
    setPanel(false);
    setMarkers([]);
    setPolygons([]);
    setCircles([]);
    setGpsMarkers({});
    unsubscribeToImeis(subscribedImeis);
    setSubscribedImeis([]);
    dispatch({type: 'CLEAR_ALL_IMEI_SUBSCRIPTION'});
    dispatch({type: 'CLEAR_ALL_FILTERS'});
  };

  const googleSuggestHandler = (place) => {
    geocodeByPlaceId(place.place_id)
        .then(results => {
          if(results[0].geometry){
            setCurrentLocation({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            })
          }
        })
        .catch(error => console.error(error));
  };

  const delay = (delayInms) => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(1);
      }, delayInms);
    });
  };

  const changeMapStatus = async () => {
    // setShowMap(false)
    // await delay(50)
    // setShowMap(true)
  };

  useEffect(() => {
    changeMapStatus()
  }, [currentLocation])

  const addPolygon = (polygon) => {
    let index = polygons.findIndex((x)=>{return x.id === polygon.id});
    if(index !== -1)return;
    setPolygons(prevState => (mode === 'multi' ? [
      ...prevState,
      polygon
    ]: [polygon]));
    if(mode === 'single'){
      setMarkers([]);
      setCircles([]);
    }
  }

  const addCircle = (circle) => {
    let index = circles.findIndex((x)=>{return x.id === circle.id});
    if(index !== -1)return;
    setCircles(prevState => (mode === 'multi' ?[
      ...prevState,
      circle
    ]: [circle]));
    if(mode === 'single'){
      setMarkers([]);
      setPolygons([]);
    }
  }

  const addMarker = (marker) => {
    let index = markers.findIndex((x)=>{return x.id === marker.id});
    if(index !== -1)return;
    setMarkers(prevState => (mode === 'multi' ? [
      ...prevState,
      marker
    ]: [marker]));
    if(mode === 'single'){
      setPolygons([]);
      setCircles([]);
    }
  };

  const getGeometry = (message, fitBounds = false) => {
    if(message.data.type === 'polygon'){
      return {...message.data, id: message.id};
    }
    else if(message.data.type === 'marker'){
      return{type: message.data.type, lat: message.data.paths.lat, lng: message.data.paths.lng, ...message.data, id: message.id, fitBounds};
    }
    else if(message.data.type === 'circle'){
      return {radius: message.data.paths.radius, lat: message.data.paths.lat, lng: message.data.paths.lng, ...message.data, id: message.id};
    }
  };

  useEffect(() => {
    let color = generateUniqueColor();
    if(!gps.live && subscribedImeis.length > 0){
      unsubscribeToImeis(subscribedImeis);
      setSubscribedImeis([]);
    }
    if(gps.live && gps.points.length > 0 && gpsMarkers[gps.points[0].imei]){
      color = gpsMarkers[gps.points[0].imei].color;
    }
    const objs = gps?.points?.map((obj, idx)=>{return createPeopleMarker(obj, color, ((idx === 0 && !gps.live) || idx === gps.points.length - 1) && gps.points.length > 1)}) ?? [];
    if(objs.length > 0) {
      const imei = objs[0].imei;
      let tempMarkers = [...gpsMarkers[imei] ? gpsMarkers[imei].markers: []];
      if(gps.live && objs.length === 1 && (!latestUpdateImei || latestUpdateImei.mode))
        tempMarkers = [...objs, ...tempMarkers];
      else
        tempMarkers = objs;
      setGpsMarkers(prevState => ({
        ...prevState,
        [imei]: {markers: tempMarkers, color},
      }));
      setLatestUpdatedImei({imei, mode: gps.live})
    }
  }, [gps]);


  useEffect(() => {
    const imeisToSubscribe = new Set();
    const imeisToUnsubscribe = new Set(subscribedImeis);
    const imeiSubscriptions = new Set(subscribedImeis);
    for (let imei of subscriptions) {
      if (!imeiSubscriptions.has(imei)) {
        imeisToSubscribe.add(imei);
        imeiSubscriptions.add(imei);
      }
      imeisToUnsubscribe.delete(imei);
    }
    for (let imei of Array.from(imeiSubscriptions)) {
      if (!subscriptions.includes(imei)) {
        imeiSubscriptions.delete(imei);
      }
    }
    setSubscribedImeis(Array.from(imeiSubscriptions));
    subscribeToImeis(Array.from(imeisToSubscribe));
    unsubscribeToImeis(Array.from(imeisToUnsubscribe));

  }, [subscriptions]);

  // const handlePlaces =  (message) => {
  //   const {flag} = message.data;
  //   if(flag === 'replace'){
  //     let geometryArr = geometryMapping[message.data.type];
  //     let index = geometryArr.findIndex((x)=>{return x.id === message.data.originalPageMessageId});
  //     if(index !== -1) {
  //       geometryArr[index] = getGeometry(message);
  //       setGeometryMapping[message.data.type](geometryArr);
  //       forceUpdate();
  //       return;
  //     }
  //   }
  //   else if(flag === 'remove'){
  //     let geometryArr = geometryMapping[message.data.type];
  //     let index = geometryArr.findIndex((x)=>{return x.id === message.data.originalPageMessageId});
  //     if(index !== -1) {
  //       geometryArr.splice(index, 1);
  //       setGeometryMapping[message.data.type](geometryArr);
  //       forceUpdate();
  //     }
  //     return;
  //   }
  //
  //   if(message.data.type === 'polygon'){
  //     addPolygon(getGeometry(message))
  //   }
  //   else if(message.data.type === 'marker'){
  //     addMarker(getGeometry(message))
  //   }
  //   else if(message.data.type === 'circle'){
  //     addCircle(getGeometry(message))
  //   }
  // }


  // useEffect(() => {
  //   if(focusLatLng){
  //     const {message} = focusLatLng;
  //     handlePlaces(message)
  //   }
  // }, [focusLatLng]);


  const updateSettings = (key, value) => {
    setSetting(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  return (
      <div className="main-column map-panel mr-4">
        <div className="main-content">
          {activePop === 'settings' && <SettingsPop setActivePop={setActivePop} settings={settings} updateSettings={updateSettings}/>}
          {activePop === 'filters' && <FilterPop mapType={mapType} setActivePop={setActivePop} />}
          {activePop === 'pin' && <PinPop setActivePop={setActivePop} />}
          <div className="main-header">
            <div className="d-flex">
              <CompassIcon color={color} />
              <h3 className="ml-3" style={{color}}>{mapType === 'PAGE' ? (selectedItem.title || `Page ${selectedItem.pageId || ''}`)+"'s": 'Main'} map</h3>
            </div>
            <div className="d-flex">
              <ReactSVG src="/images/expand-icon.svg" className="close-icon mr-4" onClick={() => setScreens({...screens, active: screens.active? null: 'map'})} />
              <ReactSVG src="/images/close-icon.svg" className="close-icon" onClick={() => setScreens({...screens, map: false})} />
            </div>
          </div>
          <div className="search-bar mt-3">
            <div className="d-flex">
              <div className="g-map-search-input">
                {addressComponent &&
                <GooglePlacesAutocomplete
                    onPlaceSelected={(place) => googleSuggestHandler(place)}
                    autocompletionRequest={{
                      // bounds: bounds,
                      // componentRestrictions: {
                      //   country: [addressComponent.country],
                      // }
                    }}
                    selectProps={{
                      onChange: (place) => {
                        // console.log('place here', place)
                        googleSuggestHandler(place.value)
                      },
                      placeholder: "Search map",
                      styles: {
                        input: (provided) => ({
                          ...provided,
                          fontFamily: 'Nunito-Regular'
                        }),
                      },
                    }}
                    apiKey={"AIzaSyDNIyoSDV1wnJVW8wdHkiNG-eZ2bMcq0iY"}/>
                }

              </div>
              <Button className="beam-btn ml-2" onClick={() => setActivePop('settings')}>
                <SettingsIcon />
              </Button>
              <Button className="beam-btn ml-2" onClick={() => setActivePop('filters')}>
                <FilterIcon />
              </Button>
              <Button className="beam-btn ml-2"><ReactSVG src="/images/location-icon.svg" onClick={getMyLocationHandler}/></Button>
              <Button className="beam-btn ml-2"><ReactSVG src="/images/reload-icon.svg" onClick={()=>{clearMapHandler(); getMyLocationHandler()}}/></Button>
            </div>
          </div>
          <div className="main-cards">
            <Row className="map-container-layout">
              <Col md={(screens.active && panel) ? 6: 0}>
                <div id="direction-panel"/>
              </Col>
              <Col md={(screens.active && panel) ? 18: 24}>
                { showMap && <Map
                    isMarkerShown={false}
                    onClick={(event) => {}}
                    currentLocation={currentLocation}
                    markers={markers}
                    gpsMarkers={gpsMarkers}
                    polygons={polygons}
                    circles={circles}
                    active={active}
                    setActive={setActive}
                    showPanel={setPanel}
                    settings={settings}
                    mapType={mapType}
                    activePop={activePop}
                    getPlaces={(bounds)=> {
                      if(mapType == 'HOME' && activePop !== 'filters') {
                        dispatch({
                          type: 'GET_MAP_PLACES_BOUNDS', payload: {
                            clientId: '60c83dcefe8cea1cd6699618',
                            objectType: 'place',
                            type: 'point',
                            bounds: bounds
                          }
                        });
                      }
                    }}
                    clearPlaces={()=>{
                      if(mapType == 'HOME' && activePop !== 'filters' && markers.length > 0 && !markers[0].fitBounds) {
                        setMarkers([]);
                        dispatch({type: 'SET_SELECTED_PLACES', payload: {items: [], fitBounds: false}})
                      }
                    }}
                    lockTracking={lockTracking}
                    latestUpdateImei={latestUpdateImei}
                    toggleLock={(id)=>{
                        if(id !== lockTracking.lock)setLockTracking({});
                        setLock(!lock ? id: null);
                      }
                    }
                />}
              </Col>
            </Row>

            {lock && settings.track === 'auto' &&
              <Row className="lock-icons">
                <Col span={12}>
                  Lock tracking
                </Col>
                <Col span={12}>
                  <Switch checked={lockTracking.checked} onChange={(checked) => {
                    setLockTracking({checked, lock})
                  }}/>
                </Col>
              </Row>
            }


            <Row className="map-ctrl-icons">
              <Col span={12} className={mode === 'single' ? 'ant-col-active': ''} onClick={()=>{
                setMode('single')
              }}>
                <ReactSVG src="/images/map-line-icon.svg"/>
              </Col>
              <Col className={mode === 'multi' ? 'ant-col-active': ''} span={12} onClick={()=>{
                setMode('multi')
              }}>
                <ReactSVG src="/images/map-triple-icon.svg"/>
              </Col>
            </Row>
          </div>
        </div>
      </div>
  )
}

export default MapPanel;
