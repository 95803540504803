import React, { useState } from 'react';
import { Input, Button, Modal, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import { useDispatch, useSelector } from 'react-redux';


function AddIoTModal(props) {
    const { onClose, client } = props;
    const [isModalVisible, setIsModalVisible] = useState(true);
    const dispatch = useDispatch();
    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };
    const handleOk = (users) => {
    }

    const handleIOTClick = (type) => {
        dispatch({type: 'SEND_MESSAGE', payload: {message: `${type} IoT added`, type: 'IoT'}})
    }

    return (
        <Modal title="" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='480px'
            className="beam-modal scrollable"
            footer={[]}
            closable={false}
            destroyOnClose={true}
        >
            <Row className="d-flex mt-1 mb-4">
                <Col><h3>IoT</h3></Col>
               
            </Row>
            <Row>
                <Col span={24}>
                    <Input placeholder="Search" suffix={
                      <SearchOutlined />
                    } />
                </Col>
            </Row>
            <Row className="scrollable-body">
                <Col span={24}>
                    <Row className="d-flex mt-4 mb-4">
                        <Col><h5>Add to page</h5></Col>
                    </Row>
                    <Row className="person-item">
                        <Col span={4}><img src={`/images/IoT-icon.png`} width="40" height="40" /></Col>
                        <Col span={16}>
                            <h5>GPS</h5>
                            <p>Location</p>
                            <p>2022-12-01</p>
                        </Col>
                        <Col span={4} className="t-right">
                            <Button className="beam-btn green-btn" onClick={() => handleIOTClick('GPS')}>
                                Add
                            </Button>
                        </Col>
                    </Row>
                    <Row className="person-item">
                        <Col span={4}><img src={`/images/IoT-icon.png`} width="40" height="40" /></Col>
                        <Col span={16}>
                            <h5>Camera</h5>
                            <p>Video</p>
                            <p>2022-12-12</p>
                        </Col>
                        <Col span={4} className="t-right">
                            <Button className="beam-btn green-btn" onClick={() => handleIOTClick('Camera')}>
                                Add
                            </Button>
                        </Col>
                    </Row>
                    <Row className="person-item">
                        <Col span={4}><img src={`/images/IoT-icon.png`} width="40" height="40" /></Col>
                        <Col span={16}>
                            <h5>RX</h5>
                            <p>RX</p>
                            <p>2022-02-01</p>
                        </Col>
                        <Col span={4} className="t-right">
                            <Button className="beam-btn green-btn" onClick={() => handleIOTClick('RX')}>
                                Add
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    )
}

export default AddIoTModal;
