import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { 
  Form,
  Input,
  Button,  
} from 'antd';
import { LOGIN_USER } from 'reducers/authReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AUTHENTICATE_USER } from 'reducers/authReducer';

export default () => {

    const [error, setError] = useState(null);
    const [resendForm] = Form.useForm();
    const history = useHistory();
    const dispatch = useDispatch();
	const { registeredEmail, registeredPhone, loginSuccessfull, isLoading } = useSelector((state) => state.auth);
    console.log(registeredEmail);

    const onSubmitHandler = (values) => {
        let payload = { registerRequest: true, password: values.verificationCode }
        const email = registeredEmail;
        const phone = registeredPhone;
        if(email) {
            payload['email'] = email;
        } 
        if(phone) {
            payload['phone'] = phone;
            payload['verificationCodePhone'] = values.verificationCodePhone
        }


		dispatch(LOGIN_USER(payload));

		// dispatch(LOGIN_USER({email: registeredEmail, password: values.verificationCode+''}));
    };

	useEffect(() => {
        console.log(loginSuccessfull);
		if (loginSuccessfull) {
			history.push('/dashboard');
		}
		console.log(loginSuccessfull)
	}, [loginSuccessfull])
    return (
        <div className="page-container auth-page">
            <Form 
                className="auth-form"
                name="resend-form"
                id="resend-form"
                layout='vertical'
                form={resendForm}
                onFinish={onSubmitHandler}
                requiredMark={false}
            >
                <img alt='1' src='/images/back.png' className="back-btn" onClick={() => history.goBack()}/>
                <h3>Verify Code</h3>
                <span>Please enter code you received on {registeredEmail &&  <a href="" onClick={(e)=>e.preventDefault()}>{registeredEmail}</a>}</span>
                <Form.Item className="mt-2" name="verificationCode" rules={[{ required: true, message: '**Required Field' }]}>
                    <Input type="text" placeholder={!registeredPhone ? "Verification Code" : "Email Verification Code"} />
                </Form.Item>
                {registeredPhone && 
                    <>
                        <span>Please enter code you received on {registeredPhone &&  <a href="" onClick={(e)=>e.preventDefault()}>{registeredPhone}</a>}</span>
                        <Form.Item className="mt-2" name="verificationCodePhone" rules={[{ required: true, message: '**Required Field' }]}>
                            <Input type="text" placeholder={"Number Verification Code"} />
                        </Form.Item> 
                    </>
                }
                {error && <Form.Item><p className="error">{ error }</p></Form.Item>}
                <Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit" onClick={() => setError('')}>Verify</Button>
                </Form.Item>
            </Form>
        </div>
    )
}
