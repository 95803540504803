import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, Checkbox } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import { useDispatch } from 'react-redux';

const SurveyTypes = [
    {span: 7, label: '3-Button'},
    {span: 6, label: 'Yes / No'},
    {span: 9, label: 'Multiple Choice'},
    {span: 5, label: 'Bar'},
    {span: 8, label: 'Number'},
    {span: 9, label: 'Currency'},
    {span: 9, label: 'Date & Time'},
    {span: 6, label: 'Date'},
    {span: 7, label: 'Time'},
    {span: 6, label: 'Text'},
    {span: 7, label: 'Photo'},
    {span: 9, label: 'Signature'},
]

function AddSurveyModal(props) {
    const { onClose, rsvp } = props;

    const [isModalVisible, setIsModalVisible] = useState(true);
    const [rsvpTitle, setRSVPTitle] = useState('');
    const [errorText, setErrorText] = useState('');
    const [activeType, setActiveType] = useState('3-Button');
    const [choiceCount, setChoiceCount] = useState(1);

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };
    const dispatch = useDispatch();
    const handleOk = () => {
        setIsModalVisible(false);
    }

    let renderEle = '';

    switch (activeType) {
        case '3-Button':
            renderEle = <Row className="tiles">
                <Col span={24}>
                    <Row className="d-flex mb-4">
                        <Col span={12} className="pr-2"><Button className="beam-btn green-btn cover w-full"><Input className="beam-input t-white" placeholder="Yes"/></Button></Col>
                        <Col span={12} className="pl-2"><Button className="beam-btn w-full"><Input className="beam-input" placeholder="Maybe"/></Button></Col>
                    </Row>
                    <Row>
                        <Col span={24}><Button className="beam-btn orange-btn cover w-full"><Input className="beam-input t-white" placeholder="No"/></Button></Col>
                    </Row>
                </Col>
            </Row>
            break;
        case 'Yes / No':
            renderEle = <Row className="tiles">
                <Col span={24}>
                    <Row className="d-flex mb-4">
                        <Col span={12} className="pr-2"><Button className="beam-btn green-btn cover w-full"><Input className="beam-input t-white" placeholder="Yes"/></Button></Col>
                        <Col span={12} className="pl-2"><Button className="beam-btn orange-btn cover w-full" key="yes-no"><Input className="beam-input t-white" placeholder="No"/></Button></Col>
                    </Row>
                </Col>
            </Row>
            break;
        case 'Multiple Choice':
            renderEle = <Row className="tiles">
                { _.times(choiceCount, (i) => {
                    return (
                        <Col span={24} key={i} className="mb-3">
                            <Input className="beam-input" />
                        </Col>
                    )
                })}
                <Col span={24} className="t-right">
                    <Button className="beam-btn" 
                        onClick={() => setChoiceCount(choiceCount+1)}>Add</Button>
                </Col>
            </Row>
            break;
        case 'Bar':
            renderEle = <Row className="tiles">
                <Col span={24}>
                    <Input type="number" placeholder="Minimum" min={0} className="beam-input" />
                </Col>
                <Col span={24}>
                    <Input type="number" placeholder="Maximum" min={0} className="beam-input mt-3" />
                </Col>
                <Col span={24}>
                    <Input type="number" placeholder="Step" min={0} className="beam-input mt-3" />
                </Col>
            </Row>
            break;
        default:
            renderEle = '';
    }

    return (
        <Modal title="Add Survey"
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row className="mb-4">
                <Col><h5>Choose the Survey type</h5></Col>
            </Row>
            <Row className="d-flex">
                {SurveyTypes.map((sType, idx) =>
                    <Col span={sType.span} key={idx} className="mb-3">
                        <Button className={`beam-btn w-full ${activeType === sType.label? "green-btn cover": ""}`}
                            onClick={() => setActiveType(sType.label)}
                        >
                            {sType.label}
                        </Button>
                    </Col>
                )}
            </Row>
            <Row className="mt-4 mb-4">
                <Col span={24}>                    
                    <Input value={rsvpTitle} placeholder={activeType} onChange={e => setRSVPTitle(e.target.value)} />
                    {errorText && <span className="error-text">Please fill this field</span> }
                </Col>
            </Row>
            {renderEle}
            <Row className="action-grp mt-4">
                <Col span={14} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full" onClick={handleOk}>Add</Button>
                </Col>
                <Col span={10} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default AddSurveyModal;
