import React, { useEffect,useState } from "react";
import { Row, Col, Input, Button } from "antd";
import { ReactSVG } from 'react-svg';
import { SearchOutlined } from '@ant-design/icons';
import {
  SearchPanel,
  MessagePanel,
  MapPanel,
  HeaderPanel,
  RideDispatchPanel,
  RxPanel,
  Nine11Panel,
  StorePanel,
  LogisticsPanel,
  ContactTracePanel
} from "../../components/Dashboard";
import "./Dashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "react-google-autocomplete";
import RidePanel from "components/Dashboard/RidePanel";
import { useHistory, useParams } from "react-router-dom";
import { getPagesRequestPublishTopic, getPagesSubscriptionTopic, getCLBSLocations } from "hooks/MQTTConstants";
import { GET_USERS_LIST } from "reducers/authReducer";


export default ({client}) => {
  const { searchResult, selectedItem } = useSelector((state) => state.search);
  const history = useHistory();
  const dispatch = useDispatch();
  const {pageId} = useParams();
  const [screens, setScreens] = useState({
    list: false,
    message: false,
    map: false,
    active: null,
    ridePanel: false,
    cadType: '',
  });

  const params = {
    screens,
    setScreens
  }

  useEffect(() => {
		dispatch(GET_USERS_LIST());
    if(!localStorage.getItem('TOKEN')) {
      history.push('/auth/login')
    }
  }, [])

  const setSelectedPage = (item) => {
    setScreens({...screens, map: true, message: true, rxPanel: false, ridePanel: false, active: null});
    dispatch({type: 'SET_SELECTED_ITEM', payload: item})
  }
  useEffect(() => {
    if(pageId && searchResult && searchResult.length > 0) {
      const page = searchResult.find(item => item.pageId === pageId);
      console.log(searchResult)
      setSelectedPage(page);
    }
  }, [pageId, searchResult])

  useEffect(() => {
    console.log('selectedItem')
    console.log(selectedItem)
    if (selectedItem.pageId) {
      setScreens({...screens,map: true, message: true, ridePanel: false, active: null})
    }
  }, [selectedItem])

  useEffect(() => {
    if(client){
      client.subscribe(getPagesSubscriptionTopic(), { qos: 1 }, (error) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
        console.log('Subscribed')
      });
      //
      // client.subscribe(`${getCLBSLocations()}/response`, { qos: 1 }, (error) => {
      //   if (error) {
      //     console.log('Subscribe to clbs topic error', error);
      //     return
      //   }
      //   console.log('Subscribed')
      // });


      // client.publish(getCLBSLocations(), JSON.stringify({
      //   clientId: '60c83dcefe8cea1cd6699618',
      //   objectType: 'place',
      //   offset: 0,
      //   limit: 100
      // }));

      // client.subscribe(`beam/service/+/user/${localStorage.getItem('RID')}/+`, { qos: 1 }, (error) => {
      client.subscribe(`${process.env.REACT_APP_ECO_SYSTEM}/service/+/user/+/+`, { qos: 1 }, (error) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
        console.log('Subscribed to topic')
      });


      dispatch({type: 'GET_LOCATION_PROFILES', payload: {
          objectId: "41",
          paths: ['$'],
          returnPaths: ['$..admin.clbs.profile', '$..admin.asset.profile'],
        }});

      client.publish(getPagesRequestPublishTopic(), JSON.stringify({
        paths: [
          "$.persistent"
        ],
        // paths: [`$.persistent.static.members[?(@.rootUserID == 21)]`, '$.persistent'],
        returnPaths: [
          "$.persistent.static"
        ],
        pagination: {
          offset: 0,
          limit: 200
        }
      }), { qos: 1 }, error => {
				if (error) {
					console.log('Publish error: ', error);
				}
			});
    }
  },[client]);

  useEffect(() => {

  },[client]);

  const fixedColSize = pageId ? 12 : 8;

  return (
    <Row className="main-layout">
      {!pageId && <Col md={8} xs={24}>
        <HeaderPanel />
        <SearchPanel client={client} {...params} />
        <div className="footer-panel mr-4">
          <p>© All rights reserved dream ers.</p>
        </div>
      </Col>}
      {
        (screens.message || screens.ridePanel) && <Col md={screens.map? fixedColSize: 16} xs={24} className={(screens.active === 'message' || screens.active === 'rideDispatch' || screens.active === 'rxPanel') && 'active-screen'}>
          { screens.message &&  <MessagePanel client={client} {...params} /> }
          {/* { screens.rideDispatch && <RideDispatchPanel {...params} /> } */}
          { screens.ridePanel && <RidePanel type={screens.cadType} {...params} /> }
          {/* { screens.rxPanel && <RxPanel {...params} /> } */}
        </Col>
      }
      {
        screens.map && <Col md={screens.message || screens.ridePanel ? fixedColSize: 16} xs={24} className={screens.active === 'map' && 'active-screen'}>
          <MapPanel  {...params} />
        </Col>
      }
      <Autocomplete apiKey="AIzaSyDNIyoSDV1wnJVW8wdHkiNG-eZ2bMcq0iY" style={{display: 'none'}} options={{
        types: ["(regions)"], componentRestrictions: { country: ["us", "ca", "uk"] }
      }}/>
    </Row>
  );
};
