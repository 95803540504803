import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {Input, Button, Modal, Row, Col, DatePicker} from 'antd';
import {DownOutlined, SearchOutlined} from '@ant-design/icons';
import _ from "lodash";
import { useDispatch } from 'react-redux';
import moment from "moment";

const { TextArea } = Input;

function GPSModal(props) {
    const { onClose } = props;
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [report, setReport] = useState({key: '', startDate: new Date(), endDate: new Date()});


    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };
    const dispatch = useDispatch();
    const handleOk = () => {
        if(report.key !== undefined && report.key !== ''){
            dispatch({type: 'SEND_MESSAGE', payload: {message: `${report.key} (GPS report)`, type: 'gps-report', data: {"title": report.key, "deviceKey":report.key, "reportingStart": report.startDate,"reportingEnd": report.endDate}}})

            // dispatch({type: 'SEND_MESSAGE', payload: {message: key, type: 'gps', data: {"title": key,"deviceKey": key,"reportingStart": new Date()}}})
        }
        setIsModalVisible(false);
    };

    const updateReport = (key, value) =>{
        setReport(prevState => ({
            ...prevState,
            [key]: value
        }));
    }

    return (
        <Modal title="GPS Report"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row>
                <Col span={24}>
                    <Input value={report.key} onChange={(e)=>{updateReport('key', e.target.value)}} placeholder="Device key" />
                </Col>
            </Row>
            <br/>
            <Row>
                <Col span={24}>
                    <div className="datetime-item">
                        <img src="/images/date.png" /><DatePicker value={moment(report.startDate)} onChange={(date)=>{date && updateReport('startDate', date.format('MM/DD/YYYY HH:mm:ss'))}} placeholder="Start date" suffixIcon={<DownOutlined />} />
                    </div>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col span={24}>
                    <div className="datetime-item">
                        <img src="/images/date.png" /><DatePicker value={moment(report.endDate)} onChange={(date)=>{date && updateReport('endDate', date.format('MM/DD/YYYY HH:mm:ss'))}} placeholder="End date" suffixIcon={<DownOutlined />} />
                    </div>
                </Col>
            </Row>
            <Row className="action-grp mt-4">
                <Col span={14} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full" onClick={handleOk}>Add</Button>
                </Col>
                <Col span={10} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default GPSModal;
