import * as constants from '../constants/actions';
import {takeEvery} from 'redux-saga/effects';

import * as Raven from "raven-js";


/**
 *
 * @param {Object} payload: {
 *  message,
 *  topic
 * }
 */
function* onMessageFromEventListener({payload}) {
  try {
      let publishedEventResponse = payload.message;
      let event = publishedEventResponse.event;
      let eventState = publishedEventResponse.eventState;
console.log(eventState)
console.log(event)
  } catch (e) {
    yield Raven.captureException(e);
  }
}
export function* onMessageFromEventListenerSaga() {
  yield takeEvery(constants.ON_MESSAGE_FROM_EVENT_LISTENER, onMessageFromEventListener)
}

