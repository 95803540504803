import React, { useState } from 'react';
import {  Modal, Row, Col, Collapse } from 'antd';
import _ from "lodash";
import './index.scss'
import { 
    Form,
    Input,
    Button,
  } from 'antd';
import { FormLabel } from 'react-bootstrap';
const { Panel } = Collapse;


function ConfirmationModal(props) {
    const { onClose, handleConfirm, message } = props;
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [confirmation] = Form.useForm();

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };

    const handleOk = () => {
        setIsModalVisible(false);
        onClose && onClose();
    }

    return (
        <Modal title="Please Confirm" 
            visible={isModalVisible}
            onCancel={handleCancel} 
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            
            <Form
                className="auth-form"
                name="confirmation-form"
                id="confirmation-form"
                layout='vertical'
                form={confirmation}
                requiredMark={false}
            >
                <Form.Item name="password" rules={[{ required: true, message: '**Required Field' }]} className="mt-3">
                    <FormLabel>{message || 'Are you sure to logout?'}</FormLabel>
                </Form.Item>

                <Form.Item className="action-grp mt-4">
                    <Row>
                        <Col span={14} className="pr-2">
                            <Button className="beam-btn green-btn cover w-full" onClick={handleConfirm}>Confirm</Button>
                        </Col>
                        <Col span={10} className="pl-2">
                            <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ConfirmationModal;
