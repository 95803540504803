import React, { useEffect, useState } from 'react';
import {  Modal, Row, Col, Collapse } from 'antd';
import _ from "lodash";
import './index.scss'
import { 
    Form,
    Input,
    Button,
  } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AUTHENTICATE_USER } from 'reducers/authReducer';
const { Panel } = Collapse;


function VerifyPinModal({ onClose, onVerification, data }) {
	const { authenticationSuccess, authenticationFailure, verificationFailed, isLoading } = useSelector((state) => state.auth);
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [confirmation] = Form.useForm();
    const dispatch = useDispatch();
    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };

    useEffect(() => {
        if(verificationFailed) {
            dispatch({type: 'RESET_REDIRECT_STATE'})
            handleCancel();
        }
        if(authenticationFailure) {
            dispatch({type: 'RESET_REDIRECT_STATE'})
        }
    }, [verificationFailed, authenticationFailure]);

    useEffect(() => {
        if(authenticationSuccess) {
            dispatch({type: 'RESET_REDIRECT_STATE'})
            onVerification(true);
        }
    }, [authenticationSuccess]);
    

    const handleOk = () => {
        setIsModalVisible(false);
        onClose && onClose();
    }

    const onSubmitHandler = (values) => {
        let payload = { password: values.password }
        const email = localStorage.getItem('email');
        const phone = localStorage.getItem('phone');
        if(email) {
            payload['email'] = email;
        } 
        if(phone) {
            payload['phone'] = phone;
        }
		dispatch(AUTHENTICATE_USER(payload));
        // confirmPassword(values.password);
        // onVerification(true);
        // onClose && onClose();
    };

    return (
        <Modal title="Verify Identity" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Form
                className="auth-form"
                name="confirmation-form"
                id="confirmation-form"
                layout='vertical'
                form={confirmation}
                onFinish={onSubmitHandler}
                requiredMark={false}
            >
                <p>A verification code was sent to <a href="" onClick={(e)=>e.preventDefault()}><small>{data || localStorage.getItem('email') || localStorage.getItem('phone')}</small></a>. Please enter the verification code below.</p>
                <Form.Item name="password" rules={[{ required: true, message: '**Required Field' }]} className="mt-3">
                    <Input type="password" placeholder="Enter Verification Code" />
                </Form.Item>

                <Form.Item className="action-grp mt-4">
                    <Row>
                        <Col span={14} className="pr-2">
                            <Button loading={isLoading} className="beam-btn green-btn cover w-full" htmlType="submit">Confirm</Button>
                        </Col>
                        <Col span={10} className="pl-2">
                            <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default VerifyPinModal;
