import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Input, Button, Select, Radio, DatePicker, TimePicker, Collapse } from "antd";
import { ReactSVG } from 'react-svg';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import _ from "lodash";

const { Option } = Select;

const { TextArea } = Input;

const { Panel } = Collapse;


function StorePanel(props) {
    const {screens, setScreens} = props;
    
    return (
        <div className="main-column ride-dispatch-panel mr-4">
          <div className="main-content">
            <div className="main-header">
                <div className="d-flex">
                    <ReactSVG src="/images/ride-dispatch-icon.svg" />
                    <h3 className="ml-3">Store</h3>
                </div>
                <div className="d-flex">
                    <ReactSVG src="/images/expand-icon.svg" className="close-icon mr-4" onClick={() => setScreens({...screens, active: screens.active? null: 'rideDispatch'})} />
                    <ReactSVG src="/images/close-icon.svg" className="close-icon" onClick={() => setScreens({...screens, ridePanel: false, message: true})} />
                </div>
            </div>
            <Row className="mt-3">
                <Col span={24}>
                    <Select className="w-full" placeholder="Features">
                        <Option>Option1</Option>
                    </Select>
                </Col>
            </Row>
            <div className="main-cards">
                <Row>
                    <Col span={12} className="pr-2">
                        <Select className="w-full" placeholder="Incident Type"></Select>
                    </Col>
                    <Col span={12} className="pl-2"><Input placeholder="Phone" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}>
                        <Select className="w-full" placeholder="Select" mode="multiple" options={[
                            {label: "Option1", value: 'Option1'},
                            {label: "Option2", value: 'Option2'},
                        ]}></Select>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Visit ID" /></Col>
                </Row>
                <hr />
                <Row className="mt-3">
                    <Col span={24}>
                        <div className="datetime-item">
                            <img src="/images/date.png" /><DatePicker placeholder="Date" suffixIcon={<DownOutlined />} />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}>
                        <div className="datetime-item">
                            <img src="/images/warning.png" /><TimePicker placeholder="Notify Time" suffixIcon={<DownOutlined />} />
                        </div>
                    </Col>
                </Row>
                <hr />
                <div className="sub-cards">
                    <Row>
                        <Col span={24}><Input placeholder="Project ID" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}><Input placeholder="Vendor ID" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}><Select className="w-full" placeholder="Select one"></Select></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}><Input placeholder="Phone" /></Col>
                    </Row>
                </div>
                <div className="sub-cards mt-3">
                    <Row>
                        <Col span={24}><Input placeholder="Store ID" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}><Input placeholder="Name" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}><Input placeholder="Contact" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}><Input placeholder="Phone" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}><Input placeholder="Address" /></Col>
                    </Row>
                </div>
                <hr />
                <Row className="mt-3">
                    <Col span={24}><Select className="w-full" placeholder="Select one"></Select></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}>
                        <TextArea autoSize={{ minRows: 4, maxRows: 6 }} placeholder="" />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}>
                        <TextArea autoSize={{ minRows: 4, maxRows: 6 }} placeholder="" />
                    </Col>
                </Row>
            </div>
            <Row className="action-grp mt-3">
                <Col span={8} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full">Submit</Button>
                </Col>
                <Col span={8} className="px-2">
                    <Button className="beam-btn yellow-btn cover w-full">Save</Button>
                </Col>
                <Col span={8} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full">Cancel</Button>
                </Col>
            </Row>
          </div>
        </div>
    )
}

export default StorePanel;
