import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, Dropdown, Menu } from 'antd';
import { SearchOutlined, EllipsisOutlined } from '@ant-design/icons';
import _ from "lodash";
import { useDispatch, useSelector } from 'react-redux';


function UserStatusModal(props) {
	const { selectedItem } = useSelector(state => state.search);
	const { users } = useSelector(state => state.auth);
    const { client, isModalVisible, setIsModalVisible } = props;
    const [selectedUsers, setSelectedUsers] = useState(selectedItem.members?.users || []);
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = (users) => {
        setIsModalVisible(false);
    }

    return (
        <Modal title="" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='480px'
            className="beam-modal"
            footer={[]}
            closable={false}
            destroyOnClose={true}
        >
            <Row className="d-flex mt-1 mb-4">
                <Col><h3>User status</h3></Col>
            </Row>
            <Row className="mb-4">
                <Col span={24}>
                    <Input placeholder="Search" suffix={
                      <SearchOutlined />
                    } />
                </Col>
            </Row>
            {users && users.map(user => !selectedUsers.find(item => item.rootUserID === user.id) && (
                <Row className="person-item" key={user.id}>
                    <Col span={4}><img src={`/images/users/user-1.png`} width="45" height="45" /></Col>
                    <Col span={14}>
                        <h5>{user.name}</h5>
                        <p>{user.email || "-"}</p>
                        <p>{user.phone || "-"}</p>
                    </Col>
                    <Col span={6} className="t-right">
                        <Button className="beam-btn mr-2">
                            Offline
                        </Button>
                    </Col>
                </Row>
            ))}
            <Row className="action-grp mt-4">
                <Col span={24}>
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Close</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default UserStatusModal;
