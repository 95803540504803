import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, DatePicker, TimePicker, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import _ from "lodash";


function SelectDateTimeModal(props) {
    const { onClose } = props;

    const [isModalVisible, setIsModalVisible] = useState(true);

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };

    const handleOk = () => {
        setIsModalVisible(false);
    }

    return (
        <Modal title="Select date-time range" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row>
                <Col span={12} className="pr-2">
                    <div className="datetime-item">
                        <img src="/images/date.png" /><DatePicker placeholder="Date from" suffixIcon={<DownOutlined />} />
                    </div>
                </Col>
                <Col span={12} className="pl-2">
                    <div className="datetime-item">
                        <img src="/images/time.png" /><TimePicker placeholder="Time from" suffixIcon={<DownOutlined />} />
                    </div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={12} className="pr-2">
                    <div className="datetime-item">
                        <img src="/images/date.png" /><DatePicker placeholder="Date to" suffixIcon={<DownOutlined />} />
                    </div>
                </Col>
                <Col span={12} className="pl-2">
                    <div className="datetime-item">
                        <img src="/images/time.png" /><TimePicker placeholder="Time to" suffixIcon={<DownOutlined />} />
                    </div>
                </Col>
            </Row>
            <Row className="action-grp mt-5">
                <Col span={14} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full" onClick={handleOk}>Save</Button>
                </Col>
                <Col span={10} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default SelectDateTimeModal;
