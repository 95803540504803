import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";

// fake data generator
const getItems = () =>
  [
    {key: "Relevance", value: "Relevance"},
    {key: "Read/Unread", value: "Read/Unread"},
    {key: "Last updated", value: "Last updated"},
    {key: "Page created", value: "Page created"},
    {key: "Title", value: "Title"}
  ]

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 0,
  margin: `0 0 ${grid}px 0`,
  display: "flex",
  alignItems: "center",

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "inherit",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: "#EFF2F7"
});


function SelectDateTimeModal(props) {

    const [items, setItems] = useState(getItems(5))
    const onDragEnd = (result) => {
        if (!result.destination) {
          return;
        }

        const newItems = reorder(
          items,
          result.source.index,
          result.destination.index
        );

        setItems(newItems)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {items.map((item, index) => (
                    <Draggable key={item.key} draggableId={item.key} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                            <img src="/images/move.png" className="mr-3"/>
                            <h5>{item.value}</h5>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
    )
}

export default SelectDateTimeModal;
