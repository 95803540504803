import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, Dropdown, Menu, Tabs } from 'antd';
import { SearchOutlined, EllipsisOutlined } from '@ant-design/icons';
import _ from "lodash";
import { useDispatch, useSelector } from 'react-redux';

const { TabPane } = Tabs;

function CallLogsModal(props) {
    const { client, isModalVisible, setIsModalVisible } = props;
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = (users) => {
        setIsModalVisible(false);
    }

    return (
        <Modal title="" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='600px'
            className="beam-modal call-logs-modal"
            footer={[]}
            closable={false}
            destroyOnClose={true}
        >
            <Row className="d-flex mt-1 mb-4">
                <Col><h3>Call logs</h3></Col>
            </Row>
            <Row className="mt-4">
                <Col span={24}>
                    <Tabs type="card">
                        <TabPane tab="Queued" key="queued">
                            <Row className="custom-table">
                                <Col span={24}>
                                    { _.times(2, (idx) => 
                                        <Row className="table-row d-flex" idx={idx}>
                                            <Col span={9}>26/01/2021 - 20:30:42</Col>
                                            <Col span={5}>Incoming</Col>
                                            <Col span={6}>+19584875987</Col>
                                            <Col span={4}>Queued</Col>
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Completed" key="completed">
                            <Row className="custom-table">
                                <Col span={24}>
                                    { _.times(3, (idx) => 
                                        <Row className="table-row d-flex" idx={idx}>
                                            <Col span={9}>26/01/2021 - 20:30:42</Col>
                                            <Col span={5}>Incoming</Col>
                                            <Col span={6}>+19584875987</Col>
                                            <Col span={4}>Completed</Col>
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Cancelled" key="cancelled">
                            <Row className="custom-table">
                                <Col span={24}>
                                    { _.times(8, (idx) => 
                                        <Row className="table-row d-flex" idx={idx}>
                                            <Col span={9}>26/01/2021 - 20:30:42</Col>
                                            <Col span={5}>Incoming</Col>
                                            <Col span={6}>+19584875987</Col>
                                            <Col span={4}>Cancelled</Col>
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Missed" key="missed">
                            <Row className="custom-table">
                                <Col span={24}>
                                    { _.times(1, (idx) => 
                                        <Row className="table-row d-flex" idx={idx}>
                                            <Col span={9}>26/01/2021 - 20:30:42</Col>
                                            <Col span={5}>Incoming</Col>
                                            <Col span={6}>+19584875987</Col>
                                            <Col span={4}>Missed</Col>
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
            <Row className="action-grp mt-4">
                <Col span={24}>
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Close</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default CallLogsModal;
