import React, { PureComponent } from "react";
import { Panel, Columns } from "react-bulma-components";
import { isEmpty } from "lodash";

import videoRoomPropType from "../../propTypes/videoRoom";
import Participant from "../Participant/Participant";
import LocalParticipant from "../Participant/LocalParticipant";
import EventSubscriber from "../EventSubscriber/EventSubscriber";

const EVENTS = [
  "dominantSpeakerChanged",
  "participantConnected",
  "participantDisconnected",
  "reconnected",
  "reconnecting",
  "trackDimensionsChanged",
  "trackDisabled",
  "trackEnabled",
  "trackPublished",
  "trackStarted",
  "trackSubscribed",
  "trackUnpublished",
  "trackUnsubscribed"
];

class VideoRoom extends PureComponent {
  static propTypes = {
    videoRoom: videoRoomPropType.isRequired
  };

  update = () => this.forceUpdate();

  render() {
    const { videoRoom } = this.props;
    const remoteParticipants = Array.from(videoRoom.participants.values());
    console.log(remoteParticipants);
    return (
      <EventSubscriber
        events={EVENTS}
        eventEmitterObject={videoRoom}
        onUpdate={this.update}
      >
        <Columns>
          <Columns.Column>
            <Panel>
              {/* <Panel.Header>Local Participant</Panel.Header> */}
              <Panel.Block paddingless>
                <LocalParticipant participant={videoRoom.localParticipant} />
              </Panel.Block>
            </Panel>
          </Columns.Column>

          <Columns.Column>
            <Panel>
              {/* <Panel.Header>Remote Participants</Panel.Header> */}
              <Panel.Block paddingless={!isEmpty(remoteParticipants)}>
                {!isEmpty(remoteParticipants)
                  ? remoteParticipants.map(participant => (
                      <Participant
                        className="mt-5 remote-participant"
                        key={participant.sid}
                        isRemote={true}
                        participant={participant}
                      />
                    ))
                  : 
                  <div className="page-alert">
                    <h4>No participants</h4>
                    <p>Waiting for someone to connect...</p>
                  </div>
                }
              </Panel.Block>
            </Panel>
          </Columns.Column>
        </Columns>
      </EventSubscriber>
    );
  }
}

export default VideoRoom;
