import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, DatePicker } from 'antd';
import _ from "lodash";
import { ReactSVG } from 'react-svg';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';

const breakTime = [
    {time: 2, name: 'Event', icon: 'orange'},
    {time: 12, name: 'Place', icon: 'purple'},
    {time: 20, name: 'IoT', icon: 'yellow'},
    {time: 33, name: 'Capture', icon: 'gray'},
    {time: 41, name: 'Incident', icon: 'orange'},
]

const tableRows = [
    {sensor: 'HEART_RATE', value: '94', unit: 'bpm', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'OXYGEN', value: '98', unit: '%', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'BLOOD_PRESSURE', value: '130/90', unit: 'mmHg', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'TEMPERATURE', value: '36.3', unit: 'C', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'STEPS', value: '630', unit: 'Steps', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'DISTANCE', value: '422', unit: 'm', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'CAL', value: '25', unit: 'calories', datetime: '26/01/2021 - 20:30:42'}
]

const { TextArea } = Input;

const LocationItem = ({type}) => 
    <Row className="location-item d-flex">
        <Col>
            <Button className="beam-btn inbound icon-btn"><ReactSVG src="/images/geolocation-icon.svg" className={`${type}-icon`} /></Button>
        </Col>
        <Col span={20}>
            <h5>Source - 06 / 11 / 2021 - 05:36:26</h5>
            <p>712 Tom Kite Drive, Round Rock, Texas, USA</p>
        </Col>
    </Row>

const PersonItem = ({type, avatar}) =>
    <Row className="person-item d-flex">
        <Col><img src={avatar} /></Col>
        <Col span={9}>
            <h5>Jerome Bell</h5>
            <p>ID: 154687654</p>
        </Col>
        <Col><label>Injured officer</label></Col>
        <Col>
            <Button className="beam-btn icon-btn"><ReactSVG src="/images/flag-icon.svg" className={`${type}-icon`} /></Button>
        </Col>
    </Row>
    

function AnalyticsSummaryModal(props) {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [gender, setGender] = useState('Male');
    const { onClose } = props;

    const handleCancel = () => {
        onClose && onClose();
        setIsModalVisible(false);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    }

    const getTime = (time) => {
        if (time < 10)
            time = '0' + time;
        return time;
    }

    const checkin = (time) => {
        let points = breakTime.filter(bt => bt.time === time);
        if (points.length > 0)
            return points[0]
        return null
    }

    return (
        <Modal title="" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='926px'
            className="beam-modal analytics-summary-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row className="d-flex">
                <Col>
                    <h3 className="mb-1">People Summary</h3>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col><h4 className="mt-3">Bio</h4></Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Row className="text-center mb-5">
                        <Col span={24}>
                            <img src={'/images/scene.png'} className="profileImage cursor-pointer" />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Input placeholder="First Name" readOnly />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}>
                            <Input placeholder="Last Name" readOnly />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}>
                            <Input placeholder="12/27/2021" readOnly prefix={<img src="/images/date.png" />}/>
                        </Col>
                    </Row>
                    <Row className="d-flex mb-4 mt-3">
                        <Col span={12} className="pr-2"><Button className={`beam-btn w-full${gender==='Female' ? " green-btn cover": ""}`}>Female</Button></Col>
                        <Col span={12} className="pl-2"><Button className={`beam-btn w-full${gender==='Male' ? " green-btn cover": ""}`}>Male</Button></Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Input prefix={<img src="/images/location.png" />} placeholder="Enter Street Address" readOnly/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col><h4 className="mt-3">Table name</h4></Col>
            </Row>
            <Row className="custom-table mt-4">
                <Col span={24}>
                    <Row className="header">
                        <Col span={6}>Sensor</Col>
                        <Col span={6}>Value</Col>
                        <Col span={5}>Unit</Col>
                        <Col span={7}>Date & Time</Col>
                    </Row>
                    {
                        tableRows.map((item, idx) => 
                            <Row className="table-row d-flex" idx={idx} key={idx}>
                                <Col span={6}>{item.sensor}</Col>
                                <Col span={6}>{item.value}</Col>
                                <Col span={5}>{item.unit}</Col>
                                <Col span={7}>{item.datetime}</Col>
                            </Row>
                        )
                    }
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={12} className="pr-3">
                    <Row>
                        <Col><h4 className="mt-3">Assets On Scene</h4></Col>
                    </Row>
                    <Row className="assets-block d-flex mt-3">
                        <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                        <Col><ReactSVG src="/images/user-icon.svg" /></Col>
                        <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                        <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                        <Col><ReactSVG src="/images/user-icon.svg" /></Col>
                        <Col span={6}><ReactSVG src="/images/heart-icon.svg" /></Col>
                    </Row>
                </Col>
                <Col span={12} className="pl-3">
                    <Row>
                        <Col><h4 className="mt-3">Assets Recommended</h4></Col>
                    </Row>
                    <Row className="assets-block d-flex mt-3">
                        <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                        <Col><ReactSVG src="/images/user-icon.svg" /></Col>
                        <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                        <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                        <Col><ReactSVG src="/images/user-icon.svg" /></Col>
                        <Col span={6}><ReactSVG src="/images/heart-icon.svg" /></Col>
                    </Row>
                </Col>
            </Row>
            <Row className="timeline mt-4">
                <Col span={24}>
                    <div className="main-bar">
                        {_.times(48, (i) => {
                            let timePoint = null;
                            let breakPoint = null;
                            let point = checkin(i-1);
                            if ((i-1) % 5 === 0)
                                timePoint = <h6>9:{getTime(i-1)} am</h6>
                            if (point)
                                breakPoint = <div className="break-point">
                                    <Button className="beam-btn icon-btn"><img src={`/images/${point.name}-icon.png`} /></Button>
                                    <h5 className="mt-2">{point.name}</h5>
                                    <p className="mt-1">9:{getTime(i-1)} am</p>
                                </div>
                            if (timePoint != null || breakPoint != null)
                                return (
                                    <div className={`time-point mark-time ${breakPoint? point.icon +'-icon break': ''}`} key={i}>
                                        {timePoint}
                                        {breakPoint}
                                    </div>
                                )
                            else
                                return  <div className="time-point" key={i}></div>
                        })}
                    </div>
                </Col>
            </Row>
            <Row className="custom-table mt-4">
                <Col span={24}>
                    <Row className="header">
                        <Col span={4}>Event</Col>
                        <Col span={4}>User</Col>
                        <Col span={3}>Time</Col>
                        <Col span={3}>Status</Col>
                        <Col span={10}>Location</Col>
                    </Row>
                    <Row className="table-row d-flex">
                        <Col span={4} className="event-column">
                            <div><img src="/images/Event-icon.png" /></div>
                            <span>Event</span>
                        </Col>
                        <Col span={4}>Dispatcher XYZ</Col>
                        <Col span={3}>9:02 am</Col>
                        <Col span={3}><label className="orange-icon">Stopped</label></Col>
                        <Col span={10}>746 W University Dr, Mesa, Maricopa County, AZ85201</Col>
                    </Row>
                    <Row className="table-row d-flex">
                        <Col span={4} className="event-column">
                            <div><img src="/images/Place-icon.png" /></div>
                            <span>Place</span>
                        </Col>
                        <Col span={4}></Col>
                        <Col span={3}>9:02 am</Col>
                        <Col span={3}><label className="green-icon">Open</label></Col>
                        <Col span={10}>746 W University Dr, Mesa, Maricopa County, AZ85201</Col>
                    </Row>
                    <Row className="table-row d-flex">
                        <Col span={4} className="event-column">
                            <div><img src="/images/IoT-icon.png" /></div>
                            <span>IoT</span>
                        </Col>
                        <Col span={4}>Call taker ABC</Col>
                        <Col span={3}>9:02 am</Col>
                        <Col span={3}><label className="green-icon">Received</label></Col>
                        <Col span={10}>746 W University Dr, Mesa, Maricopa County, AZ85201</Col>
                    </Row>
                    <Row className="table-row d-flex">
                        <Col span={4} className="event-column">
                            <div><img src="/images/Capture-icon.png" /></div>
                            <span>Capture</span>
                        </Col>
                        <Col span={4}>Capture</Col>
                        <Col span={3}>9:02 am</Col>
                        <Col span={3}><label className="green-icon">Open</label></Col>
                        <Col span={10}>746 W University Dr, Mesa, Maricopa County, AZ85201</Col>
                    </Row>
                    <Row className="table-row d-flex">
                        <Col span={4} className="event-column">
                            <div><img src="/images/Incident-icon.png" /></div>
                            <span>Incident</span>
                        </Col>
                        <Col span={4}>Responder ID</Col>
                        <Col span={3}>9:02 am</Col>
                        <Col span={3}><label className="green-icon">Open</label></Col>
                        <Col span={10}>746 W University Dr, Mesa, Maricopa County, AZ85201</Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    )
}

export default AnalyticsSummaryModal;
