import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, DatePicker, TimePicker, Checkbox, Radio, Switch, Collapse  } from 'antd';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import _ from "lodash";
import Icon from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Reorder from "./Reorder";

const { Panel } = Collapse;

function FilterByModal(props) {

    const {isModalVisible, setIsModalVisible} = props;

    const [filters, setFilters] = useState({
        readStatus: ['read', 'unread'],
        pageType: 'friends',
        userStatus: 1,
        pageStatus: 2,
        liveIoT: ['gps'],
        owner: 90,
        createdAt: '',
        deleted: false,

    })

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    }

    const {readStatus, pageType, pageStatus, userStatus, liveIoT, owner, createdAt, deleted} = filters;

    return (
        <Modal title="Filter By" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='380px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row className="d-flex pl-2">
                <Col span="9"><h5>Read Status</h5></Col>
                <Col>
                    <Checkbox.Group onChange={console.log} options={['Read', 'Unread']} defaultValue={['Read']} />
                </Col>
            </Row>
            <hr />
            <Row className="d-flex">
                <Col span={8}><Button className="beam-btn green-btn cover">Friends</Button></Col>
                <Col span={8}><Button className="beam-btn">Groups</Button></Col>
                <Col span={8}><Button className="beam-btn">Incidents</Button></Col>
            </Row>
            <hr />
            <Row className="pl-2">
                <Col span="5"><h5>User <br/>status</h5></Col>
                <Col span="19">
                    <Radio.Group  onChange={console.log} defaultValue={1}>
                        <Radio value={1} className="orange-radio"></Radio>
                        <Radio value={2} className="blue-radio"></Radio>
                        <Radio value={3} className="green-radio"></Radio>
                        <Radio value={4} className="gray-radio"></Radio>
                    </Radio.Group>
                </Col>
            </Row>
            <hr />
            <Row className="pl-2">
                <Col span="5"><h5>Page <br/>status</h5></Col>
                <Col span="19">
                    <Radio.Group  onChange={console.log} defaultValue={1}>
                        <Radio value={1} className="orange-radio"></Radio>
                        <Radio value={2} className="blue-radio"></Radio>
                        <Radio value={3} className="green-radio"></Radio>
                        <Radio value={4} className="yellow-radio"></Radio>
                        <Radio value={5} className="pink-radio"></Radio>
                    </Radio.Group>
                </Col>
            </Row>
            <hr />
            <Row className="d-flex pl-2">
                <Col span="9"><h5>Live IoT</h5></Col>
                <Col span="15">
                    <Checkbox.Group  onChange={console.log} options={['GPS', 'Video']} defaultValue={['GPS']} />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={24}>
                    <Input placeholder="Owner" />
                </Col>
            </Row>
             <Row className="mt-3">
                <Col span={24}>
                    <div className="datetime-item">
                        <img src="/images/date.png" /><DatePicker placeholder="Filter by date" suffixIcon={<DownOutlined />} />
                    </div>
                </Col>
            </Row>
            <hr />
            <Row className="d-flex px-2">
                <Col span="7"><h5>Deleted</h5></Col>
                <Col span="17">
                    <Switch defaultChecked />
                </Col>
            </Row>
            <Row className="mt-3">
                <Collapse defaultActiveKey={['1']} bordered={false}>
                    <Panel header="Apps:" key="1">
                        <Row className="d-flex">
                            <Col><Button className="beam-btn round-btn"><img src="/images/pause.png" style={{ maxWidth: 40, maxHeight: 40 }} /></Button></Col>
                            <Col><Button className="beam-btn round-btn"><img src="/images/user.png" /></Button></Col>
                            <Col><Button className="beam-btn round-btn"><img src="/images/net.png" /></Button></Col>
                            <Col><Button className="beam-btn round-btn"><img src="/images/car.png" /></Button></Col>
                            <Col><Button className="beam-btn round-btn"><img src="/images/iot_blue.png" /></Button></Col>
                            <Col><Button className="beam-btn round-btn"><img src="/images/Incident-icon.png" /></Button></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Row>
            <Row className="mt-3">
                <Col><h3>Sort By</h3></Col>
            </Row>
            <hr />
            <Reorder />
            <Row className="action-grp mt-3">
                <Col span={12} className="pr-2">
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Reset</Button>
                </Col>
                <Col span={12} className="pl-2">
                    <Button className="beam-btn green-btn cover w-full" onClick={handleOk}>Save</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default FilterByModal;
