import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, DatePicker, TimePicker, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import _ from "lodash";
import { useDispatch } from 'react-redux';
import moment from 'moment';

const { Option } = Select;

function AddEventModal(props) {
    const { onClose } = props;

    const {eventObj} = props;
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [event, setEvent] = useState({title: '', repeat: [], date: new Date(), time: new Date(), notifyTime: new Date(), profile: '', location: ''});

    const updateEvent = (key, value) =>{
        setEvent(prevState => ({
            ...prevState,
            [key]: value
        }));
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };

    const dispatch = useDispatch();
    const handleOk = () => {
        dispatch({type: 'SEND_MESSAGE', payload: {message: event.title, type: 'Event', data: event}})
        setIsModalVisible(false);
    }

    useEffect(() => {
        const {eventObj} = props;
        if(eventObj)
            setEvent(eventObj)
    }, []);

    return (
        <Modal title={eventObj? `Event information`: 'Add Event'}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row>
                <Col span={24}>
                    <Input value={event.title} onChange={e => updateEvent('title', e.target.value)} placeholder="Event Title" />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={24}>
                    <div className="datetime-item prefix-select-wrapper">
                        <img src="/images/refresh.png" />
                        <Select value={event.repeat} className="w-full" placeholder="Repeat" onChange={(values)=>{updateEvent('repeat', values)}} mode="multiple">
                            <Option value="Option1">Every day</Option>
                            <Option value="Option2">Every week</Option>
                            <Option value="Option3">Every month</Option>
                        </Select>
                    </div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={12} className="pr-2">
                    <div className="datetime-item">
                        <img src="/images/date.png" /><DatePicker value={moment(event.date)} onChange={(date)=>{updateEvent('date', date.format('MM/DD/YYYY HH:mm:ss'))}} placeholder="Date" suffixIcon={<DownOutlined />} />
                    </div>
                </Col>
                <Col span={12} className="pl-2">
                    <div className="datetime-item">
                        <img src="/images/time.png" /><TimePicker value={moment(event.time)} onChange={(time)=>{updateEvent('time', time.format('MM/DD/YYYY HH:mm:ss'))}} placeholder="Time" suffixIcon={<DownOutlined />} />
                    </div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={12} className="pr-2">
                    <div className="datetime-item">
                        <img src="/images/warning.png" /><TimePicker value={moment(event.notifyTime)} onChange={(time)=>{updateEvent('notifyTime', time.format('MM/DD/YYYY HH:mm:ss'))}} placeholder="Notify Time" suffixIcon={<DownOutlined />} />
                    </div>
                </Col>
                <Col span={12} className="pl-2">
                    <Select value={event.notifyVia} className="w-full" placeholder="Notify via" onChange={(values)=>{updateEvent('notifyVia', values)}}
                        options={[
                            {label: "Push", value: "Push"},
                            {label: "SMS", value: "SMS"},
                            {label: "Email", value: "Email"},
                        ]}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={24}>
                    <Select className="w-full" placeholder="Profile" options={[]} />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={24}>
                    <div className="datetime-item prefix-select-wrapper">
                        <img src="/images/location.png" />
                        <Select className="w-full" placeholder="Location">
                        </Select>
                    </div>
                </Col>
            </Row>
            {!eventObj ?
                <Row className="action-grp mt-5">
                    <Col span={14} className="pr-2">
                        <Button className="beam-btn green-btn cover w-full"
                                onClick={handleOk}>{'Save'}</Button>
                    </Col>
                    <Col span={10} className="pl-2">
                        <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                    </Col>
                </Row>:
                <Row className="action-grp mt-5">
                    <Col span={14} className="pr-2">
                        <Button className="beam-btn green-btn cover w-full">Add to calendar</Button>
                    </Col>
                    <Col span={10} className="pl-2">
                        <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Remove</Button>
                    </Col>
                </Row>
            }
        </Modal>
    )
}

export default AddEventModal;
