import {createAction} from "@reduxjs/toolkit";

export const GET_RX = createAction('GET_RX');
export const GET_VIDEO_SNAPSHOT = createAction('GET_VIDEO_SNAPSHOT');
export const GET_HISTORICAL_GPS = createAction('GET_HISTORICAL_GPS');
export const ADD_IMEI_SUBSCRIPTION = createAction('ADD_IMEI_SUBSCRIPTION');
export const CLEAR_ALL_IMEI_SUBSCRIPTION = createAction('CLEAR_ALL_IMEI_SUBSCRIPTION');
export const REMOVE_IMEI_SUBSCRIPTION = createAction('REMOVE_IMEI_SUBSCRIPTION');
export const ADD_MAP_PEOPLES = createAction('ADD_MAP_PEOPLES');
export const GET_MAP_PLACES_BOUNDS = createAction('GET_MAP_PLACES_BOUNDS');
export const GET_MAP_PLACES_FILTER = createAction('GET_MAP_PLACES_FILTER');
export const SET_SELECTED_PLACES = createAction('SET_SELECTED_PLACES');
export const SET_PLACES = createAction('SET_PLACES');
export const GET_LOCATION_PROFILES = createAction('GET_LOCATION_PROFILES');


const initialState = {
    gps: {},
    subscriptions: [],
    places: {items: [], page: 0, pagination: true, searching: false},
    assets: {items: [], page: 0, pagination: true, searching: false},
    medias: {items: [], page: 0, pagination: true, searching: false},
    pages: {items: [], page: 0, pagination: true, searching: false},
    peoples: {items: [], page: 0, pagination: true, searching: false},
    selected_places: {items: [], fitBounds: false}
};

const setHistoricalGPS = (state, action) => {
    const {response, live} = action.payload;
    return {
        ...state,
        gps: {points: response?.meta_data?.gps ?? [], live},
    };
};

const setRx = (state, action) => {
    return {
        ...state,
        rx: action.payload,
    };
};

const setSnapshotUrl = (state, action) => {
    return {
        ...state,
        snapshotUrl: action.payload,
    };
};

const clearRx = (state, action) => {
    return {
        ...state,
        rx: [],
    };
};

const setMapPlacesBounds = (state, action) => {
    return {
        ...state,
        map_places_bounds: action.payload,
        places: {...state.places, items: action.payload, pagination: false},
        selected_places: {items: action.payload, fitBounds: false}
    };
};

const setMapPlacesFilter = (state, action) => {
    return {
        ...state,
        map_places_filter: action.payload,
    };
};

const setMapAssetsFilter = (state, action) => {
    return {
        ...state,
        map_assets_filter: action.payload,
    };
};

const setMapPagesFilter = (state, action) => {
    return {
        ...state,
        map_pages_filter: action.payload,
    };
};

const setMapMediasFilter = (state, action) => {
    return {
        ...state,
        map_medias_filter: action.payload,
    };
};

const setMapPeoplesFilter = (state, action) => {
    return {
        ...state,
        map_peoples_filter: action.payload,
    };
};


const addImeiSubscription = (state, action) => {
    const {imei} = action.payload;
    const prevSubscriptions =  [...state.subscriptions];
    let index = prevSubscriptions.findIndex((x)=>{return x === imei});
    if(index === -1)
        prevSubscriptions.push(imei);
    return {
        ...state,
        subscriptions: prevSubscriptions
    };
};

const removeImeiSubscription = (state, action) => {
    const {imei} = action.payload;
    const prevSubscriptions =  [...state.subscriptions];
    let index = prevSubscriptions.findIndex((x)=>{return x === imei});
    if(index !== -1)
        prevSubscriptions.splice(index, 1);
    return {
        ...state,
        subscriptions: prevSubscriptions
    };
};

const clearAllSubscription = (state, action) => {
    return {
        ...state,
        subscriptions: [],
        gps: {}
    };
};

const clearAllFilters = (state, action) => {
    return {
        ...state,
        places: {items: [], page: 0, pagination: true, searching: false},
        assets: {items: [], page: 0, pagination: true, searching: false},
        medias: {items: [], page: 0, pagination: true, searching: false},
        pages: {items: [], page: 0, pagination: true, searching: false},
        peoples: {items: [], page: 0, pagination: true, searching: false},
        selected_places: {items: [], fitBounds: false}
    };
};

const setSelectedPlaces = (state, action) => {
    return {
        ...state,
        selected_places: action.payload,
    };
};

const addSelectedPlaces = (state, action) => {
    let selected_places =  {items: [...state.selected_places.items], fitBounds: true};
    const message = action.payload;
    const {flag} = message.data;


    if(flag === 'replace'){
        let index = selected_places.items.findIndex((x)=>{return x.id === message.data.originalPageMessageId});
        if(index !== -1) {
            selected_places.items[index] = {
                ...message,
                id: message.id,
                name: message.message,
                objectType: 'place',
            }
            return {
                ...state,
                selected_places
            }
        }
    }
    else if(flag === 'remove'){
        let index = selected_places.items.findIndex((x)=>{return x.id === message.data.originalPageMessageId});
        if(index !== -1) {
            selected_places.items.splice(index, 1);
            return {
                ...state,
                selected_places
            }
        }
    }

    if(selected_places.items.filter((e)=>{return e.id === action.payload.id}).length !== 0) {
        return {
            ...state
        };
    }
    else {
        selected_places.items.push(action.payload);
        return {
            ...state,
            selected_places,
        };
    }

};

const setPlaces = (state, action) => {
    return {
        ...state,
        places: {
            ...state.places,
            ...action.payload
        },
    };
};

const setAssets = (state, action) => {
    return {
        ...state,
        assets: {
            ...state.assets,
            ...action.payload
        },
    };
};

const setPeoples = (state, action) => {
    return {
        ...state,
        peoples: {
            ...state.peoples,
            ...action.payload
        },
    };
};

const setPages = (state, action) => {
    return {
        ...state,
        pages: {
            ...state.pages,
            ...action.payload
        },
    };
};

const setMedias = (state, action) => {
    return {
        ...state,
        medias: {
            ...state.medias,
            ...action.payload
        },
    };
};


const setLocationProfiles = (state, action) => {
    return {
        ...state,
        location_profiles: action.payload?.[0]?.[0],
    };
};

const addPageGroup = (state, action) => {
    return {
        ...state,
        page_group: action.payload
    };
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_IMEI_SUBSCRIPTION': return addImeiSubscription(state, action);
        case 'REMOVE_IMEI_SUBSCRIPTION': return removeImeiSubscription(state, action);
        case 'CLEAR_ALL_IMEI_SUBSCRIPTION': return clearAllSubscription(state, action);
        case 'ADD_GPS': return setHistoricalGPS(state, action);
        case 'ADD_RX': return setRx(state, action);
        case 'ADD_VIDEO_SNAPSHOT': return setSnapshotUrl(state, action);
        case 'ADD_MAP_PLACES_BOUNDS': return setMapPlacesBounds(state, action);
        case 'ADD_MAP_PLACE_FILTER': return setMapPlacesFilter(state, action);
        case 'ADD_MAP_ASSET_FILTER': return setMapAssetsFilter(state, action);
        case 'ADD_MAP_USER_FILTER': return setMapPeoplesFilter(state, action);
        case 'ADD_MAP_MEDIA_FILTER': return setMapMediasFilter(state, action);
        case 'ADD_MAP_PAGE_FILTER': return setMapPagesFilter(state, action);
        case 'GET_RX': return clearRx(state, action);
        case 'SET_SELECTED_PLACES': return setSelectedPlaces(state, action);
        case 'ADD_SELECTED_PLACES': return addSelectedPlaces(state, action);
        case 'SET_PLACES': return setPlaces(state, action);
        case 'SET_ASSETS': return setAssets(state, action);
        case 'SET_PEOPLES': return setPeoples(state, action);
        case 'SET_MEDIAS': return setMedias(state, action);
        case 'SET_PAGES': return setPages(state, action);
        case 'SET_LOCATION_PROFILES': return setLocationProfiles(state, action);
        case 'CLEAR_ALL_FILTERS': return clearAllFilters(state, action);
        case 'ADD_PAGE_GROUP': return addPageGroup(state, action);
        default: return state
    }
};

export default dashboardReducer;
