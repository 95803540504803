import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, DatePicker, Switch } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import './index.scss'
import { GET_USER_INFO, UPDATE_USER_INFO } from 'reducers/authReducer';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';


function EditPermissionsModal(props) {
    const { onClose } = props;
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(true);
   
    useEffect(() => {
        dispatch(GET_USER_INFO());
    }, [])

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };

    const handleOk = () => {
        setIsModalVisible(false);
        onClose && onClose();
    }

    return (
        <Modal title="Edit Permissions" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row className="mt-3 d-flex">
                <Col span={8}><h4>Mic</h4></Col>
                <Col span={16}>
                    <Switch defaultChecked />
                </Col>
            </Row>
            <Row className="mt-3 d-flex">
                <Col span={8}><h4>Camera</h4></Col>
                <Col span={16}>
                    <Switch defaultChecked />
                </Col>
            </Row>
            <Row className="mt-3 d-flex">
                <Col span={8}><h4>Contact list</h4></Col>
                <Col span={16}>
                    <Switch defaultChecked />
                </Col>
            </Row>
            <Row className="mt-3 d-flex">
                <Col span={8}><h4>Calendar</h4></Col>
                <Col span={16}>
                    <Switch defaultChecked />
                </Col>
            </Row>
            <Row className="mt-3 d-flex">
                <Col span={8}><h4>Location</h4></Col>
                <Col span={16}>
                    <Switch defaultChecked />
                </Col>
            </Row>
            <Row className="action-grp mt-4">
                <Col span={14} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full" onClick={handleOk}>Save</Button>
                </Col>
                <Col span={10} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default EditPermissionsModal;
