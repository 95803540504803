import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import { AddRSVPModal, IotDeviceModal, PeopleModal, SelectDateTimeModal, UploadFileModal, GPSModal, GPSReportModal, RXSensorModal, PageSummaryModal, VideoRecorderModal } from '.';
import AddEventModal from './AddEventModal';
import { useDispatch } from 'react-redux';
import Geocode from "react-geocode";
import AddIoTModal from './AddIoTModal';

const google = window.google = window.google ? window.google : {}

Geocode.setApiKey("AIzaSyDNIyoSDV1wnJVW8wdHkiNG-eZ2bMcq0iY");
Geocode.setLanguage("en");
Geocode.setRegion("us");
const ICONS = {
	FILTER: [
		{key: 'Image', value: 'Image', selected: false},
		{key: 'Media', value: 'Media', selected: false},
		{key: 'Audio', value: 'Audio', selected: false},
		{key: 'File', value: 'File', selected: false},
		{key: 'Pay', value: 'Pay', selected: false},
		{key: 'Event', value: 'Event', selected: false},
		{key: 'Place', value: 'Place', selected: false},
		{key: 'IoT', value: 'IoT', selected: false},
		{key: 'Capture', value: 'Capture', selected: false},
		{key: 'Incident', value: 'Incident', selected: false},
		{key: 'RSVP', value: 'RSVP', selected: false},
		{key: 'People', value: 'People', selected: false},
		{key: 'Text', value: 'Text', selected: false},
		{key: 'Alert', value: 'Alert', selected: false},
		{key: 'IoT File', value: 'IoT File', selected: false},
		{key: 'GPS live', value: 'GPS live', selected: false},
	],
	MESSAGE: [
		{key: 'Media', value: 'Media'},
		{key: 'Place', value: 'Place'},
		// {key: 'File', value: 'File'},
		// {key: 'IoT', value: 'IoT'},
		{key: 'RSVP', value: 'RSVP'},
		{key: 'Event', value: 'Event'},
		// {key: 'B2T', value: ''},
		{key: 'People', value: 'People'},
		// {key: 'Capture', value: ''},
		// {key: 'Page Summary', value: 'Page Summary'},
		{key: 'GPS Live', value: 'GPS Live'},
		{key: 'GPS Report', value: 'GPS Report'},
		{key: 'RX Sensor', value: 'RX Sensor'},
		{key: 'Video Recorder', value: 'Video Recorder'},
	]
}


function PopupModal(props) {
	const [options, setOptions] = useState(props.type==='MESSAGE'? ICONS['MESSAGE'] : ICONS['FILTER']);
	const [isModalVisible, setIsModalVisible] = useState(true);
	const [filePopupDisplayed, setFilePopupDisplayed] = useState(false);
	const [iotPopupDisplayed, setIotPopupDisplayed] = useState(false);
	const [peoplePopupDisplayed, setPeoplePopupDisplayed] = useState(false);
	const [rsvpPopupDisplayed, setRSVPPopupDisplayed] = useState(false);
	const [dateTimePopupDisplayed, setDateTimePopupDisplayed] = useState(false);
	const [addEventPopupDisplayed, setAddEventPopupDisplayed] = useState(false);
	const [addGPSDisplayed, setAddGPSDisplayed] = useState(false);
	const [addGPSReportDisplayed, setAddGPSReportDisplayed] = useState(false);
	const [addRxSensorDisplayed, setAddRxSensorDisplayed] = useState(false);
	const [addPageSummaryDisplayed, setAddPageSummaryDisplayed] = useState(false);
	const [addVideoRecorderDisplayed, setAddVideoRecorderDisplayed] = useState(false);
	let {type, onClose, client} = props;

	const dispatch = useDispatch();

	const handleCancel = () => {
		onClose && onClose();
		setIsModalVisible(false);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	}

	const selectType = (selectedType) => {
		console.log('event type here', selectedType)
		if(type === 'MESSAGE') {
			if (selectedType === 'File') {
				setFilePopupDisplayed(true);
				return;
			}
			if (selectedType === 'IoT') {
				// dispatch({type: 'SEND_MESSAGE', payload: {message: 'IoT Item added', type: 'IoT'}})
				// dispatch({type: 'SEND_MESSAGE', payload: {message: 'officer.three (GPS report)', type: 'gps-report', data: {"title":"officer.three","deviceKey":"dev.hassan.11","reportingStart":'2021-12-16T15:23:04.751Z',"reportingEnd":'2022-01-05T11:08:43.629Z'}}})
				// dispatch({type: 'SEND_MESSAGE', payload: {message: 'officer.smith', type: 'gps', data: {"title":"officer.smith","deviceKey":"dev.hassan.11","reportingStart":'2021-12-16T15:23:04.751Z'}}})
				// setIotPopupDisplayed(true);
				return;
			}
			if (selectedType === 'RSVP') {
				setRSVPPopupDisplayed(true);
				return;
			}
			if (selectedType === 'People') {
				setPeoplePopupDisplayed(true);
				return;
			}
			if (selectedType === 'Event') {
				setAddEventPopupDisplayed(true);
				return;
			}
			if (selectedType === 'Media') {
				// dispatch({type: 'SEND_MESSAGE', payload: {message: 'Media Item added', type: 'Media'}})
				setFilePopupDisplayed(true)
				return;
			}
			// if (selectedType === 'B2T') {
			// 	dispatch({type: 'SEND_MESSAGE', payload: {message: 'B2T Item added', type: 'B2T'}})
			// 	return;
			// }
			if (selectedType === 'GPS Live') {
				setAddGPSDisplayed(true)
				return;
			}
			if (selectedType === 'GPS Report') {
				setAddGPSReportDisplayed(true)
				return;
			}
			if (selectedType === 'RX Sensor') {
				setAddRxSensorDisplayed(true)
				return;
			}
			if (selectedType === 'Page Summary') {
				setAddPageSummaryDisplayed(true)
				return;
			}
			if (selectedType === 'Video Recorder') {
				setAddVideoRecorderDisplayed(true)
				return;
			}
			if (selectedType === 'Place') {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(position => {
						getReverseGeocodingData(position.coords.latitude, position.coords.longitude)
						// getReverseGeocodingData(51.509865, 51.509865)
					});
				} else {
					alert("Geolocation is not supported by this browser.");
				}
				return;
			}
			if (selectedType === 'Capture') {
				dispatch({type: 'SEND_MESSAGE', payload: {message: 'Capture Item added', type: 'Capture'}})
				return;
			}
		} else {
			const updatedOptions = options.map(option => (option.key === selectedType ?  {...option, selected: !option.selected}: {...option}));
			console.log(updatedOptions);
			setOptions([...updatedOptions]);
		}
	}

	const getReverseGeocodingData = (lat, lng) => {
		var latlng = new google.maps.LatLng(lat, lng);
		// This is making the Geocode request
		var geocoder = new google.maps.Geocoder();
		geocoder.geocode({ 'latLng': latlng },  (results, status) =>{
			if (status !== google.maps.GeocoderStatus.OK) {
				alert(status);
			}
			// This is checking to see if the Geoeode Status is OK before proceeding
			if (status == google.maps.GeocoderStatus.OK) {
				console.log(results);
				var address = (results[0].formatted_address);
				dispatch({
					type: 'SEND_MESSAGE',
					payload: {
						message: 'My current location',
						type: 'Place',
						data: {
							paths: {lat, lng},
							type: 'marker',
							address,
							title: 'My current location'
						}
					}
				});
			}
		});
	}
	if (!type)
		type = 15;

	return (
		<div>
			<Modal title="Popup name"
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={type === 'MESSAGE'? '372px': '600px'}
				className="beam-modal"
				footer={[]}
				destroyOnClose={true}
			>
				<Row className={`d-flex popups m-col-${type==='MESSAGE'? '9': '15'}`}>
					{options.map((icon, idx) =>
						<Col key={idx} className="popup-item">
							{ icon.value !== '' && <>
								<Button className={`beam-btn${icon.selected ? ' beam-filter-selected': ''}`} onClick={() => selectType(icon.value)}>
									<img src={`/images/${icon.value}-icon.png`} /></Button>
								<p>{icon.value}</p>
							</>}
						</Col>
					)}
				</Row>
			</Modal>
			{filePopupDisplayed && <UploadFileModal onClose={() => setFilePopupDisplayed(false)} />}
			{rsvpPopupDisplayed && <AddRSVPModal onClose={() => setRSVPPopupDisplayed(false)} />}
			{iotPopupDisplayed && <AddIoTModal onClose={() => setIotPopupDisplayed(false)} />}
			{peoplePopupDisplayed && <PeopleModal client={client} onClose={() => setPeoplePopupDisplayed(false)} />}
			{dateTimePopupDisplayed && <SelectDateTimeModal onClose={() => setDateTimePopupDisplayed(false)} />}
			{addEventPopupDisplayed && <AddEventModal onClose={() => setAddEventPopupDisplayed(false)} />}
			{addGPSDisplayed && <GPSModal onClose={() => setAddGPSDisplayed(false)} />}
			{addGPSReportDisplayed && <GPSReportModal onClose={() => setAddGPSReportDisplayed(false)} />}
			{addRxSensorDisplayed && <RXSensorModal onClose={() => setAddRxSensorDisplayed(false)} />}
			{addPageSummaryDisplayed && <PageSummaryModal onClose={() => setAddPageSummaryDisplayed(false)} />}
			{addVideoRecorderDisplayed && <VideoRecorderModal onClose={() => setAddVideoRecorderDisplayed(false)} />}
		</div>
	);
}

export default PopupModal;
