import React, { useState } from 'react';
import { Input, Button, Modal, Row, Col, Layout, Card, TimePicker, Select } from 'antd';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import _ from "lodash";
import MapPreview from 'components/Dashboard/MessagePanel/MapPreview';
import { MapPanel } from 'components/Dashboard';
import { HeaderPanel } from 'components/Dashboard';
import "../../containers/Dashboard/Dashboard.scss";
import { ReactSVG } from 'react-svg';
import Pi from "../../../src/assets/images/pi-chart.PNG"
import Bar from "../../../src/assets/images/bar-chart.PNG"
import { PieChart } from 'react-minimal-pie-chart';
import { VerticalGraph } from 'common/Graphs/VerticalGraph';
import './index.scss'

const { Option } = Select;
function ExecutiveModal(props) {
    const { onClose } = props;

    const [isModalVisible, setIsModalVisible] = useState(true);

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };
    const handleOk = () => {
        setIsModalVisible(false);
        onClose && onClose();
    }

    return (
        <Modal
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width='100%'
            style={{ top: 0}}
            className="preview-modal exec mt-5"
            footer={[]}
            destroyOnClose={true}
        >
            <Layout className="main-layout exec-layout">
                <Row gutter={30}>
                    <Col className="gutter-row"  sm={24} md={10}>
                        <Card className="filter-card">
                            <Row gutter={16} className="d-flex px-1 flex-wrap">
                                <Col sm={4}><h5 className="mb-0">Filters</h5></Col>
                                <Col sm={5}><Button className="beam-btn green-btn cover w-70">AM</Button></Col>
                                <Col sm={5}><Button className="beam-btn w-70">Project</Button></Col>
                                <Col sm={5}><Button className="beam-btn w-70">Camera</Button></Col>
                                <Col sm={5}><Button className="beam-btn w-70">Customer</Button></Col>
                            </Row>
                            <Row className="mt-3">
                                    <Col span={24}>
                                        <Select className="w-full" placeholder="Select ID">
                                            <Option>Option1</Option>
                                        </Select>
                                </Col>
                            </Row>
                            <Row gutter={15} className="d-flex px-2 flex-wrap mt-4">
                                <Col sm={24} md={4}><h5 className="mb-0">Status</h5></Col>
                                <Col sm={24} md={10}><Button className="beam-btn green-btn cover w-100">Camera</Button></Col>
                                <Col sm={24} md={10}><Button className="beam-btn w-100">Projects</Button></Col>
                            </Row>
                            <Row className="mt-3">
                                <Col span={24}> 
                                    <div className="gps-report d-flex">
                                        <div className="d-flex">
                                            <div style={{ paddingLeft: 11}}>Select ID<br /><h5 className="mb-0">216316</h5></div>
                                        </div>
                                        <DownOutlined />
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col className="gutter-row" sm={24} md={10}>
                        <Card className="filter-card">
                            <Row className="d-flex flex-wrap" id="filters">
                                <Col sm={4}><h5 className="mb-0">Filters</h5></Col>
                                <Col sm={4}><Button className="beam-btn green-btn cover">DM</Button></Col>
                                <Col sm={4}><Button className="beam-btn">State</Button></Col>
                                <Col sm={4}><Button className="beam-btn">City</Button></Col>
                                <Col sm={4}><Button className="beam-btn">County</Button></Col>
                                <Col sm={4}><Button className="beam-btn">Zip</Button></Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={24}><div className="gps-report d-flex">
                                    <div className="d-flex">
                                        <img src="/images/bell.png" />
                                        <div style={{ paddingLeft: 11}}><h5 className="mb-0">Select ID</h5></div>
                                    </div>
                                        <DownOutlined />
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={15} className="d-flex flex-wrap mt-3 mx-0">
                                <Col xs={24} md={10} className="datetime-item">
                                    <img src="/images/time.png" /><TimePicker placeholder="From" suffixIcon={<DownOutlined />} />
                                </Col>
                                <Col xs={24} md={4} className="d-flex justify-content-center">
                                    <ReactSVG src="/images/next.svg" />
                                </Col>
                                <Col xs={24} md={10} className="datetime-item">
                                    <img src="/images/time.png" /><TimePicker placeholder="To" suffixIcon={<DownOutlined />} />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                    <Col span={24}>
                                        <Select className="w-full" placeholder="Select Hour(s)">
                                            <Option>Option1</Option>
                                        </Select>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col className="gutter-row" sm={24} md={4}>
                        <Card className="filter-card">
                            <p className="mb-2 text-center">Export to</p>

                                <div className="d-flex flex-row flex-md-column justify-content-center">
                                    <div>
                                        <Button className="beam-btn export mb-0 mb-md-2 mr-3 mr-md-0">
                                            <ReactSVG src="/images/export-csv.svg" />
                                        </Button>
                                    </div>
                                    <div>
                                        <Button className="beam-btn export mb-0 mb-md-2 mr-3 mr-md-0">
                                            <ReactSVG src="/images/export-xls.svg" />
                                        </Button>
                                    </div>
                                    <div>
                                        <Button className="beam-btn export mb-0 mb-md-2 mr-3 mr-md-0">
                                            <ReactSVG src="/images/export-pdf.svg" />
                                        </Button>
                                    </div>
                                </div>
                        </Card>
                    </Col>
                </Row>

        <Row className="d-flex mt-3">
            <Col className="border rounded text-center p-2 mx-2">
                <div>
                    <span>Projects</span>
                    <h4>9872</h4>
                </div>
            </Col>
            <Col className="border rounded text-center p-2 mx-2">
                <div>
                    <span>Start</span>
                    <h4>-</h4>
                </div>
            </Col>
            <Col className="border rounded text-center p-2 mx-2">
                <div>
                    <span>End</span>
                    <h4>-</h4>
                </div>
            </Col>
            <Col className="border rounded text-center p-2 mx-2">
                <div className="purple">
                    <span>Alerts</span>
                    <h4>989,872</h4>
                </div>
            </Col>
            <Col className="border rounded text-center p-2 mx-2">
                <div className="blue">
                    <span>Live</span>
                    <h4>97123</h4>
                </div>
            </Col>
            <Col className="border rounded text-center p-2 mx-2">
                <div className="green">
                    <span>Green</span>
                    <h4>1876</h4>
                </div>
            </Col>
            <Col className="border rounded text-center p-2 mx-2">
                <div className="red">
                    <span>Red</span>
                    <h4>1234</h4>
                </div>
            </Col>
            <Col className="border rounded text-center p-2 mx-2">
                <div>
                    <span>Falty</span>
                    <h4>27811</h4>
                </div>
            </Col>
            <Col className="border rounded text-center p-2 mx-2">
                <div className="amber">
                    <span>Amber</span>
                    <h4>0</h4>
                </div>
            </Col>
            <Col className="border rounded text-center p-2 mx-2">
                <div className="blue">
                    <span>Blue</span>
                    <h4>7631</h4>
                </div>
            </Col>
            <Col className="border rounded text-center p-2 mx-2">
                <div className="green">
                    <span>Alerts</span>
                    <h4>100%</h4>
                </div>
            </Col>
            <Col className="border rounded text-center p-2 mx-2">
                <div>
                    <span>Manager</span>
                    <h4>-</h4>
                </div>
            </Col>

        </Row>
        <Row gutter={30} className="map-chart mt-4">
            <Col xs={24} sm={12} md={10}>
                <div className="main-column message-panel mr-4">
                    <div className="main-content p-0">
                        <div className="main-header pt-3 pr-5 pl-4 border-bottom-0">
                            <div className="d-flex">
                                    {/* <GroupIcon color={color} /> */}
                                <h3 className="ml-3">Chart Name</h3>
                            </div>
                            <div className="d-flex">
                                    <ReactSVG src="/images/expand-icon.svg" className="close-icon mr-4" />
                                    <ReactSVG src="/images/close-icon.svg" className="close-icon" />
                            </div>
                        </div>
                        <MapPreview location={{lat: 55.3781, lng: 3.4360}}/> 
                    </div>
                </div>
            </Col>
            <Col xs={24} sm={12} md={7}>
                <div className="main-column message-panel mr-4">
                    <div className="main-content">
                        <div className="main-header">
                            <div className="d-flex">
                                    {/* <GroupIcon color={color} /> */}
                                <h3 className="ml-3">Chart Name</h3>
                            </div>
                            <div className="d-flex">
                                    <ReactSVG src="/images/expand-icon.svg" className="close-icon mr-4" />
                                    <ReactSVG src="/images/close-icon.svg" className="close-icon" />
                            </div>
                        </div>
                        
                        <PieChart
                            radius={30}
                            lineWidth={50}
                            data={[
                                { title: 'One', value: 20, color: '#FBAE16' },
                                { title: 'Two', value: 3, color: '#463BBD' },
                                { title: 'Three', value: 7, color: '#40CC52' },
                            ]}
                        />;
                    </div>
                </div>
            </Col>
            <Col xs={24} sm={12} md={7}>
                <div className="main-column message-panel mr-4">
                    <div className="main-content">
                        <div className="main-header">
                            <div className="d-flex">
                                    {/* <GroupIcon color={color} /> */}
                                <h3 className="ml-3">Chart Name</h3>
                            </div>
                            <div className="d-flex">
                                    <ReactSVG src="/images/expand-icon.svg" className="close-icon mr-4" />
                                    <ReactSVG src="/images/close-icon.svg" className="close-icon" />
                            </div>
                        </div>
                        <VerticalGraph />
                    </div>
                </div>
            </Col>
        </Row>


                <Row gutter={20} className="mt-4">
                    <Col xs={24} sm={16} md={18}>
                        <Row gutter={15}>
                            <Col className="summary-card d-flex" xs={24} sm={12} md={8}>
                                <div className="red">
                                    <Row gutter={8}>
                                        <Col xs={16}>
                                            <div>
                                                <h5>Summary Name</h5>
                                                <p>Description Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. </p>
                                            </div>
                                        </Col>
                                        <Col xs={8}>
                                        <Row gutter={4} className="assets-block d-flex flex-nowrap">
                                            <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        <Row gutter={4} className="mt-3">
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    <span id="badge">6</span>
                                    <div className="half-circle"></div>
                                </div>
                            </Col>
                            <Col className="summary-card d-flex" xs={24} sm={12} md={8}>
                                <div className="orange">
                                    <Row gutter={8}>
                                        <Col xs={16}>
                                            <div>
                                                <h5>Summary Name</h5>
                                                <p>Description Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. </p>
                                            </div>
                                        </Col>
                                        <Col xs={8}>
                                        <Row gutter={4} className="assets-block d-flex flex-nowrap">
                                            <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        <Row gutter={4} className="mt-3">
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    <span id="badge">6</span>
                                    <div className="half-circle"></div>
                                </div>
                            </Col>
                            <Col className="summary-card d-flex" xs={24} sm={12} md={8}>
                                <div className="blue">
                                    <Row gutter={8}>
                                        <Col xs={16}>
                                            <div>
                                                <h5>Summary Name</h5>
                                                <p>Description Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. </p>
                                            </div>
                                        </Col>
                                        <Col xs={8}>
                                        <Row gutter={4} className="assets-block d-flex flex-nowrap">
                                            <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        <Row gutter={4} className="mt-3">
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    <span id="badge">6</span>
                                    <div className="half-circle"></div>
                                </div>
                            </Col>
                            <Col className="summary-card d-flex" xs={24} sm={12} md={8}>
                                <div className="purple">
                                    <Row gutter={8}>
                                        <Col xs={16}>
                                            <div>
                                                <h5>Summary Name</h5>
                                                <p>Description Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. </p>
                                            </div>
                                        </Col>
                                        <Col xs={8}>
                                        <Row gutter={4} className="assets-block d-flex flex-nowrap">
                                            <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        <Row gutter={4} className="mt-3">
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    <span id="badge">6</span>
                                    <div className="half-circle"></div>
                                </div>
                            </Col>
                            <Col className="summary-card d-flex" xs={24} sm={12} md={8}>
                                <div className="orange">
                                    <Row gutter={8}>
                                        <Col xs={16}>
                                            <div>
                                                <h5>Summary Name</h5>
                                                <p>Description Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. </p>
                                            </div>
                                        </Col>
                                        <Col xs={8}>
                                        <Row gutter={4} className="assets-block d-flex flex-nowrap">
                                            <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        <Row gutter={4} className="mt-3">
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    <span id="badge">6</span>
                                    <div className="half-circle"></div>
                                </div>
                            </Col>
                            <Col className="summary-card d-flex" xs={24} sm={12} md={8}>
                                <div className="green">
                                    <Row gutter={8}>
                                        <Col xs={16}>
                                            <div>
                                                <h5>Summary Name</h5>
                                                <p>Description Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. </p>
                                            </div>
                                        </Col>
                                        <Col xs={8}>
                                        <Row gutter={4} className="assets-block d-flex flex-nowrap">
                                            <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        <Row gutter={4} className="mt-3">
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    <span id="badge">6</span>
                                    <div className="half-circle"></div>
                                </div>
                            </Col>
                            <Col className="summary-card d-flex" xs={24} sm={12} md={8}>
                                <div className="purple">
                                    <Row gutter={8}>
                                        <Col xs={16}>
                                            <div>
                                                <h5>Summary Name</h5>
                                                <p>Description Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. </p>
                                            </div>
                                        </Col>
                                        <Col xs={8}>
                                        <Row gutter={4} className="assets-block d-flex flex-nowrap">
                                            <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        <Row gutter={4} className="mt-3">
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    <span id="badge">6</span>
                                    <div className="half-circle"></div>
                                </div>
                            </Col>
                            <Col className="summary-card d-flex" xs={24} sm={12} md={8}>
                                <div className="blue">
                                    <Row gutter={8}>
                                        <Col xs={16}>
                                            <div>
                                                <h5>Summary Name</h5>
                                                <p>Description Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. </p>
                                            </div>
                                        </Col>
                                        <Col xs={8}>
                                        <Row gutter={4} className="assets-block d-flex flex-nowrap">
                                            <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        <Row gutter={4} className="mt-3">
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    <span id="badge">6</span>
                                    <div className="half-circle"></div>
                                </div>
                            </Col>
                            <Col className="summary-card d-flex" xs={24} sm={12} md={8}>
                                <div className="green">
                                    <Row gutter={8}>
                                        <Col xs={16}>
                                            <div>
                                                <h5>Summary Name</h5>
                                                <p>Description Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. </p>
                                            </div>
                                        </Col>
                                        <Col xs={8}>
                                        <Row gutter={4} className="assets-block d-flex flex-nowrap">
                                            <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        <Row gutter={4} className="mt-3">
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    <span id="badge">6</span>
                                    <div className="half-circle"></div>
                                </div>
                            </Col>
                            <Col className="summary-card d-flex" xs={24} sm={12} md={8}>
                                <div className="red">
                                    <Row gutter={8}>
                                        <Col xs={16}>
                                            <div>
                                                <h5>Summary Name</h5>
                                                <p>Description Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. </p>
                                            </div>
                                        </Col>
                                        <Col xs={8}>
                                        <Row gutter={4} className="assets-block d-flex flex-nowrap">
                                            <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        <Row gutter={4} className="mt-3">
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    <span id="badge">6</span>
                                    <div className="half-circle"></div>
                                </div>
                            </Col>
                            <Col className="summary-card d-flex" xs={24} sm={12} md={8}>
                                <div className="purple">
                                    <Row gutter={8}>
                                        <Col xs={16}>
                                            <div>
                                                <h5>Summary Name</h5>
                                                <p>Description Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. </p>
                                            </div>
                                        </Col>
                                        <Col xs={8}>
                                        <Row gutter={4} className="assets-block d-flex flex-nowrap">
                                            <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        <Row gutter={4} className="mt-3">
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    <span id="badge">6</span>
                                    <div className="half-circle"></div>
                                </div>
                            </Col>
                            <Col className="summary-card d-flex" xs={24} sm={12} md={8}>
                                <div className="blue">
                                    <Row gutter={8}>
                                        <Col xs={16}>
                                            <div>
                                                <h5>Summary Name</h5>
                                                <p>Description Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. </p>
                                            </div>
                                        </Col>
                                        <Col xs={8}>
                                        <Row gutter={4} className="assets-block d-flex flex-nowrap">
                                            <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        <Row gutter={4} className="mt-3">
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col span={8} className="pr-3">
                                                <img src="/images/users/user-2.png" />
                                            </Col>
                                            <Col><p className="count">+99</p></Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    <span id="badge">6</span>
                                    <div className="half-circle"></div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="summary-card d-flex h-100" xs={24} sm={8} md={6}>
                        <div className="purple">
                            <div gutter={8}>
                                <div>
                                    <h5>Summary Name</h5>
                                    <p>Description Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. </p>
                                    <p>Description Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. </p>
                                    <p>Description Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. </p>
                                    <p>Description Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. </p>
                                </div>
                                <h5 className="m-1">
                                    People on Page
                                </h5>
                                <Row gutter={4} className="m-1">
                                    <Col span={4} className="pr-3">
                                        <img src="/images/users/user-2.png" />
                                    </Col>
                                    <Col span={4} className="pr-3">
                                        <img src="/images/users/user-2.png" />
                                    </Col>
                                    <Col span={4} className="pr-3">
                                        <img src="/images/users/user-2.png" />
                                    </Col>
                                    <Col span={4} className="pr-3">
                                        <img src="/images/users/user-2.png" />
                                    </Col>
                                    <Col span={4} className="pr-3">
                                        <img src="/images/users/user-2.png" />
                                    </Col>
                                    <Col><p className="count">+99</p></Col>
                                </Row>
                                <h5 className="mt-1">
                                    Assets on Scene
                                </h5>
                                <Row className="assets-block d-flex p-3">
                                    <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                                    <Col><ReactSVG src="/images/user-icon.svg" /></Col>
                                    <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                                    <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                    <Col><ReactSVG src="/images/user-icon.svg" /></Col>
                                    <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                    <Col><p className="count">+99</p></Col>
                                </Row>
                                <h5 className="mt-1">
                                    Assets Recommended
                                </h5>
                                <Row className="assets-block d-flex p-3 mt-1">
                                    <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                                    <Col><ReactSVG src="/images/user-icon.svg" /></Col>
                                    <Col><ReactSVG src="/images/tester-icon.svg" /></Col>
                                    <Col><ReactSVG src="/images/heart-icon.svg" /></Col>
                                    <Col></Col>
                                    <Col></Col>
                                </Row>
                                <Row id="btn" span={14} className="pr-2 mt-3">
                                    <Button className="beam-btn green-btn cover w-full mt-2">Go To Summary Page</Button>
                                </Row>

                            </div>
                            <span id="badge">6</span>
                            <div className="half-circle"></div>
                        </div>
                    </Col>
                </Row>
            </Layout>
        </Modal>
    )
}
export default ExecutiveModal;
