import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, Dropdown, Menu, Switch, Radio } from 'antd';
import { SearchOutlined, EllipsisOutlined } from '@ant-design/icons';
import _ from "lodash";
import { PAGE_TYPES } from 'utils/PageTypes';
import { GET_USERS_LIST } from 'reducers/authReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getCreatePagePublishTopic, getPagesRequestPublishTopic } from 'hooks/MQTTConstants';
import moment from 'moment';
import { Formik } from 'formik';
import * as yup from 'yup';
const clientId = '60c83dcefe8cea1cd6699618';

function CreateNewPageModal(props) {
	const dispatch = useDispatch();
	const {users} = useSelector(state => state.auth);
	const {isModalVisible, setIsModalVisible, onClose, client, type, currentLocation} = props;
    const [initialState, setInitialState] = useState({ title: '' });
	const [text, setText] = useState('');
	const [groupTitleText, setGroupTitleText] = useState('');
	const [selectedUsers, setSelectedUsers] = useState([]);

	const validationSchema = yup.object().shape({
		title: yup.string().min(2, `Title has to be at least 2 characters`).required('Title is required'),
	});

	useEffect(() => {
		dispatch(GET_USERS_LIST());
	}, [])

	const handleCancel = () => {
			setIsModalVisible({visible: false});
			onClose && onClose();
	};


	const createPage = (title, members, location) => {
		const createdAt = moment();
		client.publish(getCreatePagePublishTopic(), JSON.stringify({
			header: {
				accessToken: "",
				connectionId: localStorage.getItem('CID'),
				rootUserId: localStorage.getItem('RID'),
				udid: localStorage.getItem('UUID')
			},
			page: {
				persistent: {
					static: {
						title,
						type: isModalVisible.type,
						appId: 1,
						members,
						location,
						clientId,
						createdAt,
						numan: {
							readStatus: ["read"],
							pageType: "friend",
							userStatus: 3,
							pageStatus: 4,
							liveIoT: ["GPS"],
							owner: 90,
							deleted: false,
							createdAt,
							apps: ["911","dispatch","iot","emergency"]
						}
					}
				}
			}
		}), error => {
			if (error) {
				console.log('Publish error: ', error);
			} else {
				setTimeout(() => client.publish(getPagesRequestPublishTopic(), JSON.stringify({
					paths: [
						"$.persistent"
					],
					returnPaths: [
						"$.persistent.static"
					],
					pagination: {
						offset: 0,
						limit: 200
					}
				}), { qos: 1 }, error => {
					if (error) {
						console.log('Publish error: ', error);
					}
				}), 1000)
			}
		});
	};

	const handleOk = (values) => {

		let members = [{
			rootUserID: localStorage.getItem('RID'), chat_name: 'User - ' + localStorage.getItem('UID'), email: '', phone: ''
		}, ...selectedUsers];

		if(client && values.title){
			if (currentLocation) {
				createPage(values.title, members, props.currentLocation);
			} else {
				alert("Geolocation is not supported by this browser.");
				createPage(values.title, members)
			}
			setIsModalVisible({visible: false});
		}
	};

	const addUser = (user) => {
		setSelectedUsers([...selectedUsers, {rootUserID: user.id, chat_name: user.name, email: user.email, phone: user.phone }]);
	};

	const removeUser = (user) => {
		selectedUsers.splice(selectedUsers.findIndex(item => item.rootUserID === (user.id || user.rootUserID)), 1);
		setSelectedUsers([...selectedUsers])
	};

    const editMenu = (
        <div className="menu-container edit-menu ml-auto people-dropdown">
            <div className="menu-item">
                <Radio.Group defaultValue="Edit">
                    {["Edit", "Read", "Write"].map(item =>
                        <Radio.Button value={item} key={item}>{item}</Radio.Button>
                    )}
                </Radio.Group>
            </div>
            <div className="menu-item">
                <p className="mr-3">Classify</p> <Switch defaultChecked />
            </div>
        </div>
    );

	return (
		<Modal title={`Create ${isModalVisible.type} page`}
			visible={isModalVisible.visible}
			onOk={handleOk}
			onCancel={handleCancel}
			width='480px'
			className="beam-modal scrollable"
			footer={[]}
			destroyOnClose={true}
		>
			<Formik initialValues={initialState} validationSchema={validationSchema} onSubmit={handleOk}>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
					<>
					<Row>
						<Col span={24}>
							<Input onBlur={handleBlur} name="title" onChange={handleChange} placeholder="Page Title" />
							<span className="error text-danger">
								<small>{errors && touched && touched['title'] && errors['title']}</small>
							</span>
						</Col>
					</Row>
					<Row className="scrollable-body mt-4">
						<Col span={24}>
						<Row className="d-flex mb-4">
							<Col><h5>Add people to page</h5></Col>
							<Col><p>{selectedUsers.length} people</p></Col>
						</Row>
						{
							selectedUsers && selectedUsers.map((user, index) => (
								<Row className="person-item" key={user.rootUserID}>
									<Col span={4}><img src={`/images/users/user-1.png`} width="45" height="45" /></Col>
									<Col span={16}>
										<h5>{user.chat_name && !user.chat_name.includes('undefined') ? user.chat_name : 'User '+ index}</h5>
										<p>{user.email || "N/A"}</p>
										<p>{user.phone || "N/A"}</p>
									</Col>
									<Col span={4} className="t-right">
										<Button className="beam-btn orange-btn mr-2" onClick={() => removeUser(user)}>
											Remove
										</Button>
										<Dropdown overlay={editMenu} className="message-dropdown" placement="bottomRight">
											<a className="ant-dropdown-link" onClick={e => e.preventDefault()}><EllipsisOutlined /></a>
										</Dropdown>
									</Col>
								</Row>
							))
						}
						<Row className="mb-4">
							<Col span={24}>
								<Input placeholder="Search" suffix={
									<SearchOutlined />
								} />
							</Col>
						</Row>
						{
							users && users.map((user, index) => !selectedUsers.find(item => item.rootUserID === user.id) && (
								<Row className="person-item" key={user.id}>
									<Col span={4}><img src={`/images/users/user-1.png`} width="45" height="45" /></Col>
									<Col span={16}>
										<h5>{user.name && !user.name.includes('undefined') ? user.name : 'User '+ index}</h5>
										<p>{user.email || "N/A"}</p>
										<p>{user.phone || "N/A"}</p>
									</Col>
									<Col span={4} className="t-right">
										{
											selectedUsers.find(item => item.rootUserID === user.id) ?
											<Button className="beam-btn orange-btn mr-2" onClick={() => removeUser(user)}>
												Remove
											</Button> :
											<Button className="beam-btn green-btn" onClick={() => addUser(user)}>
												Add
											</Button>

										}
										<Dropdown overlay={editMenu} className="message-dropdown" placement="bottomRight">
											<a className="ant-dropdown-link" onClick={e => e.preventDefault()}><EllipsisOutlined /></a>
										</Dropdown>
									</Col>
								</Row>
							))
						}
						{/* {_.times(3, (idx) => (
							<Row className="person-item" key={idx}>
								<Col span={4}><img src={`/images/users/user-${idx}.png`} width="45" height="45" /></Col>
								<Col span={15}>
									<h5>Carla Vaccaro {idx}</h5>
									<p>support@beam.live</p>
									<p>+36 1 568-9874</p>
								</Col>
								<Col span={5} className="t-right">
									<Button className="beam-btn green-btn">Add</Button>
								</Col>
							</Row>
						))} */}
						</Col>
					</Row>
					<Row className="action-grp mt-4 sticky-footer">
						<Col span={14} className="pr-2">
							<Button className="beam-btn green-btn cover w-full" onClick={handleSubmit}>Save</Button>
						</Col>
						<Col span={10} className="pl-2">
							<Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
						</Col>
					</Row>
				</>
			)}
			</Formik>
		</Modal>
	)
}

export default CreateNewPageModal;
