class ApiError extends Error {
  constructor(httpStatusCode, httpStatusText, responseCode, message) {
    super(message);
    this.httpStatusCode = httpStatusCode;
    this.httpStatusText = httpStatusText;
    this.responseCode = responseCode;
  }
}

export const parseApiResponse = (response) => {
  if(response !== undefined) {
    // console.log('response here', response.json())
    return response.text().then(function(text) {
      let resp = text ? JSON.parse(text) : {};
      console.log('text here', resp)
      if(!resp.status) {
        return resp.message;
      } else {
        return resp;
      }
    });
  } else {
    throw new Error('Response is Required');
  }
};

export const parseApiResponseJSON = (response) => {
  if(response !== undefined) {
    // console.log('response here', response.json())
    return response.text().then(function(text) {
      return text ? JSON.parse(text) : {};
    });
  } else {
    throw new Error('Response is Required');
  }
};
