
const initialState = {
	// messages: [{
	// 	id: 1,
	// 	name: 'Leonetta Llyod',
	// 	avatar: '',
	// 	text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras id tortor pretium, dictum purus ut, ullamcorper nulla. Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. Fusce ullamcorper lectus et feugiat convallis. Curabitur interdum.',
	// 	type: 'message',
	// 	time: '11:12:48',
  //       seenBy: [{
  //           id: 1,
  //           name: 'Sister'
  //       },
  //       {
  //           id: 2,
  //           name: 'Leona'
  //       },
  //       {
  //           id: 3,
  //           name: 'Ekoterina'
  //       }]
	// },
	// {
	// 	id: 2,
	// 	name: 'Ekaterina',
	// 	avatar: '',
	// 	text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras id tortor pretium, dictum purus ut, ullamcorper nulla. Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. Fusce ullamcorper lectus et feugiat convallis. Curabitur interdum.',
	// 	type: 'message',
	// 	time: '11:12:48',
  //       seenBy: [{
  //           id: 1,
  //           name: 'Leona'
  //       },
  //       {
  //           id: 2,
  //           name: 'Ekoterina'
  //       },
  //       {
  //           id: 3,
  //           name: 'Sister'
  //       }]
	// },{
	// 	id: 3,
	// 	name: 'sister@beam.live',
	// 	avatar: '',
	// 	text: 'has been added to the page by Zsolt',
	// 	type: 'member',
	// 	time: '11:12:48',
  //       seenBy: [{
  //           id: 1,
  //           name: 'Sister'
  //       },
  //       {
  //           id: 2,
  //           name: 'Leona'
  //       },
  //       {
  //           id: 3,
  //           name: 'Ekoterina'
  //       }]
	// },{
	// 	id: 4,
	// 	name: 'Sister',
	// 	avatar: '',
	// 	text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras id tortor pretium, dictum purus ut, ullamcorper nulla. Curabitur diam metus, suscipit ac mattis sit amet, efficitur non turpis. Fusce ullamcorper lectus et feugiat convallis. Curabitur interdum.',
	// 	type: 'message',
	// 	time: '11:35:48',
  //       seenBy: [{
  //           id: 1,
  //           name: 'Sister'
  //       },
  //       {
  //           id: 1,
  //           name: 'Leona'
  //       },
  //       {
  //           id: 1,
  //           name: 'Ekoterina'
  //       }]
	// },{
	// 	id: 3,
	// 	name: 'Leonetta Lloyd',
	// 	avatar: '',
	// 	text: 'has been added to the page by Zsolt',
	// 	type: 'member',
	// 	time: '11:12:48',
  //       seenBy: [{
  //           id: 1,
  //           name: 'Sister'
  //       },
  //       {
  //           id: 1,
  //           name: 'Leona'
  //       },
  //       {
  //           id: 1,
  //           name: 'Ekoterina'
  //       }]
	// }],
	messages:{},
  messageToSend: undefined,
  focusLatLng: undefined,
}

const getSelectedPageMessages = (state, action) => {
	const updatedMessages =  state.messages;
	const {pageId} = action.payload;
    const {flag} = action.payload.data;
	if (updatedMessages[pageId]) {
		if(flag === 'replace'){
			let index = updatedMessages[pageId].findIndex((x)=>{return x.id === action.payload.data.originalPageMessageId})
			updatedMessages[pageId][index] = action.payload;
		}
		else if(flag === 'remove'){
			let index = updatedMessages[pageId].findIndex((x)=>{return x.id === action.payload.data.originalPageMessageId})
			updatedMessages[pageId].splice(index, 1)
		}
		else
			updatedMessages[pageId].push(action.payload);
	} else {
		updatedMessages[pageId] = [action.payload];
	}
	return {
		...state,
		messages: updatedMessages,
		currentMessage: action.payload.data
	};
}

const deleteMessage = (state, action) => {
	let messages =  state.messages;
	const {pageId, messageId} = action.payload;

	let index = messages[pageId].findIndex((x)=>{return x.id === messageId})
	messages[pageId].splice(index, 1)

	return {
		...state,
		messages: messages
	};
}

const updateMessage = (state, action) => {
	let messages =  state.messages;
	const {pageId, messageId, changes} = action.payload;

	let index = messages[pageId].findIndex((x)=>{return x.id === messageId})
	messages[pageId][index].message = changes[0].value;

	return {
		...state,
		messages: messages
	};
}


const clearMessage = (state, action) => {
	let messages =  state.messages;
	const {pageId} = action.payload;
	messages[pageId] = []
	return {
		...state,
		messages: messages
	};
}

const getMessageToSend = (state, action) => {
	return {
		...state,
		messageToSend: action.payload
	};
}

// const setFakeLatLng = (state, action) => {
// 	return {
// 		...state,
// 		focusLatLng: undefined
// 	};
// }

const getUpdatedLatLng = (state, action) => {
	// setFakeLatLng(state, action);
	return {
		...state,
		focusLatLng: {...action.payload}
	};
}

const getFileToSend = (state, action) => {
	return {
		...state,
		fileToSend: action.payload
	};
}

const setPageMessages = (state, action) => {
	const updatedMessages =  state.messages;
	const {pageId, messages} = action.payload;
	updatedMessages[pageId] = messages
	return {
		...state,
		messages: updatedMessages
	};
}

const messageReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_SELECTED_PAGE_MESSAGE': return getSelectedPageMessages(state, action)
		case 'SEND_MESSAGE': return getMessageToSend(state, action)
		case 'DELETE_MESSAGE': return deleteMessage(state, action)
		case 'UPDATE_MESSAGE': return updateMessage(state, action)
		case 'CLEAR_MESSAGE': return clearMessage(state, action)
		case 'CHANGE_MAP_FOCUS': return getUpdatedLatLng(state, action)
		case 'SET_MESSAGES': return setPageMessages(state, action)
		case 'ADD_FILE': return getFileToSend(state, action)
		default: return state
	}
}

export default messageReducer;
