import React, { useState, useEffect } from 'react';
import mqtt from 'mqtt';
import {useDispatch, useSelector} from 'react-redux';
import { generateUUID } from 'utils/appUtil';
import moment from 'moment';
import { getPagesRequestPublishTopic } from "hooks/MQTTConstants";


export default () => {
	const [client, setClient] = useState(null);
	const [connectedClient, setConnectedClient] = useState(null);
	const {selected_places} = useSelector(state => state.dashboard);
	const dispatch = useDispatch();

	const mqttPublish = (topic, payload, onError, onSuccess) => {
		if (client) {
			client.publish(topic, JSON.stringify(payload), { qos: 1 }, error => {
				if (error) {
				}
			});
		}
	}

	const mqttSub = (topic, onError, onSuccess) => {
		if (client) {
			client.subscribe(topic, { qos: 1 }, (error) => {
				if (error) {
					console.log('Subscribe to topics error', error)
					return
				}
				console.log('Subsribed')
			});
		}
	};

	useEffect(()=>{
		return () => {
				if (client) {
				client.end(() => {
						console.log('Connect');
				});
				}
		}
	},[])

	const mqttConnect = () => {
		setClient(mqtt.connect(process.env.REACT_APP_BASE_WS_URL, {clientId: generateUUID(), username: 'live', password: 'beamL1ve'}));
	};

	useEffect(() => {
		if (client) {
			client.on('connect', () => {
				console.log('Connected');
				setConnectedClient(client);
			});
			client.on('error', (err) => {
				console.error('Connection error: ', err);
				client.end();
			});
			client.on('reconnect', () => {
				console.log('Reconnecting');
			});
			client.on('disconnect', () => {
				console.log('Disconnecting');
			});
			client.on('message', mqttMessageReceivedListener);
		}
	}, [client]);

	const processMessage = (updatedMessages, payload) => {
		const { flag } = payload.data;
		if(flag === 'replace') {
			let index = updatedMessages.findIndex((x)=>{return x.id === payload.data.originalPageMessageId})
			updatedMessages[index] = payload;
		}
		else if(flag === 'remove') {
			let index = updatedMessages.findIndex((x)=>{return x.id === payload.data.originalPageMessageId})
			updatedMessages.splice(index, 1)
		}
		else
			updatedMessages.push(payload);
		return updatedMessages;
	}

	const mqttMessageReceivedListener = (topic, message) => {
		const payload = { topic, message: message.toString() };
		console.log(JSON.parse(payload.message))
		if(topic.includes('searchPageResult')) {
			handlePageResults(JSON.parse(payload.message).data.data);
		} else if (topic.includes('createMessageResult')){
			handleTextMessage(payload);
		} else if (topic.includes('focusMap')) {
			dispatch(JSON.parse(message))
		} else if (topic.includes('user')) {
			const topics = topic.split('/');
			console.log('topics', topics[4], topics[5], localStorage.getItem('UID'));
			if(topics[4] === localStorage.getItem('UID')){
				console.log('notification intended for me')
			}
			if(topics[5] === 'pageCreated') {
				client.publish(getPagesRequestPublishTopic(), JSON.stringify({
					paths: ["$.persistent"], returnPaths: ["$.persistent.static"], pagination: {offset: 0, limit: 50}
				}));
			}
		}
		else if(topic.includes('searchMessageResult')){
			let messages = JSON.parse(payload.message).data;
			let updatedMessages = [];
			let pageId;
			messages.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
			for(let message of messages){
				pageId =  parseInt(message.pageId);
				message = {
					messageType: message.pageMessage.message.type,
					createdAt: message.createdAt,
					data: message.pageMessage.message.attributes,
					senderName: `user# ${message.pageMessage.pageInfo.rootUserId}`,
					message: message.pageMessage.message.attributes.body,
					pageId,
					id: message.pageMessageId
				}
				// dispatch({type: 'SET_SELECTED_PAGE_MESSAGE', payload: message })
				updatedMessages = processMessage(updatedMessages, message);
			}
			// console.log('processed list here')
			dispatch({type: 'SET_MESSAGES', payload: {messages: updatedMessages, pageId}});
		} else if(topic.includes('get/places')){
			dispatch({type: 'ADD_MAP_PLACES', payload: JSON.parse(payload.message).data})
		} else if (topic.includes('getTokenResult')) {
			dispatch({ type: 'SET_TWILIO_TOKEN', payload: JSON.parse(payload.message).data.data})
		}
	};

	const handlePageResults = (data) => {
		console.log(data)
		// move this layer into another service
		const organizedData =  data.map(page => (page[Object.keys(page)[0]][0][0] && page[Object.keys(page)[0]][0][0]?.title && { pageId: Object.keys(page)[0], title: page[Object.keys(page)[0]][0][0]?.title, type: page[Object.keys(page)[0]][0][0]?.type, members: page[Object.keys(page)[0]][0][0]?.members, createdAt: page[Object.keys(page)[0]][0][0]?.createdAt }))
		const filteredData = organizedData.filter(data => data).sort((a,b) => moment(b.createdAt) - moment(a.createdAt));
		console.log('Orgaized Data', filteredData);
		if(filteredData && filteredData.length > 0) {
			dispatch({type: 'SET_SEARCH_RESULT', payload: filteredData })
		}
	}

	const handleTextMessage = (payload) => {
		// const resp = JSON.parse(payload.message).data.messages[0];
		const resp = JSON.parse(payload.message).data[0];
		const pageId =  resp.pageInfo.pageId;
		const message = {
			messageType: resp.message.type,
			createdAt: resp.pageInfo.publishedAt,
			data: resp.message.attributes,
			senderName: `user# ${resp.pageInfo.rootUserId}`,
			message: resp.message.attributes.body,
			pageId,
			id: resp.pageInfo.pageMessageId
		};

		dispatch({type: 'SET_SELECTED_PAGE_MESSAGE', payload: message });
		if(message.messageType === 'place'){
			//TODO: replace original message on delete/ replace

			// const {flag} = message.data;
			// if(flag === 'replace'){
			// 	let index = selected_places.items.findIndex((x)=>{return x.id === message.data.originalPageMessageId});
			// 	console.log('index here', index)
			// 	if(index !== -1) {
			// 		const tempPlaces = {...selected_places}
			// 		tempPlaces.items[index] = {
			// 			...message,
			// 			id: message.id,
			// 			name: message.message,
			// 			objectType: 'place',
			// 		}
			// 		dispatch({type: 'SET_SELECTED_PLACES', payload: {items: tempPlaces, fitBounds: true}});
			// 		return;
			// 	}
			// }
			// else if(flag === 'remove'){
			// 	console.log('test', selected_places.items)
			// 	console.log('test 1', message.data.originalPageMessageId)
			// 	let index = selected_places.items.findIndex((x)=>{return x.id === message.data.originalPageMessageId});
			// 	console.log('index here', index)
			// 	if(index !== -1) {
			// 		const tempPlaces = {...selected_places}
			// 		tempPlaces.items.splice(index, 1);
			// 		dispatch({type: 'SET_SELECTED_PLACES', payload: {items: tempPlaces, fitBounds: true}});
			// 		return;
			// 	}
			// }

			// dispatch({type: 'CHANGE_MAP_FOCUS', payload: { message }})

			client.subscribe(`focusMap`, { qos: 1 }, (error) => {});
			client.publish('focusMap', JSON.stringify({type: 'ADD_SELECTED_PLACES', payload: {
					...message,
					id: message.id,
					name: message.message,
					objectType: 'place',
				}}));

		};
	}

	return {
		client: connectedClient,
		connectMQTT: mqttConnect,
		mqttPublish,
	}
};

