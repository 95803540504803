import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Input, Button, Select, Radio, DatePicker, Collapse, Checkbox } from "antd";
import { ReactSVG } from 'react-svg';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import _ from "lodash";
import { AddEventModal } from '../../Modals';

const { Option } = Select;

const { TextArea } = Input;

const { Panel } = Collapse;


function LogisticsPanel(props) {
    const {screens, setScreens} = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    
    return (
        <div className="main-column ride-dispatch-panel mr-4">
          <div className="main-content">
            <div className="main-header">
                <div className="d-flex">
                    <ReactSVG src="/images/ride-dispatch-icon.svg" />
                    <h3 className="ml-3">Losgistics</h3>
                </div>
                <div className="d-flex">
                    <ReactSVG src="/images/expand-icon.svg" className="close-icon mr-4" onClick={() => setScreens({...screens, active: screens.active? null: 'rideDispatch'})} />
                    <ReactSVG src="/images/close-icon.svg" className="close-icon" onClick={() => setScreens({...screens, ridePanel: false, message: true})} />
                </div>
            </div>
            <Row className="mt-3">
                <Col span={24}>
                    <Select className="w-full" placeholder="Features">
                        <Option>Option1</Option>
                    </Select>
                </Col>
            </Row>
            <div className="main-cards">
                <Row>
                    <Col span={12} className="pr-2"><Input placeholder="Account Number" /></Col>
                    <Col span={12} className="pl-2"><Input placeholder="Account Name" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Name" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Email" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Phone" /></Col>
                </Row>
                <hr />
                <Row>
                    <Col span={24}><Input placeholder="Customer Name" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={12} className="pr-2"><Input placeholder="Phone Number" /></Col>
                    <Col span={12} className="pl-2"><Input placeholder="Alternative Phone" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Email" /></Col>
                </Row>
                <hr />
                <div className="sub-cards">
                    <h5>Safe Hands Delivery</h5>
                    <Row className="mt-3">
                        <Col span={24}><Select className="w-full" placeholder="Types" options={[
                            {label: 'Small', value: 'Small'},
                            {label: 'Medium', value: 'Medium'},
                            {label: 'Large', value: 'Large'},
                            {label: 'Heavy', value: 'Heavy'},
                            {label: 'Envelope', value: 'Envelope'},
                            {label: 'Carton', value: 'Carton'},
                            {label: 'Fragile', value: 'Fragile'},
                        ]}></Select></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}><Input placeholder="Pickup Location" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}>
                            <Button className="beam-btn w-full more-btn" onClick={() => setIsModalVisible(true)}>Schedule</Button>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}><Select className="w-full" placeholder="Package Type" options={[
                            {label: 'SafeHands', value: 'SafeHands'},
                            {label: 'Warehouse', value: 'Warehouse'},
                        ]}></Select></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}>
                            <Checkbox.Group options={['Picture', 'Video', 'Signature', 'Survey']} defaultValue={[]} />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={24}>
                            <TextArea autoSize={{ minRows: 4, maxRows: 6 }} placeholder="Instructions" />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={12} className="pr-2"><Input placeholder="Order" /></Col>
                        <Col span={12} className="pl-2"><Input placeholder="Group" /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col span={12} className="pr-2"><Button className="beam-btn w-full more-btn">Add Package</Button></Col>
                        <Col span={12} className="pl-2"><Button className="beam-btn w-full more-btn">Load CSV</Button></Col>
                    </Row>
                </div>
                <Row className="mt-3">
                    <Col span={24}><Select className="w-full" placeholder="IoT Device"></Select></Col>
                </Row>
            </div>
            <Row className="action-grp mt-3">
                <Col span={8} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full">Submit</Button>
                </Col>
                <Col span={8} className="px-2">
                    <Button className="beam-btn yellow-btn cover w-full">Save</Button>
                </Col>
                <Col span={8} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full">Cancel</Button>
                </Col>
            </Row>
          </div>
          <AddEventModal 
            isModalVisible={isModalVisible} 
            setIsModalVisible={setIsModalVisible}
        />
        </div>
    )
}

export default LogisticsPanel;
