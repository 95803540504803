import React, { PureComponent } from "react";

import trackPropType from "../../propTypes/track";

class Track extends PureComponent {
  media = React.createRef();

  static propTypes = {
    track: trackPropType.isRequired
  };

  componentDidMount() {
    const { track } = this.props;

    console.log(track);

    track.attach(this.media.current);
  }

  render() {
    const { track, isRemote } = this.props;
    

    return <track.kind className={track.kind === 'video' ? !isRemote ? 'video-local': 'video-remote': ''} autoPlay ref={this.media} />;
  }
}

export default Track;
