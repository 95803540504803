import 'whatwg-fetch';
import _ from 'lodash';
import {AUTH0_USER_ID, AUTH_API_ACCESS_TOKEN} from "../../constants/localStoreKeys";
import {logger as log} from "../../logger"

export default class Api {

  /**
   * @constructor
   * @param {String} base the base URL of Api
   * @param {String} token Optional. Authorization Token
   */
  constructor(base, token, userId) {
    if (base === undefined) throw new Error("base URL is required");
    this.base = base;
    this.token = token;
    this.userId = userId;
  }

  getHeaders(headers=null){
    const defaultHeaders = {
      'Content-Type': 'application/json',
      'mode': 'cors',
      'Access-Control-Allow-Origin': '*',
      'Origin': this.base
    };
    if (!headers) headers = defaultHeaders;
    if (this.token) headers.Authorization = 'Bearer ' + this.token;
    if (this.userId) headers['X-AUTH0-USER-ID'] = this.userId;

    return headers;
  }

  getUrl(endpoint) {
    return `${this.base}${endpoint}`;
  }

  /**
   * Sends a request to the given endpoint of the API. This method is just
   * a wrapper for fetch(). If an auth token is present, it will be
   * automatically added to auth header of all requests.
   *
   * @param {String} endpoint Should start with slash, like /endpoint.
   * @param {Object} options Optional. Fetch options.
   * @param {Object} headers Optional. Fetch headers.
   * @param {String} contenttype Optional. 'Content-Type' headers.
   * @return {Promise} It resolves to the HTTP response object. To get the
   *  actual data you probably want to call .json() method of that object.
   *  The promise rejects only on network errors. In case of HTTP errors
   *  (404, etc.) the promise will be resolved successfully.
   */
  fetch(endpoint, options, headers=null) {
    const defaultHeaders = {
      'Content-Type': 'application/json',
      'mode': 'cors',
      'Access-Control-Allow-Origin': '*',
      'Origin': this.base
    };
    if (!headers) headers = defaultHeaders;
    if (this.token) headers.Authorization = 'Bearer ' + this.token;
    if (this.userId) headers['X-AUTH0-USER-ID'] = this.userId;
    const opts = _.merge(_.cloneDeep(options) || {}, {headers});
    return fetch(`${this.base}${endpoint}`, opts)
      .catch(e => {
        if (e.statusCode === 401) {
          log.error({...headers, message: '401 UnAuthorized'});
        }
        throw e;
      })
  }


  fetchPost(endpoint, options) {
    const headers = {
      'Content-Type': 'application/json'
    };
    const opts = _.merge(_.cloneDeep(options) || {}, {headers});
    return fetch(`${this.base}${endpoint}`, opts)
        .catch(e => {
          if (e.statusCode === 401) {
            log.error({ message: '401 UnAuthorized'});
          }
          throw e;
        })
  }

  fetchPostWithoutHeaders(endpoint, options) {
    const opts = _.merge(_.cloneDeep(options) || {});
    return fetch(`${this.base}${endpoint}`, opts)
        .catch(e => {
          if (e.statusCode === 401) {
            log.error({ message: '401 UnAuthorized'});
          }
          throw e;
        })
  }


  /**
   * Sends DELETE request to the endpoint.
   *
   * @param {String} endpoint
   * @param {FormData|String} body
   * @return {Promise}
   */
  delete(endpoint, body) {
    return this.fetch(endpoint, { body, method: 'DELETE' });
  }

  /**
   * Sends GET request to the endpoint.
   *
   * @param {String} endpoint
   * @return {Promise}
   */
  get(endpoint) {
    return this.fetch(endpoint);
  }

  getWithHeaders(endpoint, headers) {
    return this.fetch(endpoint, {}, headers);
  }


  /**
   * Sends POST request to the endpoint.
   *
   * @param {String} endpoint
   * @param {FormData|String} body
   * @return {Promise}
   */
  post(endpoint, body) {
    return this.fetchPost(endpoint, { body, method: 'POST' });
  }

  /**
   * Sends POST request to the endpoint.
   *
   * @param {String} endpoint
   * @param {FormData|String} body
   * @param {Object} headers Optional. Fetch options.
   * @return {Promise}
   */
  postWithHeaders(endpoint, body, headers) {
    return this.fetch(endpoint, { body, method: 'POST' }, headers);
  }

  postWithoutHeaders(endpoint, body) {
    return this.fetchPostWithoutHeaders(endpoint, { body, method: 'POST' });
  }

  /**
   * Sends POST request to the endpoint, with JSON payload.
   *
   * @param {String} endpoint
   * @param {JSON} json
   * @return {Promise}
   */
  postJson(endpoint, json) {
    return this.post(endpoint, JSON.stringify(json));
  }

  /**
   * Sends PUT request to the endpoint.
   *
   * @param {String} endpoint
   * @param {FormData|String} body
   * @return {Promise}
   */
  put(endpoint, body) {
    return this.fetch(endpoint, { body, method: 'PUT' });
  }

  /**
   * Sends PUT request to the endpoint.
   *
   * @param {String} endpoint
   * @param {JSON} json
   * @return {Promise}
   */
  putJson(endpoint, json) {
    return this.put(endpoint, JSON.stringify(json));
  }

  /**
   * Sends PATCH request to the endpoint.
   *
   * @param {String} endpoint
   * @param {FormData|String} body
   * @return {Promise}
   */
  patch(endpoint, body) {
    return this.fetch(endpoint, { body, method: 'PATCH' })
  }

  /**
   * Sends PATCH request to the endpoint.
   * @param {String} endpoint
   * @param {JSON} json
   * @return {Promise}
   */
  patchJson(endpoint, json) {
    return this.patch(endpoint, JSON.stringify(json));
  }
}

/**
 * Returns a new or existing Api object for API v1.
 *
 * @param {String} token Optional. Auth token
 * @return {Api} API v1 object
 */
export function getApiV1() {
  return new Api(`${process.env.REACT_APP_BASE_API_URI}`,
                  localStorage.getItem(AUTH_API_ACCESS_TOKEN),
                  localStorage.getItem(AUTH0_USER_ID)
  );
}

export function getMessagingApiV1() {
  return new Api(`${process.env.REACT_APP_MEDIA_UPLOAD_API_URI}`,
      localStorage.getItem(AUTH_API_ACCESS_TOKEN),
      localStorage.getItem(AUTH0_USER_ID)
  );
}

export function geGPSApiV1() {
  return new Api(`${process.env.REACT_APP_GPS_URL}`
  );
}

export function geRXApiV1() {
  return new Api(`${process.env.REACT_APP_RX_API_URI}`
  );
}

export function geVideoApiV1() {
  return new Api(`${process.env.REACT_APP_VIDEO_URI}`
  );
}

export function getCUDBClientApiV1() {
  return new Api(`${process.env.REACT_APP_CUDB_CLIENT_API}`
  );
}
