import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Input, Button, Select, Radio, DatePicker, Collapse } from "antd";
import { ReactSVG } from 'react-svg';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import _ from "lodash";

const { Option } = Select;

const { TextArea } = Input;

const { Panel } = Collapse;


function Nine11Panel(props) {
    const {screens, setScreens} = props;
    
    return (
        <div className="main-column ride-dispatch-panel mr-4">
          <div className="main-content">
            <div className="main-header">
                <div className="d-flex">
                    <ReactSVG src="/images/ride-dispatch-icon.svg" />
                    <h3 className="ml-3">911</h3>
                </div>
                <div className="d-flex">
                    <ReactSVG src="/images/expand-icon.svg" className="close-icon mr-4" onClick={() => setScreens({...screens, active: screens.active? null: 'rideDispatch'})} />
                    <ReactSVG src="/images/close-icon.svg" className="close-icon" onClick={() => setScreens({...screens, ridePanel: false, message: true})} />
                </div>
            </div>
            <Row className="mt-3">
                <Col span={24}>
                    <Select className="w-full" placeholder="Features">
                        <Option>Option1</Option>
                    </Select>
                </Col>
            </Row>
            <div className="main-cards">
                <Row>
                    <Col span={12} className="pr-2"><Input placeholder="Account Number" /></Col>
                    <Col span={12} className="pl-2"><Input placeholder="Account Name" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Name" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Email" /></Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}><Input placeholder="Phone" /></Col>
                </Row>
                <hr />
                <Row className="mt-3">
                    <Col span={16} className="pr-2"><Input placeholder="Phone Number" /></Col>
                    <Col span={8}>
                        <Button className="beam-btn w-full more-btn">More</Button>
                    </Col>
                </Row>
                <hr />
                <Collapse defaultActiveKey={['who']} bordered={false} className="simple-collapse">
                    <Panel header={<h5>Where</h5>} key="where">
                        <div className="sub-cards">
                            <Row>
                                <Col span={16} className="pr-2"><Input placeholder="Caller Location" /></Col>
                                <Col span={8}>
                                    <Button className="beam-btn w-full more-btn">Incident</Button>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col span={16} className="pr-2"><Input placeholder="Incident Location" /></Col>
                                <Col span={8}>
                                    <Button className="beam-btn w-full more-btn">More</Button>
                                </Col>
                            </Row>
                        </div>
                    </Panel>
                    <Panel header={<h5>What</h5>} key="what">
                        <div className="sub-cards">
                            <Row>
                                <Col span={24}>
                                    <Select className="w-full" placeholder="Incident Type">
                                    </Select>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col span={24}><Input placeholder="Priority" /></Col>
                            </Row>
                            <hr />
                            <Row className="mt-3">
                                <Col span={24}>
                                    <TextArea autoSize={{ minRows: 4, maxRows: 6 }} placeholder="Protocols" />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col span={8} className="pr-2">
                                    <Button className="beam-btn cover w-full">SURVEY</Button>
                                </Col>
                                <Col span={8} className="px-2">
                                    <Button className="beam-btn cover w-full">NCIC</Button>
                                </Col>
                                <Col span={8} className="pl-2">
                                    <Button className="beam-btn bordered w-full">NFIRS</Button>
                                </Col>
                            </Row>
                        </div>
                    </Panel>
                    <Panel header={<h5>Who</h5>} key="who">
                        <div className="sub-cards">
                            <Row>
                                <Col span={12} className="pr-2">
                                    <Input placeholder="First Name" />
                                </Col>
                                <Col span={12} className="pl-2">
                                    <Input placeholder="Last name" />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col span={24}>
                                    <div className="datetime-item">
                                        <img src="/images/date.png" /><DatePicker placeholder="Date" suffixIcon={<DownOutlined />} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col span={12} className="pr-2">
                                    <Input placeholder="Alternative Phone" />
                                </Col>
                                <Col span={12} className="pl-2">
                                    <Select className="w-full" placeholder="Gender" options={[
                                        {label: "Male", value: 'Male'},
                                        {label: "Female", value: 'Female'},
                                    ]}>
                                    </Select>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col span={24}>
                                    <TextArea autoSize={{ minRows: 4, maxRows: 6 }} placeholder="Instructions" />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col span={24}>
                                    <Button className="beam-btn w-full more-btn">More</Button>
                                </Col>
                            </Row>
                        </div>
                    </Panel>
                </Collapse>
            </div>
            <Row className="action-grp mt-3">
                <Col span={8} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full">Submit</Button>
                </Col>
                <Col span={8} className="px-2">
                    <Button className="beam-btn yellow-btn cover w-full">Save</Button>
                </Col>
                <Col span={8} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full">Cancel</Button>
                </Col>
            </Row>
          </div>
        </div>
    )
}

export default Nine11Panel;
