import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Row, Col, Button, Dropdown, Menu, Image } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import MapPreview from './MapPreview';
import { RSVPRespModal, RxSensorTableModal, CameraVideoModal } from 'components/Modals';
import MapPreviewModal from 'components/Modals/MapPreviewModal';
import AddEventModal from 'components/Modals/AddEventModal';
import { useDispatch, useSelector } from 'react-redux';
import MapService from "../MapPanel/MapService";
import moment from 'moment';

function MessageItem({message, onRemove, onEdit, onMessageVisible, screens, setScreens, client}) {
  const {users} = useSelector(state => state.auth);
  const [rsvpModalOpen, setRSVPModalOpen] = useState(false);
  const [mapPreviewOpen, setMapPreviewOpen] = useState(false);
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [rxSensorDevice, setRxSensorDevice] = useState(false);
  const [cameraVideo, setCameraVideo] = useState(false);
  const [username, setUsername] = useState(message.senderName);
  const { showGPSHistorical } = MapService();

  useEffect(() => {
    const user = users.find(obj => obj.id === message.senderName.split('# ')[1]);
    setUsername(user?.name || message.senderName)
    onMessageVisible(true)
  }, []);
  const dispatch = useDispatch();
  const infoMenu = (
    <div className="menu-container">
      <div className="menu-item">
        <ReactSVG src="/images/gps-icon.svg" />
        <p>-97-50382455 30.50382455</p>
      </div>
      <div className="menu-item">
        <ReactSVG src="/images/geolocation-icon.svg" />
        <p>712 Tom Kite Drive, Round Rock, Texas, USA</p>
      </div>
    </div>
  );

  const editMenu = (
    <div className="menu-container edit-menu">
      <div className="menu-item" onClick={()=>{onEdit(message)}}>
        <p>Edit</p>
      </div>
      <div className="menu-item">
        <p>Copy</p>
      </div>
      <div className="menu-item">
        <p>Quote</p>
      </div>
      <div className="menu-item">
        <p>Forward</p>
      </div>
      <div className="menu-item">
        <p>Collaborate</p>
      </div>
      <div className="menu-item">
        <p>Bookmark</p>
      </div>
      <div className="menu-item" onClick={()=>{onRemove(message)}}>
        <p>Remove</p>
      </div>
    </div>
  );
  const handleIconClick = () => {
    if(message.messageType === 'RSVP') {
      setRSVPModalOpen(true);
      return;
    }
    if(message.messageType === 'place') {
      // setMapPreviewOpen(true);
      // dispatch({type: 'CHANGE_MAP_FOCUS', payload: { message }})

       // move to mqtt
       // dispatch({type: 'ADD_SELECTED_PLACES', payload: {
       //      ...message,
       //      id: message.id,
       //      name: message.message,
       //      objectType: 'place',
       //  }});


      client.subscribe(`focusMap`, { qos: 1 }, (error) => {});

      // client.publish('focusMap', JSON.stringify(message));
      client.publish('focusMap', JSON.stringify({type: 'ADD_SELECTED_PLACES', payload: {
              ...message,
              id: message.id,
              name: message.message,
              objectType: 'place',
          }}));

      return;
    }


    if(message.messageType === 'Event') {
        setEventModalOpen(true);
        return;
    }
    if(message.messageType === 'gps-report') {
        showGPSHistorical(message.data.deviceKey, message.data.reportingStart, message.data.reportingEnd);
    }

    if(message.messageType === 'gps') {
        // showGPSHistorical(message.data.deviceKey, message.data.reportingStart, undefined, true);
        // dispatch({type: 'ADD_SELECTED_PLACES', payload: {
        //         ...message,
        //         id: message.id,
        //         name: message.message,
        //         objectType: 'user',
        //     }})

        client.subscribe(`focusMap`, { qos: 1 }, (error) => {});

        client.publish('focusMap', JSON.stringify({type: 'ADD_SELECTED_PLACES', payload: {
                ...message,
                id: message.id,
                name: message.message,
                objectType: 'user',
            }}));

        return;
    }
    if(message.messageType === 'rx-iot') {
        setRxSensorDevice(true)
    }
    if(message.messageType === 'summary') {
        window.open(`https://first-shared.beam.live/?pageId=${message.data.pageId}`, '_blank')
    }
      if(message.messageType === 'video-recorder') {
          setCameraVideo(true)
      }
  }
 // in case of new attributes, store them from useMQTTService()
  return (
    <div className="message-item">
      <div className="user-info">
        <div className="d-flex">
          <img src="/images/users/user.png" className="user-avatar" />
          <h4>{username}</h4>
          <span>{message.createdAt ? moment(message.createdAt).format('ddd DD HH:mm') : '11:15:48'}</span>
        </div>
        <div className="d-flex">
          {message.messageType !== 'text' &&
            <img onClick={handleIconClick} className="ml-2 message-type cursor-pointer" src={`/images/${message.messageType}-icon.png`} />
          }
          <Dropdown overlay={infoMenu} className="message-dropdown" placement="bottomRight">
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              Info
            </a>
          </Dropdown>
          <Dropdown overlay={editMenu} className="message-dropdown" placement="bottomRight">
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              Edit
            </a>
          </Dropdown>
        </div>
      </div>


        <p>
            {message.message}
        </p>

        {message.messageType === 'place' &&
            <span className="sub-text">
                <p>Address: {message.data.address}</p>
            </span>
        }

        {message.messageType === 'gps-report' &&
            <span className="sub-text">
                <p>Start date: {new Date(message.data.reportingStart).toLocaleString()}</p>
                <p>End date: {new Date(message.data.reportingEnd).toLocaleString()}</p>
            </span>
        }

        {message.messageType === 'gps' &&
            <span className="live-mode">
                <p>Live Mode: on</p>
            </span>
        }

        {message.messageType === 'media' &&
            <Image
                width={125}
                src={`${process.env.REACT_APP_MEDIA_UPLOAD_API_URI}/${message.data.filename}`}
            />
        }

      {/* <span className="message-location">
        <MapPreview />
      </span> */}
      {rsvpModalOpen && <RSVPRespModal onClose={() => setRSVPModalOpen(false)} rsvp={message.data} /> }
      {mapPreviewOpen && <MapPreviewModal onClose={() => setMapPreviewOpen(false)} location={message.data} /> }
      {eventModalOpen && <AddEventModal onClose={() => setEventModalOpen(false)} eventObj={message.data} /> }
      {rxSensorDevice && <RxSensorTableModal onClose={() => setRxSensorDevice(undefined)} sensorObj={message.data} /> }
      {cameraVideo && <CameraVideoModal onClose={() => setCameraVideo(undefined)} videoObj={message.data} /> }
    </div>
  )
}

export default MessageItem;
