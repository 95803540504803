export const GET_LIST = 'GET_LIST'
export const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS'
export const GET_LIST_FAILURE = 'GET_LIST_FAILURE'
export const GET_LOGIN_ERROR = 'GET_LOGIN_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const SHOW_PROGRESS = 'SHOW_PROGRESS'
export const HIDE_PROGRESS = 'HIDE_PROGRESS'
export const LOGOUT_USER = 'LOGOUT_USER'
export const SET_USER_INFO = 'SET_USER_INFO'
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
export const RESET_REGISTRATION_SUCCESS = 'RESET_REGISTRATION_SUCCESS'
export const SET_USERS_LIST = 'SET_USERS_LIST'
export const RESET_EMAIL_SENT = 'RESET_EMAIL_SENT'
export const PASSWORD_RESET_SUCCESSFULLY = 'PASSWORD_RESET_SUCCESSFULLY'
export const RESET_REDIRECT_STATE = 'RESET_REDIRECT_STATE'
