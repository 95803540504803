
const initialState = {
	// searchResult: [{
	// 	pageId: 1,
	// 	title: 'Emergency Page Name',
	// 	date: '03/02/21 11:33 PM',
	// 	icon: '',
	// 	number: '923122331111',
	// 	lastMessage: 'Last message long text here',
	// 	count: 15,
	// 	type: 'orange'
	// },
	// {
	// 	pageId: 2,
	// 	title: '03/02/21 - 11:33 PM',
	// 	date: '03/02/21 11:33 PM',
	// 	icon: '',
	// 	number: '923122331111',
	// 	lastMessage: 'last message',
	// 	count: 2,
	// 	type: 'green'
	// },
	// {
	// 	pageId: 3,
	// 	title: 'Very Long Title Lorem Ipsum Lorem ipsum',
	// 	date: '03/02/21 11:33 PM',
	// 	icon: '',
	// 	number: undefined,
	// 	lastMessage: 'last message',
	// 	count: 12,
	// 	type: 'blue'
	// },
	// {
	// 	pageId: 4,
	// 	title: 'Very Long Title Lorem Ipsum Lorem ipsum',
	// 	date: '03/02/21 11:33 PM',
	// 	icon: '',
	// 	number: '923122331111',
	// 	lastMessage: 'last message',
	// 	count: 10,
	// 	type: 'blue'
	// }],
	searchResult: [],
	selectedItem: {
		// id: 1,
		// title: 'Emergency Page Name',
		// date: '03/02/21 11:33 PM',
		// icon: '',
		// number: '923122331111',
		// lastMessage: 'Last message long text here',
		// count: 15,
		// type: 'orange'
	},
	twilioToken: '',
	mapType: 'PAGE'
}

const getSearch = (state, action) => ({
	...state,
	searchLoading: true
})
const getSelectedSearchItem = (state, action) => ({
	...state,
	selectedItem: action.payload
})
const getSearchResults = (state, action) => ({
	...state,
	searchResult: action.payload,
	selectedItem: action.payload.length > 0 ? state.selectedItem.pageId ? action.payload.find(item => item.pageId === state.selectedItem.pageId) :  action.payload[0] : {}
})

const searchReducer = (state = initialState, action) => {
	console.log(action);
	switch (action.type) {
		case 'GET_SEARCH': return getSearch(state, action)
		case 'SET_SELECTED_ITEM': return getSelectedSearchItem(state, action)
		case 'SET_SEARCH_RESULT': return getSearchResults(state, action)
		case 'SET_MAP_TYPE': return {...state, mapType: action.payload}
		case 'SET_TWILIO_TOKEN': return {...state, twilioToken: action.payload}
		default: return state
	}
}

export default searchReducer
