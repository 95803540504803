import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import _ from "lodash";
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';


function CallBoxModal(props) {
    const { client, isModalVisible, setIsModalVisible } = props;
    const [ phoneNumber, setPhoneNumber] = useState('');
    
    const handleChange = (num) => {
        if (num)
            setPhoneNumber(`${phoneNumber}${num}`)
        else
            setPhoneNumber(phoneNumber.slice(0, -1))
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = (users) => {
        setIsModalVisible(false);
    }

    return (
        <Modal title="" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='380px'
            className="beam-modal call-box-modal"
            footer={[]}
            closable={false}
            destroyOnClose={true}
        >
            <Row className="d-flex mt-1 mb-4">
                <Col><h3>Calls</h3></Col>
            </Row>
            <Row className="d-flex mt-4">
                <Col span={24}><Input className="beam-input" value={phoneNumber}/></Col>
            </Row>
            <Row className="d-flex mt-4">
                { _.times(9, (idx) => 
                    <Col span={8} className="d-flex mb-3">
                        <Button className="beam-btn round-btn m-auto mb-4" key={idx} onClick={() => handleChange(idx+1)}>{idx+1}</Button>
                    </Col>
                )}
            </Row>
            <Row className="d-flex">
                <Col span={8} className="d-flex mb-3">
                    <Button className="beam-btn round-btn m-auto mb-4" 
                        onClick={() => handleChange('*')}
                        style={{lineHeight: 1.6, fontSize: 48}}
                    >*</Button>
                </Col>
                <Col span={8} className="d-flex mb-3"><Button className="beam-btn round-btn m-auto mb-4" onClick={() => handleChange('0')}>0</Button></Col>
                <Col span={8} className="d-flex mb-3"><Button className="beam-btn round-btn m-auto mb-4" onClick={() => handleChange('#')}>#</Button></Col>
            </Row>
            <Row className="d-center mt-3">
                <Col span={8}></Col>
                <Col span={8} className="d-flex">
                    <Button className="beam-btn round-btn cover green-btn call-btn m-auto"><ReactSVG src="/images/call-icon.svg" /></Button>
                </Col>
                <Col span={8} className="d-flex"><Button className="beam-btn m-auto" onClick={() => handleChange(null)}>
                    <ArrowLeftOutlined />
                </Button></Col>
            </Row>
            <Row className="action-grp mt-5">
                <Col span={24}>
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Close</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default CallBoxModal;
