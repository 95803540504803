import React, { useState } from "react";

export default ({ color = "#91A5C6" }) => {
  const displayColor = 'color';
  const [isHovered, setHover] = useState(false);
  return (
    <svg
      onMouseEnter={() => setHover(true)} 
      onMouseLeave={() => setHover(false)} 
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={isHovered ? '#508AFA': (color || '#91A5C6')}
        d="M20.6538 13.1917L19.368 12.2126C18.9943 11.9281 18.8105 11.4688 18.8105 10.9992V10.9992V10.9992C18.8105 10.5298 18.9946 10.0701 19.3681 9.78585L20.6555 8.80586C21.0064 8.53586 21.1022 8.05668 20.8789 7.65754L18.9157 4.35C18.706 3.97668 18.2465 3.785 17.7801 3.9525L16.2576 4.54769C15.8173 4.71983 15.3193 4.64829 14.9081 4.41496V4.41496C14.485 4.17492 14.172 3.77517 14.1011 3.29398L13.8761 1.76668C13.8205 1.33 13.4303 1 12.969 1H9.03253C8.57126 1 8.18191 1.33 8.12712 1.76L7.90137 3.29442C7.83046 3.77645 7.52954 4.1713 7.10606 4.41222V4.41222C6.69673 4.6451 6.18933 4.72214 5.75073 4.55064L4.21885 3.95168C3.80034 3.79418 3.29972 3.97082 3.09177 4.3425L1.12611 7.65418C0.894209 8.03586 0.990041 8.53168 1.34862 8.80836L2.63507 9.78795C3.00838 10.0722 3.19194 10.5308 3.19194 11V11V11C3.19194 11.4698 3.00812 11.9288 2.63434 12.2133L1.34689 13.1934C0.996018 13.4642 0.901028 13.9434 1.12438 14.3417L3.08752 17.6492C3.2972 18.0217 3.75245 18.215 4.22311 18.0467L5.74547 17.4515C6.18592 17.2794 6.68361 17.3504 7.09488 17.5839V17.5839C7.51748 17.8237 7.8304 18.2236 7.90123 18.7043L8.12627 20.2317C8.18187 20.67 8.57126 21 9.03337 21H12.9699C13.4311 21 13.8214 20.67 13.8761 20.24L14.1019 18.7057C14.1728 18.224 14.4729 17.8295 14.8961 17.5887V17.5887C15.306 17.3554 15.8143 17.278 16.2535 17.4498L17.7844 18.0483C17.8922 18.0891 18.0043 18.11 18.1199 18.11C18.4519 18.11 18.7574 17.9333 18.9114 17.6583L20.8831 14.3333C21.1022 13.9433 21.0063 13.4642 20.6538 13.1917ZM11.0008 14.3333C9.11296 14.3333 7.57771 12.8383 7.57771 11C7.57771 9.16168 9.11296 7.66668 11.0008 7.66668C12.8886 7.66668 14.4238 9.16168 14.4238 11C14.4238 12.8383 12.8886 14.3333 11.0008 14.3333Z"
        strokeWidth="2"
      />
    </svg>
  );
};
