import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { 
  Form,
  Input,
  Button,  
} from 'antd';

function ChangePin() {

    const [error, setError] = useState(null);
    const [changePinForm] = Form.useForm();
    const history = useHistory();

    const onSubmitHandler = (values) => {
        console.log(values)
    };

    return (
        <div className="page-container auth-page">
            <Form 
                className="auth-form"
                name="changePin-form"
                id="changePin-form"
                layout='vertical'
                form={changePinForm}
                onFinish={onSubmitHandler}
                requiredMark={false}
            >
                <img alt='1' src='/images/back.png' className="back-btn" onClick={() => history.goBack()}/>
                <h3>Change PIN</h3>
                <p>Please enter your data here</p>
                <Form.Item name="vCode" rules={[{ required: true, message: '**Required Field' }]}>
                    <Input type="text" placeholder="PIN Reset Verification Code" />
                </Form.Item>
                <Form.Item>
                    <p className="info-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis.</p>
                </Form.Item>
                <Form.Item name="pin" rules={[{ required: true, message: '**Required Field' }]}>
                    <Input type="text" placeholder="New PIN" />
                </Form.Item>
                {error && <Form.Item><p className="error">{ error }</p></Form.Item>}
                <Form.Item>
                    <Button type="primary" htmlType="submit" onClick={() => setError('')}>Change PIN</Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default ChangePin;
