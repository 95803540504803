import { all, call, put, takeLatest, takeEvery} from 'redux-saga/effects'
import { pushNotification } from 'utils/notifications'
import {
  getPosts,
  getUserInfo,
  loginUser,
  registerUser,
  updateUserInfo,
  getUsersList,
  uploadMedia,
  requestResetPassword,
  resetPassword,
  getUserAccount,
  modifyIds,
  authenticate
} from "../services/apis/collections/oAuthApis";
import { LOGIN_USER, REGISTER_USER, REQUEST_VERIFICATION_CODE, GET_USER_INFO, UPDATE_USER_INFO, UPLOAD_MEDIA, AUTHENTICATE_USER } from 'reducers/authReducer';
import { getListSuccess, getLoginError } from 'actions/Auth';
import { GET_LIST, LOGIN_SUCCESS } from 'actions/Auth/actionTypes';
import { GET_USERS_LIST } from 'reducers/authReducer';
import { RESET_PIN } from 'reducers/authReducer';
import { GET_USER_ACCOUNT } from 'reducers/authReducer';
import { ADD_IDENTIFIER } from 'reducers/authReducer';
import { UPDATE_IDENTIFIER } from 'reducers/authReducer';
import { SHOW_PROGRESS } from 'actions/Auth/actionTypes';
import { HIDE_PROGRESS } from 'actions/Auth/actionTypes';
import { DELETE_IDENTIFIER } from 'reducers/authReducer';

function* getJsonData(action) {
  try {
    debugger
    const response = yield call(getPosts)
    if (response.data) {
      pushNotification('Get data success', 'success', 'TOP_CENTER', 1000)
      yield put(getListSuccess(response.data))
    }
  } catch (error) {
    pushNotification('Get data failure', 'error', 'TOP_CENTER', 1000)
    yield put(getLoginError(),error)
  }
}

function* loginUserAPI(action) {
  console.log(action);
  const uuid = localStorage.getItem('UUID');
  const {registerRequest, email, phone, password, verificationCodePhone} = action.payload;
  try {
    const data = {
      userIds: [
        ... registerRequest ?  [{
          phone: phone.trim()
        }]:[],
        ... action.payload.email.includes('@') ? [{
          email: email.trim()
        }] :
        [{
          phone: email.trim()
        }]
      ],
      deviceIds: [
        {
          udid: uuid,
        }
      ],
      authTypeParams: [
        ... registerRequest ? [
          {
            typeId: 2,
            param: {
              password: verificationCodePhone
            }
          }
        ] : [],
        ... [{
          typeId: 2,
          param: {
            password
          }
        }]
      ]
    }
    console.log(JSON.stringify({data}))
    yield put({type: SHOW_PROGRESS})
    const response = yield call(loginUser, {data})
    console.log(response);
    // "data": {
    //   "authCode": 0, // to be checked here 0 means success
    //   "authentications": [
    //     {
    //       "cudbId": 2,
    //       "status": "ok",
    //       "rootUserId": 68,
    //       "authCount": 1,
    //       "localUserId": 59,
    //       "permissionTypeIds": [],
    //       "errors": [],
    //       "connectionId": null
    //     },
    console.log(response.data.authCode)
    const [ responseData ] = response.data;
    if (responseData.authCode === 0 ) {
      if( responseData.authentications && responseData.authentications.length > 0 ) {
        const [authData] = responseData.authentications
        console.log(authData);
        localStorage.setItem('TOKEN', authData.connectionId);
        localStorage.setItem('CID', authData.connectionId);
        localStorage.setItem('RID', responseData.userId);
        localStorage.setItem('UID', authData.localUserId);
        // localStorage.setItem('USER', JSON.stringify(response.meta_data.user));
        pushNotification('User logged in', 'success', 'TOP_CENTER', 1000)
        yield put({ type: LOGIN_SUCCESS });
      } else {
        yield put({type: HIDE_PROGRESS})
        pushNotification('User not found', 'error', 'TOP_CENTER', 1000)
      }
    } else if (responseData.authCode === 1) { // redirect user to verify pin
      yield put({type: 'REGISTRATION_SUCCESS', payload: { success: true, email: action.payload.email }})
    } else if (responseData.authCode === 3 || responseData.authCode === 4 ) {
      const payload = {
        ... action.payload.email.includes('@') ? {
          email: action.payload.email.trim()
        } :
        {
          phone: action.payload.email.trim()
        },
        password: action.payload.password,
        reRegisteration: true,
      }
      console.log(payload)
      yield call(registerUserApi, {payload})
    } else {
      yield put({type: HIDE_PROGRESS})
      pushNotification('Email or password is incorrect', 'error', 'TOP_CENTER', 1000)
      // yield put(getLoginError(),response)
    }
  } catch (error) {
    console.log(error);
    yield put({type: HIDE_PROGRESS})
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
    // yield put(getLoginError(),error)
  }
}

function* authenticateUserAPI(action) {
  console.log(action);
  const uuid = localStorage.getItem('UUID');

  try {
    const data = {
      userIds: [
        ... action.payload.email && action.payload.email !== "" ?  [{
          email: action.payload.email.trim()
        }]: [],
        ... action.payload.phoneNumber && action.payload.phoneNumber !== "" ?  [{
          phone: action.payload.phoneNumber.trim()
        }]: [],
      ],
      deviceIds: [
        {
          udid: uuid,
        }
      ],
      authTypeParams: [
        {
          typeId: 9,
          param: {
            password: action.payload.password
          }
        }
      ]
    }
    yield put({type: SHOW_PROGRESS})
    const response = yield call(authenticate, {data})
    console.log(response);
    const [responseData]= response.data;
    if (responseData.authCode === 0 ) {
      yield put({type: 'AUTHENTICATION_SUCCESSFULL'})
    } else {
      yield put({type: 'AUTHENTICATION_FAILED'})
      pushNotification('Verification Code incorrect', 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    console.log(error);
    yield put({type: 'AUTHENTICATION_FAILED'})
    pushNotification('Verification Code incorrect', 'error', 'TOP_CENTER', 1000)
    // yield put(getLoginError(),error)
  }
}

function* registerUserApi(action) {
  console.log(action)
  const uuid = localStorage.getItem('UUID');
  try {
    const data = {
      userIds: [
        ... action.payload.email && action.payload.email !== "" ?  [{
          email: action.payload.email.trim()
        }]: [],
        ... action.payload.phoneNumber && action.payload.phoneNumber !== "" ?  [{
          phone: action.payload.phoneNumber.trim()
        }]: [],
      ],
      deviceIds: [
        {
          udid: uuid
        }
      ],
      authTypeParams: [
        {
          typeId: 2,
          param: {
            password: action.payload.password,
            useMultiOtp: true,
            generatePassword: false,
            verificationChannels: [
              ... action.payload.email && action.payload.email !== "" ?  [{
                email: action.payload.email.trim()
              }]: [],
              ... action.payload.phoneNumber && action.payload.phoneNumber !== "" ?  [{
                phone: action.payload.phoneNumber.trim()
              }]: [],
            ]
          }
        }
      ]
    }
    yield put({type: SHOW_PROGRESS})
    const response = yield call(registerUser, {data})
    console.log(response);

    if (response && response.data) {
      yield put({type: 'REGISTRATION_SUCCESS', payload: {success: true, email: action.payload.email || action.payload.phoneNumber, phone: action.payload.phoneNumber}})
      const [dataObject] = response.data;
      if (dataObject.userId) {
        localStorage.setItem('RID', dataObject.userId)
        // const data = {
        //   userId: response.data.localUserId,
        //   operation: "replace",
        //   path: "$",
        //   value: userData
        // }
        if(!action.payload.reRegisteration)
          yield call(updateUserInfoApi, {payload: {firstName: action.payload.firstName, lastName: action.payload.lastName, registerRequest: true, email: action.payload.email, phone: action.payload.phoneNumber}})
      }
      pushNotification('Verification Code Sent', 'success', 'TOP_CENTER', 1000)
    } else {
      yield put({type: HIDE_PROGRESS})
      pushNotification(response, 'error', 'TOP_CENTER', 1000)
      // yield put(getLoginError(),response)
    }
  } catch (error) {
    console.log(error);
    yield put({type: HIDE_PROGRESS})
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
    // yield put(getLoginError(),error)
  }
}

function* requestVerificationCodeAPI(action) {
  let data = {};
  console.log(action.payload)
  const uuid = localStorage.getItem('UUID');
  if(action.payload.pinVerification) {//action.payload.pinVerification
    let verificationChannels = [];
    if (action.payload.newIdentifier) {
      verificationChannels = [
        action.payload.newIdentifier.includes('@') ? {
          email: action.payload.newIdentifier.trim()
        } :
        {
          phone: action.payload.newIdentifier.trim()
        }
      ]
    } else {
      verificationChannels = [
        ... action.payload.email && action.payload.email !== "" ?  [{
          email: action.payload.email.trim()
        }]: [],
        ... action.payload.phone && action.payload.phone !== "" ?  [{
          phone: action.payload.phone.trim()
        }]: [],
      ]
    }
    data = {
      userIds: [
        ... action.payload.email && action.payload.email !== "" ?  [{
          email: action.payload.email.trim()
        }]: [],
        ... action.payload.phone && action.payload.phone !== "" ?  [{
          phone: action.payload.phone.trim()
        }]: [],
      ],
      deviceIds: [
        {
          udid: uuid
        }
      ],
      authTypeParams: [{
        typeId: 9,
        param: {
          verificationChannels
        }
      }]
    }
  } else {
    data = {
      userIds:[
        ... action.payload.email && action.payload.email !== "" ?  [{
          email: action.payload.email.trim()
        }]: [],
        ... action.payload.phone && action.payload.phone !== "" ?  [{
          phone: action.payload.phone.trim()
        }]: [],
      ],
      deviceIds: [
        {
          udid: uuid
        }
      ],
      authTypeParams:[
        {
          typeId:9,
          param:{
            verificationChannels:[
              action.payload.email.includes('@') ? {
                email: action.payload.email.trim()
              } :
              {
                phone: action.payload.email.trim()
              }
            ]
          }
        }]
    }
  }
  try {
    console.log(data)
    yield put({type: SHOW_PROGRESS})
    const response = yield call(authenticate, {data})
    console.log(response);
    if (response && response.status) {
      if(action.payload.pinVerification) {
        yield put({type: 'VERIFICATION_CODE_SENT', payload: action.payload})
      }
      yield put({type: 'RESET_EMAIL_SENT', payload: action.payload})
    } else {
      yield put({type: HIDE_PROGRESS})
      if(action.payload.pinVerification) {
        pushNotification('Verification Failed', 'error', 'TOP_CENTER', 1000)
        yield put({type: 'VERIFICATION_FAILED', payload: action.payload})
      }
      pushNotification(response, 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    yield put({type: HIDE_PROGRESS})
    if(action.payload.pinVerification) {
      yield put({type: 'VERIFICATION_FAILED', payload: action.payload})
    }
    console.log(error);
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}

function* resetPasswordAPI(action) {
  const uuid = localStorage.getItem('UUID');
  try {
    const data = {
      userIds:[
        action.payload.rootUserId ? {
          rootUserId: action.payload.rootUserId
        } :
        action.payload.email.includes('@') ? {
          email: action.payload.email.trim()
        } :
        {
          phone: action.payload.email.trim()
        }
      ],
      deviceIds: [
        {
          udid: uuid,
        }
      ],
      authTypeParams: [
        {
          typeId: action.payload.rootUserId ? 2 : 9,
          param: {
            password: action.payload.password,
            
          }
        }
      ],
      newAuthTypeParam:
        {
          typeId:2,
          param:{
            password: action.payload.newPassword,
          }
        }
    }
    yield put({type: SHOW_PROGRESS})
    const response = yield call(resetPassword, {data})
    const  [responseData] = response.data;
    if (responseData && responseData.authCode === 0) {
      pushNotification(response.message, 'success', 'TOP_CENTER', 1000)
      yield put({type: 'PASSWORD_RESET_SUCCESSFULLY', payload: true})
    } else {
      yield put({type: HIDE_PROGRESS})
      pushNotification(response.message, 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    yield put({type: HIDE_PROGRESS})
    console.log(error);
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}

function* getAddIdentifierAPI(action) {
  const uuid = localStorage.getItem('UUID');
  const rootUserId = localStorage.getItem('RID');
  try {
    const data = {
      userIds:[
        {
          rootUserId: rootUserId
        }
      ],
      deviceIds: [
        {
          udid: uuid,
        }
      ],
      operations: {
        userIds: {
          add: [
            action.payload.email ? {
              email: action.payload.email.trim()
            } :
            {
              phone: action.payload.phone.trim()
            }
          ]
        }
      }
    }
    const response = yield call(modifyIds, {data})
    console.log(response);
    if (response.status) {
      pushNotification(response.message, 'success', 'TOP_CENTER', 1000)
      yield put({type: 'IDENTIFIER_ADDED', payload: true})
    } else {
      yield put({type: HIDE_PROGRESS })
      pushNotification(response, 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    console.log(error);
    yield put({type: HIDE_PROGRESS })
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}

function* getDeleteIdentifierAPI(action) {
  const uuid = localStorage.getItem('UUID');
  const rootUserId = localStorage.getItem('RID');
  try {
    const data = {
      userIds:[
        {
          rootUserId: rootUserId
        }
      ],
      deviceIds: [
        {
          udid: uuid,
        }
      ],
      operations: {
        userIds: {
          remove: [
            action.payload.email ? {
              email: action.payload.email.trim()
            } :
            {
              phone: action.payload.phone.trim()
            }
          ]
        }
      }
    }
    const response = yield call(modifyIds, {data})
    console.log(response);
    if (response.status) {
      // pushNotification(response.message, 'success', 'TOP_CENTER', 1000)
      yield put({type: HIDE_PROGRESS})
    } else {
      yield put({type: HIDE_PROGRESS})
      pushNotification(response, 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    console.log(error);
    yield put({type: HIDE_PROGRESS})
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}

function* getUpdateIdentifierAPI(action) {
  console.log(action)
  const uuid = localStorage.getItem('UUID');
  const rootUserId = localStorage.getItem('RID');
  try {
    const data = {
      userIds:[
        {
          rootUserId: rootUserId
        }
      ],
      deviceIds: [
        {
          udid: uuid,
        }
      ],
      authTypeParams: [
        {
          typeId: 2,
          param: {
            password: action.payload.password,
          }
        }
      ],
      operations: {
        userIds: {
          update: [
            action.payload.email ? {
              email: action.payload.email
            } :
            {
              phone: action.payload.phone
            }
          ]
        }
      }
    }
    const response = yield call(modifyIds, {data})
    console.log(response);
    if (response.status) {
      pushNotification(response.message, 'success', 'TOP_CENTER', 1000)
      yield put({type: 'ACTION_SUCCESS', payload: true})
    } else {
      yield put({type: 'ACTION_SUCCESS', payload: true})
    }
  } catch (error) {
    console.log(error);
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}

function* getUserInfoAPI(action) {
  const uid = localStorage.getItem('RID');
  try {
    const data = {
      objectId: uid,
      paths: [
        "$"
      ],
      returnPaths: [
        "$"
      ],
      resultType: "value"
    }
    const response = yield call(getUserInfo, {data})
    console.log(response);
    if (response && response.data) {
      const user = response.data[0][0].live;
      console.log(user)
      if(user.email)
        localStorage.setItem('email', user.email);
      if(user.phone)
        localStorage.setItem('phone', user.phone);
      yield put({type: 'SET_USER_INFO', payload: user})
    } else {
      pushNotification(response, 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    console.log(error);
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}


function* getUserAccountAPI(action) {
  const rootUserId = localStorage.getItem('RID');
  try {
    const data = {
      rootUserId: rootUserId
    }
    const response = yield call(getUserAccount, {data})
    console.log(response);
    if (response && response.status) {
      const {user} = response.data;
      const userIdentifiers = {};
      if(user && user.alternativeIds) {
        const alternativeIds = user.alternativeIds;
        alternativeIds.map(obj => {
          userIdentifiers[Object.keys(obj)[0]] = obj[Object.keys(obj)[0]];
        });
        console.log(userIdentifiers)
      }
      yield put({type: 'SET_USER_IDENTIFIERS', payload: userIdentifiers})
    } else {
      pushNotification(response, 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    console.log(error);
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}

function* updateUserInfoApi(action) {
  const uuid = localStorage.getItem('UUID');
  let live = {};
  Object.keys(action.payload).map(att => live[att] = action.payload[att])
  delete live['registerRequest'];
  delete live['silentRequest'];
  
  try {
    const data = {
      header: {
        accessToken: "",
        connectionId: localStorage.getItem('CID'),
        rootUserId: localStorage.getItem('RID'),
        udid: ""
      },
      objectId: localStorage.getItem('RID'),
      operation: "replace",
      path: "$",
      value: {
        live
      }
    }
    yield put({type: SHOW_PROGRESS})
    const response = yield call(updateUserInfo, {data})
    console.log(response);
    if (response && response.status) {
      yield put({type: HIDE_PROGRESS})
      if (!action.payload.registerRequest && !action.payload.silentRequest) {
        pushNotification('User updated successfully', 'success', 'TOP_CENTER', 1000)
      }
      const response = yield call(getUserInfoAPI, {data})
    } else {
      yield put({type: HIDE_PROGRESS})
      pushNotification(response, 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    console.log(error);
    yield put({type: HIDE_PROGRESS})
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}

function* getUsersListApi(action) {
  const uuid = localStorage.getItem('UUID');
  try {
    // const data = {
    //   path: "$.live",
    //   pagination: {
    //     offset: 0,
    //     limit: 1000
    //   }
    // }
    const data = {
      paths: [
        "$"
      ],
      returnPaths: [
        "$"
      ],
      pagination: {
        offset: 0,
        limit: 100
      }
    }
    const response = yield call(getUsersList, {connectionId: localStorage.getItem('CID'), data})
    if (response && response.status) {
      console.log(response.data)
      const resolvedData = response.data
        .map(item => ({ id: Object.keys(item)[0], obj: item[Object.keys(item)[0]][0] }))
        .filter(item => item.obj && Object.keys(item.obj).length > 0)
        .map(item => ({ 
          id: item.id, 
          name: item.obj[0].live? item.obj[0].live?.firstName + ' ' + item.obj[0].live?.lastName: '',
          firstName: item.obj[0].live? item.obj[0].live?.firstName : '',
          lastName: item.obj[0].live? item.obj[0].live?.lastName : '',
          gender: item.obj[0].live? item.obj[0].live?.gender : '',
          dob: item.obj[0].live? item.obj[0].live?.dob : '',
          email: item.obj[0].live && item.obj[0]?.live.length > 0 && item.obj[0]?.live?.email,
          phone: item.obj[0].phone
        }))
      console.log(resolvedData)
      yield put({type: 'SET_USERS_LIST', payload: resolvedData})
    } else {
      pushNotification(response, 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    console.log(error);
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}


function* uploadMediaAPI(action) {
  try {
    console.log('action ', action)
    const response = yield call(uploadMedia, action.payload)
    console.log(response);
    if (response && response.data) {
      yield put({type: 'ADD_FILE', payload: response.data})
    } else {
      pushNotification(response, 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    console.log(error);
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}


function* watchGetRequest() {
  yield takeLatest(GET_LIST, getJsonData);
  yield takeEvery(LOGIN_USER, loginUserAPI);
  yield takeEvery(REGISTER_USER, registerUserApi);
  yield takeEvery(RESET_PIN, resetPasswordAPI);
  yield takeEvery(REQUEST_VERIFICATION_CODE, requestVerificationCodeAPI);
  yield takeEvery(GET_USER_INFO, getUserInfoAPI);
  yield takeEvery(GET_USER_ACCOUNT, getUserAccountAPI);
  yield takeEvery(ADD_IDENTIFIER, getAddIdentifierAPI);
  yield takeEvery(UPDATE_IDENTIFIER, getUpdateIdentifierAPI);
  yield takeEvery(UPDATE_USER_INFO, updateUserInfoApi);
  yield takeEvery(GET_USERS_LIST, getUsersListApi);
  yield takeEvery(UPLOAD_MEDIA, uploadMediaAPI);
  yield takeEvery(AUTHENTICATE_USER, authenticateUserAPI);
  yield takeEvery(DELETE_IDENTIFIER, getDeleteIdentifierAPI);
  
}

export default function* sagas() {
  yield all([
    watchGetRequest()
  ])
}
