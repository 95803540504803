import { Badge, Calendar } from 'antd';
import moment from 'moment';
import React from 'react'
import './index.scss'
const events = [
	{date: '2021-12-12'},
	{date: '2021-12-18'},
	{date: '2021-12-11'},
	{date: '2021-12-15'},
	{date: '2021-11-28'},
	{date: '2021-12-21'},
	{date: '2021-12-09'},
]

function dateCellRender(value) {
	return (
		<div className="events">
			{ events.find(event => value.isSame(moment(event.date), 'day')) ? 
				<Badge status="success"/> : <span></span>
			}
		</div>
	);
  }
export default ({ theme }) => {
	
	return (
		<div className="clock">
			<Calendar dateCellRender={dateCellRender} fullscreen={false} />
		</div>);
}