import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, DatePicker, Collapse } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import './index.scss'
import { GET_USER_INFO, UPDATE_USER_INFO } from 'reducers/authReducer';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import UploadFileModal from './UploadFileModal';

const { Panel } = Collapse;


function EditPaymentModal(props) {
    const { onClose } = props;
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(true);
   
    useEffect(() => {
        dispatch(GET_USER_INFO());
    }, [])

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };

    const handleOk = () => {
        setIsModalVisible(false);
        onClose && onClose();
    }

    return (
        <Modal title="Edit Payment" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row>
                <Col span={24}>
                    <Input placeholder="Name" />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={24}>
                    <Input placeholder="Card Number" />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={12}>
                    <div className="datetime-item">
                        <img src="/images/date.png" /><DatePicker placeholder="MM/YY" suffixIcon={<DownOutlined />} />
                    </div>
                </Col>
                <Col span={12} className="pl-2">
                    <div className="datetime-item">
                        <img src="/images/date.png" /><Input className="field-with-image" placeholder="CVC/CVV" />
                    </div>
                </Col>
            </Row>

            <Row className="action-grp mt-4">
                <Col span={14} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full" onClick={handleOk}>Save</Button>
                </Col>
                <Col span={10} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default EditPaymentModal;
