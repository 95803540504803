import { all, call, put, takeLatest, takeEvery} from 'redux-saga/effects'
import { pushNotification } from 'utils/notifications'
import {
  getGPSHistoricalData,
  getRXData,
  getVideoSnapshot,
  getMapPlaces,
  getMapPlacesFilter,
  getLocationProfile
} from "../services/apis/collections/oAuthApis";
import { GET_HISTORICAL_GPS, GET_RX, GET_VIDEO_SNAPSHOT, GET_MAP_PLACES_BOUNDS, GET_MAP_PLACES_FILTER, GET_LOCATION_PROFILES } from 'reducers/dashboardReducer';


function* GetGPSHistoricalDataAPI(action) {
  try {
    const {requestData, live} = action.payload;
    const response = yield call(getGPSHistoricalData, requestData);
    if (response && response.status) {
      yield put({type: 'ADD_GPS', payload: {response, live}})
    } else {
      pushNotification(response, 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    console.log(error);
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}

function* GetMapPlacesDataAPI(action) {
  try {
    const response = yield call(getMapPlaces, action.payload);
    if (response && response.data) {
      yield put({type: 'ADD_MAP_PLACES_BOUNDS', payload: response.data})
    } else {
      pushNotification(response, 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    console.log(error);
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}


function* GetRXDataAPI(action) {
  try {
    const {id} = action.payload;
    const response = yield call(getRXData, id);
    if (response && response.status) {
      yield put({type: 'ADD_RX', payload: response.meta_data})
    } else {
      pushNotification(response, 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    console.log(error);
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}

function* GetVideoSnapshotDataAPI(action) {
  try {
    const {id} = action.payload;
    const response = yield call(getVideoSnapshot, id);
    if (response && response.length > 0) {
      yield put({type: 'ADD_VIDEO_SNAPSHOT', payload: response[0]})
    } else {
      console.log('waiting...');
      yield put({type: 'ADD_VIDEO_SNAPSHOT', payload: {success: false}})
    }
  } catch (error) {
    console.log(error);
    yield put({type: 'ADD_VIDEO_SNAPSHOT', payload: {success: false}})
  }
}

function* GetMapPlacesFilterDataAPI(action) {
  try {
    const response = yield call(getMapPlacesFilter, action.payload);
    if (response && response.data) {
      yield put({type: `ADD_MAP_${action.payload.objectType.toUpperCase()}_FILTER`, payload: response.data})
    } else {
      pushNotification(response, 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    console.log(error);
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}

function* GetLocationProfilesDataAPI(action) {
  try {
    const response = yield call(getLocationProfile, action.payload);
    console.log('location profile response', response)
    if (response && response.data) {
      yield put({type: 'SET_LOCATION_PROFILES', payload: response.data})
    } else {
      pushNotification(response, 'error', 'TOP_CENTER', 1000)
    }
  } catch (error) {
    console.log(error);
    pushNotification(error, 'error', 'TOP_CENTER', 1000)
  }
}

function* watchGetRequest() {
  yield takeEvery(GET_HISTORICAL_GPS, GetGPSHistoricalDataAPI);
  yield takeEvery(GET_RX, GetRXDataAPI);
  yield takeEvery(GET_VIDEO_SNAPSHOT, GetVideoSnapshotDataAPI);
  yield takeEvery(GET_MAP_PLACES_BOUNDS, GetMapPlacesDataAPI);
  yield takeEvery(GET_MAP_PLACES_FILTER, GetMapPlacesFilterDataAPI);
  yield takeEvery(GET_LOCATION_PROFILES, GetLocationProfilesDataAPI);
}


export default function* sagas() {
  yield all([
    watchGetRequest()
  ])
}
