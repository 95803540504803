import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import { useDispatch } from 'react-redux';

const tableRows = [
    {sensor: 'HEART_RATE', value: '94', unit: 'bpm', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'OXYGEN', value: '98', unit: '%', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'BLOOD_PRESSURE', value: '130/90', unit: 'mmHg', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'TEMPERATURE', value: '36.3', unit: 'C', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'STEPS', value: '630', unit: 'Steps', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'DISTANCE', value: '422', unit: 'm', datetime: '26/01/2021 - 20:30:42'},
    {sensor: 'CAL', value: '25', unit: 'calories', datetime: '26/01/2021 - 20:30:42'}
]

const { TextArea } = Input;

function IoTAlertTableModal(props) {
    const { onClose } = props;
    const [isModalVisible, setIsModalVisible] = useState(true);

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };
    const dispatch = useDispatch();
    const handleOk = () => {
        dispatch({type: 'SEND_MESSAGE', payload: {message: 'IoT Item added', type: 'IoT'}})
        setIsModalVisible(false);
    }

    return (
        <Modal title="IoT Alert" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='760px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row className="custom-table mt-4">
                <Col span={24}>
                    <Row className="header">
                        <Col span={6}>Sensor</Col>
                        <Col span={3}>Value</Col>
                        <Col span={3}>Unit</Col>
                        <Col span={3}>Sleeping</Col>
                        <Col span={3}>Seating</Col>
                        <Col span={3}>Walking</Col>
                        <Col span={3}>Exercise</Col>
                    </Row>
                    {
                        tableRows.map((item, idx) => 
                            <Row className="table-row d-flex" idx={idx} style={{ alignItems: 'baseline'}}>
                                <Col span={6}>{item.sensor}</Col>
                                <Col span={3}>{item.value}</Col>
                                <Col span={3}>{item.unit}</Col>
                                <Col span={3}>
                                    <Input type="number" min={0} placeholder="Min" />
                                    <Input type="number" min={0} placeholder="Max" className="mt-1" />
                                </Col>
                                <Col span={3}>
                                    <Input type="number" min={0} placeholder="Min" />
                                    <Input type="number" min={0} placeholder="Max" className="mt-1" />
                                </Col>
                                <Col span={3}>
                                    <Input type="number" min={0} placeholder="Min" />
                                    <Input type="number" min={0} placeholder="Max" className="mt-1" />
                                </Col>
                                <Col span={3}>
                                    <Input type="number" min={0} placeholder="Min" />
                                    <Input type="number" min={0} placeholder="Max" className="mt-1" />
                                </Col>
                            </Row>
                        )
                    }
                </Col>
            </Row>
            <Row className="action-grp mt-4">
                <Col span={14} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full" onClick={handleOk}>Save</Button>
                </Col>
                <Col span={10} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default IoTAlertTableModal;
