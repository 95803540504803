import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, DatePicker, Switch, TimePicker } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import './index.scss'
import { GET_USER_INFO, UPDATE_USER_INFO } from 'reducers/authReducer';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';


function EditNotificationModal(props) {
    const { onClose } = props;
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [notificationTime, setNotificationTime] = useState('Daily');
    const [notificationType, setNotificationType] = useState('Push');
   
    useEffect(() => {
        dispatch(GET_USER_INFO());
    }, [])

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };

    const handleOk = () => {
        setIsModalVisible(false);
        onClose && onClose();
    }

    const handleNotificationTypeClick = (value) => {
        if(notificationType === value) {
            setNotificationType('');
        } else {
            setNotificationType(value)
        }
    }


    return (
        <Modal title="Edit Notification" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row className="d-flex px-2">
                <Col><h5>Type</h5></Col>
                <Col span={4}><Button className={`beam-btn${notificationType==='Push' ? ' green-btn cover': ''}`} onClick={() => handleNotificationTypeClick('Push')}>Push</Button></Col>
                <Col span={4}><Button className={`beam-btn${notificationType==='SMS' ? ' green-btn cover': ''}`} onClick={() => handleNotificationTypeClick('SMS')}>SMS</Button></Col>
                <Col span={4}><Button className={`beam-btn${notificationType==='Email' ? ' green-btn cover': ''}`} onClick={() => handleNotificationTypeClick('Email')}>Email</Button></Col>
            </Row>
            <Row className="d-flex px-2 pt-3">
                <Col><h5>Time</h5></Col>
                <Col span={4}><Button className={`beam-btn${notificationTime==='Instant' ? ' green-btn cover': ''}`} onClick={() => setNotificationTime('Instant')}>Instant</Button></Col>
                <Col span={4}><Button className={`beam-btn${notificationTime==='Hourly' ? ' green-btn cover': ''}`} onClick={() => setNotificationTime('Hourly')}>Hourly</Button></Col>
                <Col span={4}><Button className={`beam-btn${notificationTime==='Daily' ? ' green-btn cover': ''}`} onClick={() => setNotificationTime('Daily')}>Daily</Button></Col>
            </Row>
            {notificationTime === 'Daily' && 
                <Row className="mt-3">
                    <Col span={24} className="datetime-item">
                        <img src="/images/time.png" /><TimePicker showSecond={false} placeholder="Select Daily Time" suffixIcon={<DownOutlined />} />
                    </Col>
                </Row>
            }
            <Row className="action-grp mt-4">
                <Col span={14} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full" onClick={handleOk}>Save</Button>
                </Col>
                <Col span={10} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default EditNotificationModal;
