import React, { useState } from 'react';
import { Input, Button, Modal, Row, Col } from 'antd';
import _ from "lodash";
import MapPreview from 'components/Dashboard/MessagePanel/MapPreview';
import { MapPanel } from 'components/Dashboard';


function MapPreviewModal(props) {
    const { onClose, location } = props;

    const [isModalVisible, setIsModalVisible] = useState(true);

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };
    const handleOk = () => {
        setIsModalVisible(false);
    }

    return (
        <Modal 
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel} 
            width='700px'
            className="preview-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <MapPreview location={location} />
            {/* <MapPanel /> */}
        </Modal>
    )
}

export default MapPreviewModal;
