import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import {  Modal, Row, Col, DatePicker, Collapse } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import './index.scss'
import { GET_USER_INFO, UPDATE_USER_INFO } from 'reducers/authReducer';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import UploadFileModal from './UploadFileModal';
import { 
    Form,
    Input,
    Button,
  } from 'antd';
import { RESET_PIN } from 'reducers/authReducer';
import { GET_USER_ACCOUNT } from 'reducers/authReducer';
import PasswordConfirmation from './PasswordConfirmation';
import { ADD_IDENTIFIER } from 'reducers/authReducer';
import { UPDATE_IDENTIFIER, DELETE_IDENTIFIER } from 'reducers/authReducer';
import VerifyPinModal from './VerifyPinModal';
import { REQUEST_VERIFICATION_CODE } from 'reducers/authReducer';
const { Panel } = Collapse;


function EditPrivacyModal(props) {
    const { onClose } = props;
    const { passwordResetSuccessfull, actionSuccess, userInfo: userIdentifier, identifierAdded, isLoading } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [emailState, setEmailState] = useState({ email: '' });
    const [phoneState, setPhoneState] = useState({ phone: '' });
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [passwordConfirmation, setPasswordConfimation] = useState(false);
    const [verifyPinModalOpen, setVerifyPinModalOpen] = useState(false);
    const [actionType, setActionType] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [changePinForm] = Form.useForm();

    const emailValidationSchema = yup.object().shape({
		email: yup.string().email('Invalid email format').required('Required')
	});

    const phoneValidationSchema = yup.object().shape({
		phone: yup.string().min(10, 'Invalid phone format').required('Required')
	});
    
    useEffect(() => {
        if(userIdentifier) {
            setEmailState({email: userIdentifier.email})
            setPhoneState({phone: userIdentifier.phone})
            setEmail(userIdentifier.email);
            setPhone(userIdentifier.phone);
        }
    }, [userIdentifier])

    useEffect(() => {
        if(identifierAdded) {
            dispatch({type: 'RESET_REDIRECT_STATE'})
            if(actionType === 'email') {
                dispatch(UPDATE_USER_INFO({...userIdentifier, email, silentRequest: true}))
            } else if(actionType === 'phone') {
                dispatch(UPDATE_USER_INFO({...userIdentifier, phone, silentRequest: true}))
            }
        }
    }, [identifierAdded])
    
    // useEffect(() => {
    //     if(userInfo) {
    //         setEmailState({email: userInfo.email})
    //         setPhoneState({phone: userInfo.phone})
    //         setEmail(userInfo.email);
    //         setPhone(userInfo.phone);
    //     }
    // }, [userInfo])

    useEffect(() => {
        dispatch(GET_USER_INFO());
        // dispatch(GET_USER_ACCOUNT());
    }, [])

    useEffect(() => {
        if(actionSuccess) {
            dispatch({type: 'RESET_REDIRECT_STATE'})
            handleCancel();
        }
    }, [actionSuccess])

    useEffect(() => {
        if(passwordResetSuccessfull) {
            dispatch({type: 'RESET_REDIRECT_STATE'})
            handleCancel();
        }
    }, [passwordResetSuccessfull]);

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };

    const handleOk = () => {
        setIsModalVisible(false);
        onClose && onClose();
    }

    const onSubmitHandler = (values) => {
        console.log(values)
        setActionType('')
		dispatch(RESET_PIN({ password: values.password, newPassword: values.newPassword, rootUserId: localStorage.getItem('RID') }));
    };

    const handleEmailSubmit = async (values) => {
        if(userIdentifier.email === values['email']) {
            return;
        }
        setEmail(values['email'])
        setActionType('email')
        const email = localStorage.getItem('email');
        const phone = localStorage.getItem('phone');
        let payload = {pinVerification: true, newIdentifier: values['email']}
        if (email) {
            payload['email'] = email;
        }
        if (phone) {
            payload['phone'] = phone;
        }
		dispatch(REQUEST_VERIFICATION_CODE(payload));
        setVerifyPinModalOpen(true);
    }

    const handlePhoneSubmit = async (values) => {
        if(userIdentifier.phone === values['phone']) {
            return;
        }
        setPhone(values['phone'])
        setActionType('phone')
        const email = localStorage.getItem('email');
        const phone = localStorage.getItem('phone');
        let payload = {pinVerification: true, newIdentifier: values['phone']}
        if (email) {
            payload['email'] = email;
        }
        if (phone) {
            payload['phone'] = phone;
        }
		dispatch(REQUEST_VERIFICATION_CODE(payload));
        setVerifyPinModalOpen(true);
    }

    const handleConfirmedPassword = (password) => {
        if(passwordConfirmation === 'email') {
            handleEmailAddition(password);
        } else {
            handlePhoneAddition(password);
        }
    }

    const handlePhoneAddition = () => {
        console.log(userIdentifier?.phone);
        const oldPhone = localStorage.getItem('phone');
        dispatch(ADD_IDENTIFIER({phone}))
        dispatch(DELETE_IDENTIFIER({phone: oldPhone}))
    }

    const handleEmailAddition = () => {
        console.log(userIdentifier?.email);
        const oldEmail = localStorage.getItem('email');
        dispatch(ADD_IDENTIFIER({email}))
        dispatch(DELETE_IDENTIFIER({email: oldEmail}))
    }
    
    const handlePinVerification = (status) => {
        setVerifyPinModalOpen(false);
        if(status) {
            if(actionType === 'email') {
                handleEmailAddition()
            } else if (actionType === 'phone') {
                handlePhoneAddition()
            }
        }
    }

    return (
        <Modal title="Edit Privacy" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Formik enableReinitialize initialValues={emailState} validationSchema={emailValidationSchema} onSubmit={handleEmailSubmit}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <Row className="mt-3 d-flex">
                        <Col span={19}>
                            <Input onBlur={handleBlur} name="email" value={values['email']} onChange={handleChange} type="email" placeholder="Email" />
                            <span className="error text-danger">
								<small>{errors && touched && touched['email'] && errors['email']}</small>
							</span>
                        </Col>
                        <Col span={4}>
                            <Button loading={isLoading && actionType === 'email'} onClick={handleSubmit} className="beam-btn">{userIdentifier?.email ? 'Update' : 'Add'}</Button>
                        </Col>
                    </Row>
                )}
            </Formik>
            <Formik enableReinitialize initialValues={phoneState} validationSchema={phoneValidationSchema} onSubmit={handlePhoneSubmit}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <Row className="mt-3 d-flex">
                        <Col span={19}>
                            <Input onBlur={handleBlur} name="phone" value={values['phone']} onChange={handleChange} type="text" placeholder="Phone" />
                            <span className="error text-danger">
								<small>{errors && touched && touched['phone'] && errors['phone']}</small>
							</span>
                        </Col>
                        <Col span={4}>
                            <Button loading={isLoading && actionType === 'phone'} onClick={handleSubmit} className="beam-btn">{userIdentifier?.phone ? 'Update' : 'Add'}</Button>
                        </Col>
                    </Row>
                )}
            </Formik>
            <hr className="mb-4 mt-4" />

            <Form
                className="auth-form"
                name="changePin-form"
                id="changePin-form"
                layout='vertical'
                form={changePinForm}
                onFinish={onSubmitHandler}
                requiredMark={false}
            >
                <Form.Item name="password" rules={[{ required: true, message: '**Required Field' }]} className="mt-3">
                    <Input type="password" placeholder="Enter existing PIN" />
                </Form.Item>

                <Form.Item name="newPassword" rules={[{ required: true, message: '**Required Field' }]} className="mt-3">
                    <Input type="password" placeholder="Enter new PIN" />
                </Form.Item>

                <Form.Item
                    name="confirm-pin" 
                    dependencies={['newPassword']}
                    className="mt-3"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your pin!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two pins that you entered do not match!'));
                            },
                        })
                    ]}
                >
                    <Input type="password" placeholder="Verify new PIN" />
                </Form.Item>

                

                <Form.Item className="action-grp mt-4">
                    <Row>
                        <Col span={14} className="pr-2">
                            <Button loading={isLoading && !actionType} className="beam-btn green-btn cover w-full" htmlType="submit">Save</Button>
                        </Col>
                        <Col span={10} className="pl-2">
                            <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
            {
                passwordConfirmation && 
                <PasswordConfirmation onClose={() => setPasswordConfimation(false)} confirmPassword={handleConfirmedPassword} /> 
            }
            {verifyPinModalOpen && <VerifyPinModal data={actionType === 'email' ? email : phone} onClose={()=> setVerifyPinModalOpen(false)} onVerification={handlePinVerification} /> }

        </Modal>
    )
}

export default EditPrivacyModal;
