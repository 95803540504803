import { DatePicker, Dropdown, Menu } from 'antd';
import React, { useRef, useState } from 'react'
import EventCalendarPreview from './EventCalendarPreview';
import './index.scss'

const menu = (
	<Menu>
	  <Menu.Item>
		  	<div className="site-calendar-demo-card"> 
				<EventCalendarPreview />
			</div>
	  </Menu.Item>
	</Menu>
);
export default ({ theme }) => {
	// For digital clock

	const [open, setOpen] = useState(false);
	let time = new Date().toLocaleTimeString();
	let [ctime, setCTime] = useState();
	const date = useRef();
	const updateTime = () => {
		time = new Date().toLocaleTimeString();
		setCTime(time);
	}
	setInterval(updateTime, 1000);
	return (
		<div className="clock">
			<Dropdown overlay={menu} placement="bottomLeft" trigger="click" arrow>
				<span onClick={() => setOpen(false)}>
					{ctime}
				</span>
			</Dropdown>
		</div>);
}