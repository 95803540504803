import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, Upload } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from "lodash";
import { useDispatch } from 'react-redux';
import {UPLOAD_MEDIA} from "../../reducers/authReducer";


function UploadFileModal(props) {
    const { onClose } = props;
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [file, setFile] = useState();


    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };
    const dispatch = useDispatch();
    const handleOk = () => {
        // dispatch({type: 'SEND_MESSAGE', payload: {message: 'Media item added', type: 'media', data: {file}}})
        let form = new FormData()
        form.append('file', file)
        dispatch(UPLOAD_MEDIA(form));
        setIsModalVisible(false);
    }

    const getCurrentLocation = () => {
        return new Promise((resolve, reject)=>{
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                        resolve({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        })
                    }
                );
            } else {
                reject("Geolocation is not supported by this browser.");
            }
        })
    }

    const fileParser = (file) => {
        return new Promise(async (s, r) => {
            const location = await getCurrentLocation();
            const data = {
                filename: file.name,
                size: file.size,
                mimetype: file.type,
                location: location
            };
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                const content = (e.target).result;
                data.content = content;
                s(data);
            };
        });
    }

    return (
        <Modal title="Upload a file"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width='480px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Upload maxCount={1} {...props}
                    beforeUpload={async (file, fileList)=>{
                        setFile(file)
                        // let parsedFile= await fileParser(file)
                        // console.log('parsed file here', parsedFile)
                        // setFile(parsedFile)
                        return false
                    }}
                // action={'http://localhost:5500/upload/media'}
            >
                <div>Drop your file here (or click)</div>
            </Upload>
            <Row className="action-grp mt-4">
                <Col span={14} className="pr-2">
                    <Button className="beam-btn green-btn cover w-full" onClick={handleOk}>Upload</Button>
                </Col>
                <Col span={10} className="pl-2">
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Cancel</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default UploadFileModal;
