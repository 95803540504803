import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { 
  Form,
  Input,
  Button,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN_USER } from 'reducers/authReducer';
import { useHistory } from 'react-router-dom';
import { REGISTER_USER } from 'reducers/authReducer';
import { RESET_REGISTRATION_SUCCESS } from 'actions/Auth/actionTypes';

function Login() {
	const dispatch = useDispatch();
	const history = useHistory();
	const [error, setError] = useState(null);
	const [loginForm] = Form.useForm();
	const { loginSuccessfull, registrationSuccess, registeredEmail, isLoading } = useSelector((state) => state.auth);

	useEffect(() => {
		if (localStorage.getItem('TOKEN')) {
			history.push('/dashboard');
		}
	}, [])
	
	useEffect(() => {
		if(registrationSuccess && registeredEmail) {
			dispatch({type: RESET_REGISTRATION_SUCCESS})
			history.push('/auth/pin/verify');
		}
	}, [registrationSuccess])

	useEffect(() => {
		if (loginSuccessfull) {
			history.push('/dashboard');
		}
		console.log(loginSuccessfull)
	}, [loginSuccessfull])

	const onSubmitHandler = (values) => {
		dispatch(LOGIN_USER({email: values.username, password: values.pin+'', phoneNumber: '+92w3123322112'}));
		console.log(values)
	};
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<Form 
			className="auth-form"
			name="login-form"
			id="login-form"
			layout='vertical'
			form={loginForm}
			onFinish={onSubmitHandler}
			onFinishFailed={onFinishFailed}
			requiredMark={false}
		>
			<h3>Sign in</h3>
			<p>Please enter your data here</p>
			<Form.Item name="username" rules={[{ required: true, message: '**Required Field' }]}>
				<Input type="text" placeholder="Phone or email" />
			</Form.Item>
			<Form.Item name="pin" hasFeedback rules={[{ required: true, message: '**Required Field' }]}>
				<Input type="password"  placeholder="5-Digit Security PIN" />
			</Form.Item>
			{error && <Form.Item><p className="error">{ error }</p></Form.Item>}
			<Form.Item>
				<Button loading={isLoading} type="primary" htmlType="submit">Sign in</Button>
			</Form.Item>
			<Form.Item className="mb-0">
				<p className="option-text">New to Beam? <Link to="/auth/register">Sign Up</Link></p>
				<p className="option-text mb-0">Forgot Security PIN? <Link onClick={() => dispatch({type: 'RESET_EMAIL_SENT', payload: undefined})} to="/auth/pin/reset">Reset PIN</Link></p>
			</Form.Item>
		</Form>
	)
}

export default Login;
