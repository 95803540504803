import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Input, Button, Select, Radio, DatePicker } from "antd";
import { ReactSVG } from 'react-svg';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import _ from "lodash";
import { ContactTracePanel, LogisticsPanel, Nine11Panel, RideDispatchPanel, RxPanel, StorePanel } from '..';

const { Option } = Select;

const { TextArea } = Input;


function RidePanel(props) {
	const {type, screens, setScreens} = props;

	const params = {
    screens,
    setScreens
  }
	
	switch(type) {
		case 'RxPanel':
			return <RxPanel {...params} />
		case 'RideDispatch':
			return <RideDispatchPanel {...params} />
		case 'Store':
			return <StorePanel {...params} />
		case 'Nine11':
			return <Nine11Panel {...params} />
		case 'Logistics':
			return <LogisticsPanel {...params} />
		case 'ContactTracing':
			return <ContactTracePanel {...params} />
		default:
			return <div></div>
	}
	
}

export default RidePanel;
