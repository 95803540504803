import React, { useState } from 'react';

export default ({color = '#91A5C6'}) => {
  const [isHovered, setHover] = useState(false);

  return (
    <svg 
      onMouseEnter={() => setHover(true)} 
      onMouseLeave={() => setHover(false)}
      width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    	<path fillRule="evenodd" clipRule="evenodd"
			 d="M12 24.9884C18.6274 24.9884 24 19.6159 24 12.9884C24 6.36103 18.6274 0.988449 12 0.988449C5.37258 0.988449 0 6.36103 0 12.9884C0 19.6159 5.37258 24.9884 12 24.9884ZM22 12.9884C22 18.5113 17.5228 22.9884 12 22.9884C6.47715 22.9884 2 18.5113 2 12.9884C2 7.4656 6.47715 2.98845 12 2.98845C17.5228 2.98845 22 7.4656 22 12.9884ZM16.591 7.53323C16.7526 6.96819 16.509 6.36565 16 6.0718C15.491 5.77794 14.8474 5.8682 14.4389 6.2907L9.92326 10.9608C9.58097 11.3148 9.33108 11.7476 9.19565 12.2211L7.409 18.4668C7.24737 19.0318 7.49103 19.6343 8 19.9282C8.50897 20.2221 9.15262 20.1318 9.56114 19.7093L14.0767 15.0392C14.419 14.6852 14.6689 14.2524 14.8043 13.7789L16.591 7.53323ZM11.5 13.866C11.0217 13.5899 10.8578 12.9783 11.134 12.5C11.4101 12.0217 12.0217 11.8578 12.5 12.134C12.9783 12.4101 13.1422 13.0217 12.866 13.5C12.5899 13.9783 11.9783 14.1422 11.5 13.866Z" 
			 fill={isHovered ? '#508AFA': (color || '#91A5C6')}/>
    </svg>
  )
}
