import {
  GET_LIST,
  GET_LIST_SUCCESS,
  GET_LIST_FAILURE
} from 'actions/Auth/actionTypes'
import { createAction } from '@reduxjs/toolkit';
import { LOGIN_SUCCESS, REGISTRATION_SUCCESS, RESET_REGISTRATION_SUCCESS } from 'actions/Auth/actionTypes';
import { SET_USER_INFO } from 'actions/Auth/actionTypes';
import { getUserInfo } from 'services/apis/collections/oAuthApis';
import { SET_USERS_LIST, RESET_EMAIL_SENT } from 'actions/Auth/actionTypes';
import { PASSWORD_RESET_SUCCESSFULLY } from 'actions/Auth/actionTypes';
import { SHOW_PROGRESS, RESET_REDIRECT_STATE } from 'actions/Auth/actionTypes';
import { HIDE_PROGRESS } from 'actions/Auth/actionTypes';


export const LOGIN_USER = createAction('loginUser');
export const REGISTER_USER = createAction('registerUser');
export const REQUEST_VERIFICATION_CODE = createAction('forgotPassword');
export const RESET_PIN = createAction('resetPin');
export const LOGOUT_USER = createAction('logoutUser');
export const GET_USER_INFO = createAction('getUserInfo');
export const ADD_IDENTIFIER = createAction('add_identifier');
export const UPDATE_IDENTIFIER = createAction('update_identifier');
export const DELETE_IDENTIFIER = createAction('delete_identifier');
export const GET_USER_ACCOUNT = createAction('getUserAccount');
export const GET_USERS_LIST = createAction('GET_USERS_LIST');
export const UPDATE_USER_INFO = createAction('UPDATE_USER_INFO');
export const UPLOAD_MEDIA = createAction('UPLOAD_MEDIA');
export const AUTHENTICATE_USER = createAction('AUTHENTICATE_USER');

const initialState = {
  getListLoading: false,
  loginSuccessfull: false,
  registrationSuccess: false,
  userInfo: {},
  posts: [],
  resetPinPayload: undefined,
  userIdentifier: {},
  isLoading: false,
  verificationCodeSent: false,
  verificationFailed: false,
  authenticationFailure: false,
  authenticationSuccess: false,
  identifierAdded: false,
}

const getList = (state, action) => ({
  ...state,
  getListLoading: true
})

const getListSuccess = (state, action) => {
  console.log('check the data in reducer', action)
  return ({
    ...state,
    getListLoading: false,
    posts: action.payload,
    passwordResetSuccessfull: false
  })
}

const getListFailed = (state, action) => ({
  ...state,
  getListLoading: false,
  posts: []
})

const getLoginSuccess = (state, action) => ({
  ...state,
  loginSuccessfull: true,
  registeredEmail: '',
  registeredPhone: '',
  isLoading: false,
  passwordResetSuccessfull: false
})

const getLogout = (state, action) => {
  localStorage.removeItem('TOKEN');
  return ({
    ...state,
    loginSuccessfull: false,
    registeredEmail: '',
    registeredPhone: '',
  })
}

const setUserInfo = (state, action) => {
  console.log('hererer');
  return ({
    ...state,
    userInfo: action.payload,
  })
}

const setRegistrationSuccess = (state, action) => {
  return ({
    ...state,
    registrationSuccess: action.payload.success,
    registeredEmail: action.payload.email,
    registeredPhone: action.payload.phone,
    isLoading: false,
    passwordResetSuccessfull: false
  })
}

const resetRegistrationSuccess = (state, action) => {
  return ({
    ...state,
    registrationSuccess: false,
    isLoading: false,
    actionSuccess: false,
  })
}

const setUsersList = (state, action) => {
  return ({
    ...state,
    users: action.payload,
    actionSuccess: false,
  })
}

const setResetEmailPayload = (state, action) => {
  return ({
    ...state,
    resetPinPayload: action.payload,
    passwordResetSuccessfull: false,
    isLoading: false,
    actionSuccess: false,
  })
}

const setPasswordResetSuccess = (state, action) => {
  return ({
    ...state,
    resetPinPayload: undefined,
    passwordResetSuccessfull: true,
    isLoading: false,
    actionSuccess: false,
  })
}

const resetRedirectState = (state, action) => {
  return ({
    ...state,
    resetPinPayload: undefined,
    passwordResetSuccessfull: false,
    verificationCodeSent: false,
    actionSuccess: false,
    isLoading: false,
    verificationFailed: false,
    authenticationFailure: false,
    authenticationSuccess: false,
    identifierAdded: false,
  })
}

const setUserIdentifier = (state, action) => {
  return ({
    ...state,
    userIdentifier: action.payload,
    actionSuccess: false,
  })
}

const setActionSuccess = (state, action) => {
  return ({
    ...state,
    actionSuccess: true,
  })
}

const handleVerificationCodeSent = (state, action) => {
  return ({
    ...state,
    verificationCodeSent: true,
    verificationFailed: false,
    isLoading: false,
  })
}

const handleVerificationFailure = (state, action) => {
  return ({
    ...state,
    verificationFailed: true,
    isLoading: false,
  })
}


const handleAuthenticationSuccess = (state, action) => {
  return ({
    ...state,
    authenticationSuccess: true,
    isLoading: false,
  })
}

const handleAuthenticationFailure = (state, action) => {
  return ({
    ...state,
    authenticationFailure: true,
    isLoading: false,
  })
}

const handleIdentifierAddition = (state, action) => {
  return ({
    ...state,
    identifierAdded: true,
    isLoading: false,
  })
}


const setShowProgress = (state, action) => {
  return ({
    ...state,
    isLoading: true,
  })
}

const setHideProgress = (state, action) => {
  return ({
    ...state,
    isLoading: false,
  })
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST: return getList(state, action)
    case 'loginUser': return ({
      ...state, registrationSuccess: false,
    })
    case GET_LIST_SUCCESS: return getListSuccess(state, action)
    case GET_LIST_FAILURE: return getListFailed(state, action)
    case LOGIN_SUCCESS: return getLoginSuccess(state, action)
    case SET_USER_INFO: return setUserInfo(state, action)
    case REGISTRATION_SUCCESS: return setRegistrationSuccess(state, action)
    case RESET_REGISTRATION_SUCCESS: return resetRegistrationSuccess(state, action)
    case SET_USERS_LIST: return setUsersList(state, action)
    case RESET_EMAIL_SENT: return setResetEmailPayload(state, action)
    case PASSWORD_RESET_SUCCESSFULLY: return setPasswordResetSuccess(state, action)
    case SHOW_PROGRESS: return setShowProgress(state, action)
    case HIDE_PROGRESS: return setHideProgress(state, action)
    case RESET_REDIRECT_STATE: return resetRedirectState(state, action)
    case 'SET_USER_IDENTIFIERS': return setUserIdentifier(state, action)
    case 'ACTION_SUCCESS': return setActionSuccess(state, action)
    case 'VERIFICATION_CODE_SENT': return handleVerificationCodeSent(state, action)
    case 'VERIFICATION_FAILED': return handleVerificationFailure(state, action)
    case 'AUTHENTICATION_SUCCESSFULL': return handleAuthenticationSuccess(state, action)
    case 'AUTHENTICATION_FAILED': return handleAuthenticationFailure(state, action)
    case 'IDENTIFIER_ADDED': return handleIdentifierAddition(state, action)
    
    
    case 'logoutUser': return getLogout(state, action)
    default: return state
  }
}

export default authReducer
