import { PureComponent } from "react";
import PropTypes from "prop-types";
import TwilioVideo from "twilio-video";
import { isEmpty, first } from "lodash";


class AppContainer extends PureComponent {
  static propTypes = {
    render: PropTypes.func.isRequired
  };
  state = {
    videoRoom: null,
    isJoining: false,
    userName: "",
    roomName: "",
    errorMessage: null,
    videoAllowed: true,
  };

  componentDidMount() {
    console.log(this.props);
    const {token, pageId} = this.props;
    
    this.joinRoom(token, pageId);
  }


  joinRoom = async (token, pageId) => {
    const { roomName } = this.state;

    this.setState({ isJoining: true });

    try {
      // const token = await this.getToken();

      // const localVideoTrack = await TwilioVideo.createLocalVideoTrack();
      // this.setState({ localVideoTrack });

      const localAudioTrack = await TwilioVideo.createLocalAudioTrack();
      this.setState({ localAudioTrack });

      const videoRoom = await TwilioVideo.connect(
        token,
        {
          name: pageId,
          tracks: [localAudioTrack],
          insights: false
        }
      );

      videoRoom.on("disconnected", () => {
        this.stopVideoTrack();
        this.stopAudioTrack();
        this.stopScreenTrack();
        const {onRoomLeave} = this.props;
        onRoomLeave();
        this.setState({
          videoRoom: null
        });
      });
      console.log(this.props);
      const {onRoomJoin} = this.props;
      onRoomJoin();
      this.setState({ videoRoom });
    } catch (error) {
      this.stopVideoTrack();
      this.stopAudioTrack();

      this.setState({
        errorMessage: error.message
      });
    }

    this.setState({ isJoining: false });
  };

  leaveRoom = async () => {
    const { videoRoom } = this.state;

    if (videoRoom) {
      videoRoom.disconnect();
    }
  };

  handleMuteChange = async (muted) => {
    const { videoRoom } = this.state;

    if (videoRoom) {
      videoRoom.localParticipant.audioTracks.forEach(function(publication) {
        if (muted) {
          publication.track.disable();
        } else {
          publication.track.enable();
        }
      });
    }
  };

  handleVideoChange = async (paused) => {
    const { videoRoom } = this.state;

    if (videoRoom) {
      console.log(videoRoom.localParticipant);
      if(videoRoom.localParticipant.videoTracks.size === 0) {
        const localVideoTrack = await TwilioVideo.createLocalVideoTrack();
        this.setState({ localVideoTrack });
        if(localVideoTrack) {
          videoRoom.localParticipant.publishTrack(localVideoTrack)
        }
      }
      this.setState({ videoAllowed: true });
      videoRoom.localParticipant.videoTracks.forEach(function(publication) {
        if (paused) {
          publication.track.disable();
        } else {
          publication.track.enable();
        }
      });
      // if(paused) {
      //   videoRoom.localParticipant.unpublishTrack(this.state.localVideoTrack);
      // } else {
      //   videoRoom.localParticipant.publishTrack(this.state.localVideoTrack);
      // }
    }
  };

  stopTrack = trackName => {
    const track = this.state[trackName];

    if (track) {
      track.stop();
      this.setState({ [trackName]: null });
    }
  };

  stopScreenTrack = () => this.stopTrack("screenTrack");

  stopVideoTrack = () => this.stopTrack("localVideoTrack");

  stopAudioTrack = () => this.stopTrack("localAudioTrack");

  shareScreen = async () => {
    try {
      const { videoRoom, localVideoTrack: videoTrack, screenTrack } = this.state;
      let localVideoTrack = videoTrack;
      if(videoRoom.localParticipant.videoTracks.size === 0) {
        localVideoTrack = await TwilioVideo.createLocalVideoTrack();
        this.setState({ localVideoTrack, videoAllowed: false });
        // if(localVideoTrack) {
        //   videoRoom.localParticipant.publishTrack(localVideoTrack)
        // }
      }
      if (!screenTrack) {
        const stream = await navigator.mediaDevices.getDisplayMedia({
          video: true
        });
        const newScreenTrack = first(stream.getVideoTracks());

        this.setState({
          screenTrack: new TwilioVideo.LocalVideoTrack(newScreenTrack)
        });

        videoRoom.localParticipant.publishTrack(newScreenTrack);
        videoRoom.localParticipant.unpublishTrack(localVideoTrack);
      } else {
        videoRoom.localParticipant.unpublishTrack(screenTrack);
        if(this.state.videoAllowed) {
          videoRoom.localParticipant.publishTrack(localVideoTrack);
        }
        this.stopScreenTrack();
      }
    } catch (error) {
      this.stopScreenTrack();

      this.setState({
        errorMessage: error.message
      });
    }
  };

  hideErrorMessage = () => this.setState({ errorMessage: null });

  changeUserName = userName => this.setState({ userName });

  changeRoomName = roomName => this.setState({ roomName });

  render() {
    const { render, pageTitle, pageId, token } = this.props;
    console.log(this.props)
    const {
      videoRoom,
      isJoining,
      userName,
      roomName,
      errorMessage,
      screenTrack
    } = this.state;

    return render({
      videoRoom,
      userName,
      roomName: pageTitle,
      isVideoSupported: TwilioVideo.isSupported,
      isScreenSharingSupported: Boolean(
        navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia
      ),
      isScreenSharingEnabled: Boolean(screenTrack),
      canJoin: !isEmpty(userName) && !isEmpty(roomName),
      isJoining,
      onJoin: this.joinRoom,
      onLeave: this.leaveRoom,
      handleMuteChange: this.handleMuteChange,
      handleVideoChange: this.handleVideoChange,
      onShare: this.shareScreen,
      onRoomNameChange: this.changeRoomName,
      onUserNameChange: this.changeUserName,
      errorMessage,
      onErrorMessageHide: this.hideErrorMessage
    });
  }
}

export default AppContainer;
