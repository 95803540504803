import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { 
  Form,
  Input,
  Button,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_PIN } from 'reducers/authReducer';

function ResetPin() {

    const { resetPinPayload, passwordResetSuccessfull, isLoading } = useSelector((state) => state.auth);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const [changePinForm] = Form.useForm();
    const history = useHistory();
    console.log(resetPinPayload)
    const onSubmitHandler = (values) => {
		dispatch(RESET_PIN({ password: values.password, newPassword: values.newPassword, email: resetPinPayload.email }));
    };

    useEffect(() => {
        if(passwordResetSuccessfull) {
            history.push('/auth/login');
        }
    }, [passwordResetSuccessfull]);
    

    return (
        <div className="page-container auth-page">
            <Form 
                className="auth-form"
                name="changePin-form"
                id="changePin-form"
                layout='vertical'
                form={changePinForm}
                onFinish={onSubmitHandler}
                requiredMark={false}
            >
                <img alt='1' src='/images/back.png' className="back-btn" onClick={() => history.goBack()}/>
                <h3>Reset PIN</h3>
                <p>A verification code was sent to <a href="" onClick={(e)=>e.preventDefault()}><small>{resetPinPayload?.email}</small></a>. Please enter the verification code below.</p>
                <Form.Item name="password" rules={[{ required: true, message: '**Required Field' }]}>
                    <Input type="password" placeholder="PIN reset Verificaiton Code" />
                </Form.Item>
                
                <Form.Item>
                    <p className="info-text">Please enter your new pin here</p>
                </Form.Item>
                <Form.Item name="newPassword" hasFeedback rules={[{ required: true, message: '**Required Field' }]}>
                    <Input type="password" style={{ width: '100%' }} placeholder="New 5-Digit Security PIN" />
                </Form.Item>
                <Form.Item 
                    name="confirm-pin" 
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                            {
                                    required: true,
                                    message: 'Please confirm your pin!',
                            },
                            ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two pins that you entered do not match!'));
                                    },
                            })]}
                    >
                    <Input type="password" style={{ width: '100%' }} placeholder="Confirm PIN" />
                </Form.Item>
                {error && <Form.Item><p className="error">{ error }</p></Form.Item>}
                <Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit" onClick={() => setError('')}>Reset Pin</Button>
                </Form.Item>
                <Form.Item className="mb-0">
                    <p className="option-text">Want to login? <Link to="/auth/login">Login</Link></p>
                    <p className="option-text">Didn't receive verification Code? <Link onClick={() => dispatch({type: 'RESET_EMAIL_SENT', payload: undefined})} to="/auth/pin/resend">Resend</Link></p>
                </Form.Item>
            </Form>
        </div>
    )
}

export default ResetPin;
