import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { interceptor } from "utils/interceptor";
// import HomeContainer from "containers/Home/homeContainer";
// import Layout from "common/Layout";

import AuthContainer from "../Auth";
import TopHeader from "components/TopHeader";
import BottomFooter from "components/Footer";
// import ForgotPassword from "components/PublicComponent/Auth/ForgotPassword/index";
// import RecoverPassword from "components/PublicComponent/Auth/RecoverPassword/index";
import { Layout } from "antd";
import Dashboard from "containers/Dashboard/Dashboard";
import { connect, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { generateUUID } from "utils/appUtil";
import useMQTTService from "hooks/useMQTTService";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Map from "containers/Map/Map";
import Video from "containers/Video/Video";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const { Header, Content, Footer } = Layout

export default () => {

  const history = useHistory();
  const [isLoggedIn, setLoggedIn] = useState();
	const { loginSuccessfull, registeredEmail } = useSelector((state) => state.auth);
  const { client, mqttPublish, connectMQTT } = useMQTTService();
  useEffect(()=>{
    if(!localStorage.getItem('UUID')) {
      localStorage.setItem('UUID', generateUUID());
    }
    return () => {
      if (client) {
        client.end(() => {
          console.log('Ended');
        });
      }
    }
  },[])
  useEffect(()=>{
    setLoggedIn(!!localStorage.getItem('TOKEN'));
  },[isLoggedIn])


	useEffect(() => {
    console.log(loginSuccessfull)
    setLoggedIn(loginSuccessfull)
    if(!loginSuccessfull && !localStorage.getItem('TOKEN') && (!registeredEmail || registeredEmail === '')) {
      history.push('/auth/login')
    } else {
      connectMQTT()
    }
	}, [loginSuccessfull])

  interceptor();

  return (
    <Elements stripe={stripePromise}>
      <Layout>
      {!isLoggedIn &&
        <Header>
          <TopHeader />
        </Header>
      }
      <Content className="site-layout">
        <Switch>
          <Route exact path="/">
            <Redirect to="/auth/login" />
          </Route>
          <Route path="/auth" component={AuthContainer} />
          <Route path="/dashboard"
            render={(props) => (
              <Dashboard {...props} client={client} />
            )}
          />
          <Route path="/page/:pageId"
            render={(props) => (
              <Dashboard {...props} client={client} />
            )}
          />
          <Route path="/map/:pageId"
            render={(props) => (
              <Map {...props} client={client} />
            )}
          />
           <Route path="/video/:pageId"
            render={(props) => (
              <Video {...props} client={client} />
            )}
          />
        </Switch>
      </Content>
      {!isLoggedIn &&
        <Footer>
          <BottomFooter />
        </Footer>
      }

    </Layout>
    </Elements>
  );
}
