import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { 
  Form,
  Input,
  Button,
  Select,
} from 'antd';
import { Option } from 'antd/node_modules/rc-select';
import { REGISTER_USER } from 'reducers/authReducer';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss'
import { RESET_REGISTRATION_SUCCESS } from 'actions/Auth/actionTypes';

function Register() {
	const dispatch = useDispatch();
	const history = useHistory();
	const [error, setError] = useState(null);
	const [registerForm] = Form.useForm();
	const { registrationSuccess, registeredEmail, isLoading } = useSelector((state) => state.auth);

	useEffect(() => {
		if(registrationSuccess && registeredEmail) {
			dispatch({type: RESET_REGISTRATION_SUCCESS})
			history.push('/auth/pin/verify');
		}
	}, [registrationSuccess])

	const onSubmitHandler = (values) => {
		const {email,  pin, phone, firstname, lastname} = values;
		setError('')
		if(!email) {
			setError('Please enter email to complete registeration');
			return;
		}

		if(!phone) {
			setError('Please enter phone number to complete registeration');
			return;
		}

		dispatch(REGISTER_USER({
			email: email,
			password: pin,
			phoneNumber: phone,
			firstName: firstname,
			lastName: lastname
		}));
		console.log(values)
		console.log(registerForm);
		console.log(values)
	};

	const countryCodeSelector = (
		<Form.Item name="prefix" noStyle>
			<Select style={{ width: 70 }}>
				<Option value="1">+1</Option>
				<Option value="91">+91</Option>
				<Option value="92">+92</Option>
			</Select>
		</Form.Item>
	);

	return (
		<div className="page-container auth-page">
				<Form className="auth-form"
						name="register-form"
						id="register-form"
						layout='vertical'
						form={registerForm}
						onFinish={onSubmitHandler}
						requiredMark={false}
				>
						<h3>Sign up</h3>
						<p>Please enter your data here</p>
						<Form.Item 
								name="email" 
								rules={[
										{
											type: 'email',
											message: 'The input is not valid Email!',
										},
								]}
						>
								<Input type="email" placeholder="Email" />
						</Form.Item>
						<Form.Item name="phone">
								<Input type="text" placeholder="Phone number (include +)" />
						</Form.Item>
						<Form.Item name="firstname">
								<Input type="text" placeholder="First name" />
						</Form.Item>
						<Form.Item name="lastname">
								<Input type="text" placeholder="Last name" />
						</Form.Item>
						<Form.Item name="organization">
								<Input type="text" placeholder="Organization" />
						</Form.Item>
						<Form.Item name="pin" hasFeedback rules={[{ required: true, message: '**Required Field' }]}>
								<Input type="password" placeholder="5-Digit Security PIN" />
						</Form.Item>
						<Form.Item 
								name="confirm-pin" 
								dependencies={['pin']}
								hasFeedback
								rules={[
										{
												required: true,
												message: 'Please confirm your pin!',
										},
										({ getFieldValue }) => ({
												validator(_, value) {
												if (!value || getFieldValue('pin') === value) {
														return Promise.resolve();
												}
												return Promise.reject(new Error('The two pins that you entered do not match!'));
												},
										})]}
								>
								<Input type="password" style={{ width: '100%' }} placeholder="Confirm PIN" />
						</Form.Item>
						{error && <Form.Item><p className="error">{ error }</p></Form.Item>}
						<Form.Item>
								<Button loading={isLoading} type="primary" htmlType="submit" onClick={() => setError('')}>Register</Button>
						</Form.Item>
						<Form.Item className="mb-0">
							<p className="option-text">Already have an account? <Link to="/auth/login">Login</Link></p>
							<p className="option-text">Didn't get verification code? <Link to="/auth/resend">Resend</Link></p>
								{/* <p className="option-text mb-0">Forgot Security PIN? <Link to="/auth/pin/reset">Reset PIN</Link></p> */}
						</Form.Item>
				</Form>
		</div>
	)
}

export default Register;
