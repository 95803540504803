import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Input, Button, Modal, Row, Col, DatePicker, TimePicker  } from 'antd';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import _ from "lodash";
import Icon from '@ant-design/icons';
import { PeopleModal } from '.';


function PageSettingsModal(props) {
    const { onClose } = props;

    const [isModalVisible, setIsModalVisible] = useState(true);
    const [peopleModalVisible, setPeopleModalVisible] = useState(false);
    
    const handleCancel = () => {
        setIsModalVisible(false);
        onClose && onClose();
    };

    const handleOk = () => {
        setIsModalVisible(false);
    }

    return (
        <Modal title="Page Settings" 
            visible={isModalVisible}
            onOk={handleOk} 
            onCancel={handleCancel} 
            width='450px'
            className="beam-modal"
            footer={[]}
            destroyOnClose={true}
        >
            <Row className="d-flex px-2">
                <Col><h5>Notification</h5></Col>
                <Col span={4}><Button className="beam-btn green-btn cover">Push</Button></Col>
                <Col span={4}><Button className="beam-btn">SMS</Button></Col>
                <Col span={4}><Button className="beam-btn">Email</Button></Col>
            </Row>
            <Row className="mt-3">
                <Col span={24} className="datetime-item">
                    <img src="/images/time.png" /><TimePicker placeholder="Notification Time" suffixIcon={<DownOutlined />} />
                </Col>
            </Row>
            <Row className="d-flex mt-3 px-2">
                <Col span={4}><h5>Archive</h5></Col>
                <Col span={9}><Button className="beam-btn green-btn cover w-full">Archive</Button></Col>
                <Col span={9}><Button className="beam-btn w-full">Snap</Button></Col>
            </Row>
            <Row className="mt-3">
                <Col span={24} className="datetime-item">
                    <img src="/images/time.png" /><TimePicker placeholder="Enter time in secs" suffixIcon={<DownOutlined />} />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={24}>
                    <div className="gps-report d-flex">
                        <div className="d-flex">
                            <img src="/images/bell.png" />
                            <div style={{ paddingLeft: 11}}>Ring<br /><h5>pingpong</h5></div>
                        </div>
                        <DownOutlined />
                    </div>
                </Col>
            </Row>
            <hr />
            <Row className="d-flex mt-3 px-2">
                <Col><h5>Tag</h5></Col>
                <Col><Button className="beam-btn green-btn cover w-full">Tag</Button></Col>
                <Col><Button className="beam-btn">Tag</Button></Col>
                <Col><Button className="beam-btn">Tag</Button></Col>
                <Col><Button className="beam-btn">Tag</Button></Col>
                <Col><Button className="beam-btn">Tag</Button></Col>
            </Row>
            <hr />
            <Row className="mt-3">
                <Input placeholder="Pin: Enter a 5 digit number" prefix={<img src="/images/lock.png" />}/>
            </Row>
            <Row className="mt-3 px-2">
                <Col><h5>Select Flag</h5></Col>
            </Row>
            <Row className="d-flex mt-3 px-3">
                {['green', 'blue', 'orange', 'yellow', 'gray'].map(item => 
                    <Col><Button className="beam-btn round-btn" key={item}><img src={`/images/flag-${item}.png`} /></Button></Col>
                )}
            </Row>
            <hr />
            <Row className="mt-3">
                <Input placeholder="Add a Thumbnail" prefix={<img src="/images/scene.png" />}/>
            </Row>
            <Row className="mt-3">
                <Input placeholder="Assign Projects" prefix={<SearchOutlined />}/>
            </Row>
            <Row className="mt-3">
                <Button className="beam-btn green-btn cover w-full" onClick={() => setPeopleModalVisible(true)}>Change Owner</Button>
            </Row>
            <Row className="action-grp mt-4">
                <Col span={12} className="pr-2">
                    <Button className="beam-btn orange-btn bordered w-full" onClick={handleCancel}>Reset</Button>
                </Col>
                <Col span={12} className="pl-2">
                    <Button className="beam-btn green-btn cover w-full" onClick={handleOk}>Save</Button>
                </Col>
            </Row>
            {peopleModalVisible && <PeopleModal onClose={() => setPeopleModalVisible(false)}/> }
        </Modal>
    )
}

export default PageSettingsModal;
