import FormItemLabel from "antd/lib/form/FormItemLabel";
import React from "react";

import participantPropType from "../../propTypes/participant";
import Track from "../Track/Track";

// import styles from "./Participant.module.scss";

const Participant = ({ participant, className, isRemote }) => (
  <div className={className ? `${className}` : ''}>
    {/* { isRemote && <label className="label-participant">{participant.identity}</label> } */}
    {Array.from(participant.tracks.values()).map(
      trackPublication =>
        trackPublication.track && (
          <Track
            isRemote={isRemote}
            key={trackPublication.track.name}
            track={trackPublication.track}
          />
        )
    )}
  </div>
);

Participant.propTypes = {
  participant: participantPropType.isRequired
};

export default Participant;
