import React, {useEffect, useState} from 'react';
import { ReactSVG } from 'react-svg';
import { SearchOutlined } from '@ant-design/icons';
import { Dropdown } from "antd";

import {
  CreateNewPageModal,
  UserStatusModal,
  CallLogsModal,
  CallBoxModal,
  ExecutiveModal,
  PeopleModal,
  AnalyticsSummaryModal,
  AnalyticsSummaryModalTemp,
  IoTAlertTableModal
} from "../../Modals";
import { PAGE_TYPES } from 'utils/PageTypes';
import AnalyticsVideoModal from 'components/Modals/AnalyticsVideoModal';
import {useSelector} from "react-redux";

function SideBar(props) {
  const {screens, setScreens, client} = props;
  const [isCreateNewPageModalVisible, setIsCreateNewPageModalVisible] = useState({visible: false, type: PAGE_TYPES.REGULAR});
  const [isUserStatusModalVisible, setIsUserStatusModalVisible] = useState(false);
  const [isCallLogsModalVisible, setIsCallLogsModalVisible] = useState(false);
  const [isCallBoxModalVisible, setIsCallBoxModalVisible] = useState(false);
  const [createBroadcasePageModalVisible, setCreateBroadcasePageModalVisible] = useState(false);
  const [analyticsVideoModalDisplayed, setAnalyticsVideoModal] = useState(false);
  const [executiveModalDisplayed, setExecutiveModalDisplayed] = useState(false);
  const [peopleModalDisplayed, setPeopleModalDisplayed] = useState(false);
  const [isAlertTableModalVisible, setIsAlertTableModalVisible] = useState(false);
  const [currentLocation, setCurrentLocation] = useState();
  const {page_group} = useSelector(state => state.dashboard);


  useEffect(() => {
      if(page_group)
        setIsCreateNewPageModalVisible({visible: true, type: PAGE_TYPES.GROUP});
  }, [page_group]);


    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                    console.log('set current location', position)
                    setCurrentLocation({latitude: position.coords.latitude, longitude: position.coords.longitude})
                }
            );
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    };

    useEffect(() => {
        getCurrentLocation()
    }, []);


    useEffect(() => {
        console.log('current location updated')
    }, [currentLocation]);

  const menu = (
    <ul className="user-dropdown">
      <li onClick={() => setScreens({...screens, ridePanel: true, cadType: 'Nine11', message: false})}>
          <span className="ml-2">First</span>
      </li>
      <li onClick={() => setScreens({...screens, ridePanel: true, cadType: 'RxPanel', message: false})}>
          <span className="ml-2">RX</span>
      </li>
      <li onClick={() => setScreens({...screens, ridePanel: true, cadType: 'RideDispatch', message: false})}>
          <span className="ml-2">Ride</span>
      </li>
      <li>
          <span className="ml-2">MedRide</span>
      </li>
      <li>
        <span className="ml-2">SafeHands</span>
      </li>
      <li onClick={() => setScreens({...screens, ridePanel: true, cadType: 'Logistics', message: false})}>
        <span className="ml-2">Logistics</span>
      </li>
      <li onClick={() => setScreens({...screens, ridePanel: true, cadType: 'Store', message: false})}>
        <span className="ml-2">Store</span>
      </li>
      <li onClick={() => setScreens({...screens, ridePanel: true, cadType: 'ContactTracing', message: false})}>
        <span className="ml-2">Contact Tracing</span>
      </li>
      <li>
        <span className="ml-2">Door</span>
      </li>
    </ul>
  );

  const callCenterMenu = (
    <ul className="user-dropdown">
      <li onClick={() => setIsCallBoxModalVisible(true)}>
          <span className="ml-2">Call</span>
      </li>
      <li onClick={() => setIsCallLogsModalVisible(true)}>
          <span className="ml-2">Logs</span>
      </li>
      <li onClick={() => setIsUserStatusModalVisible(true)}>
          <span className="ml-2">Status</span>
      </li>
    </ul>
  );

  return (
    <div className="sidebar">
      <ul>
        <li className="mb-5">
          <ReactSVG src="/images/plus-icon.svg" onClick={() => setIsCreateNewPageModalVisible({visible: true, type: PAGE_TYPES.REGULAR})}/>
          <CreateNewPageModal page_group= {page_group} currentLocation={currentLocation} client={client} isModalVisible={isCreateNewPageModalVisible} setIsModalVisible={setIsCreateNewPageModalVisible} />
        </li>
        <li><ReactSVG src="/images/broadcast-icon.svg" onClick={() => setIsCreateNewPageModalVisible({visible: true, type: PAGE_TYPES.BROADCAST})} /></li>
        <li>
          <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
            <ReactSVG src="/images/dispatcher-icon.svg" />
          </Dropdown>
        </li>
        <li><ReactSVG src="/images/emergency-icon.svg" onClick={() => setIsAlertTableModalVisible(true)} /></li>
        <li>
          <Dropdown overlay={callCenterMenu} placement="bottomRight" trigger={['click']}>
            <ReactSVG src="/images/car-icon.svg" />
          </Dropdown>
        </li>
        <li onClick={()=>{setExecutiveModalDisplayed(true)}}><ReactSVG src="/images/desktop-icon.svg" /></li>
        <li onClick={()=>{setPeopleModalDisplayed(true)}}><ReactSVG src="/images/community-icon.svg" /></li>
        <li onClick={()=>{setAnalyticsVideoModal(true)}}><ReactSVG src="/images/media-icon.svg" /></li>

      </ul>

      { isUserStatusModalVisible && <UserStatusModal isModalVisible={isUserStatusModalVisible} setIsModalVisible={setIsUserStatusModalVisible} />}
      { isCallLogsModalVisible && <CallLogsModal isModalVisible={isCallLogsModalVisible} setIsModalVisible={setIsCallLogsModalVisible} />}
      { isCallBoxModalVisible && <CallBoxModal isModalVisible={isCallBoxModalVisible} setIsModalVisible={setIsCallBoxModalVisible} />}
      {executiveModalDisplayed && <ExecutiveModal onClose={() => setExecutiveModalDisplayed(false)} /> }
      {/*{executiveModalDisplayed && <AnalyticsSummaryModalTemp onClose={() => setExecutiveModalDisplayed(false)} /> }*/}
      {analyticsVideoModalDisplayed && <AnalyticsVideoModal onClose={() => setAnalyticsVideoModal(false)} /> }
      {peopleModalDisplayed && <AnalyticsSummaryModal onClose={() => setPeopleModalDisplayed(false)} /> }
      {isAlertTableModalVisible && <IoTAlertTableModal onClose={() => setIsAlertTableModalVisible(false)} /> }

      </div>
  )
}

export default SideBar;
